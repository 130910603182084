import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Trash } from "../../Common/Icons/trash";
import "./style.scss";
import "../../../styles/App.scss";
import {
  ACCEPTED,
  ADMIN,
  ARCHIVED,
  DECLINED,
  PENDING,
  PERCENT_OF_LOADING,
  DOCTOR,
} from "../../../constants";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../Typography/Typography";
import { PopupContainer } from "../Popup/PopupContainer";
import { Textarea } from "../TextArea/Textarea";
import { ArchiveFiles } from "../Icons";

export const EventsDocument = ({
  valueLoading,
  onDelete,
  file,
  flag,
  updateDataFile,
}) => {
  const [value, setValue] = useState(PERCENT_OF_LOADING.state0);
  const [showPopup, setShowPopup] = useState(false);
  const [textArea, setTextarea] = useState("");

  useEffect(() => {
    if (value !== 0) {
      const time = setTimeout(() => {
        if (value !== PERCENT_OF_LOADING.state95) setValue(value + 5);
      }, 250);
      return () => {
        clearTimeout(time);
      };
    }
  }, [value]);

  useEffect(() => {
    if (valueLoading === PERCENT_OF_LOADING.state0) {
      setValue(5);
    } else {
      setValue(0);
    }
  }, [valueLoading]);

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);
  const setValueTextArea = (e) => setTextarea(e.target.value);
  const sendDeclineStatus = () => {
    updateDataFile({
      accepted_status: DECLINED,
      comment: textArea ? textArea : "",
    });
    setShowPopup(false);
    setTextarea("");
  };

  const loading = valueLoading !== PERCENT_OF_LOADING.state100;
  const showDeclined =
    flag === ADMIN && file.statusFile !== DECLINED && !loading && file.uuid;
  const showAccepted =
    flag === ADMIN && file.statusFile !== ACCEPTED && !loading && file.uuid;
  const showTrash =
    !loading &&
    (file.statusFile === PENDING ||
      (flag === DOCTOR && file.statusFile === DECLINED));
  const showArchive = !loading && file.statusFile !== PENDING && flag === ADMIN;

  return (
    <>
      <div>
        <div className="circular_btn-container">
          {showDeclined && (
            <button
              onClick={openPopup}
              className="no-default-style-button circular_btn-container_btn"
            >
              <Typography
                text="decline"
                uppercase
                variant="span"
                color={colors.redDisabled}
              />
            </button>
          )}
          {showAccepted && (
            <button
              className="no-default-style-button circular_btn-container_btn"
              onClick={() => updateDataFile({ accepted_status: ACCEPTED })}
            >
              <Typography
                text="accept"
                uppercase
                variant="span"
                color={colors.navy}
              />
            </button>
          )}
          {showTrash && <Trash className="trash-custom" onClick={onDelete} />}
          {showArchive && (
            <ArchiveFiles
              className="archive-custom"
              onClick={() => updateDataFile({ accepted_status: ARCHIVED })}
            />
          )}
        </div>
        {loading && (
          <div style={{ width: 50 }}>
            <CircularProgressbar value={value} text={`${value}%`} />
          </div>
        )}
      </div>

      <PopupContainer
        textPrimary="Send"
        textDefault="Cancel"
        open={showPopup}
        close={closePopup}
        title="Please fill in the reason"
        actionPrimaryButton={sendDeclineStatus}
        actionDefaultButton={closePopup}
      >
        <Textarea
          placeholder={"Fill in the reason"}
          value={textArea}
          onChange={setValueTextArea}
          className="step-symptoms-list_wrapper-other-symptoms_textarea"
        />
      </PopupContainer>
    </>
  );
};
