import { getDateFromISO } from "../../utils/date";

export const processShiftCalendar = (items) => {
  return items.map((i) => ({
    id: i.uuid,
    data: {
      ...i,
      frame_end: i.end_date,
      frame_start: i.start_date,
    },
    start: getDateFromISO(`${i.start_date}T${i.start_time}Z`),
    end: getDateFromISO(`${i.end_date}T${i.end_time}Z`),
  }));
};
