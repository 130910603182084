import TwilioChat from "twilio-chat";
import { fetchWrapper } from "../../helpers/helpers";
import { setTwilioClient } from "./slice";

const getTokenTwilio = () => (dispatch) =>
  fetchWrapper({
    url: `twilio/token/`,
  })
    .then((resp) => {
      TwilioChat.create(resp.twilio_token).then((client) => {
        if (client) {
          dispatch(setTwilioClient(client));
          client.on("tokenAboutToExpire", async () => {
            fetchWrapper({
              url: `twilio/token/`,
            }).then((resp) => {
              client.updateToken(resp.twilio_token);
            });
          });
        }
      });
    })
    .catch((resp) => {
      return resp;
    });

const getChatTwilioSid = ({ request }) => {
  return fetchWrapper({
    url: `twilio/channel/${request}/`,
  });
};

export { getTokenTwilio, getChatTwilioSid };
