import React from "react";
import { PatientSidebar } from "../../Common/PatientSidebar";
import { requestStatuses } from "../../../constants";
import { groupPatientsByRelation } from "../../../helpers/helpers";

export const PatientsList = (props) => {
  const hideExamination =
    props.data.status?.toLowerCase() !== requestStatuses.inProgress;
  const hideSignature = !(
    props.data.status?.toLowerCase() === requestStatuses.inProgress ||
    props.data.status?.toLowerCase() === requestStatuses.requestCompleted ||
    props.data.status?.toLowerCase() === requestStatuses.visitCompete
  );
  return (
    <>
      {groupPatientsByRelation(props.data?.patients, true)?.map((i, idx) => {
        const label = i.label === "null" ? "Requester" : i.label;
        return (
          <>
            <div className="details-request_body_relation_tag">{label}</div>
            {i.items.map((j) => {
              return (
                <PatientSidebar
                  {...props}
                  data={j}
                  detailsRequest={props.data}
                  key={idx}
                  hideExamination={hideExamination}
                  hideAddButton
                  hideSignature={!j.patient_signature_path || hideSignature}
                  hideNote={!j.doctor_comment}
                />
              );
            })}
          </>
        );
      })}
    </>
  );
};
