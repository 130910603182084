import React from "react";
import "./style.scss";
import cn from "classnames";
import { Close } from "@material-ui/icons";
import { IconButton as MUIconButton } from "@material-ui/core";
import { COLORS_ICON_BTN, SIZES_ICON_BUTTON } from "./constants";
export const IconButton = ({ onClick, children, className, size, color }) => {
  return (
    <MUIconButton
      onClick={onClick}
      className={cn(
        "icon-button",
        {
          "icon-button_big": SIZES_ICON_BUTTON.BIG === size,
          "icon-button_small": SIZES_ICON_BUTTON.SMALL === size,
          "icon-button_medium": SIZES_ICON_BUTTON.MEDIUM === size || !size,

          "icon-button_default": COLORS_ICON_BTN.DEFAULT === color || !color,
          "icon-button_primary": COLORS_ICON_BTN.PRIMARY === color,
          "icon-button_secondary": COLORS_ICON_BTN.SECONDARY === color,
        },
        className,
      )}
    >
      {children || <Close />}
    </MUIconButton>
  );
};
