export const excuse = {
  textExcuse1: "To Whom it May Concern:",
  textExcuse2:
    "was evaluated by a physician through Uplin's Doctor House Calls Service on",
  textExcuse3: "and may return to work / school on",
  textExcuse4: "Please excuse",
  textExcuse5: "for",
  textExcuse6: "day (s).",
  textExcuse7: "Sincerely,",
};

export const CANCEL_REQUEST = {
  TITLE: "Do you really want to cancel the request?",
  SUB_TITLE: "Please, put the reason of the cancellation.",
};

export const ACTIVATE_REQUEST = {
  TITLE: "Do you really want to activate the request?",
  SUB_TITLE: "Please, confirm the change.",
};
