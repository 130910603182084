import { createSlice } from "@reduxjs/toolkit";
import { requestsTypesFields } from "./constants";

const initialState = {
  data: [],
};

const serviceRequestTypesSlice = createSlice({
  name: "serviceRequestTypesSlice",
  initialState,
  reducers: {
    setServiceRequestTypes(state, { payload: { data, isAdmin } }) {
      state.data = data.map((i) => {
        let item = {
          ...i,
          ...requestsTypesFields[i.name],
          name: i.name.replace(/_/g, "-"),
        };
        return {
          ...item,
          viewLabel: item.viewLabel(isAdmin),
        };
      });
    },
    clearServiceRequestTypesSlice: (state) => {
      state.data = [];
    },
  },
});

export const { setServiceRequestTypes, clearServiceRequestTypesSlice } =
  serviceRequestTypesSlice.actions;
export const serviceRequestTypesReducer = serviceRequestTypesSlice.reducer;
