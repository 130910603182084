import { fetchWrapper } from "../../helpers/helpers";
import {
  CLEAR_ALL_DATA_DOCTOR,
  CLEAR_DATA_AFTER_DELETE_VISIT_NOTE,
  SET_ADDITIONAL_INFO,
  SET_ATTACHMENT_PMH,
  SET_ATTACHMENTS,
  SET_CALENDAR_EVENTS,
  SET_CPT_CODE,
  SET_DASHBOARD_DOCTOR_DATA,
  SET_DATA_COVID,
  SET_DATA_EXCUSE,
  SET_DATA_TEMPLATE,
  SET_DB_DATA,
  SET_DEFAULT_VALUE_REQUEST,
  SET_DEFAULT_VALUE_VISIT_NOTES,
  SET_DISCHARGE_DATA,
  SET_FOLLOW_UPS,
  SET_FSH_OPTIONS,
  SET_ICD_LIST,
  SET_LIST_REQUEST_PATIENT,
  SET_PCP_DOCTORS,
  SET_PMH_INITIAL_INFO,
  SET_RECEIPT_DATA,
  SET_SIGNATURE,
  SET_STATUS_ARRIVE,
  SET_TEMPLATES,
  SET_TYPES_DEGREE,
  SET_TYPES_DOCTOR,
  UPDATE_REQYEST_STATE_NOTE_DOCTOR,
  UPDATE_STSTUS_NOTES,
} from "./actionTypes";

import {
  converterDataField,
  convertResponseTime,
  convertResponseTimeTable,
  getFileName,
  processBodyTemplate,
  setFormatFE,
} from "../../utils/common";
import {
  getNotesCompletionDashboard,
  getOrderResponseTimeDashboard,
  getPatientSatisfactionDashboard,
  getStatisticDoctorDashboard,
  getTotalVisitsDashboard,
} from "../actions";
import moment from "moment";
import history from "../../helpers/history";
import { setTimezoneEvents } from "../../utils/date";
import { colors } from "../../assets/colors/colors";

const setDBData = (payload) => ({
  type: SET_DB_DATA,
  payload,
});
const cleatDataVisitNoteAfterDelete = (payload) => ({
  type: CLEAR_DATA_AFTER_DELETE_VISIT_NOTE,
  payload,
});
const setAdditionalInfo = (payload) => ({
  type: SET_ADDITIONAL_INFO,
  payload,
});
const setSignature = (payload) => ({
  type: SET_SIGNATURE,
  payload,
});
const setDefaultValueVisitNotes = () => ({
  type: SET_DEFAULT_VALUE_VISIT_NOTES,
});
const setDefaultValueRequest = () => ({
  type: SET_DEFAULT_VALUE_REQUEST,
});
const setDataTemplate = (payload) => ({
  type: SET_DATA_TEMPLATE,
  payload,
});
const setDataExcuse = (payload) => ({
  type: SET_DATA_EXCUSE,
  payload,
});
const setDataCovid = (payload) => ({
  type: SET_DATA_COVID,
  payload,
});
const setTemplates = (payload) => ({
  type: SET_TEMPLATES,
  payload,
});
const setFollowUps = (payload) => ({
  type: SET_FOLLOW_UPS,
  payload,
});
const setAttachments = (payload) => ({
  type: SET_ATTACHMENTS,
  payload,
});

const setPCPDoctors = (payload) => ({
  type: SET_PCP_DOCTORS,
  payload,
});

const updateStatusNotes = (payload) => ({
  type: UPDATE_STSTUS_NOTES,
  payload,
});
const setTypesDoctor = (payload) => ({
  type: SET_TYPES_DOCTOR,
  payload,
});
const setTypesDegree = (payload) => ({
  type: SET_TYPES_DEGREE,
  payload,
});
const setDashboardDataDoctor = (payload) => ({
  type: SET_DASHBOARD_DOCTOR_DATA,
  payload,
});

const clearDataDoctor = () => ({
  type: CLEAR_ALL_DATA_DOCTOR,
});

const setEvents = (payload) => ({ type: SET_CALENDAR_EVENTS, payload });

const setArrive = (payload) => ({
  type: SET_STATUS_ARRIVE,
  payload,
});
export const setLastRequestsOfPatient = (payload) => ({
  type: SET_LIST_REQUEST_PATIENT,
  payload,
});

const setPMHinitialInfo = (payload) => ({
  type: SET_PMH_INITIAL_INFO,
  payload,
});

const setAttachmentPMH = (payload) => ({
  type: SET_ATTACHMENT_PMH,
  payload,
});

const setFSHoptions = (payload) => ({
  type: SET_FSH_OPTIONS,
  payload,
});

const setCPTcode = (payload) => ({
  type: SET_CPT_CODE,
  payload,
});

const setDischarge = (payload) => ({
  type: SET_DISCHARGE_DATA,
  payload,
});

const setICDaction = (payload) => ({
  type: SET_ICD_LIST,
  payload,
});

const setReceiptData = (payload) => ({
  type: SET_RECEIPT_DATA,
  payload,
});

export const updateRequestOnListDoctor = (payload) => ({
  type: UPDATE_REQYEST_STATE_NOTE_DOCTOR,
  payload,
});

const getLastRequestsOfPatient = (patientUuid) => (dispatch) => {
  fetchWrapper({
    url: "electronic-medical-record/service-requests/",
    body: {
      patients: patientUuid,
      limit: 500,
      application_time_after: "2010-10-10",
      application_time_before: "2040-10-10",
    },
  }).then((resp) => {
    resp?.results.length && dispatch(setLastRequestsOfPatient(resp.results));
  });
};

const getFileUpload = async (body) => {
  const resp = await fetchWrapper(
    { method: "POST", url: "doctors/file/", body },
    true,
  );
  return converterDataField(resp, true);
};

const deleteFileFromData = (uuid) =>
  fetchWrapper({
    method: "DELETE",
    url: `doctors/file/${uuid}/`,
  }).then((resp) => resp);

const setDoctorCreate = (body) =>
  fetchWrapper({ method: "POST", url: "admin/doctors/create/", body }).then(
    (resp) => {
      if (resp.uuid) return resp;
      else throw "";
    },
  );

const updateDoctor = (uuid, body, isAdmin) => () =>
  fetchWrapper({
    method: "PUT",
    url: `${isAdmin ? "admin/" : ""}doctors/${uuid}/`,
    body,
  }).then((resp) => {
    if (!resp.uuid) {
      throw resp;
    }
  });

const getCalendarEvents =
  (body, useLoading = true) =>
  (dispatch) =>
    fetchWrapper({
      useLoading: useLoading,
      url: "service-requests/calendar/events/",
      body,
    }).then((resp) => {
      Array.isArray(resp) && dispatch(setEvents(setTimezoneEvents(resp)));
    });

const getAdditionalServices = () =>
  fetchWrapper({
    url: "service-requests/additional-services/",
  }).then((resp) => resp);

const setStatusAndArrive = (status, arrive) => (dispatch) => {
  dispatch(
    setArrive({
      status,
      arrive,
    }),
  );
};

const sendServiceRequest = (eventUuid, services) => {
  const data = services.map((item) =>
    fetchWrapper({
      url: "service-requests/additional-services/assign/",
      method: "POST",
      body: { service_request: eventUuid, additional_service: item.uuid },
    }),
  );
  return Promise.all(data).then((resp) => resp);
};

const assignDoctorToAppointment = (body) =>
  fetchWrapper({
    url: `service-requests/reassign/`,
    method: "POST",
    body: body,
  });

const clearAllDataDoctor = () => (dispatch) => {
  dispatch(clearDataDoctor());
};
const getDoctorDataDashboard = (date) => (dispatch) => {
  const getNameOfMonth = (order) => moment(order, "M").format("MMMM");
  return Promise.all([
    getNotesCompletionDashboard(date),
    getTotalVisitsDashboard(date),
    getPatientSatisfactionDashboard(date),
    getOrderResponseTimeDashboard(date),
    getStatisticDoctorDashboard(date),
  ]).then((resp) => {
    let from = +date.date_from.substr(5, 2);
    let to = +date.date_to.substr(5, 2);
    getNameOfMonth(from);
    const label = [
      from === to
        ? getNameOfMonth(from)
        : `${getNameOfMonth(from)} - ${getNameOfMonth(to)}`,
    ];
    const notes =
      resp[0].notes_completed_on_shift !== undefined
        ? {
            labels: label,
            datasets: [
              {
                label: "  Notes Completed on shift",
                data: [resp[0].notes_completed_on_shift],
                backgroundColor: colors.elfName,
                borderColor: colors.elfName,
                borderWidth: 0,
              },
              {
                label: "  Notes Completed < 24 hours",
                data: [resp[0].notes_completed_before_24_hours],
                backgroundColor: colors.navy,
                borderColor: colors.navy,
                borderWidth: 2,
              },
              {
                label: "  Notes Completed > 24 hours",
                data: [resp[0].notes_completed_after_24_hours],
                backgroundColor: colors.peach,
                borderColor: colors.peach,
                borderWidth: 2,
              },
              {
                label: "  Notes Not Completed",
                data: [resp[0].notes_completed_not_completed],
                backgroundColor: colors.redError,
                borderColor: colors.redError,
                borderWidth: 2,
              },
            ],
          }
        : {};
    const data = {
      notesChar: notes,
      visits: resp[1].total_visits ? resp[1] : {},
      patientSatisfaction: resp[2].patient_satisfaction_by_doctor
        ? resp[2]
        : {},
      responseTime: convertResponseTime(resp[3]),
    };
    dispatch(setDashboardDataDoctor(data));
    return Array.isArray(resp[4]) ? convertResponseTimeTable(resp[4]) : [];
  });
};

const getPCPDoctor = (uuid) => (dispatch) =>
  fetchWrapper({
    url: `pcp/list/${uuid}/`,
  }).then((resp) => {
    dispatch(setPCPDoctors(resp.map((i) => setFormatFE(i))));
  });

const getTypesDoctor = () => (dispatch) =>
  fetchWrapper({
    url: `pcp/provider-types/`,
  }).then((resp) => {
    dispatch(
      setTypesDoctor(resp.map((i) => ({ label: i.value, value: i.value }))),
    );
  });

const getTypesDegree = () => (dispatch) =>
  fetchWrapper({
    url: `pcp/degree-types/`,
  }).then((resp) => {
    dispatch(
      setTypesDegree(resp.map((i) => ({ label: i.value, value: i.value }))),
    );
  });

const createUpdatePCPDoctor = (data, patient, uuid) => (dispatch) =>
  fetchWrapper({
    url: `pcp/${uuid ? uuid + "/" : ""}`,
    method: uuid ? "PUT" : "POST",
    body: data,
  }).then((resp) => {
    resp.uuid && dispatch(getPCPDoctor(patient));
    return resp;
  });

const deletePCPDoctor = (uuid, patient) => (dispatch) =>
  fetchWrapper({
    url: `pcp/${uuid}/`,
    method: "DELETE",
  }).then(() => {
    dispatch(getPCPDoctor(patient));
  });

const getFollowUps = (uuid) => (dispatch) => {
  const arr = [];
  return fetchWrapper({
    url: `electronic-medical-record/follow-up/${uuid}/`,
  }).then((resp) => {
    Promise.all(resp.map((i) => fetch(i.created_by.signature)))
      .then((respSignatureBlob) =>
        Promise.all(respSignatureBlob.map((i) => i.blob())),
      )
      .then((respSignature) => {
        respSignature.map((i, idx) => {
          const reader = new FileReader();
          reader.readAsDataURL(i);
          reader.onloadend = function () {
            arr[idx] = reader.result;
            if (resp.length === arr.length) {
              const data = resp
                .map((i) => setFormatFE(i))
                .map((i, idx) => ({
                  ...i,
                  createdBy: { ...i.createdBy, signature: arr[idx] },
                }));
              dispatch(setFollowUps(data));
            }
          };
        });
      });
  });
};

const createUpdateFollowUp = (data, request, uuid) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/follow-up/${request}/${
      uuid ? uuid + "/" : ""
    }`,
    method: uuid ? "PUT" : "POST",
    body: data,
  }).then((resp) => {
    resp.uuid && dispatch(getFollowUps(request));
    return resp;
  });

const deleteFollowUp = (uuid, request) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/follow-up/${request}/${uuid}/`,
    method: "DELETE",
  }).then((resp) => {
    !resp && dispatch(getFollowUps(request));
    return !resp;
  });

const getAttachments = (uuid) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/service-request-attachments/${uuid}/`,
  }).then((resp) => {
    dispatch(
      setAttachments(
        resp.map((i) => ({
          ...i,
          format: getFileName(i.file, 1),
        })),
      ),
    );
  });

const deleteAttachments = (uuid, request) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/service-request-attachments/${request}/${uuid}/`,
    method: "DELETE",
  }).then((resp) => {
    if (!resp) dispatch(getAttachments(request));
    return !resp;
  });

const createUpdateAttachments =
  (data, request, uuid, notStringify) => (dispatch) =>
    fetchWrapper(
      {
        url: `electronic-medical-record/service-request-attachments/${request}/${
          uuid ? uuid + "/" : ""
        }`,
        method: uuid ? "PATCH" : "POST",
        body: data,
      },
      notStringify,
    ).then((resp) => {
      resp.uuid && dispatch(getAttachments(request));
      return resp;
    });

const getAdditionalInfo = (request) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/additional-info/${request}/`,
    method: "GET",
  }).then((resp) => {
    if (Array.isArray(resp)) {
      dispatch(setAdditionalInfo(resp));
    } else {
      dispatch(setAdditionalInfo([]));
    }
  });

const updateAdditionalInfo = (newText, oldData, request) => (dispatch) =>
  fetchWrapper({
    url: oldData
      ? `electronic-medical-record/additional-info-instance/${oldData.uuid}/`
      : `electronic-medical-record/additional-info/${request}/`,
    method: oldData ? "PATCH" : "POST",
    body: { text: newText },
  }).then((resp) => {
    resp.uuid && dispatch(getAdditionalInfo(request));
    return resp;
  });
const deleteAdditionalInfo = (uuid, request) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/additional-info-instance/${uuid}/`,
    method: "DELETE",
  }).then((resp) => {
    if (!resp) dispatch(getAdditionalInfo(request));
    else throw "additional info did not remove";
  });

const getUpdatePMHinfo =
  (uuid, data = null, updateStore = false, extraParam) =>
  (dispatch) => {
    const urlStr = `electronic-medical-record/past-medical-history/${uuid}/${extraParam ? `?visit_note_uuid=${extraParam}` : ""}`;
    return fetchWrapper({
      url: urlStr,
      method: data ? "PATCH" : "GET",
      body: data,
    }).then((resp) => {
      let obj = {};
      if (resp.status) return;
      for (let key in resp) {
        if (
          key === "updated_at" ||
          key === "updated_by" ||
          key === "appointed_medications" ||
          key === "appointed_allergies" ||
          key === "comment"
        )
          obj[key] = resp[key];
        else {
          obj[key] = (resp[key] || []).map((i) => {
            if (typeof i === "string") {
              return { value: i, label: i };
            } else if (key === "medications") {
              return {
                label: i.name,
                value: i.name,
                status: i.is_active ? "active" : "passive",
                doze: i.quantity,
                quantity: i.quantity,
                name: i.name,
                expire: i.expiration_date,
                created: i.prescription_date,
              };
            } else if ("family_social_history" === key)
              return { value: i.name, label: i.name };
            else return { value: i.code, label: i.name, code: i.code, ...i };
          });
        }
      }
      obj = setFormatFE(obj);

      if (updateStore) dispatch(setPMHinitialInfo(obj));
      return obj;
    });
  };

const getAttachmentsPMH = (uuid) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/past-medical-history-attachments/${uuid}/`,
  }).then((resp) => {
    dispatch(
      setAttachmentPMH(
        resp.map((i) => ({
          ...i,
          size: 50343,
          format: getFileName(i.file, 1),
        })),
      ),
    );
  });

const deleteAttachmentsPMH = (patientUuid, attachmentUuid) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/past-medical-history-attachments/${patientUuid}/${attachmentUuid}/`,
    method: "DELETE",
  }).then((resp) => {
    if (!resp) dispatch(getAttachmentsPMH(patientUuid));
    return !resp;
  });

const createUpdateAttachmentsPMH =
  (data, patientUuid, uuid, notStringify) => (dispatch) =>
    fetchWrapper(
      {
        url: `electronic-medical-record/past-medical-history-attachments/${patientUuid}/${uuid ? uuid + "/" : ""}`,
        method: uuid ? "PATCH" : "POST",
        body: data,
      },
      notStringify,
    ).then((resp) => {
      resp.uuid && dispatch(getAttachmentsPMH(patientUuid));
      return resp;
    });

const getListTemplatesCommon = () => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/common-emr-templates/`,
  }).then((resp) => {
    dispatch(setTemplates(resp.map((i) => ({ label: i.name, value: i.uuid }))));
  });

const getTemplateCommon = (templateUuid) =>
  fetchWrapper({
    url: `electronic-medical-record/common-emr-templates/${templateUuid}/`,
  }).then((resp) => {
    if (resp.uuid) return resp.body.body;
    else throw "Template did not load";
  });

const getTemplate = (uuid) =>
  fetchWrapper({
    url: `electronic-medical-record/emr-templates/${uuid}/`,
  }).then((resp) => {
    if (resp.body) return resp.body;
    else throw "Template did not load";
  });

const getLinksFiles = () =>
  fetchWrapper({
    url: `doctors/forms/`,
  });

const getDataTemplateOfRequest = (uuid, uuidPatient) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/emr-templates/${uuid}/`,
  }).then((resp) => {
    dispatch(getUpdatePMHinfo(uuidPatient, null, false, resp.uuid)).then(
      (pmhInfo) => {
        dispatch(getUpdatePMHinfo(uuidPatient, null, false)).then(
          (pmhInfoAll) => {
            if (resp.uuid) {
              getMedicationFromVeradigm(uuid)
                .then((data) => {
                  if (Array.isArray(data)) {
                    dispatch(
                      setDataTemplate({
                        ...resp,
                        body: processBodyTemplate(
                          resp.body,
                          pmhInfo,
                          resp.status,
                          data,
                        ),
                      }),
                    );
                  }
                })
                .finally(() => {
                  dispatch(setPMHinitialInfo(pmhInfoAll));
                });
            } else {
              dispatch(setDataTemplate(null));
              dispatch(setPMHinitialInfo(pmhInfo));
            }
          },
        );
      },
    );

    if (resp.uuid) {
      getDischargeReceipt(dispatch, resp, uuid);
      return resp;
    } else {
      dispatch(clearTemplateData());
    }
  });

const getDischargeReceipt = (
  dispatch,
  resp,
  uuid,
  requiredDataFromTemplate,
  dataTemplate,
  pathRedirect,
  noNavigate,
) => {
  if (resp.status === "final" || resp.status === "sent") {
    Promise.resolve()
      .then(async () => {
        if (requiredDataFromTemplate) {
          const result = await dispatch(
            updateRequiredFieldTemplate(resp.uuid, requiredDataFromTemplate),
          );
          if (!result.emr_note) {
            dispatch(clearTemplateData());
            dispatch(setDataTemplate({ ...dataTemplate, status: "draft" }));
            throw "not valid required fields";
          }
        }
      })
      .then(() => {
        dispatch(getdischargeData(uuid));
        dispatch(getReceipt(uuid));
        if (!noNavigate && pathRedirect) history.push(pathRedirect);
      });
  } else {
    if (!noNavigate && pathRedirect) history.push(pathRedirect);
    dispatch(clearTemplateData());
  }
};

const createUpdateTemplate =
  (
    body,
    uuid,
    create,
    requiredDataFromTemplate,
    newBody,
    pathRedirect,
    noNavigate,
  ) =>
  (dispatch) =>
    fetchWrapper({
      url: `electronic-medical-record/emr-templates/${create ? uuid + "/" : ""}`,
      method: create ? "PATCH" : "POST",
      body: body,
    }).then((resp) => {
      if (resp.uuid) {
        const data = { ...resp, body: newBody };
        dispatch(setDataTemplate(data));
        getDischargeReceipt(
          dispatch,
          resp,
          uuid,
          requiredDataFromTemplate,
          data,
          pathRedirect,
          noNavigate,
        );
      } else throw "Template did not send";
    });

const clearTemplateData = () => (dispatch) => {
  dispatch(setDefaultValueVisitNotes());
};

const clearRequestData = () => (dispatch) => {
  dispatch(setDefaultValueRequest());
  dispatch(setDefaultValueVisitNotes());
};

const getExcuse = (uuidRequest) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/excuse/${uuidRequest}/`,
  }).then((resp) => {
    if (resp.uuid) dispatch(setDataExcuse(resp));
    else {
      dispatch(setDataExcuse(null));
    }
  });

const getCovidDoc = (uuidRequest) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/covid-test-result/${uuidRequest}/`,
  }).then((resp) => {
    if (resp.uuid) dispatch(setDataCovid(resp));
    else {
      dispatch(setDataCovid(null));
    }
  });

const createUpdateExcuse = (uuidRequest, create, body) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/excuse/${uuidRequest}/`,
    method: create ? "POST" : "PATCH",
    body: body,
  }).then((resp) => {
    if (resp.uuid) dispatch(setDataExcuse(resp));
    else throw "Excuse did not update";
  });

const createUpdateCovid = (uuidRequest, create, body) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/covid-test-result/${uuidRequest}/`,
    method: create ? "POST" : "PATCH",
    body: body,
  }).then((resp) => {
    if (resp.uuid) dispatch(setDataCovid(resp));
    else throw "Covid did not update";
  });

const getFamilySocialHistoryOptions = () => (dispatch) =>
  fetchWrapper({
    url: "electronic-medical-record/past-medical-history/family-social-history-choices/",
  }).then((resp) => {
    dispatch(
      setFSHoptions(resp.map((el) => ({ value: el.name, label: el.name }))),
    );
    return resp;
  });

const getCPTcode = () => (dispatch) =>
  fetchWrapper({
    url: "electronic-medical-record/cpt-codes/",
  }).then((resp) => {
    const arr = resp.map((el) => ({
      label: el.code,
      name: el.name,
      price: el.price,
      value: el.uuid,
      is_free_type: el.is_free_type,
    }));
    dispatch(setCPTcode(arr));
    return resp;
  });

const updateRequiredFieldTemplate = (uuidEmrNote, body) => () =>
  fetchWrapper({
    method: "POST",
    body: body,
    url: `electronic-medical-record/save-template-data/${uuidEmrNote}/`,
  });

const getDbDataTemplate = (uuidRequest) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/template-data/${uuidRequest}/`,
  }).then((resp) => {
    dispatch(setDBData(resp));
  });

const getdischargeData = (uuid) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/discharge-data/${uuid}/`,
  }).then((resp) => {
    dispatch(setDischarge(resp));
  });

const sendSelectedAttachments =
  (body, type, bool = false) =>
  () => {
    return fetchWrapper(
      {
        url: `electronic-medical-record/send-${type === "attachment" ? "attachments" : "follow-ups"}/`,
        method: "POST",
        body,
      },
      bool,
    ).then((resp) => {
      return resp;
    });
  };
const sendSelectedNotes = (body, uuidRequest, selectedNote) => (dispatch) => {
  return fetchWrapper(
    {
      url: `electronic-medical-record/send-emr-docs/${uuidRequest}/`,
      method: "POST",
      body,
    },
    true,
  ).then((resp) => {
    if (
      resp.hasOwnProperty("discharge") ||
      resp.hasOwnProperty("emr_note") ||
      resp.hasOwnProperty("receipt") ||
      resp.hasOwnProperty("excuse") ||
      resp.hasOwnProperty("covid_test_result")
    ) {
      dispatch(updateStatusNotes(selectedNote));
      return resp;
    } else throw "Files did not send";
  });
};

const getICDList = () => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/icd-10-list/`,
  }).then((resp) => {
    const data = resp.map((i) => ({
      value: i.code,
      label: i.name,
    }));
    dispatch(setICDaction(data));
    return resp;
  });

const getReceipt = (uuid, body) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/${body ? "update-receipt-data" : "receipt-data"}/${uuid}/`,
    method: body ? "PUT" : "GET",
    body: body,
  }).then((resp) => {
    body ? dispatch(getReceipt(uuid)) : dispatch(setReceiptData(resp));
    return resp;
  });

const deleteVisitNote = (uuidRequest) => (dispatch) =>
  fetchWrapper({
    url: `electronic-medical-record/emr-templates/${uuidRequest}/`,
    method: "DELETE",
  }).then(() => {
    dispatch(cleatDataVisitNoteAfterDelete());
  });

export const getSetSignatureOfPatient = (uuidRequest, data) => {
  return fetchWrapper(
    {
      url: `doctors/share-documents-signature/${uuidRequest}/`,
      method: data ? "PUT" : "GET",
      body: data,
    },
    true,
  );
};

const saveSignature =
  (img, url, updateStore = true) =>
  (dispatch) => {
    updateStore && dispatch(setSignature(null));
    return fetchWrapper(
      {
        url: `doctors/${url}/signature/`,
        method: img ? "PUT" : "GET",
        body: img,
      },
      true,
    ).then((resp) => {
      if (!updateStore) return resp.signature;
      if (resp.signature) {
        fetch(resp.signature_download_link)
          .then((resp) => {
            return resp.blob();
          })
          .then((respNew) => {
            const reader = new FileReader();
            reader.readAsDataURL(respNew);
            reader.onloadend = function () {
              dispatch(
                setSignature({
                  preview: resp.signature,
                  download: reader.result,
                }),
              );
            };
          })
          .catch(() => {
            dispatch(setSignature({ preview: resp.signature, download: null }));
          });
      }
    });
  };

export const getMedicationFromVeradigm = (requestUuid) => {
  return fetchWrapper({
    url: `electronic-medical-record/current-medications/${requestUuid}/`,
  });
};

export const syncPmhFromMedicationVeradigm = (
  requestUuid,
  isSkippedRedirect403,
) => {
  return fetchWrapper({
    url: `veradigm/sync-medications/${requestUuid}/`,
    isSkippedRedirect403,
  });
};

export {
  getCalendarEvents,
  getFileUpload,
  setDoctorCreate,
  updateDoctor,
  deleteFileFromData,
  getAdditionalServices,
  setStatusAndArrive,
  sendServiceRequest,
  getDoctorDataDashboard,
  assignDoctorToAppointment,
  clearAllDataDoctor,
  getPCPDoctor,
  getTypesDoctor,
  createUpdatePCPDoctor,
  deletePCPDoctor,
  getFollowUps,
  createUpdateFollowUp,
  deleteFollowUp,
  getAttachments,
  createUpdateAttachments,
  getTypesDegree,
  getLastRequestsOfPatient,
  deleteAttachments,
  getAdditionalInfo,
  updateAdditionalInfo,
  getUpdatePMHinfo,
  getListTemplatesCommon,
  getTemplate,
  clearTemplateData,
  getDataTemplateOfRequest,
  getAttachmentsPMH,
  deleteAttachmentsPMH,
  createUpdateAttachmentsPMH,
  createUpdateTemplate,
  getTemplateCommon,
  createUpdateExcuse,
  getExcuse,
  getFamilySocialHistoryOptions,
  getCPTcode,
  updateRequiredFieldTemplate,
  getDbDataTemplate,
  getdischargeData,
  sendSelectedAttachments,
  getICDList,
  getReceipt,
  clearRequestData,
  sendSelectedNotes,
  deleteVisitNote,
  saveSignature,
  deleteAdditionalInfo,
  getLinksFiles,
  getCovidDoc,
  createUpdateCovid,
};
