import { fetchWrapper } from "../helpers/helpers";
import { SET_LOCATION_DATA, SET_SPECIALITY_DATA } from "./doctor/actionTypes";
import { SET_ARCHIVED_FILES } from "./admin/actionTypes";
import { convertArchiveFiles } from "../utils/common";
import { routesCommon } from "../constants";
import history from "../helpers/history";

const setLocations = (payload) => ({ type: SET_LOCATION_DATA, payload });
const setSpeciality = (payload) => ({ type: SET_SPECIALITY_DATA, payload });
const setArchivedFiles = (payload) => ({ type: SET_ARCHIVED_FILES, payload });

const getLockFilters = async (key, body = {}) =>
  await fetchWrapper({
    url: `users/state/${key}/`,
    method: !!Object.keys(body).length ? "POST" : "GET",
    body: body,
  }).catch((resp) => {
    return resp;
  });

const getLocations = () => (dispatch) =>
  fetchWrapper({
    url: "locations/regions/",
  }).then((resp) => {
    dispatch(
      setLocations(
        resp.map((item) => ({ value: item.uuid, label: item.name })),
      ),
    );
  });

const getSpeciality = () => (dispatch) =>
  fetchWrapper({
    url: "doctors/specialities/",
  }).then((resp) => {
    dispatch(
      setSpeciality(
        resp.map((item) => ({ value: item.uuid, label: item.name })),
      ),
    );
  });

const getArchivedDocuments = (uuid) => (dispatch) =>
  fetchWrapper({
    url: `admin/doctors/${uuid}/archive/`,
  }).then((resp) => {
    let data = convertArchiveFiles(resp);
    dispatch(setArchivedFiles(data));
  });

const unArchiveDocument = (uuidDoctor, uuidFile, body) => (dispatch) =>
  fetchWrapper({
    url: `admin/doctors/${uuidDoctor}/archive/${uuidFile}/`,
    method: "PUT",
    body,
  }).then((resp) => {
    if (!resp) {
      getArchivedDocuments(uuidDoctor)(dispatch);
    }
    return resp;
  });

//dashoboard
const getSummaryDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/summary/`,
    body,
  });

const getOrderResponseTimeDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/order-response-time/`,
    body,
  });
const getNotesCompletionDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/notes-completion/`,
    body,
  });
const getAllSatisfactionDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/all-uplin-satisfaction/`,
    body,
  });
const getStatisticDoctorDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/doctors-statistic/`,
    body,
  });

const getTotalVisitsDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/doctor/total-visits/`,
    body,
  });
const getPatientSatisfactionDashboard = (body) =>
  fetchWrapper({
    url: `dashboards/doctor/patient-satisfaction/`,
    body,
  });

const refreshToken = () => {
  const token = window.localStorage.getItem("refresh_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.setItem("is_refresh", "true");
  fetchWrapper({
    method: "POST",
    url: `auth/token/refresh/`,
    body: { refresh: token },
  })
    .then((resp) => {
      const path = window.location.pathname.split("/");
      window.localStorage.removeItem("is_refresh");
      window.localStorage.setItem("refresh_token", resp.refresh);
      window.localStorage.setItem("access_token", resp.access);
      history.push({
        pathname: `/${path[1]}`,
        state: { refreshUrl: `/${path[2]}` },
      });
    })
    .catch(() => {
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("uuid_user");
      window.localStorage.removeItem("is_refresh");
      history.push(`${routesCommon.login_form}/patient`);
    });
};

export {
  getLockFilters,
  getSpeciality,
  getLocations,
  getArchivedDocuments,
  unArchiveDocument,
  getSummaryDashboard,
  getOrderResponseTimeDashboard,
  getNotesCompletionDashboard,
  getAllSatisfactionDashboard,
  getStatisticDoctorDashboard,
  getTotalVisitsDashboard,
  getPatientSatisfactionDashboard,
  refreshToken,
};
