export const GYM = () => {
  return {
    type: "label",
    data: "",
    required: true,
    items: [
      {
        type: "blockCheckboxCondition",
        data: {
          checkbox: [{ checked: false, data: "GYN History:" }],
          hiddenBlock: [
            {
              type: "checkbox",
              data: [
                { checked: false, data: "LMP", datepicker: { data: null } },
                { checked: false, data: "NO period" },
                {
                  checked: false,
                  data: "Contraception",
                  inputText: { data: "" },
                },
              ],
            },
          ],
        },
      },
    ],
  };
};
