import React from "react";
import { Checkbox } from "../../Common/Checkbox/Checkbox";
import cn from "classnames";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { steps } from "../../../constants";
import patientAvatar from "../../../dist/images/accountAvatar.svg";

export const ItemPatient = ({ onClick, data, selected, activeStep }) => {
  const { first_name, last_name, date_of_birth, phone_number, email } = data;
  return (
    <div
      onClick={onClick}
      className={cn("card-patient", {
        "card-patient--selected": selected,
      })}
    >
      {activeStep === steps.PickingPatients && (
        <Checkbox checked={selected} onClick={onClick} />
      )}
      <div className="card-patient_info">
        <img className="card-patient_info_img" src={patientAvatar} />
        <div className="card-patient_info_data">
          <Typography
            variant="title"
            className=""
            text={`${first_name} ${last_name}`}
          />
          <Typography
            color={colors.greyDeep}
            variant="p"
            className=""
            text={`DOB: ${date_of_birth}`}
          />
          <Typography
            color={colors.navy}
            variant="p"
            className=""
            text={phone_number}
          />
          <Typography
            color={colors.navy}
            variant="p"
            className=""
            text={email}
          />
        </div>
      </div>
    </div>
  );
};
