import { SERVER_DOMAIN } from "../config/config.js";
import fetch from "isomorphic-fetch";
import { toast } from "react-toastify";
import history from "./history";
import moment from "moment";
import { refreshToken } from "../store/actions";
import { DECLINED, PENDING, routesCommon, VISIT_COMPLETED } from "../constants";
import { toCapitalizeChart1 } from "../utils/common";
import _ from "lodash";
import { TYPES_CHIPS } from "../components/Common/Chip/constants";

export const isLoading = (show, url) => {
  if (show) {
    window.localStorage.setItem(
      "requests",
      `${window.localStorage.getItem("requests") || ""}${url}&&`,
    );
    document
      .querySelector(".loader-page-all-requests")
      .classList.add("loader-page-all-requests_show");
  } else {
    const urls = window.localStorage
      .getItem("requests")
      .split("&&")
      .filter((i) => i !== url)
      .join("&&");
    window.localStorage.setItem("requests", urls);
    if (!urls.length)
      document
        .querySelector(".loader-page-all-requests")
        .classList.remove("loader-page-all-requests_show");
  }
};

const configError = {
  autoClose: 3000,
};

export function fetchWrapper(
  {
    url,
    method = "GET",
    query = "",
    body = null,
    headers = {},
    respFile = false,
    useLoading = true,
    isSkippedRedirect403 = false,
  },
  notStringify = false,
  noToken = false,
) {
  if (useLoading) isLoading(true, url);

  let requestParams = {
    method: method,
    headers: headers,
  };
  if (window.localStorage.getItem("access_token") && !noToken) {
    headers["Authorization"] = `Bearer ${window.localStorage.getItem(
      "access_token",
    )}`;
  }
  if (method !== "GET" && body) {
    if (notStringify) {
      requestParams.body = body;
    } else {
      requestParams.body = JSON.stringify(body);
      headers["Content-Type"] = "application/json";
    }
  }
  if (method === "GET" && !query) {
    let str = "";
    for (let key in body) {
      if (body[key]) {
        str += str ? "&" : "?";
        str += key + "=" + body[key];
      }
    }
    query = str;
  }
  return fetch(`${SERVER_DOMAIN}api/v1/${url}${query}`, requestParams).then(
    (resp) => {
      if (useLoading) isLoading(false, url);
      if (resp.status >= 200 && resp.status < 400) {
        if (resp.status === 204) return;
        if (respFile) return resp.blob();
        return resp.json().catch(() => {});
      } else {
        if (resp.status === 409) return { status: resp.status };
        if (resp.status === 404) return { status: resp.status };
        if (resp.status === 401) {
          if (!window.localStorage.is_refresh) {
            if (window.localStorage.getItem("refresh_token")) refreshToken();
            else {
              let checkRoute = (route) =>
                !!history.location?.pathname
                  .split("/")
                  .find((i) => i === route);
              if (checkRoute("patient"))
                history.push(`${routesCommon.login_form}/patient`);
              else if (checkRoute("admin"))
                history.push(`${routesCommon.login_form}/admin`);
              else if (checkRoute("doctor"))
                history.push(`${routesCommon.login_form}/doctor`);
              else {
                window.location.href = routesCommon.landing;
              }
              window.localStorage.removeItem("access_token");
              window.localStorage.removeItem("user_uuid");
            }
          }
        } else if (resp.status === 500) {
          toast(resp.statusText, configError);
        } else if (resp.status === 503) {
          toast("System is not available at the moment", configError);
        } else if (resp.status === 502) {
          toast("Server returned a status 502", configError);
        } else if (resp.status === 504) {
          toast("Server returned a status 504", configError);
        } else if (resp.status === 400) {
          if (!!resp.json) {
            return resp.json().then((data) => {
              let error = "";
              Object.keys(data).map((v) => {
                const item = data[v];
                if (typeof item === "string") {
                  if (typeof +v === "number") error += item;
                  else
                    error +=
                      " " +
                      toCapitalizeChart1(v.replace(/_/g, " ")) +
                      " - " +
                      item.toLowerCase();
                } else {
                  if (item.length) {
                    error +=
                      " " +
                      toCapitalizeChart1(v.replace(/_/g, " ")) +
                      " - " +
                      item.join(", ").toLowerCase();
                  } else {
                    if (typeof item === "object") {
                      for (let key in item) {
                        if (Array.isArray(item[key]))
                          error +=
                            " " +
                            toCapitalizeChart1(key.replace(/_/g, " ")) +
                            " - " +
                            item[key].join(", ").toLowerCase();
                        else error += JSON.stringify(item[key]);
                      }
                    } else error += JSON.stringify(item);
                  }
                }
              });
              toast(error, { containerId: "test", ...configError });
              return data;
            });
          } else {
            toast("Something went wrong. Please check your data", configError);
            return resp.status;
          }
        } else if (resp.status === 403) {
          return resp.json().then((response) => {
            if (!isSkippedRedirect403) {
              if (
                response.detail ===
                "You do not have permission to perform this action."
              ) {
                toast(response.detail, configError);
                history.goBack();
                throw "";
              }
              if (response.detail) toast(response.detail, configError);
            }
            return { status: resp.status };
          });
        } else {
          const isJSON = !!resp.json;
          if (isJSON) {
            try {
              return resp
                .json()
                .then((error) => {
                  return Promise.reject(error);
                })
                .catch((error) => {
                  return Promise.reject(error);
                });
            } catch (e) {
              return resp;
            }
          } else return resp;
        }
        throw resp.statusText;
      }
    },
  );
}

export const formatReceipt = (arr) => {
  const diagnosis = [];
  const services = [];
  if (Array.isArray(arr)) {
    arr.forEach((i) => {
      if (i.diagnosis) {
        diagnosis.push(i);
      } else {
        services.push(i);
      }
    });
  }
  return [...diagnosis, ...services];
};

export const dateToDisplay = (applicationTime) => {
  if (!applicationTime) {
    return moment(applicationTime).format("ll");
  }
  return moment(moment(applicationTime).format("YYYY-MM-DD")).isSame(
    moment().format("YYYY-MM-DD"),
  )
    ? "Today"
    : moment(applicationTime).format("ll");
};

export const dateForMultiple = (timeStart, timeEnd) => {
  const applicationStartTime = moment(timeStart).format("MMM DD, hh:mm A");
  const applicationEndTime = moment(timeEnd).format("hh:mm A");
  return `${applicationStartTime} – ${applicationEndTime}`;
};

export const fetchV2 =
  (
    {
      url,
      method = "GET",
      query = "",
      body = null,
      headers = {},
      respFile = false,
      useLoading = true,
      isSkippedRedirect403 = false,
    },
    notStringify = false,
    noToken = false,
  ) =>
  () => {
    if (useLoading) isLoading(true, url);

    let requestParams = {
      method: method,
      headers: headers,
    };
    if (window.localStorage.getItem("access_token") && !noToken) {
      // if (!checkToken()) {
      //   history.push("/landing");
      // }
      headers["Authorization"] = `Bearer ${window.localStorage.getItem(
        "access_token",
      )}`;
    }
    if (method !== "GET" && body) {
      if (notStringify) {
        requestParams.body = body;
      } else {
        requestParams.body = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
      }
    }
    if (method === "GET" && !query) {
      let str = "";
      for (let key in body) {
        if (body[key]) {
          str += str ? "&" : "?";
          str += key + "=" + body[key];
        }
      }
      query = str;
    }
    return fetch(
      `${SERVER_DOMAIN}${`api/v1/${url}`}${query}`,
      requestParams,
    ).then((resp) => {
      if (useLoading) isLoading(false, url);
      if (resp.status >= 200 && resp.status < 400) {
        if (resp.status === 204) return;
        if (respFile) return resp.blob();
        return resp
          .json()
          .then((result) => {
            return { timestamp: moment().format(), data: result };
          })
          .catch(() => {});
      } else {
        if (resp.status === 409) return { status: resp.status };
        if (resp.status === 404) return { status: resp.status };
        if (resp.status === 401) {
          if (!window.localStorage.is_refresh) {
            if (window.localStorage.getItem("refresh_token")) refreshToken();
            else {
              let checkRoute = (route) =>
                !!history.location?.pathname
                  .split("/")
                  .find((i) => i === route);
              if (checkRoute("patient"))
                history.push(`${routesCommon.login_form}/patient`);
              else if (checkRoute("admin"))
                history.push(`${routesCommon.login_form}/admin`);
              else if (checkRoute("doctor"))
                history.push(`${routesCommon.login_form}/doctor`);
              else {
                window.location.href = routesCommon.landing;
              }
              window.localStorage.removeItem("access_token");
              window.localStorage.removeItem("user_uuid");
            }
          }
        } else if (resp.status === 500) {
          toast(resp.statusText, configError);
        } else if (resp.status === 503) {
          toast("System is not available at the moment", configError);
        } else if (resp.status === 502) {
          toast("Server returned a status 502", configError);
        } else if (resp.status === 504) {
          toast("Server returned a status 504", configError);
        } else if (resp.status === 400) {
          if (!!resp.json) {
            return resp.json().then((data) => {
              let error = "";
              Object.keys(data).map((v) => {
                const item = data[v];
                if (typeof item === "string") {
                  if (typeof +v === "number") error += item;
                  else
                    error +=
                      " " +
                      toCapitalizeChart1(v.replace(/_/g, " ")) +
                      " - " +
                      item.toLowerCase();
                } else {
                  if (item.length) {
                    error +=
                      " " +
                      toCapitalizeChart1(v.replace(/_/g, " ")) +
                      " - " +
                      item.join(", ").toLowerCase();
                  } else {
                    if (typeof item === "object") {
                      for (let key in item) {
                        if (Array.isArray(item[key]))
                          error +=
                            " " +
                            toCapitalizeChart1(key.replace(/_/g, " ")) +
                            " - " +
                            item[key].join(", ").toLowerCase();
                        else error += JSON.stringify(item[key]);
                      }
                    } else error += JSON.stringify(item);
                  }
                }
              });
              toast(error, { containerId: "test", ...configError });
              return data;
            });
          } else {
            toast("Something went wrong. Please check your data", configError);
            return resp.status;
          }
        } else if (resp.status === 403) {
          return resp.json().then((response) => {
            if (!isSkippedRedirect403) {
              if (
                response.detail ===
                "You do not have permission to perform this action."
              ) {
                toast(response.detail, configError);
                history.goBack();
                throw "";
              }
              if (response.detail) toast(response.detail, configError);
            }
            return { status: resp.status };
          });
        } else {
          const isJSON = !!resp.json;
          if (isJSON) {
            try {
              return resp
                .json()
                .then((error) => {
                  return Promise.reject(error);
                })
                .catch((error) => {
                  return Promise.reject(error);
                });
            } catch (e) {
              return resp;
            }
          } else return resp;
        }
        throw resp.statusText;
      }
    });
  };

export const groupPatientsByRelation = (patients) => {
  let groupedPatients = _.sortBy(patients, "status");
  groupedPatients = _.groupBy(groupedPatients, "client_patient_relationship");
  groupedPatients = Object.keys(groupedPatients).map((i) => ({
    label: i,
    items: groupedPatients[i],
  }));
  groupedPatients = _.sortBy(
    groupedPatients,
    (obj) => obj.label !== "null" && obj.label,
  );
  return groupedPatients;
};

export const defineChipType = (status) => {
  const statusToLower = status.toLowerCase();
  if (statusToLower === DECLINED) {
    return TYPES_CHIPS.DECLINED;
  } else if (statusToLower === PENDING) {
    return TYPES_CHIPS.PENDING;
  } else if (statusToLower === VISIT_COMPLETED) {
    return TYPES_CHIPS.VISIT_COMPLETED;
  } else {
    return TYPES_CHIPS.PRIMARY;
  }
};
