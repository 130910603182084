export const PhysicianExamination = (props) => {
  return {
    type: "label",
    parent: true,
    isDelete: false,
    canDeleteBlock: true,
    required: true,
    data: "PHYSICAL EXAMINATION:",
    items: props?.items,
  };
};
