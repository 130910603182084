export const BUTTONSADDNEWDOCTOR = ["Doctor Information"];
export const BUTTONSEDITDOCTOR = ["Doctor Information", "Documents", "Archive"];

export const STATUSES_DOCTORS_ROW = [
  { status: "Pending", text: "send" },
  { status: "Disabled", text: "" },
  { status: "Suspended", text: "resend" },
  { status: "Invite Sent", text: "resend" },
  { status: "Active", text: "resend" },
];

export const ROUTERS_ADMIN = {
  edit: "/edit",
};

export const routersAdmin = {
  add: "/add",
  table: "/table",
  calendar: "/calendar",
  manageTemplates: "/manage-templates",
  veradigm: "/veradigm",
  reports: "/reports",
  edit: "/edit",
  visitFile: "/visit-file",
  personal_info: "/personal-info",
  request: "/request",
  emr: "/emr",
};

export const filtersCalendar = ["Appointments", "Shifts"];
export const backToShift = "shift";
export const backToAppointment = "appointment";

export const timeOptionsShift = [
  { value: "00:00", label: "12:00 am" },
  { value: "00:15", label: "12:15 am" },
  { value: "00:30", label: "12:30 am" },
  { value: "00:45", label: "12:45 am" },

  { value: "01:00", label: "01:00 am" },
  { value: "01:15", label: "01:15 am" },
  { value: "01:30", label: "01:30 am" },
  { value: "01:45", label: "01:45 am" },

  { value: "02:00", label: "02:00 am" },
  { value: "02:15", label: "02:15 am" },
  { value: "02:30", label: "02:30 am" },
  { value: "02:45", label: "02:45 am" },

  { value: "03:00", label: "03:00 am" },
  { value: "03:15", label: "03:15 am" },
  { value: "03:30", label: "03:30 am" },
  { value: "03:45", label: "03:45 am" },

  { value: "04:00", label: "04:00 am" },
  { value: "04:15", label: "04:15 am" },
  { value: "04:30", label: "04:30 am" },
  { value: "04:45", label: "04:45 am" },

  { value: "05:00", label: "05:00 am" },
  { value: "05:15", label: "05:15 am" },
  { value: "05:30", label: "05:30 am" },
  { value: "05:45", label: "05:45 am" },

  { value: "06:00", label: "06:00 am" },
  { value: "06:15", label: "06:15 am" },
  { value: "06:30", label: "06:30 am" },
  { value: "06:45", label: "06:45 am" },

  { value: "07:00", label: "07:00 am" },
  { value: "07:15", label: "07:15 am" },
  { value: "07:30", label: "07:30 am" },
  { value: "07:45", label: "07:45 am" },

  { value: "08:00", label: "08:00 am" },
  { value: "08:15", label: "08:15 am" },
  { value: "08:30", label: "08:30 am" },
  { value: "08:45", label: "08:45 am" },

  { value: "09:00", label: "09:00 am" },
  { value: "09:15", label: "09:15 am" },
  { value: "09:30", label: "09:30 am" },
  { value: "09:45", label: "09:45 am" },

  { value: "10:00", label: "10:00 am" },
  { value: "10:15", label: "10:15 am" },
  { value: "10:30", label: "10:30 am" },
  { value: "10:45", label: "10:45 am" },

  { value: "11:00", label: "11:00 am" },
  { value: "11:15", label: "11:15 am" },
  { value: "11:30", label: "11:30 am" },
  { value: "11:45", label: "11:45 am" },

  { value: "12:00", label: "12:00 pm" },
  { value: "12:15", label: "12:15 pm" },
  { value: "12:30", label: "12:30 pm" },
  { value: "12:45", label: "12:45 pm" },

  { value: "13:00", label: "01:00 pm" },
  { value: "13:15", label: "01:15 pm" },
  { value: "13:30", label: "01:30 pm" },
  { value: "13:45", label: "01:45 pm" },

  { value: "14:00", label: "02:00 pm" },
  { value: "14:15", label: "02:15 pm" },
  { value: "14:30", label: "02:30 pm" },
  { value: "14:45", label: "02:45 pm" },

  { value: "15:00", label: "03:00 pm" },
  { value: "15:15", label: "03:15 pm" },
  { value: "15:30", label: "03:30 pm" },
  { value: "15:45", label: "03:45 pm" },

  { value: "16:00", label: "04:00 pm" },
  { value: "16:15", label: "04:15 pm" },
  { value: "16:30", label: "04:30 pm" },
  { value: "16:45", label: "04:45 pm" },

  { value: "17:00", label: "05:00 pm" },
  { value: "17:15", label: "05:15 pm" },
  { value: "17:30", label: "05:30 pm" },
  { value: "17:45", label: "05:45 pm" },

  { value: "18:00", label: "06:00 pm" },
  { value: "18:15", label: "06:15 pm" },
  { value: "18:30", label: "06:30 pm" },
  { value: "18:45", label: "06:45 pm" },

  { value: "19:00", label: "07:00 pm" },
  { value: "19:15", label: "07:15 pm" },
  { value: "19:30", label: "07:30 pm" },
  { value: "19:45", label: "07:45 pm" },

  { value: "20:00", label: "08:00 pm" },
  { value: "20:15", label: "08:15 pm" },
  { value: "20:30", label: "08:30 pm" },
  { value: "20:45", label: "08:45 pm" },

  { value: "21:00", label: "09:00 pm" },
  { value: "21:15", label: "09:15 pm" },
  { value: "21:30", label: "09:30 pm" },
  { value: "21:45", label: "09:45 pm" },

  { value: "22:00", label: "10:00 pm" },
  { value: "22:15", label: "10:15 pm" },
  { value: "22:30", label: "10:30 pm" },
  { value: "22:45", label: "10:45 pm" },

  { value: "23:00", label: "11:00 pm" },
  { value: "23:15", label: "11:15 pm" },
  { value: "23:30", label: "11:30 pm" },
  { value: "23:45", label: "11:45 pm" },
  // { value: "23:59", label: "11:59 pm" }
];

export const REPORTS_TABS = [
  "Daily Visits",
  "Gift Card Usage",
  "Uplin Patients",
];
