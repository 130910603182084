import { fetchV2 } from "../../helpers/helpers";
import { setShiftsData } from "./slice";

export const getShiftsData = (filters) => (dispatch) =>
  dispatch(
    fetchV2({
      url: "shifts/",
      body: filters,
    }),
  ).then((resp) => dispatch(setShiftsData(resp)));
