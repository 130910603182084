import React from "react";
import patientAvatar from "../../dist/images/accountAvatar.svg";
import { Typography } from "../../components/Common/Typography/Typography";
import { colors } from "../../assets/colors/colors";
import { lengthObj, toCapitalizeChart1 } from "../../utils/common";
import { Chip } from "../../components/Common/Chip";
import { TYPES_CHIPS } from "../../components/Common/Chip/constants";
import cn from "classnames";
import "./style.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const UserData = (props) => {
  if (!lengthObj(props.user)) return "";
  return (
    <div className={cn("user-data", props?.className)}>
      {props.showExtendedInfo && (
        <div className="user-data__type">
          {props.completed && <CheckCircleIcon />}
          <Chip
            type={TYPES_CHIPS.NOW_LATER_REQUEST}
            className="user-data__request-indicator"
            label={`${props.isNew ? "New" : "Established"} patient`}
          />
        </div>
      )}
      <div className="user-data__info">
        {!props.isRequester && <img src={patientAvatar} alt="avatar" />}
        <div className="user-data__text">
          <Typography
            capitalize
            color={colors.black}
            text={`${props.user?.first_name || props.user?.firstName} ${props.user?.last_name || props.user?.lastName}`}
            variant="title"
          />
          {(typeof props.user?.isMember === "boolean" ||
            typeof props.user?.is_member === "boolean") && (
            <Typography
              color={colors.core70}
              text={
                props.user?.is_member || props.user?.isMember
                  ? "Member"
                  : "Non-member"
              }
              variant="p"
            />
          )}
          {props.user?.sex && (
            <Typography
              color={colors.core70}
              text={`Gender: ${toCapitalizeChart1(props.user?.sex)}`}
              variant="p"
            />
          )}
          {!props.isEmr ? (
            <>
              {!props.isRequester && (
                <Typography
                  className="user-data__text_dob"
                  color={colors.grey}
                  text={`DOB: ${props.user?.date_of_birth || props.user?.birthday}`}
                  variant="p"
                />
              )}
              <Typography
                className="user-data__text_phone"
                text={props.user?.phone_number || props.user?.phone}
                variant="p"
                color={colors.navy}
              />
              {props.showEmail && (
                <Typography
                  className="user-data__text_email"
                  text={props.user?.email}
                  variant="p"
                  color={colors.navy}
                />
              )}
            </>
          ) : (
            <Typography
              text={`ID: ${props.user?.verbose_id}`}
              variant="p"
              color={colors.steel}
            />
          )}
        </div>
      </div>
    </div>
  );
};
