import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Item } from "../ItemCheckbox";
import cn from "classnames";
import { DisabledOverlay } from "../DisabledOverlay";

export const Checkbox = ({ data, updateState, error, disabled }) => {
  const options = data.data;
  const updateStatus = (index) => {
    updateState({
      ...data,
      data: options.map((i, idx) =>
        idx === index ? { ...i, checked: !i.checked } : i,
      ),
    });
  };

  const updateText = (index, value) =>
    updateState({
      ...data,
      data: options.map((i, idx) =>
        idx === index ? { ...i, data: value } : i,
      ),
    });
  const updateTextInput = (index, value) => {
    updateState({
      ...data,
      data: options.map((i, idx) =>
        idx === index
          ? { ...i, inputText: { ...i.inputText, data: value } }
          : i,
      ),
    });
  };
  const updateDate = (index, value) => {
    updateState({
      ...data,
      data: options.map((i, idx) =>
        idx === index ? { ...i, datepicker: { ...i.date, data: value } } : i,
      ),
    });
  };

  const isError = !options.filter((i) => i.checked).length && error;
  return (
    <span
      className={cn("emr-notes-checkbox", {
        "emr-notes-checkbox_error": isError,
      })}
    >
      {options.map((i, index) => (
        <Item
          {...i}
          key={index}
          id={index}
          last={options.length - 1 === index}
          updateStatus={() => {
            updateStatus(index);
          }}
          error={i.checked && error}
          updateText={(value) => {
            updateText(index, value);
          }}
          updateDate={(value) => {
            updateDate(index, value);
          }}
          updateTextInput={(value) => {
            updateTextInput(index, value);
          }}
        />
      ))}
      <DisabledOverlay show={disabled} />
    </span>
  );
};
