import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { PENDING } from "../../../constants";
import { AutoSizer, List } from "react-virtualized";
import { DoctorInfo } from "../../../compoenentsAdditional/DoctorInfo";
import { SIZES } from "../../../compoenentsAdditional/DoctorInfo/constants";
import { Chip } from "../../Common/Chip";
import { RequestInfo } from "../../Common/RequestInfo/RequestInfo";
import { CountPatient } from "../../../compoenentsAdditional/CountPatients";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN } from "../../Common/ButtonV2/constants";
import { defineChipType } from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { updateCountRequestInList } from "../../../store/calendarEvent/slice";
import { countRequestsSelector } from "../../../store/calendarEvent/selectors";
import { MODES_ADDITIONAL_LOADING } from "../../../store/calendarEvent/constants";
import { visitNoteStatuses } from "../../../constants";
import moment from "moment";
import { getLastFromArr } from "../../../utils/utils";

const heightItem = 550;
const heightTitle = 55;
export const ContainerEvents = ({
  data,
  showDetails,
  loadMoreData,
  setCustomTitle,
  dateOfPicker,
  indicatorTopColor,
  activeRequestUUID,
  fullPermissions,
  updateDatePicker,
  useInitialHandleResize,
}) => {
  const [scrollToUuid, setScrollToUuid] = useState(null);
  const [stickyTitles, setStickyTitles] = useState([]);
  const listRef = useRef();
  const countRequestInLine = useSelector(countRequestsSelector);

  const scrollingChecking = (titles) => {
    if (!listRef) return;
    if (typeof scrollToUuid === "string") {
      let targetTitle = titles.find((i) => i.uuid === scrollToUuid);
      if (!targetTitle)
        targetTitle =
          titles.filter((i) => moment(i.uuid) > moment(scrollToUuid))[0] ||
          getLastFromArr(titles);
      listRef.current.scrollToPosition(targetTitle?.offsetTop);
      setScrollToUuid(null);
    }
  };

  const getTitles = () => {
    let arr = [];
    const arrWithOffsets = [];
    data.forEach((i, idx) => {
      if (i.title) arr.push({ ...i, idx: idx });
    });
    arr = arr.map((i, idx) => {
      const height =
        ((arr[idx + 1] ? arr[idx + 1].idx : i.lengthChildren + i.idx + 1) -
          i.idx -
          1) *
          heightItem +
        heightTitle;
      return {
        ...i,
        height: height,
      };
    });
    arr.forEach((i, idx) => {
      const top = arrWithOffsets[idx - 1]?.offsetBottom || 0;
      arrWithOffsets.push({
        ...i,
        offsetTop: top + (top === 0 ? 0 : 1),
        offsetBottom: top + i.height,
      });
    });
    if (!arrWithOffsets.length) setCustomTitle("List");
    setStickyTitles(arrWithOffsets);
    scrollingChecking(arrWithOffsets);
  };

  const getFormatDate = () => moment(dateOfPicker).startOf("day").format();

  useEffect(() => {
    if (dateOfPicker) {
      listRef.current.scrollToPosition(1);
      setScrollToUuid(getFormatDate());
    }
  }, [dateOfPicker]);

  useEffect(() => {
    getTitles();
  }, [data?.length]);

  const setWidth = () =>
    `calc((100% - (25px * ${countRequestInLine - 1}))/${countRequestInLine})`;

  const renderItem = ({ index, key, style }) => {
    const curItem = data[index];
    if (Array.isArray(curItem))
      return (
        <CardItem
          key={key}
          style={style}
          curItem={curItem}
          setWidth={setWidth}
          activeRequestUUID={activeRequestUUID}
          fullPermissions={fullPermissions}
          showDetails={showDetails}
          indicatorTopColor={indicatorTopColor}
        />
      );
    return <Title style={style} key={key} curItem={curItem} />;
  };

  const rowHeight = ({ index }) => {
    const curItem = data[index];
    if (Array.isArray(curItem)) return heightItem;
    return heightTitle;
  };
  const updateTitle = ({ scrollTop }) => {
    if (!setCustomTitle) return;
    const newTitle = stickyTitles.find(
      (i) => i.offsetBottom >= scrollTop && i.offsetTop <= scrollTop,
    );
    if (newTitle) setCustomTitle(newTitle.title);
    else if (stickyTitles[0]) setCustomTitle(stickyTitles[0].title);
  };
  const loadDataOnScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (!loadMoreData || !data?.length) return;
    if (scrollTop === 0) {
      setScrollToUuid(data[0]?.uuid);
      if (!dateOfPicker) {
        updateDatePicker(moment().toDate());
        setScrollToUuid(getFormatDate());
      }
      loadMoreData({ mode: MODES_ADDITIONAL_LOADING.TOP });
    }
    if (scrollHeight === scrollTop + clientHeight)
      loadMoreData({ mode: MODES_ADDITIONAL_LOADING.BOTTOM });
  };
  const onScroll = (args) => {
    updateTitle(args);
    loadDataOnScroll(args);
  };

  return (
    <div id="list" className={cn("container-events-patient")}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={listRef}
            height={height}
            onScroll={onScroll}
            overscanRowCount={15}
            rowCount={data.length}
            rowHeight={rowHeight}
            rowRenderer={renderItem}
            width={width}
            noRowsRenderer={() => (
              <>
                <Typography
                  variant="h2"
                  color={colors.greyMiddle}
                  text="No result matches your criteria"
                />
              </>
            )}
          />
        )}
      </AutoSizer>
      <ResizeRequestsListener useInitialHandleResize={useInitialHandleResize} />
    </div>
  );
};

const Title = ({ key, style, curItem }) => {
  return (
    <div key={key} style={{ ...style, position: "sticky" }}>
      <Typography color={colors.blackMuted} variant="h2" text={curItem.title} />
    </div>
  );
};

const CardItem = ({
  key,
  style,
  curItem,
  setWidth,
  showDetails,
  indicatorTopColor,
  activeRequestUUID,
  fullPermissions,
}) => {
  return (
    <div key={key} style={style} className="container-events-patient_wrapper">
      {curItem.map((i, index) => {
        const status = i.service_request_status.toLowerCase();
        const isPending = status === PENDING;
        const permissionsViewPatients = fullPermissions || i.is_requester;
        const permissionsPreviewDoctor = fullPermissions || !isPending;
        const doctor = permissionsPreviewDoctor ? i.doctor_initials : "";
        const isGroup = i.requests_in_group > 1;
        const isActive = activeRequestUUID === i.uuid;
        return (
          <div
            style={{ width: setWidth() }}
            key={index}
            className={cn("container-events-patient_wrapper_item", {
              "container-events-patient_wrapper_item_is-active": isActive,
            })}
            onClick={() => showDetails(i)}
          >
            {indicatorTopColor && (
              <div
                className={cn(
                  "container-events-patient_wrapper_item_indicator-color",
                  visitNoteStatuses[status].classNameRequest,
                )}
              />
            )}
            <div className="container-events-patient_wrapper_item_header">
              <Typography
                color={colors.greyMiddle}
                variant="p"
                text={`${isGroup ? "Group" : "Single"} request`}
              />
              {i.service_request_chat?.has_unread_messages && (
                <div className="container-events-patient_wrapper_item_doctor_data_is-mess" />
              )}
              <Chip
                capitalize
                type={defineChipType(i.service_request_status)}
                label={i.service_request_status}
              />
            </div>
            <RequestInfo
              data={{ ...i, service_type: { name: i.service_type } }}
              doctor
            />
            {isGroup && (
              <CountPatient
                maxView={permissionsViewPatients ? 3 : 1}
                showNote={!permissionsViewPatients}
                number={i.requests_in_group}
              />
            )}
            {permissionsPreviewDoctor && doctor && (
              <>
                <div className="container-events-patient_wrapper_item_separator" />
                <DoctorInfo doctor={doctor} size={SIZES.MEDIUM} />
              </>
            )}
            <ButtonV2
              className="container-events-patient_wrapper_item_details-btn"
              color={COLORS_BTN.PRIMARY}
            >
              OPEN DETAILS
            </ButtonV2>
          </div>
        );
      })}
    </div>
  );
};

const ResizeRequestsListener = ({ useInitialHandleResize }) => {
  const dispatch = useDispatch();

  const handleResize = () => {
    const listEl = document.querySelector("#list");
    if (listEl.offsetWidth > 1450) {
      dispatch(updateCountRequestInList(4));
    } else if (listEl.offsetWidth > 1050) {
      dispatch(updateCountRequestInList(3));
    } else if (listEl.offsetWidth > 750) {
      dispatch(updateCountRequestInList(2));
    } else {
      dispatch(updateCountRequestInList(1));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (useInitialHandleResize) handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <></>;
};
