import React from "react";
import {
  RequestedBy,
  Map,
  AssignedTo,
  RequestInfo,
  Separator,
} from "../../SideBarRequest/components";
import { PatientSidebar } from "../../Common/PatientSidebar";
import { GroupCard } from "../../Common/GroupCard/GroupCard";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";

export const OrderDetailsContainer = (props) => {
  const hideSignature =
    props.data?.patients[0].status === "main" ||
    !props.data?.patients[0].patient_signature_path;
  const hideNote =
    (props.data?.doctor_data?.uuid !== props.doctorCurrent?.uuid &&
      !props.data?.patients[0].doctor_comment) ||
    (props.data?.doctor_data?.uuid === props.doctorCurrent?.uuid &&
      !props.data?.patients[0].doctor_comment);

  return (
    <>
      <AssignedTo {...props} />
      <RequestInfo {...props} />
      <Separator />
      <RequestedBy {...props} />
      {props.data?.is_group ? (
        <GroupCard onClick={props.openPatientList} data={props.data} />
      ) : (
        <PatientSidebar
          {...props}
          detailsRequest={props.data}
          data={props.data?.patients[0]}
          hideSignature={hideSignature}
          hideExamination
          hideAddButton
          hideNote={hideNote}
        />
      )}
      <Map {...props} comment={props.data?.location.comment} isShowSuite />
      <div className="total-visit-price">
        <Typography variant="title" color={colors.navy} text="Total:" />
        <Typography
          variant="h2_5"
          color={colors.navy}
          text={`$${props?.data?.total_visit_price}`}
        />
      </div>
    </>
  );
};
