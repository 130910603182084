import React from "react";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { RoundProgressBar } from "../../../Common/RoundProgressBar/RoundProgressBar";
import { Delta } from "../../../Common/Delta/Delta";

export const ProgressButton = ({ value, label, delta }) => (
  <div className="item">
    <Typography text={label} variant="h3" className="title" />
    <RoundProgressBar value={value} />
    {delta !== undefined && (
      <Delta delta={delta} extraText="from previous 30 days" />
    )}
  </div>
);
