import React from "react";
import "./style.scss";

import { Cross } from "../Common/Icons";
import { ButtonV2 } from "../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../Common/ButtonV2/constants";

export const Notification = ({
  closeToast,
  actionButton,
  img,
  text,
  buttonText,
}) => {
  return (
    <div className="custom-notification-toaster">
      <div className="custom-notification-toaster_container-info">
        <img
          src={img}
          className="custom-notification-toaster_container-info_img"
        />
        <div className="custom-notification-toaster_container-info_block-text">
          <div className="custom-notification-toaster_container-info_block-text_title">
            <span>Just now</span>
            <Cross onClick={closeToast} />
          </div>
          <div className="custom-notification-toaster_container-info_block-text_text">
            {text}
          </div>
        </div>
      </div>
      <div className="custom-notification-toaster_button-navigate">
        <ButtonV2
          onClick={actionButton}
          uppercase
          variant={VARIATIONS_BTN.TEXT}
          color={COLORS_BTN.PRIMARY}
        >
          {buttonText}
        </ButtonV2>
      </div>
    </div>
  );
};
