import React, { useState, useEffect } from "react";
import "./style.scss";
import { Button } from "../../Common/Button/Button";
import phone from "../../../dist/icons/phone.svg";

import {
  validationMobileCode,
  verificatinPhone,
} from "../../../store/createRequest/actions";
import { Typography } from "../../Common/Typography/Typography";
import { CodeInputs } from "../../Common/CodeInputs/CodeInputs";
import { InputFlag } from "../../Common/InputFlag/InputFlag";
import { validationIsEmpty } from "../../../utils/validation";
import { filterObj, lengthObj } from "../../../utils/common";
import { routesRequest } from "../../../constants";
import history from "../../../helpers/history";
import data from "./phoneCodesFlags";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";

export const MobileCode = ({ simpleUpdateState, getDataUser, path }) => {
  const [inputCode, setInputCode] = useState(false);
  const [disableBtnResend, setDisableBtnResend] = useState(true);
  const [number, setNumber] = useState(`${data[0].dial_code}`);
  const [code, setValueCode] = useState("");

  useEffect(() => {
    Analytics.track(EVENTS.Login_Page_Open);
  }, []);

  useEffect(() => {
    simpleUpdateState(
      "mobileVerification",
      "Please Enter Your Cell Phone Number Below",
    );
    simpleUpdateState("hideSteps", true);
    return () => {
      simpleUpdateState("mobileVerification", false);
    };
  }, []);

  useEffect(() => {}, []);

  const handlerShowComponentInputCode = () => {
    if (!inputCode) {
      verificatinPhone({ phone_number: number, target: "patient" }).then(() => {
        const uuid = window.localStorage.user_uuid;
        if (uuid) {
          setTimeout(() => {
            setDisableBtnResend(false);
          }, 30000);
          simpleUpdateState(
            "mobileVerification",
            "Security is very important to Us.",
          );
          simpleUpdateState("user_mobile", number);
          setInputCode(true);
          simpleUpdateState("user_uuid", uuid);
        }
      });
    } else {
      if (code.length === 6) {
        validationMobileCode({
          user_uuid: window.localStorage.getItem("user_uuid"),
          validation_code: code,
        })
          .then(() => {
            getDataUser().then((resp) => {
              simpleUpdateState("user", resp);
              if (!!lengthObj(filterObj(validationIsEmpty(resp))))
                history.push(`${path}${routesRequest.user_info}`);
              else history.push(`${path}${routesRequest.patients}`);
            });
          })
          .catch(() => {});
      }
    }
  };

  const sendResendCode = () => {
    setDisableBtnResend(true);
    verificatinPhone({ phone_number: number, target: "patient" }).then(() => {
      setTimeout(() => {
        setDisableBtnResend(false);
      }, 30000);
    });
  };

  const dangerouslySetInnerHTML = inputCode
    ? `to your cell phone
                  number at <number>${number.replace(/\s_/g, "").substring(2)}</number>`
    : "pre-registration account";

  return (
    <div className="mobile-code-container">
      <div className="mobile-code-container_title" style={{ marginBottom: 0 }}>
        <Typography
          variant="label"
          text={
            inputCode
              ? "We will now send secure verification correspondence"
              : "This will allow us to safely and securely manage your"
          }
          className=""
          styleCustom={{ marginTop: 16 }}
        />
        <Typography
          variant="label"
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          className="title-with-number"
        />
      </div>

      <div className="mobile-code-container_wrapper-code">
        <img
          src={phone}
          className="mobile-code-container_wrapper-code_img"
          alt="code"
        />
        <div className="mobile-code-patient__request">
          {inputCode ? (
            <CodeInputs
              className="mobile-code-container_wrapper-code_field-code_mobile-code"
              onChange={setValueCode}
            />
          ) : (
            <InputFlag
              onChange={setNumber}
              className="mobile-code-container_wrapper-code_field-code_selector-country"
            />
          )}
        </div>
        <div className="mobile-code-container_wrapper-code_buttons">
          {inputCode && (
            <Button
              disabled={disableBtnResend}
              text="Resend"
              size="middle"
              variant="default"
              onClick={sendResendCode}
            />
          )}
          <Button
            text="Next"
            size="middle"
            variant="primary"
            onClick={handlerShowComponentInputCode}
          />
        </div>
      </div>
    </div>
  );
};
