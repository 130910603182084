import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  isLoaded: false,
};

const createRequestAdminDoctorsSlice = createSlice({
  name: "createRequestAdminDoctorsSlice",
  initialState,
  reducers: {
    setCreateRequestAdminDoctorsData: (state, { payload }) => {
      console.log("doctor_issue: doctors payload", payload);
      state.data = payload;
      state.isLoaded = true;
    },
    clearCreateRequestAdminDoctorsData: (state) => {
      state.data = null;
      state.isLoaded = false;
    },
  },
});

export const {
  setCreateRequestAdminDoctorsData,
  clearCreateRequestAdminDoctorsData,
} = createRequestAdminDoctorsSlice.actions;
export const createRequestAdminDoctorsReducer =
  createRequestAdminDoctorsSlice.reducer;
