import React, { Component } from "react";
import "./style.scss";
import DocumentZone from "../DocumentZone/DocumentZone";
import { ACTIVE, ADMIN, LABELS_DOCTORS, PENDING } from "../../../constants";
import history from "../../../helpers/history";
import moment from "moment";
import signature from "../../../dist/img/signature.svg";
import { routesCommon } from "../../../constants";
import { routersDoctor } from "../../../constants/Doctors";
import { routersAdmin } from "../../../constants/Admin";
import { SignatureBlock } from "../../SignatureBlock";
import { getLinksFiles } from "../../../store/doctor/actions";

class EmploymentApplication extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      app: null,
      cv: null,
      verificationForm: null,
      w4Form: null,
      photo: null,
      card: null,
      voidedCheck: null,
      form1099: null,
      license: null,
      dea: null,
    };

    this.state = {
      ...this.initialState,
      cancelAction: false,
      dateLicense: moment().toDate(),
      dateDEA: moment().toDate(),
      popupSignature: false,
      signature: null,
      linksFiles: null,
      documentZoneField: {},
    };

    this.props.saveElements(this.actionSave, this.actionCancel);
  }

  saveSignature = (data) =>
    this.props.saveSignature(data, this.props.doctorData.uuid);

  updateDoctorFiles = (uploadApplications, doctorData) => {
    let state = {};
    uploadApplications.forEach((item) => {
      for (let key in LABELS_DOCTORS) {
        if (item.fileType === LABELS_DOCTORS[key]) {
          const fileNameArr = item.file.split("/");
          state[key] = {
            ...item,
            name: fileNameArr[fileNameArr.length - 1],
            statusFile: item.statusFile,
          };
        }
      }
    });

    this.setState({
      ...state,
      documentZoneField: {
        dea: doctorData.dea,
        dea_schedule: doctorData.dea_schedule,
        license_number: doctorData.license_number,
      },
    });
  };

  updateDocumentZoneFields = ([field, value]) => {
    this.setState({
      documentZoneField: {
        ...this.state.documentZoneField,
        [field]: value,
      },
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.doctorData.uploadApplications) {
      this.updateDoctorFiles(
        nextProps.doctorData.uploadApplications,
        nextProps.doctorData,
      );
    }
    if (nextProps.signature) {
      this.setState({
        signature: signature,
      });
    }
  }

  componentDidMount() {
    if (this.props.doctorData.uploadApplications) {
      this.updateDoctorFiles(
        this.props.doctorData.uploadApplications,
        this.props.doctorData,
      );

      getLinksFiles().then((resp) => {
        this.setState({
          linksFiles: resp,
        });
      });
    }
  }

  actionSave = () => {
    this.setState({ cancelAction: true }, () => {
      this.setStateToStore().then(() => {
        this.props.saveDataDoctor();
      });
    });
  };

  actionCancel = () => {
    this.setState({ cancelAction: true }, () => {
      this.setStateToStore().then(() => {
        if (this.props.flag === ADMIN)
          history.push(`${routesCommon.admin}${routersAdmin.table}`);
        else history.push(`${routesCommon.doctor}${routersDoctor.schedule}`);
      });
    });
  };

  setStateToStore = () => {
    let arr = [];
    let extraNewState = {};

    for (let key in this.initialState) {
      if (this.state[key]) {
        const data = this.state[key];
        let initialObj = {
          uuid: data.uuid,
          file: data.file,
          fileType: data.fileType,
          doctor: this.props.doctorData.uuid,
          uploadedAt: data.uploadedAt,
          expiryDate: data.expiryDate,
          statusFile: data.statusFile,
          comment: data.comment,
        };
        arr.push(initialObj);
      }
    }
    if (
      this.props.doctorData.status === ACTIVE &&
      (!this.state.dea || !this.state.license)
    ) {
      this.props.updateStatusPersonalInfo(PENDING);
      extraNewState.status = PENDING;
    }

    return this.props.updateUploadedFiles(arr, extraNewState);
  };

  componentWillUnmount() {
    if (!this.state.cancelAction) {
      this.setStateToStore();
    }
  }

  updateState = (field, value) => {
    let prevData = this.state[field] ? this.state[field] : {};

    this.setState({
      [field]: { ...prevData, ...value },
    });
  };

  onDelete = (field) => {
    this.setState({
      [field]: null,
    });
  };

  render() {
    const { flag } = this.props;
    return (
      <div className="doctor-page-employment-application">
        {this.props.listOfDocumentsFor === "employee" && (
          <DocumentZone
            flag={flag}
            doctorData={this.props.doctorData}
            label={LABELS_DOCTORS.app}
            file={this.state.app}
            field="app"
            link={this.state.linksFiles?.pbdhc}
            isDownload
            onDelete={this.onDelete}
            updateState={this.updateState}
          />
        )}
        <DocumentZone
          flag={flag}
          doctorData={this.props.doctorData}
          label={LABELS_DOCTORS.cv}
          file={this.state.cv}
          field="cv"
          onDelete={this.onDelete}
          updateState={this.updateState}
        />
        {this.props.listOfDocumentsFor === "contractor" && (
          <DocumentZone
            flag={flag}
            doctorData={this.props.doctorData}
            label={LABELS_DOCTORS.form1099}
            file={this.state.form1099}
            employeeType={this.props.listOfDocumentsFor}
            field="form1099"
            isDownload
            onDelete={this.onDelete}
            updateState={this.updateState}
          />
        )}
        {this.props.listOfDocumentsFor === "employee" && (
          <>
            <DocumentZone
              flag={flag}
              doctorData={this.props.doctorData}
              label={LABELS_DOCTORS.verificationForm}
              file={this.state.verificationForm}
              isDownload
              link={this.state.linksFiles?.i9}
              field="verificationForm"
              onDelete={this.onDelete}
              updateState={this.updateState}
            />
            <DocumentZone
              flag={flag}
              doctorData={this.props.doctorData}
              label={LABELS_DOCTORS.w4Form}
              file={this.state.w4Form}
              field="w4Form"
              link={this.state.linksFiles?.w4}
              isDownload
              onDelete={this.onDelete}
              updateState={this.updateState}
            />
            <DocumentZone
              flag={flag}
              doctorData={this.props.doctorData}
              label={LABELS_DOCTORS.photo}
              file={this.state.photo}
              field="photo"
              onDelete={this.onDelete}
              updateState={this.updateState}
            />
            <DocumentZone
              flag={flag}
              doctorData={this.props.doctorData}
              label={LABELS_DOCTORS.card}
              file={this.state.card}
              field="card"
              onDelete={this.onDelete}
              updateState={this.updateState}
            />
            <DocumentZone
              flag={flag}
              doctorData={this.props.doctorData}
              label={LABELS_DOCTORS.voidedCheck}
              file={this.state.voidedCheck}
              field="voidedCheck"
              onDelete={this.onDelete}
              updateState={this.updateState}
            />
          </>
        )}
        <DocumentZone
          flag={flag}
          doctorData={this.props.doctorData}
          label={LABELS_DOCTORS.license}
          file={this.state.license}
          field="license"
          withDate
          placeholderId="Medical License Number"
          initialLecense={this.state.documentZoneField.license_number}
          withLicense
          updateDocumentZoneFields={this.updateDocumentZoneFields}
          onDelete={this.onDelete}
          updateState={this.updateState}
        />
        <DocumentZone
          withId
          withLevel
          flag={flag}
          doctorData={this.props.doctorData}
          label={LABELS_DOCTORS.dea}
          file={this.state.dea}
          withDate
          updateDocumentZoneFields={this.updateDocumentZoneFields}
          deaSchedule={this.state.documentZoneField.dea_schedule}
          deaId={this.state.documentZoneField.dea}
          field="dea"
          onDelete={this.onDelete}
          updateState={this.updateState}
        />
        <SignatureBlock
          canAdd={this.props.flag !== ADMIN}
          signature={this.props.signature?.preview}
          action={this.saveSignature}
        />
      </div>
    );
  }
}

export default EmploymentApplication;
