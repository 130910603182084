import mixpanel from "mixpanel-browser/src/loader-module";
import { EVENTS } from "./Events";
import { PROPERTIES } from "./Properties";
export class MixpanelService {
  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    this.isIdentified = false;
  }

  track(event, payload) {
    this.setSuperProps(payload);
    this.setProfileProps(payload);
    mixpanel.track(event, payload);
  }

  register(profile) {
    if (this.isIdentified) {
      this.logout();
    }

    if (profile.uuid) {
      mixpanel.alias(profile.uuid);
    }

    this.identify(profile);
    this.setSuperProps(profile);
    this.setProfileProps(profile);

    mixpanel.track(EVENTS.Registration, profile);
    this.isIdentified = true;
  }

  login(profile) {
    this.identify(profile);
    this.setSuperProps(profile);
    this.setProfileProps(profile);
    const user = {
      user_type: profile.user_type,
      auth_type: profile.auth_type,
      userID: profile.userID,
      error: profile.error,
    };
    mixpanel.track(EVENTS.LOGIN, user);
  }

  logout() {
    mixpanel.reset();
    this.isIdentified = false;
  }

  identify(profile) {
    if (!profile) {
      return false;
    }

    mixpanel.identify(profile.uuid);
    this.isIdentified = true;
  }

  setSuperProps(payload) {
    if (!payload) {
      return;
    }
    const superToSend = {};
    PROPERTIES.MIXPANEL_SUPER_PROPERTIES.forEach((v) => {
      if (payload.hasOwnProperty(v)) {
        superToSend[v] = payload[v];
      }
    });

    if (Object.keys(superToSend).length) {
      mixpanel.register(superToSend);
    }
  }

  setProfileProps(payload) {
    if (!payload) {
      return;
    }
    const superToSend = {};
    PROPERTIES.MIXPANEL_PROFILE_PROPERTIES.forEach((v) => {
      if (payload.hasOwnProperty(v)) {
        superToSend[v] = payload[v];
      }
    });

    if (Object.keys(superToSend).length) {
      mixpanel.people.set(superToSend);
    }
  }
}
