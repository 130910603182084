import React from "react";
import "./style.scss";
import cn from "classnames";

const ButtonGroup = ({
  page,
  changePage,
  buttonsTab,
  className,
  classNameContainer,
  v2,
  id,
}) => {
  const onClickPage = (event) => {
    if (v2) changePage(event);
    else changePage(event.target.innerHTML);
  };

  return v2 ? (
    <div id={id} className={cn("btn-group", classNameContainer)}>
      {buttonsTab.map(({ icon: Icon, label, value }, index) => (
        <button
          key={index}
          className={cn(
            "btn-group_item",
            { "btn-group_item_active": value === page },
            className,
          )}
          onClick={() => onClickPage(value)}
        >
          {Icon ? <Icon /> : label}
        </button>
      ))}
    </div>
  ) : (
    <div id={id} className="doctor-page-personal-btn-container">
      {buttonsTab.map((title, index) => (
        <button
          key={index}
          className={cn(
            "doctor-page-personal-btn-container_btn",
            { "doctor-page-personal-btn-container_btn_active": title === page },
            className,
          )}
          onClick={onClickPage}
        >
          {title}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
