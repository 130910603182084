import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import "./style.scss";
export const CheckboxV2 = ({
  color,
  label,
  onChange,
  field,
  checked,
  disabled,
}) => {
  const handleChange = (e) => onChange(field, !checked);
  return (
    <FormControlLabel
      className="checkbox-component"
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          color={color}
        />
      }
      label={label}
    />
  );
};
