import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const serviceRequestsSlice = createSlice({
  name: "serviceRequestsSlice",
  initialState,
  reducers: {
    setServiceRequests: (state, { payload }) => {
      state.data = payload;
    },
    clearServiceRequestsSlice: (state) => {
      state.data = null;
    },
  },
});

export const { setServiceRequests, clearServiceRequestsSlice } =
  serviceRequestsSlice.actions;
export const serviceRequestsReducer = serviceRequestsSlice.reducer;
