import React, { Component } from "react";
import "./style.scss";
import { Account } from "../../Common/Icons";
import logo from "../../../dist/images/common/logo.svg";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { colors } from "../../../assets/colors/colors";
import history from "../../../helpers/history";
import patientAvatar from "../../../dist/images/accountAvatar.svg";
import doctorAvatar from "../../../dist/images/doctorAvatar.svg";
import { BlockNotifications } from "../../Bell";
import { BTN_LABELS, routesCommon } from "../../../constants";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";

class CustomHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      activePage: null,
    };
  }

  handleClickOutside = (e) => {
    const conditionClickOnButton = this.refs.iconButton.contains(e.target);
    if (
      this.refs.personalInfoCard &&
      !this.refs.personalInfoCard.contains(e.target) &&
      !conditionClickOnButton
    ) {
      this.setState({
        open: false,
      });
      return;
    }
    if (conditionClickOnButton) this.setState({ open: !this.state.open });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
    if (!this.state.activePage) {
      this.setState({
        activePage: this.props.tabs.findIndex(
          (i) =>
            !!(this.props.location?.pathname.indexOf(i.path) + 1) ||
            !!i.nestedUrls?.find(
              (j) => !!(this.props.location.pathname.indexOf(j) + 1),
            ),
        ),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    if (
      !this.state.activePage &&
      this.props.location.pathname !== nextProps.location.pathname
    ) {
      this.setState({
        activePage: this.props.tabs.findIndex(
          (i) =>
            !!(nextProps.location?.pathname.indexOf(i.path) + 1) ||
            !!i.nestedUrls?.find(
              (j) => !!(nextProps.location.pathname.indexOf(j) + 1),
            ),
        ),
      });
    }
  }

  navigationLanding = () => {
    window.location.href = routesCommon.landing;
  };

  render() {
    let text =
      this.props.user &&
      `${!!this.props.user.user?.firstName ? this.props.user.user.firstName : ""} ${
        !!this.props.user.user?.lastName ? this.props.user.user.lastName : ""
      }`;
    const activePage = this.state.activePage;

    return (
      <>
        <div className={cn("container-custom-header", this.props.className)}>
          <img
            className="container-custom-header_img"
            src={logo}
            alt="logo"
            onClick={this.navigationLanding}
          />
          <div className="container-custom-header_tabs">
            {this.props.tabs.map((item, index) => (
              <div
                className={cn("container-custom-header_tabs_item", {
                  "container-custom-header_tabs_item-active":
                    activePage === index,
                })}
                key={index}
                onClick={() => {
                  history.push(item.path);
                  this.setState({ activePage: index });
                }}
              >
                {item.value}
              </div>
            ))}
          </div>
          <div className="container-custom-header_right-block">
            {!this.props.isAdmin && (
              <BlockNotifications
                user={this.props.isDoctor || this.props.isPatient}
              />
            )}
            <button
              ref="iconButton"
              className="container-custom-header_right-block_account-menu-content_button"
            >
              <Account className="container-custom-header_right-block_account-menu-content_button_icon" />
            </button>
            <div
              className="containers-popup-details-user"
              style={{ display: this.state.open ? "flex" : "none" }}
              ref="personalInfoCard"
            >
              {this.props.user && (
                <div className="container-custom-header_right-block_account-menu-content_personal-info">
                  <img
                    className="container-custom-header_right-block_account-menu-content_personal-info_img"
                    src={this.props.isPatient ? patientAvatar : doctorAvatar}
                  />
                  <div className="container-custom-header_right-block_account-menu-content_personal-info_wrapper-text">
                    <Typography color={colors.black} text={text} variant="p" />
                    {this.props.mainPath && (
                      <ButtonV2
                        uppercase
                        onClick={(e) => {
                          history.push(`${this.props.mainPath}/personal-info`);
                        }}
                        color={COLORS_BTN.PRIMARY}
                        variant={VARIATIONS_BTN.TEXT}
                      >
                        {BTN_LABELS.VIEW_PROFILE}
                      </ButtonV2>
                    )}
                  </div>
                </div>
              )}
              <div className="container-custom-header_right-block_account-menu-content_container-button">
                <ButtonV2
                  capitalize
                  color={COLORS_BTN.PRIMARY}
                  variant={VARIATIONS_BTN.CONTAINED}
                  onClick={() => {
                    window.localStorage.removeItem("access_token");
                    window.localStorage.removeItem("refresh_token");
                    window.localStorage.removeItem("user_uuid");
                    window.location.href = routesCommon.landing;
                  }}
                >
                  {BTN_LABELS.LOG_OUT}
                </ButtonV2>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomHeader;
