export const configPMHExtra = {
  diagnoses: {
    urlOptions: "electronic-medical-record/icd-10-list/",
    proceedOptions: (options) =>
      options.map((i) => ({
        value: i.name,
        code: i.code,
        label: i.name + ` (${i.code})`,
      })),
  },
  medicationsPrescribed: {
    urlOptions: null,
    proceedOptions: (options) =>
      options.map((i) => ({ value: i.name, code: i.code, label: i.name })),
  },
};

export const configPMH = {
  pastMedicalHistory: {
    textAreaData: "",
    fieldDataTextArea: "comment",
    placeholderTextArea: "Additional Information",
    urlOptions: "electronic-medical-record/icd-10-list/",
    textAddButton: "PAST MEDICAL HISTORY",
    proceedOptions: (options) =>
      options.map((i) => ({
        value: i.name,
        code: i.code,
        label: i.name + ` (${i.code})`,
      })),
  },
  familySocialHistory: {
    creatable: true,
    urlOptions:
      "electronic-medical-record/past-medical-history/family-social-history-choices/",
    textAddButton: "FAMILY AND SOCIAL HISTORY",
    proceedOptions: (options) =>
      options.map((i) => ({ value: i.name, code: i.code, label: i.name })),
  },
  medications: {
    urlOptions: null,
    textAreaData: "",
    fieldDataTextArea: "appointedMedications",
    placeholderTextArea: "Medication",
    textAddButton: "MEDICATIONS",
    proceedOptions: (options) =>
      options.map((i) => ({ value: i.name, code: i.code, label: i.name })),
  },
  medicationAllergies: {
    urlOptions: null,
    textAreaData: "",
    fieldDataTextArea: "appointedAllergies",
    placeholderTextArea: "Allergies",
    textAddButton: "ALLERGIES",
    proceedOptions: (options) =>
      options.map((i) => ({ value: i.name, code: i.code, label: i.name })),
  },
};

export const updateArrJson = ({ newData, idxChild, idxParent, jsonTree }) => {
  return jsonTree
    .map((i, idx) =>
      idx === idxParent
        ? {
            ...i,
            items: i.items
              .map((y, ydx) => (ydx === idxChild ? newData : y))
              .filter((i) => i),
          }
        : i,
    )
    .filter((i) => i.items?.length);
};

export const checkIsVitalRequired = (data) => {
  const dataLength = data.data.length;
  const emptyLength = data.data.filter((i) => !i.data).length;
  const filledLength = data.data.filter((i) => i.data).length;
  return (
    data.noMandatory &&
    emptyLength !== dataLength &&
    filledLength !== dataLength
  );
};

export const assessmentsPlanKeys = {
  medications: "medications",
  diagnosis: "diagnosis",
  textAreaAssessmentPlan: "txtAssessmentPlan",
  txtFirstPart: "txtFirstPart",
  assessments_plan: "assessments_plan",
};
