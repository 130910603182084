export const TetanusStatus = () => {
  return {
    type: "label",
    data: "TETANUS STATUS:",
    items: [
      {
        type: "radio-block",
        data: [
          { checked: true, data: "No up-to-date" },
          { checked: false, data: "up-to-date" },
        ],
      },
    ],
  };
};
