import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import cn from "classnames";
import { ArrowLeft, CommentArrow, Cross } from "../Common/Icons";
import { Textarea } from "../Common/TextArea/Textarea";
import doctorAvatar from "../../dist/images/patientAvatar.svg";
import patientAvatar from "../../dist/images/accountAvatar.svg";
import { colors } from "../../assets/colors/colors";
import { useDispatch, useSelector } from "react-redux";
import { getChatTwilioSid } from "../../store/twilio/actions";
import { clearMessageSlice, setMessages } from "../../store/message/slice";
import { setAllMessagesRead } from "../../store/message/actions";
import { readNotification } from "../../store/notification/slice";
import { v1 as uuidv1 } from "uuid";
import moment from "moment";
import { showChats } from "utils/common";
import { updateRequestOnListDoctor } from "store/doctor/actions";
import { notificationsSelector } from "store/notification/selectors";
import { twilioClientSelector } from "../../store/twilio/selectors";
import { messagesSelector } from "../../store/message/selectors";
import { currentUserSelector } from "../../store/currentUser/selectors";

let isMessageSend = true;

export const Chat = ({
  open,
  closeChat,
  isDoctor,
  isPatient,
  dataRequest,
  className,
}) => {
  const [value, setValue] = useState("");
  const [channel, setChannel] = useState(null);
  const [subscribeChat, setSubscribeChat] = useState(false);

  const messagesRef = useRef(null);
  const notifications = useSelector(notificationsSelector);
  const twilioClient = useSelector(twilioClientSelector);
  const messages = useSelector(messagesSelector);
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const serviceRequestId = dataRequest?.uuid;
  const requester = dataRequest?.client_user_info;

  const setUpSettingsChat = async () => {
    const container = document.querySelector(".main-container-app");
    if (container) {
      if (open) {
        if (isDoctor) container.style.overflowY = "hidden";
        if (twilioClient) {
          const chatTwilioSid = await getChatTwilioSid({
            request: serviceRequestId,
          });
          const channelResp = await twilioClient.channels.getChannel(
            chatTwilioSid.twilio_chat_sid,
          );
          const messagesResp = await channelResp.getMessages(1000);
          channelResp.setAllMessagesConsumed();
          setChannel(channelResp);
          dispatch(readNotification(serviceRequestId));
          if (isDoctor) {
            dispatch(
              updateRequestOnListDoctor({
                mess: false,
                uuid: serviceRequestId,
              }),
            );
          }
          setSubscribeChat(true);
          dispatch(clearMessageSlice());
          dispatch(
            setMessages(
              messagesResp.items.map((i) => ({
                mess: i.state.body,
                my: i.state.author === (user?.user?.uuid || user.uuid),
                uuid: uuidv1(),
              })),
            ),
          );
        }
      } else {
        // if(isPatient && detailsRequest) detailsRequest.style.heigth = 'initial'
        container.style.overflowY = "initial";
      }
    }
  };

  useEffect(() => {
    setUpSettingsChat();
  }, [open, !!twilioClient]);

  useEffect(() => {
    if (channel) {
      if (subscribeChat) {
        channel.on("messageAdded", onMessageAdded);
      } else {
        setChannel(null);
        channel.removeAllListeners();
      }
    }
  }, [subscribeChat]);

  useEffect(() => {
    if (!open) {
      setSubscribeChat(false);
      dispatch(clearMessageSlice());
    }
  }, [open]);

  const sendMessage = () => {
    if (value?.trim() && channel && isMessageSend) {
      isMessageSend = false;
      channel.sendMessage(value?.trim(), {
        To: user.twilio_sid,
      });
    }
  };

  useEffect(() => {
    if (!!notifications?.length && open) {
      setAllMessagesRead({ request: serviceRequestId });
      if (channel) channel.setAllMessagesConsumed();
    }
  }, [notifications?.length, open]);

  useEffect(() => {
    if (!!messages.length) {
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current?.scrollHeight;
      }
    }
  }, [messages.length]);

  const onMessageAdded = (event) => {
    setValue("");
    isMessageSend = true;
    dispatch(
      setMessages([
        {
          mess: event.state.body,
          my: event.state.author === user.uuid,
          uuid: uuidv1(),
        },
      ]),
    );
  };

  useEffect(() => {
    return () => {
      const container = document.querySelector(".main-container-app");
      if (container) container.style.overflowY = "initial";
    };
  }, []);

  const chatIsAvailable = showChats(dataRequest);

  let note = `This chat is available until ${moment(
    dataRequest?.service_request_chat?.expire_at,
  ).format("hh:mm a MM/DD/YYYY")}`;
  if (!chatIsAvailable) {
    if (isPatient)
      note =
        "<span>This chat is no longer available. Please email <a href='mailto:contactus@pbdhc.com'>contactus@pbdhc.com</a></span>";
    else note = "This chat is no longer available";
  }
  const nameDoctor =
    dataRequest?.doctor_data &&
    (dataRequest?.doctor_data.initials || dataRequest?.doctor_data.last_name);

  return (
    <div
      className={cn(
        "chat-block",
        { "chat-block_open": open, "chat-block_is-patient": isPatient && open },
        className,
      )}
    >
      <div className="chat-block_navigation">
        <ArrowLeft onClick={closeChat} />
        <Cross onClick={closeChat} />
      </div>
      <div className="chat-block_recipient">
        <img
          src={isDoctor ? patientAvatar : doctorAvatar}
          className="chat-block_recipient_img"
          alt="avatar"
        />
        <span className="chat-block_recipient_name">
          {isPatient
            ? `Dr. ${nameDoctor}`
            : `${requester?.first_name} ${requester?.last_name}`}
        </span>
      </div>
      <div className="chat-block_container">
        <div className="chat-block_messages" ref={messagesRef}>
          {messages.map((i, idx, arr) => {
            console.log(messages);
            const nextIsAlias = arr[idx + 1]?.my !== i.my;
            return (
              <div
                key={i.uuid}
                className={cn("chat-block_messages_item", {
                  "chat-block_messages_item_my": i.my,
                  "chat-block_messages_item_next-alias": nextIsAlias,
                })}
              >
                <div className="chat-block_messages_item_mess">{i.mess}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="chat-block_input-block">
        <span
          className="chat-block_input-block_time"
          dangerouslySetInnerHTML={{ __html: note }}
        />
        <Textarea
          disabled={!chatIsAvailable}
          placeholder="Your message"
          value={value}
          onKeyPress={(e) => {
            if (e.charCode === 13) sendMessage();
          }}
          field="comment"
          onChange={(e) => {
            setValue(e.target.value.replace(/\n/g, ""));
          }}
          className=""
        />
        <CommentArrow onClick={sendMessage} color={value ? colors.navy : ""} />
      </div>
    </div>
  );
};
