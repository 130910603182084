import * as components from "./components";

import { GroupBlockReviewOfSystem } from "./components/GroupBlockReviewOfSystems";

export const template = {
  name: "Epistaxis without packing",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Nose bleed",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who reports acute onset of unilateral, atraumatic nosebleed today. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [{ checked: false, data: "other bleeding" }],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "REVIEW OF SYSTEMS:",
        items: [
          GroupBlockReviewOfSystem({ title: "General:", radio: "fever" }),
          GroupBlockReviewOfSystem({ title: "HENT:", radio: "congestion" }),
          GroupBlockReviewOfSystem({ title: "Respiratory:", radio: "cough" }),
          GroupBlockReviewOfSystem({
            title: "Cardiovascular:",
            radio: "chest pain",
          }),
          GroupBlockReviewOfSystem({ title: "GI:", radio: "abdominal pain" }),
          GroupBlockReviewOfSystem({
            title: "GU:",
            radio: "urinary complaints",
          }),
          GroupBlockReviewOfSystem({
            title: "Musculoskeletal:",
            radio: "other aches or pains",
          }),
          GroupBlockReviewOfSystem({
            title: "Endocrine:",
            radio: "generalized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Neurological:",
            radio: "localized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Psychiatric:",
            radio: "emotional stress",
          }),
        ],
      },
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "petechiae",
                "ecchymosis",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["icterus"], true),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Nose: Speculum exam:",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "bleeding site" },
                ],
              },
              {
                type: "text",
                data: "identified anterior nasal septum. Pharynx: Clear except-clots. Airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(
            components.RenderRadioSingleBLocks(["thyromegaly"], true),
          ),
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "rales",
                "rhonchi",
                "wheezes",
              ]),
              {
                type: "text",
                data: "; breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          components.HeartCardiovascular(),

          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Extremities(),
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      {
        type: "label",
        data: "TREATMENT",
        required: true,
        items: [
          {
            type: "blockCheckboxCondition",
            extraType: "treatment",
            data: {
              checkbox: [{ checked: true, data: "", isHide: true }],
              hiddenBlock: [
                {
                  type: "text",
                  data: "Hemostasis achieved with",
                  editable: true,
                  source: null,
                },
                {
                  type: "radio-block",
                  data: [
                    { checked: false, data: "pressure" },
                    { checked: false, data: "AgNO3" },
                  ],
                },
                {
                  type: "text",
                  data: ".",
                  editable: true,
                  source: null,
                },
                {
                  type: "input",
                  placeholder: "",
                  data: "",
                },
              ],
            },
          },
        ],
      },
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged by",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "patient" },
                  { checked: false, data: "caretaker" },
                  { checked: false, data: "me" },
                ],
              },
              {
                type: "text",
                data: "with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "ENT" },
                ],
              },
              {
                type: "text",
                data: "in",
                editable: true,
                source: null,
              },
              {
                type: "input-static-text",
                label: "",
                data: [{ data: "", mask: "999", min: 0, max: 365 }],
                deviders: [],
              },
              {
                type: "text",
                data: "days for further evaluation.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
