import React from "react";
import "./style.scss";
import { DoctorInfo } from "./DoctorInfo";
import { Separator, RequestedBy, PriceSummary } from "../components";
import { RequestInfo } from "../../Common/RequestInfo/RequestInfo";
import {
  ACTIVATE_REQUEST,
  buttonsInnerText,
  CANCEL_REQUEST,
  DECLINED,
  PLACEHOLDERS,
} from "../../../constants";
import { ShiftRange } from "./ShiftRange";
import { ListAppointmetns } from "./ListAppointmetns";
import { backToShift } from "../../../constants/Admin";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { Map } from "../../../compoenentsAdditional/Map";
import { PatientSidebar } from "../../Common/PatientSidebar";
import { ADMIN_STEPS } from "../../Sidebar/constants";
import { MultipleVisitsList } from "./MultipleVisitsList";
import { GroupCard } from "../../Common/GroupCard/GroupCard";
import { Textarea } from "../../Common/TextArea/Textarea";
import cn from "classnames";
import { groupPatientsByRelation } from "../../../helpers/helpers";
import { AdminInfo } from "./AdminInfo";

export const ContainerDetailsAppointmentAdmin = ({
  filtersShifts,
  detailsAppointment,
  selectedShift,
  appointmentsSelectedDoctors,
  editShift,
  viewDetailsAppointmentOrShift,
  openFromShiftOrAppointment,
  openConfirmationDelete,
  confirmationPopup,
  declineAction,
  doctorShift,
  closeConfirmation,
  status,
  activePage,
  multipleRequestList,
  isMultiple,
  closeRequest,
  multipleUuid,
  selectedEvent,
  appointment,
  setActivePage,
  reasonCancellation,
  setReasonCancellation,
}) => {
  if ((appointment && !detailsAppointment) || (!appointment && !selectedShift))
    return <></>;
  const isDeclined =
    detailsAppointment?.data?.status?.toLowerCase() === DECLINED;
  const textPrimaryPopup = isDeclined
    ? buttonsInnerText.activate_request
    : buttonsInnerText.cancel_request;
  const titlePopup = isDeclined ? ACTIVATE_REQUEST.TITLE : CANCEL_REQUEST.TITLE;
  const subTitle = isDeclined
    ? ACTIVATE_REQUEST.SUB_TITLE
    : CANCEL_REQUEST.SUB_TITLE;
  return (
    <div className="container-details-appointment-admin">
      {activePage === ADMIN_STEPS.ORDER_DETAILS && detailsAppointment?.data && (
        <>
          {detailsAppointment.data?.doctor_data && (
            <DoctorInfo
              data={detailsAppointment.data?.doctor_data}
              isAppointment={openFromShiftOrAppointment !== backToShift}
              filtersShifts={filtersShifts}
              viewDetailsAppointmentOrShift={viewDetailsAppointmentOrShift}
            />
          )}
          <RequestInfo
            hideType
            data={detailsAppointment.data}
            status={status}
          />
          <PriceSummary data={detailsAppointment.data} />
          <Separator />
          <RequestedBy data={detailsAppointment.data} />
          <AdminInfo data={detailsAppointment.data} />
          {detailsAppointment.data?.is_group ? (
            <GroupCard
              onClick={() => setActivePage(ADMIN_STEPS.PATIENTS_LIST)}
              data={detailsAppointment.data}
            />
          ) : (
            <>
              <span className="details-request_body_you">
                {
                  detailsAppointment.data?.patients[0]
                    .client_patient_relationship
                }
              </span>
              <PatientSidebar
                data={detailsAppointment.data?.patients[0]}
                hideExamination
                hideAddButton
                hideNote={!detailsAppointment.data?.patients[0].doctor_comment}
                isAdmin
                detailsRequest={detailsAppointment.data}
                hideSignature={
                  !detailsAppointment.data?.patients[0]
                    .patient_signature_path ||
                  detailsAppointment.data?.patients[0].status === "main"
                }
              />
            </>
          )}
          <Map
            data={detailsAppointment.data}
            isShowSuite
            comment={detailsAppointment.data?.location.comment}
            isOutWorkingZone={
              detailsAppointment?.data?.location?.is_out_of_working_zone
            }
          />
        </>
      )}
      {activePage === ADMIN_STEPS.SHIFT_PAGE && (
        <>
          <DoctorInfo data={doctorShift} filtersShifts={filtersShifts} />
          {selectedShift && (
            <ShiftRange
              data={selectedShift}
              editShift={editShift}
              openConfirmationDelete={openConfirmationDelete}
            />
          )}
          <ListAppointmetns
            data={appointmentsSelectedDoctors}
            viewDetailsAppointmentOrShift={viewDetailsAppointmentOrShift}
          />
        </>
      )}
      {activePage === ADMIN_STEPS.PATIENTS_LIST && (
        <>
          {groupPatientsByRelation(
            detailsAppointment?.data.patients,
            true,
          )?.map((i, idx) => {
            const label = i.label === "null" ? "Requester" : i.label;
            return (
              <>
                <div className="details-request_body_relation_tag">{label}</div>
                {i.items.map((j) => {
                  return (
                    <PatientSidebar
                      data={j}
                      key={idx}
                      isAdmin
                      hideExamination
                      hideAddButton
                      hideNote={!j.doctor_comment}
                      detailsRequest={detailsAppointment.data}
                      hideSignature={
                        !j.patient_signature_path || j.status === "main"
                      }
                    />
                  );
                })}
              </>
            );
          })}
        </>
      )}
      {activePage === ADMIN_STEPS.MULTIPLE && (
        <MultipleVisitsList
          data={multipleRequestList}
          viewDetailsAppointmentOrShift={viewDetailsAppointmentOrShift}
          detailsAppointment={detailsAppointment?.data}
          isMultiple={isMultiple}
          closeRequest={closeRequest}
          multipleUuid={multipleUuid}
          appointment={appointment}
          selectedEvent={selectedEvent}
        />
      )}
      <PopupContainer
        close={closeConfirmation}
        open={confirmationPopup}
        capitalize
        textDefault={buttonsInnerText.back_to_calendar}
        textPrimary={textPrimaryPopup}
        actionDefaultButton={closeConfirmation}
        actionPrimaryButton={declineAction}
        title={titlePopup}
      >
        <span
          className={cn("cancel-request-sub-title", { activated: isDeclined })}
        >
          {subTitle}
        </span>
        {!isDeclined && (
          <Textarea
            placeholder={PLACEHOLDERS.REASON}
            value={reasonCancellation}
            maxLength={1000}
            onChange={(e) => setReasonCancellation(e.target.value)}
          />
        )}
      </PopupContainer>
    </div>
  );
};
