export const Extremities = (extra = []) => {
  return {
    type: "label",
    data: "Extremities:",
    canDelete: true,
    items: [
      {
        type: "input",
        placeholder: "",
        data: "",
      },
      {
        type: "radio-single",
        data: [{ checked: false, data: "deformity" }],
      },
      {
        type: "text",
        data: ", ",
        editable: true,
        source: null,
      },
      {
        type: "radio-single",
        data: [{ checked: false, data: "edema" }],
      },
      ...extra,
      {
        type: "text",
        data: ".",
        editable: true,
        source: null,
      },
    ],
  };
};
