import React from "react";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { Delta } from "../../../Common/Delta/Delta";

export const Summary = (props) => (
  <div className="summary-block">
    <div className="summary-additional-info">
      <div className="additional-item">
        <Typography variant="p" text="Total visits" />
        <div className="summary-info">
          <Typography variant="h2_5" text={props.total_visits} />
          <Delta delta={props.total_visits_difference} />
        </div>
      </div>
      <div className="additional-item">
        <Typography variant="p" text="Total patients" />
        <div className="summary-info">
          <Typography variant="h2_5" text={props.totalPatientCount} />
        </div>
      </div>
    </div>
    <div className="summary-additional-info">
      <div className="additional-item">
        <Typography variant="p" dangerouslySetInnerHTML={`New<br/>patients`} />
        <div className="summary-info">
          <Typography variant="h2_5" text={props.new_patients} />
          <Delta delta={props.new_patients_difference} />
        </div>
      </div>
      <div className="additional-item">
        <Typography variant="p" text="Established Patients" />
        <div className="summary-info">
          <Typography variant="h2_5" text={props.established_patients} />
          <Delta delta={props.established_patients_difference} />
        </div>
      </div>
    </div>
  </div>
);
