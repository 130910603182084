import * as components from "./components";

export const template = {
  name: "Impetigo",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Rash",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "whose caretaker reports that child has had facial rash for",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: ". Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "alteration of activity" },
              { checked: false, data: "fever" },
              { checked: false, data: "sore throat" },
              { checked: false, data: "earache" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks(["honey-colored"]),
              {
                type: "text",
                data: ", erythematous, crusted rash of",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "location",
                data: "",
              },
              {
                type: "text",
                data: ";",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["desquamation"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "periorbital swelling or erythema",
              ]),
              {
                type: "text",
                data: ". Pharynx:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["injection", "exudate"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neck:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "stiffness",
                "meningismus",
                "lymphadenopathy",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Extremities(),
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above; interacts appropriately for age. Strength and tone good.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "dermatologist" },
                ],
              },
              {
                type: "text",
                data: "as directed. Daily cleanse, topical Mupirocin ointment (Bactroban). Go to the nearest ED if patient feels worse, difficulty breathing or new symptoms.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
