import React, { useRef } from "react";
import Dropzone from "react-dropzone";
import folder from "../../../dist/images/folder.svg";
import cn from "classnames";

export const DropContainer = ({ onCatchDrop, className, errorClassName }) => {
  const regDrop = useRef("dropzoneRef");

  const openDialog = () => regDrop && regDrop.current.open();

  return (
    <Dropzone ref={regDrop} noClick noKeyboard onDrop={onCatchDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            className={cn(
              "doctor-page-employment_container__dropzone",
              className,
              errorClassName,
            )}
            onClick={openDialog}
          >
            <div
              {...getRootProps({
                className: "dropzone",
              })}
            >
              {!isDragActive ? (
                <>
                  <img src={folder} alt="folder" />
                  <input {...getInputProps()} />
                  <div>
                    <p className="dropzone_title">Drop your files here, or</p>
                    <button type="button" className="dropzone-btn">
                      Browse
                    </button>
                  </div>
                </>
              ) : (
                <div className="doctor-page-employment_container__dropzone-hover">
                  Drag And Drop Here
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};
