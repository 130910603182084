export const EVENTS = {
  Page_Open: "Page_Open",
  Registration: "Registration",
  Main_Page_Open: "Main_Page_Open",
  Main_Page_Request_Visit_Now_Btn_Pressed:
    "Main_Page_Request_Visit_Now_Btn_Pressed",
  Main_Page_Comprehensive_Visit_Btn_Pressed:
    "Main_Page_Comprehensive_Visit_Btn_Pressed",
  Main_Page_Rapid_Covid_Test_Visit_Btn_Pressed:
    "Main_Page_Rapid_Covid_Test_Visit_Btn_Pressed",
  Main_Page_Services_Send_Doctor_Comprehensive_Visit_Btn_Pressed:
    "Main_Page_Services_Send_Doctor_Comprehensive_Visit_Btn_Pressed",
  Main_Page_Services_Send_Doctor_Rapid_Covid_Test_Visit_Btn_Pressed:
    "Main_Page_Services_Send_Doctor_Rapid_Covid_Test_Visit_Btn_Pressed",
  Services_Page_Open: "Services_Page_Open",
  Services_Page_Request_Visit_Now_Btn_Pressed:
    "Services_Page_Request_Visit_Now_Btn_Pressed",
  Services_Page_Comprehensive_Visit_Btn_Pressed:
    "Services_Page_Comprehensive_Visit_Btn_Pressed",
  Services_Page_Rapid_Covid_Test_Visit_Btn_Pressed:
    "Services_Page_Rapid_Covid_Test_Visit_Btn_Pressed",
  Born_Page_Open: "Born_Page_Open",
  Born_Page_Request_Visit_Now_Btn_Pressed:
    "Born_Page_Request_Visit_Now_Btn_Pressed",
  FAQ_Page_Open: "FAQ_Page_Open",
  FAQ_Page_Request_Visit_Now_Btn_Pressed:
    "FAQ_Page_Request_Visit_Now_Btn_Pressed",
  Careers_Page_Open: "Careers_Page_Open",
  Careers_Page_Request_Visit_Now_Btn_Pressed:
    "Careers_Page_Request_Visit_Now_Btn_Pressed",
  Careers_Page_Apply_Now_Btn_Pressed: "Careers_Page_Apply_Now_Btn_Pressed",
  Contact_Page_Open: "Contact_Page_Open",
  Contact_Page_Request_Visit_Now_Btn_Pressed:
    "Contact_Page_Request_Visit_Now_Btn_Pressed",
  Contact_Page_Apply_Now_Btn_Pressed: "Contact_Page_Apply_Now_Btn_Pressed",
  Symptoms_Page_Open: "Symptoms_Page_Open",
  Symptoms_Page_Cancel_Btn_Pressed: "Symptoms_Page_Cancel_Btn_Pressed",
  Symptoms_Page_Close_Btn_Pressed: "Symptoms_Page_Close_Btn_Pressed",
  Symptoms_Page_Next_Btn_Pressed: "Symptoms_Page_Next_Btn_Pressed",
  Symptoms_Page_Symptom_Checked: "Symptoms_Page_Symptom_Checked",
  Symptoms_Page_Terms_Checked: "Symptoms_Page_Terms_Checked",
  Time_Page_Open: "Time_Page_Open",
  Time_Page_Send_Now_Selected: "Time_Page_Send_Now_Selected",
  Time_Page_Send_Later_Selected: "Time_Page_Send_Later_Selected",
  Time_Page_Close_Btn_Pressed: "Time_Page_Close_Btn_Pressed",
  Time_Page_Back_Btn_Pressed: "Time_Page_Back_Btn_Pressed",
  Time_Page_Cancel_Btn_Pressed: "Time_Page_Cancel_Btn_Pressed",
  Time_Page_Next_Btn_Pressed: "Time_Page_Next_Btn_Pressed",
  Login_Page_Open: "Login_Page_Open",
  Login_Page_Close_Btn_Pressed: "Login_Page_Close_Btn_Pressed",
  Login_Page_Back_Btn_Pressed: "Login_Page_Back_Btn_Pressed",
  Login_Page_Phone_Next_Btn_Pressed: "Login_Page_Phone_Next_Btn_Pressed",

  Comprehensive_Symptoms_Page_Open: "Comprehensive_Symptoms_Page_Open",
  Comprehensive_Symptoms_Page_Cancel_Btn_Pressed:
    "Comprehensive_Symptoms_Page_Cancel_Btn_Pressed",
  Comprehensive_Symptoms_Page_Next_Btn_Pressed:
    "Comprehensive_Symptoms_Page_Next_Btn_Pressed",
  Comprehensive_Symptoms_Page_Symptom_Checked:
    "Comprehensive_Symptoms_Page_Symptom_Checked",
  Comprehensive_Symptoms_Page_Terms_Checked:
    "Comprehensive_Symptoms_Page_Terms_Checked",

  Comprehensive_Time_Page_Open: "Comprehensive_Time_Page_Open",
  Comprehensive_Time_Page_Send_Now_Selected:
    "Comprehensive_Time_Page_Send_Now_Selected",
  Comprehensive_Time_Page_Send_Later_Selected:
    "Comprehensive_Time_Page_Send_Later_Selected",
  Comprehensive_Time_Page_Cancel_Btn_Pressed:
    "Comprehensive_Time_Page_Cancel_Btn_Pressed",
  Comprehensive_Time_Page_Next_Btn_Pressed:
    "Comprehensive_Time_Page_Next_Btn_Pressed",
  Comprehensive_Patient_Page_Open: "Comprehensive_Patient_Page_Open",
  Comprehensive_Patient_Yes_Selected: "Comprehensive_Patient_Yes_Selected",
  Comprehensive_Patient_No_Selected: "Comprehensive_Patient_No_Selected",
  Comprehensive_Patient_Cancel_Btn_Pressed:
    "Comprehensive_Patient_Cancel_Btn_Pressed",
  Comprehensive_Patient_Next_Btn_Pressed:
    "Comprehensive_Patient_Next_Btn_Pressed",

  Comprehensive_Address_Page_Open: "Comprehensive_Address_Page_Open",
  Comprehensive_Address_Cancel_Btn_Pressed:
    "Comprehensive_Address_Cancel_Btn_Pressed",
  Comprehensive_Address_Next_Btn_Pressed:
    "Comprehensive_Address_Next_Btn_Pressed",
  Comprehensive_Review_Page_Open: "Comprehensive_Review_Page_Open",
  Comprehensive_Review_Cancel_Btn_Pressed:
    "Comprehensive_Review_Cancel_Btn_Pressed",
  Comprehensive_Review_Send_Doctor_Now_Btn_Pressed:
    "Comprehensive_Review_Send_Doctor_Now_Btn_Pressed",
  Rapid_Covid_Test_Time_Page_Open: "Rapid_Covid_Test_Time_Page_Open",
  Rapid_Covid_Test_Time_Page_Send_Now_Selected:
    "Rapid_Covid_Test_Time_Page_Send_Now_Selected",
  Rapid_Covid_Test_Time_Page_Send_Later_Selected:
    "Rapid_Covid_Test_Time_Page_Send_Later_Selected",
  Rapid_Covid_Test_Time_Page_Cancel_Btn_Pressed:
    "Rapid_Covid_Test_Time_Page_Cancel_Btn_Pressed",
  Rapid_Covid_Test_Time_Page_Next_Btn_Pressed:
    "Rapid_Covid_Test_Time_Page_Next_Btn_Pressed",
  Rapid_Covid_Test_Patient_Page_Open: "Rapid_Covid_Test_Patient_Page_Open",
  Rapid_Covid_Test_Patient_Yes_Btn_Pressed:
    "Rapid_Covid_Test_Patient_Yes_Btn_Pressed",
  Rapid_Covid_Test_Patient_No_Btn_Pressed:
    "Rapid_Covid_Test_Patient_No_Btn_Pressed",
  Rapid_Covid_Test_Patient_Yes_Cancel_Btn_Pressed:
    "Rapid_Covid_Test_Patient_Yes_Cancel_Btn_Pressed",
  Rapid_Covid_Test_Patient_Yes_Next_Btn_Pressed:
    "Rapid_Covid_Test_Patient_Yes_Next_Btn_Pressed",
  Rapid_Covid_Test_Address_Page_Open: "Rapid_Covid_Test_Address_Page_Open",
  Rapid_Covid_Test_Address_Cancel_Btn_Pressed:
    "Rapid_Covid_Test_Address_Cancel_Btn_Pressed",
  Rapid_Covid_Test_Address_Next_Btn_Pressed:
    "Rapid_Covid_Test_Address_Next_Btn_Pressed",
  Rapid_Covid_Test_Review_Page_Open: "Rapid_Covid_Test_Review_Page_Open",
  Rapid_Covid_Test_Review_Cancel_Btn_Pressed:
    "Rapid_Covid_Test_Review_Cancel_Btn_Pressed",
  Rapid_Covid_Test_Review_Send_Doctor_Now_Btn_Pressed:
    "Rapid_Covid_Test_Review_Send_Doctor_Now_Btn_Pressed",

  Comprehensive_Add_Card_Page_Open: "Comprehensive_Add_Card_Page_Open",
  Rapid_Covid_Add_Card_Page_Open: "Rapid_Covid_Add_Card_Page_Open",

  Comprehensive_Add_Card_Back_Btn_Pressed:
    "Comprehensive_Add_Card_Back_Btn_Pressed",
  Comprehensive_Add_Card_Pay_Btn_Pressed:
    "Comprehensive_Add_Card_Pay_Btn_Pressed",
  Request_Details_Page_Open: "Request_Details_Page_Open",
  Request_Details_Close_Btn_Pressed: "Request_Details_Close_Btn_Pressed",
  Login_Page_Code_Resend_Btn_Pressed: "Login_Page_Code_Resend_Btn_Pressed",
  Login_Page_Code_Next_Btn_Pressed: "Login_Page_Code_Next_Btn_Pressed",
  User_Info_Page_Open: "User_Info_Page_Open",
  User_Info_Page_Cancel_Btn_Pressed: "User_Info_Page_Cancel_Btn_Pressed",
  User_Info_Page_Next_Btn_Pressed: "User_Info_Page_Next_Btn_Pressed",
  Rapid_Covid_Test_Patient_Add_New_Patient_Page_Open:
    "Rapid_Covid_Test_Patient_Add_New_Patient_Page_Open",

  Request_Details_Page_Open_From_Creation_Flow:
    "Request_Details_Page_Open_From_Creation_Flow",

  Request_Details_Page_Open_From_Comprehensive_Flow:
    "Request_Details_Page_Open_From_Comprehensive_Flow",
  Request_Details_Page_Open_From_Rapid_Covid_Test_Flow:
    "Request_Details_Page_Open_From_Rapid_Covid_Test_Flow",
};
