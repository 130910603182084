import React, { useState } from "react";
import { DoctorSidebar } from "../../DocotrSidebar/DoctorSidebar";
import { ButtonV2 } from "../../../Common/ButtonV2";
import { COLORS_BTN } from "../../../Common/ButtonV2/constants";
import { BTN_LABELS } from "../../../../constants";
import "./style.scss";
import { useSelector } from "react-redux";
import { displayChatSelector } from "../../../../store/app/selectors";

export const EMRSidebar = ({
  data,
  doctorData,
  setStatusAndArrive,
  setNeedRefresh,
  isFromEmr,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isChatOpen = useSelector(displayChatSelector);

  return (
    <div className="emr-sidebar">
      <ButtonV2
        color={COLORS_BTN.PRIMARY}
        onClick={() => setIsOpen(true)}
        uppercase
        className="emr-sidebar__button"
      >
        {BTN_LABELS.SHOW_FULL_REQUEST}
      </ButtonV2>
      {data && doctorData && (
        <DoctorSidebar
          isChatOpen={isChatOpen}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          data={data}
          isFromEmr={isFromEmr}
          userData={data?.data.doctor_data}
          doctorCurrent={doctorData}
          setStatusAndArrive={setStatusAndArrive}
          setNeedRefresh={setNeedRefresh}
        />
      )}
    </div>
  );
};
