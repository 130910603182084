import React from "react";
import "./style.scss";
import cn from "classnames";
import { DisabledOverlay } from "../DisabledOverlay";
import { modesTemplate } from "../../../../../constants/Doctors";
import { Element } from "../../chooserElements";
import { ButtonV2 } from "../../../../Common/ButtonV2";
import {
  COLORS_BTN,
  VARIATIONS_BTN,
} from "../../../../Common/ButtonV2/constants";
import { IconButton } from "../../../../Common/IconButton";
import { SIZES_ICON_BUTTON } from "../../../../Common/IconButton/constants";

export const GroupBlock = ({
  data,
  updateState,
  DbData,
  error,
  disabled,
  dataRequest,
}) => {
  const updateLocalState = (dataItem, idx) => {
    updateState({
      ...data,
      items: data.items.map((i, index) => (index === idx ? dataItem : i)),
    });
  };

  const handleDelete = () => {
    updateState(null);
  };
  return (
    <span className={cn("emr-group-block")}>
      {data.items.map((i, index) => (
        <Element
          parentData={data}
          dataRequest={dataRequest}
          mode={modesTemplate.render}
          error={error}
          type={i.type}
          updateState={(...args) => updateLocalState(...args, index)}
          data={i}
          DbData={DbData}
        />
      ))}
      <IconButton
        onClick={handleDelete}
        className="emr-group-block_delete"
        size={SIZES_ICON_BUTTON.SMALL}
      />
      <DisabledOverlay show={disabled} />
    </span>
  );
};
