import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./DoctorsList.style.scss";
import { CustomTable } from "../../Table/Table";
import { colors } from "../../../assets/colors/colors";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { useDispatch, useSelector } from "react-redux";
import { PanelFilters } from "../../AdminEmr/PanelFilters/PanelFilters";
import { useDebounce, useMountedEffect } from "../../../utils/customHooks";
import {
  CreateRequestFooter,
  useCreateRequestContext,
} from "../../../containers/AdminFlow/CreateAppointment";
import { DropDown } from "../../Common/DropDown/DropDown";
import { getFilters } from "../../../store/admin/actions";
import {
  createRequestAdminDoctorsSelector,
  isCreateRequestAdminDoctorsLoadedSelector,
} from "../../../store/createRequestAdminDoctors/selectors";
import { getCreateRequestAdminDoctors } from "../../../store/createRequestAdminDoctors/actions";

const defaultStylesRow = {
  marginLeft: 0,
  marginRight: 0,
};

const defaultStyles = {
  fontSize: 14,
  letterSpacing: "0.25px",
  textTransform: "uppercase",
  color: colors.tuna,
  fontWeight: 500,
  marginRight: 0,
  height: 50,
};

const columns = [
  {
    headerClassName: "table-header-cell",
    label: "selection",
    dataKey: "selected",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData, parent }) => {
      let selectedDoctor = parent.props.extraRowData.selectedDoctor;
      return (
        <Radiobutton
          onClick={() => parent.props.actions.onDoctorSelected(rowData)}
          checked={rowData.uuid === selectedDoctor?.uuid}
        />
      );
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "first name",
    dataKey: "firstName",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "last name",
    dataKey: "lastName",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "phone number",
    dataKey: "phone",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "Speciality",
    dataKey: "speciality",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "Email",
    dataKey: "email",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "Location",
    dataKey: "location",
    headerStyle: { width: "15%", ...defaultStyles },
    style: { width: "15%", ...defaultStylesRow },
  },
];

export const DoctorsStepContext = createContext(null);
export const useDoctorsStepContext = () => useContext(DoctorsStepContext);
export const useDoctorsStepState = () => {
  const [doctor, setDoctor] = useState();
  const [search, setSearch] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    regions: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    regions: [],
  });

  const [filtersOptions, setFiltersOptions] = useState({
    regionsOptions: [],
  });

  const onFilterSelected = (field, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    getDoctorsFilters();
  }, []);

  const getDoctorsFilters = async () => {
    console.log("doctor_issue: filters request is invoked");
    const filtersValue = await getFilters();
    console.log(
      "doctor_issue: filters request is completed successfully",
      filtersValue,
    );
    setFiltersOptions({
      regionsOptions: filtersValue.regions.map((i) => ({
        label: i.name,
        value: i.uuid,
      })),
    });
  };

  const resetFilters = () => {
    setAppliedFilters({
      regions: [],
    });
    setSelectedFilters({
      regions: [],
    });
  };

  return useMemo(
    () => ({
      doctor: doctor,
      setDoctor: setDoctor,
      search: search,
      setSearch: setSearch,
      filtersOptions: filtersOptions,
      appliedFilters: appliedFilters,
      selectedFilters: selectedFilters,
      onFilterSelected: onFilterSelected,
      setAppliedFilters: setAppliedFilters,
      resetFilters: resetFilters,
    }),
    [doctor, filtersOptions, selectedFilters, appliedFilters, search],
  );
};
export const DoctorsList = () => {
  const { onNext } = useCreateRequestContext();
  const {
    doctor,
    setDoctor,
    search,
    setSearch,
    filtersOptions,
    appliedFilters,
    selectedFilters,
    onFilterSelected,
    setAppliedFilters,
    resetFilters,
  } = useDoctorsStepContext();
  const debounceSearch = useDebounce(search, 1000);

  const doctors = useSelector(createRequestAdminDoctorsSelector);
  const isLoaded = useSelector(isCreateRequestAdminDoctorsLoadedSelector);
  const dispatch = useDispatch();
  console.log("doctor_issue: doctors request loading state", isLoaded);
  console.log("doctor_issue: doctors data", doctors);

  useMountedEffect(
    () => {
      console.log("doctor_issue: doctors request is invoked");
      dispatch(
        getCreateRequestAdminDoctors({
          offset: 0,
          search: debounceSearch,
          validation_statuses: "Active",
        }),
      );
    },
    [debounceSearch],
    !isLoaded,
  );

  const onSearchValueUpdated = (field, value) => setSearch(value);

  const onLoadMore = () => {
    if (!doctors?.next) {
      return;
    }
    dispatch(
      getCreateRequestAdminDoctors(
        {
          offset: doctors.results.length,
          search: debounceSearch,
          validation_statuses: "Active",
        },
        doctors,
      ),
    );
  };

  const getUuidFilters = () => {
    let filters = {};
    for (let key in selectedFilters) {
      filters[key] = selectedFilters[key].map((i) => i.value);
    }
    delete filters.statuses;
    return filters;
  };

  const onAppliedFiltersPressed = () => {
    dispatch(
      getCreateRequestAdminDoctors({
        offset: 0,
        ...getUuidFilters(),
        search: debounceSearch,
        validation_statuses: "Active",
      }),
    );

    setAppliedFilters({ ...selectedFilters });
  };

  const onNextPressed = () => {
    if (!!doctor) {
      onNext();
    }
  };

  const resetFiltersAndData = () => {
    dispatch(
      getCreateRequestAdminDoctors({
        offset: 0,
        search: debounceSearch,
        validation_statuses: "Active",
      }),
    );
    resetFilters();
  };

  const filtersValue = useMemo(() => {
    const obj = appliedFilters;
    let filtersValueString = "";
    let countFilters = -1;
    for (let key in obj) {
      if (obj[key].length) {
        if (countFilters === -1)
          filtersValueString = obj[key].map((i) => i.label).join(", ");
        countFilters = countFilters + 1;
      }
    }
    if (filtersValueString !== "" && !!countFilters)
      filtersValueString = `${filtersValueString}, +${countFilters}`;
    return filtersValueString;
  }, [appliedFilters]);

  return (
    <>
      <div className="doctors-list">
        <PanelFilters
          filtersValue={filtersValue}
          value={search}
          applyFilters={appliedFilters}
          applyFilterAction={onAppliedFiltersPressed}
          deleteAppliedFilters={resetFiltersAndData}
          onChange={onSearchValueUpdated}
        >
          <DropDown
            multi
            value={selectedFilters.regions}
            field="regions"
            handlerSelect={onFilterSelected}
            label="Regions"
            options={filtersOptions.regionsOptions}
          />
        </PanelFilters>
        <div className="doctors-list_divider" />
        <CustomTable
          actions={{ onDoctorSelected: setDoctor }}
          loadTableData={onLoadMore}
          heightTable="calc(100vh - 424px)"
          columnsHeader={columns}
          extraRowData={{ selectedDoctor: doctor }}
          data={doctors?.results ? doctors?.results : []}
        />
      </div>
      <CreateRequestFooter
        onNext={onNextPressed}
        disabledNextButton={!doctor}
      />
    </>
  );
};
