import React from "react";
import cn from "classnames";
import { SingleDatePicker } from "../../../../Common/SingleDatePicker/SingleDatePicker";
import "./style.scss";
import moment from "moment";
import { colors } from "../../../../../assets/colors/colors";
export const Item = ({
  datepicker,
  inputText,
  data,
  checked,
  updateText,
  updateStatus,
  updateTextInput,
  updateDate,
  last,
  error,
}) => {
  return (
    <span className={cn("checkbox-item", { "checkbox-item_checked": checked })}>
      <span
        className="checkbox-item_name"
        onClick={() => {
          updateStatus();
        }}
      >
        {checked ? (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
              fill={colors.navy}
            />
          </svg>
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
              fill="#5F6368"
            />
          </svg>
        )}
      </span>
      <span
        contentEditable={true}
        onBlur={(e) => {
          updateText(e.target.innerText);
        }}
        className="checkbox-item_text"
      >
        {data}
      </span>
      {datepicker && (
        <SingleDatePicker
          minDate={moment().subtract(1, "year").toDate()}
          error={!datepicker.data && error}
          disabled={!checked}
          classNameContainer="checkbox-item_datepicker-container"
          className="checkbox-item_datepicker"
          popperPlacement="top"
          selected={datepicker.data && moment(datepicker.data).toDate()}
          onChange={updateDate}
        />
      )}
      {inputText !== undefined && (
        <span
          className={cn("checkbox-item_input", {
            "checkbox-item_input_error": error && !inputText.data,
            "checkbox-item_input_disabled": !checked,
          })}
        >
          <span
            contentEditable={true}
            className="checkbox-item_input_text"
            onBlur={(e) => {
              updateTextInput(e.target.innerText);
            }}
          >
            {inputText.data}
          </span>
          {!checked && <span className="checkbox-item_input_overlay" />}
        </span>
      )}
      {!last && <span className="separator-slash"> / </span>}
    </span>
  );
};
