import { fetchWrapper } from "helpers/helpers";

const setAllMessagesRead = ({ request }) => {
  return fetchWrapper({
    method: "PATCH",
    useLoading: false,
    url: `twilio/channel/${request}/unread_messages/`,
  });
};

export { setAllMessagesRead };
