import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ACTIVE, rolesApp, routesCommon } from "../../constants";
import { routersDoctor } from "../../constants/Doctors";
import { PersonalInfo } from "../../components/DoctorFlow/PersonalInfo/PersonalInfo";
import Schedule from "../../components/DoctorFlow/Schedule/Schedule";
import { Dashboard } from "../../components/DoctorFlow/Dashboard/Dashboard";
import { connect } from "react-redux";
import { getServiceRequests as getTableData } from "../../store/serviceRequests/actions";
import {
  clearTemplateData,
  getCalendarEvents,
  updateDoctor,
  setStatusAndArrive,
  getDoctorDataDashboard,
  clearAllDataDoctor,
  getPCPDoctor,
  getTypesDoctor,
  createUpdatePCPDoctor,
  deletePCPDoctor,
  getFollowUps,
  createUpdateFollowUp,
  sendSelectedNotes,
  deleteFollowUp,
  getAttachments,
  createUpdateAttachments,
  getTypesDegree,
  getLastRequestsOfPatient,
  deleteAttachments,
  getAdditionalInfo,
  updateAdditionalInfo,
  getUpdatePMHinfo,
  getAttachmentsPMH,
  deleteAttachmentsPMH,
  createUpdateAttachmentsPMH,
  getListTemplatesCommon,
  getTemplate,
  getDataTemplateOfRequest,
  createUpdateTemplate,
  createUpdateExcuse,
  getExcuse,
  getCovidDoc,
  createUpdateCovid,
  getFamilySocialHistoryOptions,
  updateRequiredFieldTemplate,
  getCPTcode,
  getDbDataTemplate,
  getdischargeData,
  sendSelectedAttachments,
  getICDList,
  getReceipt,
  clearRequestData,
  deleteVisitNote,
  saveSignature,
  deleteAdditionalInfo,
} from "../../store/doctor/actions";
import CustomHeader from "../../components/AdminEmr/Header/CustomHeader";
import "../../styles/DoctorPage.scss";
import { getServiceRequests } from "../../store/serviceRequests/actions";
import { selectorEvents } from "../../store/doctor/selector";
import { subscribeToEvents, unsubscribePusher } from "../../pushers";
import history from "../../helpers/history";
import { getNotifications } from "store/notification/actions";
import { ContainerEmr } from "../../components/ContainerEmr";
import { lengthObj } from "../../utils/common";
import { getCurrentUser } from "../../store/currentUser/actions";
import { USER_ROLES } from "../../store/currentUser/constants";

const optionsTabs = [
  {
    value: "EMR",
    path: `${routesCommon.doctor}${routersDoctor.emr}`,
    nestedUrls: [`${routesCommon.doctor}${routersDoctor.request}`],
  },
  {
    value: "schedule ",
    path: `${routesCommon.doctor}${routersDoctor.schedule}`,
    nestedUrls: [`${routesCommon.doctor}${routersDoctor.personal_info}`],
  },
  {
    value: "dashboard",
    path: `${routesCommon.doctor}${routersDoctor.dashboard}`,
  },
];

class DoctorFlow extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getCurrentUser(USER_ROLES.DOCTOR).then((resp) => {
      if (resp.uuid) {
        this.props.getNotifications();
        resp.user.uuid &&
          this.props.subscribeToEvents(rolesApp.doctor, resp.user.uuid);
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const refreshUrl = nextProps.location?.state?.refreshUrl;
    if (refreshUrl) {
      this.props.getCurrentUser(USER_ROLES.DOCTOR).then((resp) => {
        resp.user?.uuid && unsubscribePusher(resp.user.uuid);
        resp.user?.uuid &&
          this.props.subscribeToEvents(rolesApp.doctor, resp.user.uuid);
      });
      history.push(`${routesCommon.doctor}${refreshUrl}`);
    }
  }

  componentWillUnmount() {
    this.props.clearAllDataDoctor();
    unsubscribePusher(this.props.doctorData.user?.uuid);
  }

  render() {
    let tabs = optionsTabs;
    if (this.props.doctorData.validation_status === ACTIVE)
      tabs.unshift({
        value: "EMR",
        path: `${routesCommon.doctor}${routersDoctor.emr}`,
        status: "active",
      });

    const isRefresh = this.props.location?.state?.refreshUrl;

    if (!lengthObj(this.props.doctorData)) return <></>;

    const routesEmr = [routersDoctor.emr, routersDoctor.request].map(
      (i) => `${routesCommon.doctor}${i}`,
    );
    return (
      <div className="doctor-flow-container">
        <CustomHeader
          mainPath={routesCommon.doctor}
          tabs={tabs}
          location={this.props.location}
          isDoctor="doctor"
          className="main-container-app_header"
          user={this.props.doctorData}
        />
        <Switch>
          <Route
            path={routesEmr}
            render={() => <ContainerEmr {...this.props} role="doctor" />}
          />
          <Route
            path={`${routesCommon.doctor}${routersDoctor.personal_info}`}
            render={({ location }) => (
              <PersonalInfo
                location={location}
                path={routesCommon.doctor}
                saveSignature={this.props.saveSignature}
                signature={this.props.signature}
                updateDoctor={this.props.updateDoctor}
              />
            )}
          />
          <Route
            path={`${routesCommon.doctor}${routersDoctor.schedule}`}
            render={({ location }) => {
              return (
                <Schedule
                  saveSignature={this.props.saveSignature}
                  location={location}
                  events={this.props.events}
                  getCalendarEvents={this.props.getCalendarEvents}
                  doctor={this.props.doctorData}
                  data={this.props.dataNotification}
                  sendEtaTime={this.props.sendEtaTime}
                  getRequestConfirmation={this.props.getRequestConfirmation}
                  sendFiveMinInfo={this.props.sendFiveMinInfo}
                  sendIamHere={this.props.sendIamHere}
                  getAdditionalServices={this.props.getAdditionalServices}
                  setStatusAndArrive={this.props.setStatusAndArrive}
                  sendCommentDoctor={this.props.sendCommentDoctor}
                  sendServiceRequest={this.props.sendServiceRequest}
                />
              );
            }}
          />
          <Route
            path={`${routesCommon.doctor}${routersDoctor.dashboard}`}
            render={() => (
              <Dashboard
                dashboardData={this.props.dashboardData}
                getDashboardDataDoctor={this.props.getDoctorDataDashboard}
              />
            )}
          />
          {!isRefresh && (
            <Redirect to={`${routesCommon.doctor}${routersDoctor.schedule}`} />
          )}
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(store) {
  console.log(store.currentUser);
  return {
    additionalInfo: store.doctor.additionalInfoRequest,
    pcpDoctors: store.doctor.pcpDoctors,
    degree: store.doctor.degree,
    followUps: store.doctor.followUps,
    attachments: store.doctor.attachments,
    attachmentsPMH: store.doctor.attachmentsPMH,
    typesDoctors: store.doctor.typesDoctors,
    dataRequest: store.doctor.dataNotification,
    dashboardData: store.doctor.dashboard,
    tableData: store.serviceRequests.data,
    doctorData: store.currentUser.data,
    events: selectorEvents(store),
    listLastRequests: store.doctor.listLastRequests,
    pmhDetailInfo: store.doctor.pmhDetailInfo,
    templates: store.doctor.templates,
    templateData: store.doctor.dataTemplate,
    excuse: store.doctor.excuse,
    covid: store.doctor.covid,
    familySocialHistoryOptions: store.doctor.familySocialHistoryOptions,
    cptCodes: store.doctor.cptCodes,
    dbDataTemplate: store.doctor.dbDataTemplate,
    dischargeData: store.doctor.dischargeData,
    icdList: store.doctor.icdList,
    receiptData: store.doctor.receiptData,
    signature: store.doctor.signature,
  };
}

export default connect(mapStateToProps, {
  getServiceRequests,
  getCalendarEvents,
  updateDoctor,
  setStatusAndArrive,
  getDoctorDataDashboard,
  clearAllDataDoctor,
  getPCPDoctor,
  getTypesDoctor,
  createUpdatePCPDoctor,
  deletePCPDoctor,
  getFollowUps,
  createUpdateFollowUp,
  deleteFollowUp,
  getAttachments,
  createUpdateAttachments,
  getTypesDegree,
  getLastRequestsOfPatient,
  deleteAttachments,
  getAdditionalInfo,
  updateAdditionalInfo,
  getAttachmentsPMH,
  deleteAttachmentsPMH,
  createUpdateAttachmentsPMH,
  getUpdatePMHinfo,
  getListTemplatesCommon,
  clearTemplateData,
  getTemplate,
  getDataTemplateOfRequest,
  createUpdateTemplate,
  createUpdateExcuse,
  getExcuse,
  getCovidDoc,
  getFamilySocialHistoryOptions,
  getCPTcode,
  updateRequiredFieldTemplate,
  getDbDataTemplate,
  getdischargeData,
  sendSelectedAttachments,
  getICDList,
  getReceipt,
  clearRequestData,
  sendSelectedNotes,
  deleteVisitNote,
  saveSignature,
  createUpdateCovid,
  deleteAdditionalInfo,
  getNotifications,
  subscribeToEvents,
  getCurrentUser,
  getTableData,
})(DoctorFlow);
