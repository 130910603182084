import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import moment from "moment";
import { DOCTOR_STEPS } from "../../Sidebar/constants";

export const TitleSideBar = ({ text, activePage, data, isAdmin }) => {
  const nextPageCondition = !isAdmin && activePage === DOCTOR_STEPS.NEXT;
  const later = data && data.urgency_type === "later";
  const isToday =
    data &&
    moment(data.application_can_start_at).format("YYYY-MM-DD") ===
      moment().format("YYYY-MM-DD");
  const firstPage = !isAdmin && activePage === DOCTOR_STEPS.CONFIRM;

  return (
    <div className="side-bar_text-container">
      <Typography
        variant="h3"
        text={
          later && firstPage
            ? !isToday
              ? "New Patient Later"
              : "A Later Appointment"
            : text
        }
        className={cn("side-bar_text", {
          "side-bar_text-services": activePage === DOCTOR_STEPS.ADD_SERVICES,
        })}
      />
      {nextPageCondition && (
        <Typography
          text="Please Enter the Time You Need to Get to the Patient"
          variant="p"
          color="#717579"
        />
      )}
    </div>
  );
};
