const STARTED = "started";
const COMPLETED = "completed";
const REQUEST_STATUS = {
  PENDING: "Pending",
  COMPLETED: "Request completed",
  ASSIGNED: "Assigned",
  IN_PROGRESS: "In progress",
  ACTIVE: "Active",
  VISIT_COMPLETED: "Visit completed",
  DECLINED: "Declined",
  DRAFT: "Draft",
};
const REQUEST_STATUS_OPTIONS = [
  { value: REQUEST_STATUS.PENDING, label: REQUEST_STATUS.PENDING },
  { value: REQUEST_STATUS.ASSIGNED, label: REQUEST_STATUS.ASSIGNED },
  { value: REQUEST_STATUS.IN_PROGRESS, label: REQUEST_STATUS.IN_PROGRESS },
  {
    value: REQUEST_STATUS.VISIT_COMPLETED,
    label: REQUEST_STATUS.VISIT_COMPLETED,
  },
  { value: REQUEST_STATUS.COMPLETED, label: REQUEST_STATUS.COMPLETED },
  { value: REQUEST_STATUS.DECLINED, label: "Canceled" },
];

export { STARTED, COMPLETED, REQUEST_STATUS_OPTIONS, REQUEST_STATUS };
