import { fetchWrapper } from "../../helpers/helpers";
import { SET_PATIENTS } from "./actionTypes";
import { changeDateWithFormat } from "../../utils/date";
import { convertPhoneValue } from "../../utils/common";

const setPatients = (payload) => ({ type: SET_PATIENTS, payload });

const getPatients = () => (dispatch) =>
  fetchWrapper({
    url: "patients/",
  }).then((resp) => {
    const data = resp.map((i) => ({
      ...i,
      date_of_birth: changeDateWithFormat(i.date_of_birth, true),
      phone_number: convertPhoneValue(i.phone_number, true),
    }));
    dispatch(setPatients(data));
    return data;
  });

function verificatinPhone(body) {
  return fetchWrapper(
    {
      method: "POST",
      url: "auth/code/request/",
      body,
    },
    false,
    true,
  ).then((resp) => {
    if (resp.uuid) window.localStorage.setItem("user_uuid", resp.uuid);
    else throw "";
  });
}

const validationZipCode = async (zipcode) =>
  fetchWrapper({
    method: "POST",
    url: "locations/validate/",
    useLoading: false,
    body: {
      zip_code: zipcode,
    },
  }).then((resp) => {
    if (resp.zip_code && resp.zip_code[0] === "Zip code is out of service")
      return false;
    if (resp.zip_code && typeof resp.zip_code === "string") return true;
  });

const validationMobileCode = (body) =>
  fetchWrapper(
    {
      method: "POST",
      url: "auth/code/validate/",
      body,
    },
    false,
    true,
  ).then((resp) => {
    if (resp.access) {
      window.localStorage.setItem("access_token", resp.access);
      window.localStorage.setItem("refresh_token", resp.refresh);
    } else throw new Error();
  });

const createPatient = (body) => (dispatch) =>
  fetchWrapper({
    method: "POST",
    url: "patients/",
    body,
  }).then((resp) => {
    dispatch(setPatients(null));
    return resp;
  });

const createRequest = (body) => (dispatch) =>
  fetchWrapper({
    method: "POST",
    url: "service-requests/",
    body: body,
  });

const getCardsUser = () => fetchWrapper({ url: "payments/list/" });
const addNewCardUser = (body) =>
  fetchWrapper({ url: "payments/add/", method: "POST", body });

export const checkGiftCardBe = (number) =>
  fetchWrapper({
    method: "PATCH",
    url: `promo_codes/${number}/`,
  });

export const createAdminRequest = (body) =>
  fetchWrapper({
    method: "POST",
    body: body,
    url: "service-requests/with_relations/",
  });

export {
  verificatinPhone,
  validationZipCode,
  validationMobileCode,
  getPatients,
  createPatient,
  createRequest,
  getCardsUser,
  addNewCardUser,
};
