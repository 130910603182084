import * as components from "./components";

export const template = {
  name: "Shoulder pain (bursitis)",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Shoulder pain (bursitis)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who reports",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: "history of",
            editable: true,
            source: null,
          },

          {
            type: "radio-block",
            data: [
              { checked: false, data: "moderate" },
              { checked: false, data: "severe" },
            ],
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "left" },
              { checked: false, data: "right" },
              { checked: false, data: "both" },
            ],
          },
          {
            type: "text",
            data: "shoulder pain aggravated by motion of arm. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "trauma" },
              { checked: false, data: "chest pain" },
              { checked: false, data: "dyspnea" },
              { checked: false, data: "other joint pain" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "Has" },
              { checked: false, data: "no" },
            ],
          },
          {
            type: "text",
            data: 'history of recent repetitive motion suggesting "overuse" syndrome.',
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert, cooperative, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "cyanosis" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["scleral icterus"]),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neck:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stiffness" }],
              },
              {
                type: "text",
                data: "; normal ROM without pain.",
                editable: true,
                source: null,
              },
            ],
          },
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Upper Extremity:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "mild" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "tenderness over lateral shoulder,",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "skin erythema",
                "soft tissue swelling",
                "ecchymosis",
                "distal neurovascular deficit",
              ]),
              {
                type: "text",
                data: ". Remainder of arm nontender; elbow and wrist nontender with full ROM.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TestPerformed({
        items: [
          {
            type: "text",
            data: "X-ray:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "fracture" },
              { checked: false, data: "dislocation" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      }),
      components.TreatmentPerformed({
        items: [
          {
            type: "text",
            data: "Patient remained stable throughout evaluation and treatment. Treated with Toradol 60mg IM. Sling applied.",
            editable: true,
            source: null,
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "orthopedic surgeon" },
                ],
              },
              {
                type: "text",
                data: "for further evaluation.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
