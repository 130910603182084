import * as components from "./components";

export const template = {
  name: "Burn mild",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Burn (mild)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who sustained burn of the",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "location",
            data: "",
          },
          {
            type: "input",
            placeholder: "mechanism of injury",
            data: "",
          },
          {
            type: "text",
            data: ". Now complains of pain in that area. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "numbness" },
              { checked: false, data: "other injuries" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.TetanusStatus(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "checkbox",
                data: [
                  { checked: false, data: "Alert" },
                  { checked: false, data: "cooperative" },
                  { checked: false, data: "in no distress" },
                ],
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Partial thickness burn",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "size",
                data: "",
              },
              {
                type: "input",
                placeholder: "location",
                data: "",
              },
              {
                type: "text",
                data: ". No full thickness burn present.",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [
                  { checked: false, data: "distal neurovascular deficit" },
                ],
              },
              {
                type: "text",
                data: ". Areas adjacent to burned areas:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "evidence of injury" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "scalp swelling" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
            ],
          },
          components.Eyes([
            {
              type: "text",
              data: ",",
              editable: true,
              source: null,
            },
            {
              type: "radio-single",
              data: [{ checked: false, data: "scleral icterus" }],
            },
          ]),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(
            [
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "thyromegaly" }],
              },
            ],
            false,
          ),
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            required: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rales" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rhonchi" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "wheezes" }],
              },
              {
                type: "text",
                data: "; breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "guarding" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rebound" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "palpable masses" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "CVA tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "deformity" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "edema" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TreatmentPerformed({
        items: [
          {
            type: "text",
            data: "Patient's condition stable during evaluation. Silvadene cream and sterile dressing applied. Tetanus prophylaxis",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "administered" },
              { checked: false, data: "not administered" },
            ],
          },

          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "dermatologist" },
                  { checked: false, data: "surgeon" },
                ],
              },
              {
                type: "text",
                data: "in 2 days.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
