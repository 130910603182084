import React, { useMemo } from "react";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { momentAbbreviation } from "../../../constants";
import moment from "moment";
import {
  addMilliseconds,
  durationTimeLaterRequest,
  timeDelta,
} from "../../../utils/date";
import { formatValueTime } from "../../../utils/common";
import { createTimeModel, useTimeModel } from "react-compound-timer";

export const TimerPatient = ({ data }) => {
  let isUrgentRequest = data.urgency_type === "now";

  if (data.doctor_arrived_at) {
    return <></>;
  }
  if (isUrgentRequest || data.doctor_should_arrive_at) {
    return <Timer request={data} />;
  }

  return <TimeVisit request={data} />;
};

const Timer = ({ request }) => {
  const serviceRequestTime =
    request.doctor_5_mins_left_should_arrive_at ||
    request.doctor_should_arrive_at ||
    request.application_time;
  const getInitialTime = () => {
    let time = timeDelta(serviceRequestTime, momentAbbreviation.milliseconds);
    if (
      !request.doctor_5_mins_left_should_arrive_at &&
      !!request.doctor_should_arrive_at
    ) {
      time = addMilliseconds(time, 300000);
    }
    return time;
  };

  let initialTime = getInitialTime();

  let timer = useMemo(() => {
    return createTimeModel({
      initialTime: initialTime,
      // count down
      direction: "backward",
    });
  }, [serviceRequestTime]);

  const { value } = useTimeModel(timer);
  return (
    <div className={cn("approximate-eta")}>
      <Typography text="Approximate ETA" variant="span" />
      <div className="approximate-eta_container">
        <span className="approximate-eta_time">
          <span>{formatValueTime(value.h)}:</span>
          <span>{formatValueTime(value.m)}:</span>
          <span>{formatValueTime(value.s)}</span>
        </span>
        <Typography text="Left" variant="p" className="approximate-eta_left" />
      </div>
    </div>
  );
};

const TimeVisit = ({ request }) => {
  const timeLaterRequest =
    request &&
    durationTimeLaterRequest(
      request.application_can_start_at,
      request.application_time,
    );

  const dataLaterRequest =
    request && moment(request.application_can_start_at).format("MMM D");
  return (
    <div className={cn("approximate-eta")}>
      <Typography text={dataLaterRequest} variant="span" />
      <div className="approximate-eta_container-extra">
        <span className="approximate-eta_time">
          {timeLaterRequest.timeOfStart}
        </span>
        <Typography
          text={timeLaterRequest.periodOfStart.toLowerCase()}
          variant="span"
        />
        <span className="approximate-eta_time approximate-eta_time-last">
          {`- ${timeLaterRequest.timeOfEnd}`}
        </span>
        <Typography
          text={timeLaterRequest.periodOfEnd.toLowerCase()}
          variant="span"
        />
      </div>
    </div>
  );
};
