export const PMH = () => {
  return {
    type: "label",
    data: "PAST MEDICAL HISTORY:",
    items: [
      {
        type: "pastMedicalHistory",
        textData: [{ placeholder: "", data: "" }],
      },
    ],
  };
};

export const FamSocHistory = () => {
  return {
    type: "label",
    data: "FAMILY / SOCIAL HISTORY:",
    items: [
      {
        type: "familySocialHistory",
        textData: [{ placeholder: "", data: "" }],
      },
    ],
  };
};

export const Medications = () => {
  return {
    type: "label",
    data: "MEDICATIONS:",
    items: [
      {
        noTextField: true,
        disabled: true,
        noAddOptions: true,
        type: "medications",
        textData: [{ placeholder: "", data: "" }],
      },
    ],
  };
};

export const Allergies = () => {
  return {
    type: "label",
    data: "ALLERGIES:",
    items: [
      {
        noTextField: true,
        noAddOptions: true,
        disabled: true,
        type: "medicationAllergies",
        textData: [{ placeholder: "", data: "" }],
      },
    ],
  };
};
