import React from "react";
import * as Icons from "../Icons";
import { Typography } from "../Typography/Typography";
import cn from "classnames";

export const InputPicker = ({
  date,
  color,
  defaultValue,
  value,
  className,
  calendar,
  ...resp
}) => {
  return (
    <button {...resp} className={cn("container-input-picker", className)}>
      {calendar && <Icons.Calendar className="container-input-picker_img" />}
      <Typography
        color={color}
        variant="p"
        text={value || defaultValue}
        className="container-input-picker_text"
      />
    </button>
  );
};
