import { fetchWrapper } from "helpers/helpers";
import { readNotification, setNotifications } from "./slice";

const getNotifications = (serviceRequestId, chatIsOpen) => (dispatch) => {
  return fetchWrapper({
    url: `notifications/`,
    useLoading: false,
  }).then((resp) => {
    dispatch(setNotifications(resp));
    if (serviceRequestId && chatIsOpen)
      dispatch(readNotification(serviceRequestId));
    return resp;
  });
};

export { getNotifications };
