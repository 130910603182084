import { fetchV2, fetchWrapper } from "../../helpers/helpers";
import { setPatientPaymentCards } from "./slice";

const getCardsPatient = () => (dispatch) =>
  dispatch(fetchV2({ url: "payments/list/" })).then((resp) => {
    dispatch(setPatientPaymentCards(resp));
  });
const addNewCard = (body) => (dispatch) =>
  dispatch(fetchV2({ url: "payments/add/", method: "POST", body })).then(
    (resp) => {
      if (resp?.data.card_number) dispatch(getCardsPatient());
      return resp?.data;
    },
  );
const removeCard = (id) => (dispatch) =>
  dispatch(fetchV2({ url: `payments/delete/${id}/`, method: "DELETE" })).then(
    (resp) => {
      dispatch(getCardsPatient());
      return resp;
    },
  );

const changeDefaultCard = (id) => () =>
  fetchWrapper({
    url: `payments/update-default-profile/`,
    method: "POST",
    body: { payment_profile_id: id },
  }).then((resp) => {
    if (!resp.payment_profile_id) throw "error";
  });

export { getCardsPatient, addNewCard, removeCard, changeDefaultCard };
