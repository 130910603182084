import React, { Component } from "react";
import cn from "classnames";
import { colors } from "../../../assets/colors/colors";

export const Clear = ({
  color = colors.navy,
  onClick,
  className,
  style,
  ...props
}) => (
  <div
    onClick={onClick}
    className={cn(className, "icon-component")}
    style={style}
  >
    {props.active ? (
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="35"
          rx="3.5"
          fill="#F3F8FB"
          stroke={color}
        />
        <path
          d="M27.443 24.6258H20.6236L24.3975 20.8533L24.4047 20.8461L24.412 20.8388L27.5032 17.7477C27.8222 17.4232 28.0007 16.9862 28 16.5311C27.9993 16.0761 27.8195 15.6396 27.4995 15.316L23.6872 11.5073C23.5288 11.3472 23.3404 11.22 23.1327 11.1329C22.9249 11.0458 22.7021 11.0007 22.4768 11H22.4718C22.2453 10.9998 22.0211 11.0445 21.8121 11.1316C21.6031 11.2187 21.4134 11.3464 21.2541 11.5073L18.1593 14.6021L11.5037 21.2556C11.1812 21.5787 11 22.0166 11 22.4732C11 22.9298 11.1812 23.3677 11.5037 23.6908L13.3664 25.5535C13.3704 25.5575 13.3758 25.5586 13.3798 25.5622C13.4276 25.6083 13.4835 25.6451 13.5447 25.6709L13.5548 25.6738C13.6176 25.6988 13.6844 25.7119 13.752 25.7122H19.302C19.3049 25.7122 19.307 25.7122 19.3096 25.7122C19.3121 25.7122 19.3146 25.7122 19.3175 25.7122H27.443C27.5872 25.7122 27.7254 25.655 27.8274 25.553C27.9293 25.4511 27.9866 25.3128 27.9866 25.1687C27.9866 25.0245 27.9293 24.8862 27.8274 24.7843C27.7254 24.6823 27.5872 24.6251 27.443 24.6251V24.6258ZM12.0872 22.4732C12.0871 22.3904 12.1033 22.3084 12.1351 22.2319C12.1668 22.1554 12.2133 22.0859 12.272 22.0275L18.5449 15.7545L23.2523 20.462L19.0867 24.6258H13.9741L12.2709 22.9204C12.212 22.8619 12.1653 22.7922 12.1338 22.7154C12.1022 22.6386 12.0864 22.5562 12.0872 22.4732Z"
          fill={color}
        />
      </svg>
    ) : (
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="35"
          rx="3.5"
          fill="#DADCE0"
          fillOpacity="0.15"
          stroke="#DADCE0"
        />
        <path
          d="M27.443 24.6258H20.6236L24.3975 20.8533L24.4047 20.8461L24.412 20.8388L27.5032 17.7477C27.8222 17.4232 28.0007 16.9862 28 16.5311C27.9993 16.0761 27.8195 15.6396 27.4995 15.316L23.6872 11.5073C23.5288 11.3472 23.3404 11.22 23.1327 11.1329C22.9249 11.0458 22.7021 11.0007 22.4768 11H22.4718C22.2453 10.9998 22.0211 11.0445 21.8121 11.1316C21.6031 11.2187 21.4134 11.3464 21.2541 11.5073L18.1593 14.6021L11.5037 21.2556C11.1812 21.5787 11 22.0166 11 22.4732C11 22.9298 11.1812 23.3677 11.5037 23.6908L13.3664 25.5535C13.3704 25.5575 13.3758 25.5586 13.3798 25.5622C13.4276 25.6083 13.4835 25.6451 13.5447 25.6709L13.5548 25.6738C13.6176 25.6988 13.6844 25.7119 13.752 25.7122H19.302C19.3049 25.7122 19.307 25.7122 19.3096 25.7122C19.3121 25.7122 19.3146 25.7122 19.3175 25.7122H27.443C27.5872 25.7122 27.7254 25.655 27.8274 25.553C27.9293 25.4511 27.9866 25.3128 27.9866 25.1687C27.9866 25.0245 27.9293 24.8862 27.8274 24.7843C27.7254 24.6823 27.5872 24.6251 27.443 24.6251V24.6258ZM12.0872 22.4732C12.0871 22.3904 12.1033 22.3084 12.1351 22.2319C12.1668 22.1554 12.2133 22.0859 12.272 22.0275L18.5449 15.7545L23.2523 20.462L19.0867 24.6258H13.9741L12.2709 22.9204C12.212 22.8619 12.1653 22.7922 12.1338 22.7154C12.1022 22.6386 12.0864 22.5562 12.0872 22.4732Z"
          fill="#E3E3E3"
        />
      </svg>
    )}
  </div>
);
