import React from "react";
import { PatientCard } from "../PatientCard";
import {
  AddAdditionalServices,
  SideBarTextArea,
  Separator,
  SignatureContainer,
  ViewServices,
} from "../../SideBarRequest/components";
import "./style.scss";
import { ButtonV2 } from "../ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../ButtonV2/constants";
import { BTN_LABELS } from "../../../constants";
import { COMPLETED } from "../../../store/request/constants";
import cn from "classnames";

export const PatientSidebar = ({
  data,
  updateComment,
  openServices,
  hideNote,
  isAdmin,
  startExamination,
  hideExamination,
  extraComment,
  hideAddButton,
  hideSignature,
  detailsRequest,
  setNeedRefresh,
  isFromEmr,
  doctorCurrent,
}) => {
  if (!data) {
    return <></>;
  }

  const examinationStatus = data.examination_status;
  let examinationButtonText = BTN_LABELS.START_EXAMINATION;
  if (!!examinationStatus) {
    examinationButtonText =
      examinationStatus === COMPLETED
        ? BTN_LABELS.EDIT
        : BTN_LABELS.PROCEED_EXAMINATION;
  }
  const additionalServices = data?.additional_services?.map((item) => {
    const { additional_service, ...i } = item;
    return {
      ...i,
      uuid: additional_service,
    };
  });
  return (
    <div
      className={cn("patient-sidebar", {
        "patient-sidebar--completed": examinationStatus === COMPLETED,
      })}
    >
      <PatientCard
        hideArrow
        symptoms={data.symptoms}
        user={data}
        showExtendedInfo
        data={data}
        otherSymptoms={data.comment}
        completed={examinationStatus === COMPLETED}
        isNew={data.patient_is_new}
      />
      {!hideSignature && (
        <>
          <Separator />
          <SignatureContainer
            data={data}
            detailsRequest={detailsRequest}
            setNeedRefresh={setNeedRefresh}
            canAdd={doctorCurrent?.uuid === detailsRequest?.doctor_data.uuid}
          />
        </>
      )}
      {((!hideAddButton && !isFromEmr) || !!additionalServices.length) && (
        <Separator />
      )}
      {!!additionalServices.length && (
        <ViewServices selectedServices={additionalServices} />
      )}
      {!hideAddButton && !isFromEmr && (
        <AddAdditionalServices openServices={openServices} />
      )}
      {!hideNote && !isFromEmr && (
        <>
          <Separator />
          <SideBarTextArea
            data={data}
            updateComment={updateComment}
            extraComment={extraComment}
            detailsRequest={detailsRequest}
          />
        </>
      )}
      {!hideExamination && !isFromEmr && (
        <ButtonV2
          color={COLORS_BTN.SECONDARY}
          onClick={() => startExamination(data?.uuid)}
          variant={VARIATIONS_BTN.CONTAINED}
          className="patient-sidebar__button"
        >
          {examinationButtonText}
        </ButtonV2>
      )}
    </div>
  );
};
