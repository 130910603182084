import React, { useEffect, useState } from "react";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Input } from "../../Common/Input/Input";
import {
  buttonsInnerText,
  buttonsText,
  PLACEHOLDERS,
  placeholdersUserInfo,
  routesCommon,
  sex,
  validationMessagesAge,
} from "../../../constants";
import cn from "classnames";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { Button } from "../../Common/Button/Button";
import {
  validation18Year,
  validationDOB,
  validationNameFields,
  validationEmail,
  validationIsEmpty,
  validationPhone,
} from "../../../utils/validation";
import {
  converterDataField,
  filterObj,
  lengthObj,
} from "../../../utils/common";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import history from "../../../helpers/history";
import { routersPatient } from "../../../constants/Patient";
import { InputAddress } from "../../Common/Input/InputAddress";
import { useDispatch } from "react-redux";
import { updateCurrentUser } from "../../../store/currentUser/actions";
import { USER_ROLES } from "../../../store/currentUser/constants";

export const Profile = ({ patient }) => {
  const [user, setUser] = useState(patient);
  const [errors, setErrors] = useState({});
  const [showPopup18, setShowPopup18] = useState(false);
  const [editMode, setEditMode] = useState(!patient.email);

  let dispatch = useDispatch();
  const simpleUpdateState = (field, value, zipCode) => {
    if (zipCode) setUser({ ...user, [field]: { ...value, zipcode: zipCode } });
    else setUser({ ...user, [field]: value });
  };

  useEffect(() => {
    patient.uuid && setUser({ ...patient });
  }, [patient]);

  const checkDOB = () => {
    let errorDOB = validation18Year(user.birthday);
    if (errorDOB === validationMessagesAge.birthday18Year) setShowPopup18(true);
    return errorDOB;
  };

  const validation = () => {
    let errors = validationIsEmpty(user);
    errors.firstName = errors.firstName || validationNameFields(user.firstName);
    errors.lastName = errors.lastName || validationNameFields(user.lastName);
    errors.birthday = validationDOB(user.birthday);
    errors.birthday = errors.birthday || checkDOB();
    errors.phone = errors.phone || validationPhone(user.phone);
    errors.email = errors.email || validationEmail(user.email);
    errors.home_address = null;
    errors.suite = null;

    return filterObj(errors);
  };

  const cancelChanges = () => {
    history.push(`${routesCommon.patient}${routersPatient.schedule}`);
  };
  const saveChanges = () => {
    if (editMode) {
      const userInfo = {
        ...user,
        firstName: user.firstName?.trim(),
        lastName: user.lastName?.trim(),
      };
      if (userInfo.home_address) {
        const location = user.home_address;
        userInfo.home_address = {
          zip_code: location.zipcode,
          address_line: location.address,
          apartment: userInfo.suite || null,
          address: location.street,
          state: location.state,
          city: location.city,
        };
      } else {
        delete userInfo.home_address;
      }
      delete userInfo.suite;

      const errorsValidations = validation();
      delete errorsValidations.twilio_sid;
      if (!lengthObj(errorsValidations)) {
        dispatch(
          updateCurrentUser(
            USER_ROLES.PATIENT,
            converterDataField(userInfo, false),
          ),
        )
          .then((resp) => {
            if (resp.uuid) {
              setErrors({});
              setEditMode(false);
            }
          })
          .catch((e) => {
            if (e?.phone_number) {
              setErrors({ phone: "Phone number must be unique" });
            }
          });
      } else setErrors(errorsValidations);
    } else {
      setEditMode(true);
    }
  };

  const contactWithUs = () => (window.location.href = routesCommon.landing);
  const closePopup18 = () => setShowPopup18(false);
  return (
    <>
      <div className="personal-info-patient_profile">
        <Typography
          color={colors.blackMuted}
          text="Profile"
          variant="h3"
          className="personal-info-patient_profile_title"
        />
        <div className="personal-info-patient_profile_block-info">
          <Input
            maxLength="200"
            disable={!editMode}
            placeholder={placeholdersUserInfo.firstName}
            value={user.firstName}
            error={errors.firstName}
            field="firstName"
            onChange={simpleUpdateState}
          />
          <Input
            maxLength="200"
            disable={!editMode}
            placeholder={placeholdersUserInfo.lastName}
            value={user.lastName}
            error={errors.lastName}
            field="lastName"
            onChange={simpleUpdateState}
          />
        </div>
        <div className="personal-info-patient_profile_block-info">
          <Input
            disable={!editMode}
            placeholder={placeholdersUserInfo.birthday}
            value={user.birthday}
            mask="99/99/9999"
            maskChar={"_"}
            defaultValue={"MM/DD/YYYY"}
            formatChars={{
              9: "[0-9]",
            }}
            error={errors.birthday}
            field="birthday"
            onChange={simpleUpdateState}
          />
        </div>
        <div
          className={cn(
            "personal-info-patient_profile_block-info personal-info-patient_profile_block-info_sex",
            { disabled: !editMode },
          )}
        >
          <Typography
            text={placeholdersUserInfo.sex}
            variant="p"
            className="personal-info-patient_profile_block-info_sex_title"
            color={colors.darkMuted}
          />
          <div className="personal-info-patient_profile_block-info_sex_options">
            {sex.map((i, index) => (
              <Radiobutton
                key={index}
                disable={!editMode}
                label={i}
                error={errors.sex}
                onClick={() => simpleUpdateState("sex", i)}
                className=""
                checked={user.sex === i}
              />
            ))}
          </div>
        </div>
        <div className="personal-info-patient_profile_block-info">
          <Input
            disable={!editMode}
            className="half-width"
            placeholder={placeholdersUserInfo.email}
            value={user.email}
            error={errors.email}
            field="email"
            maxLength="200"
            onChange={simpleUpdateState}
          />
          <Input
            disable={!editMode}
            placeholder={placeholdersUserInfo.phone}
            value={user.phone}
            mask="(999) 999-9999"
            maskChar={""}
            error={errors.phone}
            field="phone"
            onChange={simpleUpdateState}
          />
        </div>
        <div className="personal-info-patient_profile_block-info">
          <InputAddress
            disable={!editMode}
            placeholder={placeholdersUserInfo.homeAddress}
            error={errors.address}
            field="home_address"
            value={user.home_address}
            onChange={simpleUpdateState}
          />
          <Input
            disable={!editMode}
            placeholder={PLACEHOLDERS.SUITE}
            value={user.suite}
            error={errors?.suite}
            field="suite"
            onChange={simpleUpdateState}
          />
        </div>
        <div className="personal-info-patient_profile_footer">
          <Button
            text={buttonsInnerText.cancel}
            size="middle"
            capitalize
            variant="default"
            onClick={cancelChanges}
          />
          <Button
            text={editMode ? buttonsInnerText.save : buttonsInnerText.edit}
            size="middle"
            capitalize
            variant="primary"
            onClick={saveChanges}
          />
        </div>
      </div>
      <PopupContainer
        open={showPopup18}
        close={closePopup18}
        extraTitle="Make A Doctor Visit Request"
        title="Sorry, You Must Be Over 18 To"
        textDefault=""
        actionPrimaryButton={contactWithUs}
        textPrimary={buttonsText.contact}
      >
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-age"
          text="Please ask your Parents or Legal Guardian to Make an Appointment for You.If You are an Emancipated Minor with Proof of the Same, please Contact Us below."
        />
      </PopupContainer>
    </>
  );
};
