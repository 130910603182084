import { fetchWrapper } from "../../helpers/helpers";
import {
  SET_DOCTOR_DATA,
  SET_TABLE_DATA_ADMIN,
  SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN,
  SET_PERSONAL_INFO_ADMIN,
  CLEAR_DOCTOR_DATA,
  CLEAR_PHYSICIAN,
  SET_APPOINTMENTS_SELECTED_DOCTORS,
  SET_FILTERS_SHIFTS,
  SET_ACTIVE_PHYSICIAN,
  SET_DASHBOARD_DATA_ADMIN,
  SET_DOCTOR_ASSIGN,
  UPDATE_STATUS_INVITE_SENT,
} from "./actionTypes";
import {
  converterDataField,
  convertResponseTime,
  convertResponseTimeTable,
} from "../../utils/common";
import {
  getAllSatisfactionDashboard,
  getNotesCompletionDashboard,
  getOrderResponseTimeDashboard,
  getStatisticDoctorDashboard,
  getSummaryDashboard,
} from "../actions";
import moment from "moment";
import { colors } from "../../assets/colors/colors";

const setDoctorsAssign = (payload) => ({
  type: SET_DOCTOR_ASSIGN,
  payload,
});
const setEventsSelectedDoctor = (payload) => ({
  type: SET_APPOINTMENTS_SELECTED_DOCTORS,
  payload,
});
const setActivePhysicianNoFilters = (payload) => ({
  type: SET_ACTIVE_PHYSICIAN,
  payload,
});
const setFiltersShifts = (payload) => ({ type: SET_FILTERS_SHIFTS, payload });
const setDashboardDataAdmin = (payload) => ({
  type: SET_DASHBOARD_DATA_ADMIN,
  payload,
});

const setDataDoctor = (payload) => ({ type: SET_DOCTOR_DATA, payload });
const setTableData = (payload) => ({ type: SET_TABLE_DATA_ADMIN, payload });
const clearDoctorData = () => (dispatch) =>
  dispatch({ type: CLEAR_DOCTOR_DATA });
const setUploadApplications = (payload) => ({
  type: SET_UPLOAD_APPLICATIONS_DOCTORS_ADMIN,
  payload,
});
const setPersonalInfoData = (payload) => ({
  type: SET_PERSONAL_INFO_ADMIN,
  payload,
});
const clearPhysician = () => ({ type: CLEAR_PHYSICIAN });
const updateStatusToInviteSent = (payload) => ({
  type: UPDATE_STATUS_INVITE_SENT,
  payload,
});

const setInviteSentStatus = (uuid) => (dispatch) =>
  dispatch(updateStatusToInviteSent(uuid));

const updateUploadedFilesAdmin = (body, otherUpdate) => (dispatch) => {
  dispatch(setUploadApplications({ main: body, other: otherUpdate }));
  return Promise.resolve();
};

const updatePersonalInfoAdmin = (body) => (dispatch) => {
  dispatch(setPersonalInfoData(body));
};

const getTableData =
  (filters = {}, data = { results: [] }) =>
  (dispatch) =>
    fetchWrapper({
      url: "admin/doctors/",
      body: filters,
    }).then((resp) => {
      dispatch(
        setTableData({ ...resp, results: data.results.concat(resp.results) }),
      );
      return resp.results;
    });
const setActivePhysician = (resp) => (dispatch) =>
  dispatch(setActivePhysicianNoFilters(resp));

const clearTableData = () => (dispatch) => dispatch(clearPhysician());

const sendResendToEmail = (uuid) =>
  fetchWrapper({
    url: `admin/doctors/${uuid}/?invite=true`,
  });

const getFilters = async () =>
  fetchWrapper({
    url: "admin/doctors/filters/",
  });

const createShifts = (body, uuid) => () =>
  fetchWrapper({
    url: `shifts/${uuid ? `${uuid}/` : ""}`,
    method: uuid ? "PUT" : "POST",
    body: body,
  });

const getFiltersShifts = () => (dispatch) =>
  fetchWrapper({
    url: "shifts/filters/",
  }).then((resp) => {
    let filters = {
      locations: resp.regions.map((i) => ({ value: i.uuid, label: i.name })),
      speciality: resp.speciality.map((i) => ({
        value: i.uuid,
        label: i.name,
      })),
      physiciansOptions: resp.doctors.map((i) => ({
        value: i.uuid,
        label: i.name,
      })),
    };
    dispatch(setFiltersShifts(filters));
  });

const getMultipleRequestList = (uuid) => {
  return fetchWrapper({
    url: `service-requests/calendar/group-events/${uuid}/`,
  })
    .then((resp) => resp)
    .catch((resp) => resp);
};

const getDoctorsForMultiple = () => {
  return fetchWrapper({
    url: "admin/doctors/?validation_statuses=Active",
  }).then((resp) => resp);
};

const getAppointmentsDoctor = (body) => (dispatch) =>
  fetchWrapper({ url: "service-requests/calendar/events/", body }).then(
    (resp) => dispatch(setEventsSelectedDoctor(resp)),
  );

const getDataDoctor = (uuid, admin) => (dispatch) =>
  fetchWrapper({
    url: `${admin ? "admin/" : ""}doctors/${uuid}/`,
  })
    .then((resp) => {
      let data = {
        ...converterDataField(resp, true),
        user: converterDataField(resp.user, true),
      };

      data.uploadApplications = data.uploadApplications.map((i) =>
        converterDataField(i, true),
      );
      dispatch(setDataDoctor(data));
      return resp;
    })
    .catch((resp) => resp);

const updateStatus = async (uuid, body) => {
  return await fetchWrapper({
    method: "PATCH",
    url: `doctors/file/${uuid}/`,
    body,
  });
};

const deleteShift = (uuid, onlyCurrent) => () =>
  fetchWrapper({
    method: "DELETE",
    url: `shifts/${uuid}${onlyCurrent ? "/" : "?is_recurred=true"}`,
  });

const reassignAppointments = (uuid) => () =>
  fetchWrapper({
    method: "PATCH",
    url: `service/${uuid}/`,
  });
const declineAppointment = (uuid, reason, activate) => () =>
  fetchWrapper({
    method: "PUT",
    url: `service-requests/${activate ? "reactivate" : "cancel"}/${uuid}/`,
    body: activate ? null : { cancellation_reason: reason },
  });

const fetchTotalPatientCount = () =>
  fetchWrapper({
    method: "GET",
    url: `patients/total_number/`,
  });

const getDashboardDataAdmin = (date) => (dispatch) => {
  const getNameOfMonth = (order) => moment(order, "M").format("MMMM");
  return Promise.all([
    getSummaryDashboard(date),
    getOrderResponseTimeDashboard(date),
    getNotesCompletionDashboard(date),
    getAllSatisfactionDashboard(date),
    getStatisticDoctorDashboard(date),
  ]).then((resp) => {
    let from = +date.date_from.substr(5, 2);
    let to = +date.date_to.substr(5, 2);
    const label = [
      from === to
        ? getNameOfMonth(from)
        : `${getNameOfMonth(from)} - ${getNameOfMonth(to)}`,
    ];
    const notes =
      resp[2].notes_completed_on_shift !== undefined
        ? {
            labels: label,
            datasets: [
              {
                label: " Notes Completed on shift",
                data: [resp[2].notes_completed_on_shift],
                backgroundColor: colors.elfName,
                borderColor: colors.elfName,
                borderWidth: 2,
              },
              {
                label: " Notes Completed < 24 hours",
                data: [resp[2].notes_completed_before_24_hours],
                backgroundColor: colors.navy,
                borderColor: colors.navy,
                borderWidth: 2,
              },
              {
                label: " Notes Completed > 24 hours",
                data: [resp[2].notes_completed_after_24_hours],
                backgroundColor: colors.peach,
                borderColor: colors.peach,
                borderWidth: 2,
              },
              {
                label: "Notes Not Completed",
                data: [resp[2].notes_completed_not_completed],
                backgroundColor: colors.redError,
                borderColor: colors.redError,
                borderWidth: 2,
              },
            ],
          }
        : {};

    const data = {
      summary: resp[0].total_visits !== undefined ? resp[0] : {},
      orderResponceTime: convertResponseTime(resp[1]),
      notesChar: notes,
      satisfaction: resp[3].satisfaction !== undefined ? resp[3] : {},
    };
    dispatch(setDashboardDataAdmin(data));
    return Array.isArray(resp[4]) ? convertResponseTimeTable(resp[4]) : [];
  });
};

const getDoctorsForAssign = (uuid) => (dispatch) => {
  fetchWrapper({
    url: `doctors/available-to-assign/${uuid}/`,
  })
    .then((resp) => {
      dispatch(
        setDoctorsAssign(
          resp.map((i) => ({
            ...i,
            value: i.uuid,
            label: `${i.user.first_name} ${i.user.last_name}`,
          })),
        ),
      );
    })
    .catch((resp) => resp);
};

export const downloadFileGift = (data) => {
  return fetchWrapper({
    url: `promo_codes/report/`,
    body: data,
    respFile: true,
  }).catch((resp) => resp);
};

export const downloadFileReport = (data) => {
  return fetchWrapper({
    url: "admin/daily-visit-report/",
    body: data,
    respFile: true,
  }).catch((resp) => resp);
};

export const downloadPatientsReport = () => {
  return fetchWrapper({
    url: "admin/patients-report/",
    respFile: true,
  }).catch((resp) => resp);
};

export const getDoctorsData = () => {
  return fetchWrapper({
    url: "admin/doctors/",
  }).catch((resp) => resp);
};

export const createMultipleRequest = (body) => {
  return fetchWrapper(
    {
      url: "service-requests/import/",
      method: "POST",
      body,
    },
    true,
  );
};

export const getUserDataViaPhone = (phone) => {
  return fetchWrapper({
    url: `users/phone_number=${phone}/`,
  });
};

export {
  getTableData,
  getFilters,
  sendResendToEmail,
  getDataDoctor,
  updateStatus,
  updateUploadedFilesAdmin,
  updatePersonalInfoAdmin,
  clearDoctorData,
  clearTableData,
  createShifts,
  getAppointmentsDoctor,
  getFiltersShifts,
  deleteShift,
  reassignAppointments,
  setActivePhysician,
  getDashboardDataAdmin,
  declineAppointment,
  getDoctorsForAssign,
  setInviteSentStatus,
  getMultipleRequestList,
  getDoctorsForMultiple,
  fetchTotalPatientCount,
};
