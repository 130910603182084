import React from "react";
import { Checkbox } from "../../Common/Checkbox/Checkbox";

export const AddNewServices = ({
  updateCheckbox,
  services,
  checkboxServices,
}) => {
  return (
    <div className="new-services-container">
      {services.map((item, index) => {
        return (
          <Checkbox
            key={index}
            label={`${item.name} $${item.price.slice(0, -3)}`}
            className="side-bar__services"
            field={item}
            onClick={updateCheckbox}
            checked={!!checkboxServices.find((i) => i.name === item.name)}
          />
        );
      })}
    </div>
  );
};
