import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./style.scss";
import { Button } from "../../../../../Common/Button/Button";
import { Cross } from "../../../../../Common/Icons";
import { Typography } from "../../../../../Common/Typography/Typography";
import { buttonsInnerText } from "../../../../../../constants";
import { Checkbox } from "../../../../../Common/Checkbox/Checkbox";
import { colors } from "../../../../../../assets/colors/colors";
import { toCapitalizeChart1 } from "../../../../../../utils/common";
import { Separator } from "../../../../../SideBarRequest/ComponentsRequest/Separator";

export const SendAttachFollowNotes = ({
  close,
  open,
  sendToSelected,
  pcpList,
  type,
  patient,
  selectedNotes,
}) => {
  const [selected, setSelected] = useState([]);
  const [selectedPcpForCovid, setSelectedPcpForCovid] = useState([]);
  const [isPatientSelect, setIsPatientSelect] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelected([]);
      setSelectedPcpForCovid([]);
      setIsPatientSelect(false);
    }
  }, [open]);

  useEffect(() => {
    if (type === "note") setIsPatientSelect(true);
    else setIsPatientSelect(false);
  }, [type]);
  const clickDefaultButton = () => onClose();
  const clickPrimaryButton = () => {
    sendToSelected(
      selected.map((i) => i.uuid),
      type,
      isPatientSelect && patient.patient_uuid,
      selectedPcpForCovid.map((i) => i.uuid),
    );
  };
  const onClose = () => {
    close();
  };

  const handlerSelected = (item) => {
    if (selected.find((i) => i.uuid === item.uuid))
      setSelected(selected.filter((i) => i.uuid !== item.uuid));
    else setSelected([...selected, item]);
  };
  const handlerSelectedCovid = (item) => {
    if (selectedPcpForCovid.find((i) => i.uuid === item.uuid))
      setSelectedPcpForCovid(
        selectedPcpForCovid.filter((i) => i.uuid !== item.uuid),
      );
    else setSelectedPcpForCovid([...selectedPcpForCovid, item]);
  };

  const configColumn = {
    note: {
      select: () => {},
      defaultSendPatient: true,
    },
    follow: {
      select: () => {},
      defaultSendPatient: false,
      titleColumn: "Do you want to send Follow Up/s to:",
    },
    attachment: {
      select: () => {},
      defaultSendPatient: false,
      titleColumn: "Do you want to send Attachment/s to:",
    },
  };

  const activeConfig = configColumn[type];

  const disablePCP =
    type === "note" && !selectedNotes.find((i) => i === "note");
  const isCovid = type === "note" && selectedNotes.find((i) => i === "covid");

  const disableSend = !selected.length && !selectedPcpForCovid.length;

  return (
    <Popup
      open={open}
      onClose={() => {}}
      closeOnDocumentClick={false}
      className="send-attach-follow-notes"
      lockScroll={true}
      overlayStyle={{
        zIndex: 1000,
        top: -64,
      }}
      contentStyle={{
        width: "500px",
        background: "#FFFFFF",
      }}
    >
      <div className="send-attach-follow-notes-content_header">
        <Typography
          variant="h2"
          text="Confirmation"
          className="app-container-request_header-panel_wrapper-titles_title"
        />
        <Cross
          onClick={onClose}
          className="send-attach-follow-notes-content_header_close-icon"
        />
      </div>
      <div className="send-attach-follow-notes-content_body">
        {activeConfig && (
          <div className="send-attach-follow-notes-content_body_column">
            {activeConfig.titleColumn && (
              <Typography
                variant="p"
                dangerouslySetInnerHTML={
                  activeConfig.titleColumn ||
                  activeConfig.dangerouslySetInnerHTML
                }
                color={colors.grey}
                className="column-title"
              />
            )}
            {!activeConfig.defaultSendPatient && (
              <>
                <Typography
                  variant="title"
                  text="Patient"
                  color={colors.black}
                  className="title-options"
                />
                <Checkbox
                  onClick={() => {
                    setIsPatientSelect(!isPatientSelect);
                  }}
                  label={`${patient.first_name} ${patient.last_name}`}
                  checked={isPatientSelect}
                />
              </>
            )}
            <div>
              {!disablePCP && (
                <div>
                  <Typography
                    variant="title"
                    text={
                      activeConfig.defaultSendPatient
                        ? "Visit Note will be sent to:"
                        : "PCP"
                    }
                    color={colors.black}
                    className="title-options"
                  />
                  <div className="send-attach-follow-notes-content_body_column_container-pcp">
                    {pcpList.map((y) => (
                      <Checkbox
                        onClick={() => {
                          handlerSelected(y);
                        }}
                        label={`${y.firstName} ${y.lastName}${
                          y.degree ? ", " + y.degree : ""
                        } (${toCapitalizeChart1(y.contactType)})`}
                        checked={selected.find((i) => i.uuid === y.uuid)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {isCovid && !disablePCP && <Separator />}
              {isCovid && (
                <div>
                  <Typography
                    variant="title"
                    text={"COVID-19 Test Results will be sent to:"}
                    color={colors.black}
                    className="title-options"
                  />
                  <div className="send-attach-follow-notes-content_body_column_container-pcp">
                    {pcpList.map((y) => (
                      <Checkbox
                        onClick={() => {
                          handlerSelectedCovid(y);
                        }}
                        label={`${y.firstName} ${y.lastName}${
                          y.degree ? ", " + y.degree : ""
                        } (${toCapitalizeChart1(y.contactType)})`}
                        checked={selectedPcpForCovid.find(
                          (i) => i.uuid === y.uuid,
                        )}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="send-attach-follow-notes-content_footer">
        <Button
          text={buttonsInnerText.cancel}
          size="middle"
          variant="default"
          capitalize
          onClick={clickDefaultButton}
        />
        <Button
          text={buttonsInnerText.send}
          size="middle"
          variant="primary"
          capitalize
          disabled={disableSend}
          onClick={clickPrimaryButton}
        />
      </div>
    </Popup>
  );
};
