import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/AdminPage.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import TablePageAdmin from "../components/AdminEmr/TablePageAdmin/TablePageAdmin";
import { AddEditDoctor } from "../components/AdminEmr/AddEditDoctor/AddEditDoctor";
import { Dashboard } from "../components/AdminEmr/Dashboard/Dashboard";
import {
  getTableData,
  getAppointmentsDoctor,
  getDataDoctor,
  updateUploadedFilesAdmin,
  updatePersonalInfoAdmin,
  clearDoctorData,
  clearTableData,
  createShifts,
  getFiltersShifts,
  deleteShift,
  reassignAppointments,
  setActivePhysician,
  getDashboardDataAdmin,
  declineAppointment,
  getDoctorsForAssign,
  setInviteSentStatus,
} from "../store/admin/actions";

import { getServiceRequests } from "store/serviceRequests/actions";

import CustomHeader from "../components/AdminEmr/Header/CustomHeader";
import { rolesApp, routesCommon } from "../constants";
import { routersAdmin } from "../constants/Admin";
import {
  clearAllDataDoctor,
  clearRequestData,
  clearTemplateData,
  createUpdateAttachments,
  createUpdateAttachmentsPMH,
  createUpdateCovid,
  createUpdateExcuse,
  createUpdateFollowUp,
  createUpdatePCPDoctor,
  createUpdateTemplate,
  deleteAdditionalInfo,
  deleteAttachments,
  deleteAttachmentsPMH,
  deleteFollowUp,
  deletePCPDoctor,
  deleteVisitNote,
  getAdditionalInfo,
  getAttachments,
  getAttachmentsPMH,
  getCalendarEvents,
  getCovidDoc,
  getCPTcode,
  getDataTemplateOfRequest,
  getDbDataTemplate,
  getdischargeData,
  getDoctorDataDashboard,
  getExcuse,
  getFamilySocialHistoryOptions,
  getFollowUps,
  getICDList,
  getLastRequestsOfPatient,
  getListTemplatesCommon,
  getPCPDoctor,
  getReceipt,
  getTemplate,
  getTypesDegree,
  getTypesDoctor,
  getUpdatePMHinfo,
  sendSelectedAttachments,
  sendSelectedNotes,
  setStatusAndArrive,
  updateAdditionalInfo,
  updateDoctor,
  updateRequiredFieldTemplate,
} from "../store/doctor/actions";
import { ShiftsPage } from "./AdminFlow/ShiftsPage";
import { routersDoctor } from "../constants/Doctors";
import {
  selectorPhysicians,
  selectorPhysiciansActive,
} from "../store/admin/selector";

import { getSpeciality } from "../store/actions";
import { subscribeToEvents, unsubscribePusher } from "../pushers";
import history from "../helpers/history";
import { ManageTemplates } from "./AdminFlow/ManageTemplates";
import { Veradigm } from "../components/AdminPage/Veradigm";
import { Reports } from "../components/AdminPage/Reports";
import { VisitFileRequestFlow } from "./AdminFlow/VisitFileRequestFlow";
import { ContainerEmr } from "../components/ContainerEmr";
import { getNotifications } from "../store/notification/actions";
import { USER_ROLES } from "../store/currentUser/constants";
import { getCurrentUser } from "../store/currentUser/actions";

const optionsTabs = [
  {
    value: "manage users ",
    path: `${routesCommon.admin}${routersAdmin.table}`,
    nestedUrls: [
      `${routesCommon.admin}${routersAdmin.add}`,
      `${routesCommon.admin}${routersAdmin.edit}`,
    ],
  },
  {
    value: "schedule",
    path: `${routesCommon.admin}${routersAdmin.calendar}`,
    nestedUrls: [`${routesCommon.admin}${routersAdmin.visitFile}`],
  },
  {
    value: "dashboard",
    path: `${routesCommon.admin}${routersDoctor.dashboard}`,
  },
  {
    value: "EMR",
    path: `${routesCommon.admin}${routersAdmin.emr}`,
    nestedUrls: [`${routesCommon.admin}${routersAdmin.request}`],
  },
  {
    value: "reports",
    path: `${routesCommon.admin}${routersAdmin.reports}`,
  },
  {
    value: "veradigm",
    path: `${routesCommon.admin}${routersAdmin.veradigm}`,
  },
];

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getCurrentUser(USER_ROLES.ADMIN).then((resp) => {
      resp.uuid && this.props.subscribeToEvents(rolesApp.admin, resp.uuid);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const refreshUrl = nextProps.location?.state?.refreshUrl;
    if (refreshUrl) {
      this.props.getCurrentUser(USER_ROLES.ADMIN).then((resp) => {
        resp.uuid && unsubscribePusher(resp.uuid);
        resp.uuid && this.props.subscribeToEvents(rolesApp.admin, resp.uuid);
      });
      history.push(`${routesCommon.admin}${refreshUrl}`);
    }
  }

  componentWillUnmount() {
    this.props.admin.uuid && unsubscribePusher(this.props.admin?.uuid);
  }

  render() {
    const isRefresh = this.props.location?.state?.refreshUrl;
    let tabs = [...optionsTabs];
    if (window.location.hostname === "localhost") {
      tabs.push({
        value: "manage templates",
        path: `${routesCommon.admin}${routersAdmin.manageTemplates}`,
      });
    }

    const routesEmr = [routersAdmin.emr, routersAdmin.request].map(
      (i) => `${routesCommon.admin}${i}`,
    );

    return (
      <div className="admin-page-container">
        <CustomHeader
          isAdmin
          location={this.props.location}
          user={{ user: this.props.admin }}
          actionButton={this.actionButton}
          tabs={tabs}
          className="main-container-app_header"
        />
        <Switch>
          <Route
            path={routesEmr}
            render={() => (
              <ContainerEmr
                {...this.props}
                doctorData={this.props.admin}
                getTableData={this.props.getServiceRequests}
                tableData={this.props.tableDataEmr}
                role="admin"
              />
            )}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.calendar}`}
            render={({ location }) => {
              return <ShiftsPage {...this.props} location={location} />;
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersDoctor.dashboard}`}
            render={() => {
              return (
                <Dashboard
                  getSpeciality={this.props.getSpeciality}
                  speciality={this.props.speciality}
                  locations={this.props.locations}
                  dashboardData={this.props.dashboardData}
                  getDashboardDataAdmin={this.props.getDashboardDataAdmin}
                />
              );
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.table}`}
            render={() => {
              return (
                <TablePageAdmin
                  setInviteSentStatus={this.props.setInviteSentStatus}
                  clearTableData={this.props.clearTableData}
                  clearDoctorData={this.props.clearDoctorData}
                  tableData={this.props.tableData}
                  getTableData={this.props.getTableData}
                />
              );
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.manageTemplates}`}
            render={() => {
              return <ManageTemplates />;
            }}
          />

          <Route
            path={`${routesCommon.admin}${routersAdmin.visitFile}`}
            render={() => {
              return <VisitFileRequestFlow />;
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.add}`}
            render={() => {
              return (
                <AddEditDoctor
                  signature={this.props.signature}
                  doctorData={this.props.doctor}
                  uploadApplications={this.props.doctor.upload_applications}
                  updatePersonalInfo={this.props.updatePersonalInfoAdmin}
                  updateUploadedFiles={this.props.updateUploadedFilesAdmin}
                />
              );
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.edit}/:id`}
            render={({ match }) => {
              return (
                <AddEditDoctor
                  signature={this.props.signature}
                  clearDoctorData={this.props.clearDoctorData}
                  updatePersonalInfo={this.props.updatePersonalInfoAdmin}
                  updateDoctor={this.props.updateDoctor}
                  id={match.params.id}
                  getDataDoctor={this.props.getDataDoctor}
                  doctorData={this.props.doctor}
                  uploadApplications={this.props.doctor.upload_applications}
                  updateUploadedFiles={this.props.updateUploadedFilesAdmin}
                />
              );
            }}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.reports}`}
            render={() => <Reports locations={this.props.locations} />}
          />
          <Route
            path={`${routesCommon.admin}${routersAdmin.veradigm}`}
            render={() => <Veradigm />}
          />
          {!isRefresh && (
            <Redirect to={`${routesCommon.admin}${routersAdmin.calendar}`} />
          )}
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    additionalInfo: store.doctor.additionalInfoRequest,
    pcpDoctors: store.doctor.pcpDoctors,
    degree: store.doctor.degree,
    followUps: store.doctor.followUps,
    attachments: store.doctor.attachments,
    attachmentsPMH: store.doctor.attachmentsPMH,
    typesDoctors: store.doctor.typesDoctors,
    doctorData: store.currentUser.data,
    listLastRequests: store.doctor.listLastRequests,
    pmhDetailInfo: store.doctor.pmhDetailInfo,
    templates: store.doctor.templates,
    templateData: store.doctor.dataTemplate,
    excuse: store.doctor.excuse,
    covid: store.doctor.covid,
    familySocialHistoryOptions: store.doctor.familySocialHistoryOptions,
    cptCodes: store.doctor.cptCodes,
    dbDataTemplate: store.doctor.dbDataTemplate,
    dischargeData: store.doctor.dischargeData,
    icdList: store.doctor.icdList,
    receiptData: store.doctor.receiptData,

    doctorsForAssign: store.admin.doctorForAssign,
    admin: store.currentUser.data,
    speciality: store.doctor.addEditDoctor.speciality,
    locations: store.doctor.addEditDoctor.locations,
    physicians: selectorPhysicians(store),
    physiciansActive: selectorPhysiciansActive(store),
    tableData: store.admin.tableData,
    tableDataEmr: store.serviceRequests.data,
    doctor: store.admin.doctor,
    filtersShifts: store.admin.filtersShifts,
    detailsAppointment: store.admin.detailsAppointment,
    appointmentsSelectedDoctors: store.admin.appointmentsSelectedDoctors,
    dashboardData: store.admin.dashboard,
    signature: store.doctor.signature,
  };
}

export default connect(mapStateToProps, {
  getSpeciality,
  clearTableData,
  getTableData,
  getDataDoctor,
  updateDoctor,
  updateUploadedFilesAdmin,
  updatePersonalInfoAdmin,
  clearDoctorData,
  createShifts,
  getAppointmentsDoctor,
  getFiltersShifts,
  deleteShift,
  reassignAppointments,
  setActivePhysician,
  getDashboardDataAdmin,
  declineAppointment,
  getCurrentUser,
  getDoctorsForAssign,
  setInviteSentStatus,
  subscribeToEvents,
  getServiceRequests,
  getCalendarEvents,
  setStatusAndArrive,
  getDoctorDataDashboard,
  clearAllDataDoctor,
  getPCPDoctor,
  getTypesDoctor,
  createUpdatePCPDoctor,
  deletePCPDoctor,
  getFollowUps,
  createUpdateFollowUp,
  deleteFollowUp,
  getAttachments,
  createUpdateAttachments,
  getTypesDegree,
  getLastRequestsOfPatient,
  deleteAttachments,
  getAdditionalInfo,
  updateAdditionalInfo,
  getAttachmentsPMH,
  deleteAttachmentsPMH,
  createUpdateAttachmentsPMH,
  getUpdatePMHinfo,
  getListTemplatesCommon,
  clearTemplateData,
  getTemplate,
  getDataTemplateOfRequest,
  createUpdateTemplate,
  createUpdateExcuse,
  getExcuse,
  getCovidDoc,
  getFamilySocialHistoryOptions,
  getCPTcode,
  updateRequiredFieldTemplate,
  getDbDataTemplate,
  getdischargeData,
  sendSelectedAttachments,
  getICDList,
  getReceipt,
  clearRequestData,
  sendSelectedNotes,
  deleteVisitNote,
  createUpdateCovid,
  deleteAdditionalInfo,
  getNotifications,
})(AdminPage);
