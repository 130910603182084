import * as components from "./components";

import { GroupBlockReviewOfSystem } from "./components/GroupBlockReviewOfSystems";

export const template = {
  name: "Gastroenteritis (child)",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Vomiting and diarrhea (gastro child)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "whose caretaker reports that the child has had non-bloody vomiting and diarrhea",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: ". Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "apparent abdominal pain" },
              { checked: false, data: "decreased activity" },
              { checked: false, data: "decreased alertness" },
              { checked: false, data: "decreased urine output" },
              { checked: false, data: "fever" },
              { checked: false, data: "rash" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "REVIEW OF SYSTEMS:",
        items: [
          GroupBlockReviewOfSystem({ title: "General:", radio: "prior fever" }),
          GroupBlockReviewOfSystem({ title: "Skin:", radio: "rash" }),
          GroupBlockReviewOfSystem({ title: "Eyes:", radio: "discharge" }),
          GroupBlockReviewOfSystem({ title: "HENT:", radio: "congestion" }),
          GroupBlockReviewOfSystem({ title: "Respiratory:", radio: "cough" }),
          GroupBlockReviewOfSystem({
            title: "Cardiovascular:",
            radio: "chest pain",
          }),
          GroupBlockReviewOfSystem({ title: "GI:", radio: "prior vomiting" }),
          GroupBlockReviewOfSystem({
            title: "GU:",
            radio: "urinary complaints",
          }),
          GroupBlockReviewOfSystem({
            title: "Musculoskeletal:",
            radio: "joint swelling",
          }),
          GroupBlockReviewOfSystem({
            title: "Psychiatric:",
            radio: "emotional stress",
          }),
        ],
      },
      components.PMH(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert,",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "smiling" },
                  { checked: false, data: "crying" },
                  { checked: false, data: "playful" },
                ],
              },
              {
                type: "text",
                data: "well-hydrated child, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "decreased turgor",
                "cyanosis",
                "rash",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(
              ["icterus", "decreased tearing"],
              true,
            ),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "TMs:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "erythema",
                "nasal flaring",
              ]),
              {
                type: "text",
                data: ". Pharynx:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tonsillar erythema",
                "tonsillar exudate",
              ]),
              {
                type: "text",
                data: ". Airway patent:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["stridor"]),
              {
                type: "text",
                data: "Mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neck:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "meningismus",
                "lymphadenopathy",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "retractions" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rales" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rhonchi" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "wheezes" }],
              },
              {
                type: "text",
                data: ";",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "distention",
                "abnormal bowel sounds",
                "tenderness",
                "guarding",
                "rebound",
                "rigidity",
                "palpable masses",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "deformity" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above; interacts appropriately for age. Strength and tone good.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TestPerformed({
        items: [
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "None" }],
              hiddenBlock: [],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "UA:" }],
              hiddenBlock: [
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Leukocyte esterase",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "trace", label: "Trace" },
                    { value: "+", label: "+" },
                    { value: "++", label: "++" },
                    { value: "+++", label: "+++" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Nitrates",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "positive", label: "Positive" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "0.2", label: "0.2" },
                  multi: false,
                  label: "Urobilinogen",
                  options: [
                    { value: "0.2", label: "0.2" },
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "4", label: "4" },
                    { value: "8", label: "8" },
                    { value: "12", label: "12" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Proteins",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "trace", label: "Trace" },
                    { value: "+", label: "+" },
                    { value: "++", label: "++" },
                    { value: "+++", label: "+++" },
                    { value: "++++", label: "++++" },
                  ],
                },
                {
                  type: "selector",
                  data: null,
                  multi: false,
                  label: "pH 0.0",
                  options: [
                    { value: "5.0", label: "5.0" },
                    { value: "6.0", label: "6.0" },
                    { value: "6.5", label: "6.5" },
                    { value: "7.0", label: "7.0" },
                    { value: "7.5", label: "7.5" },
                    { value: "8.0", label: "8.0" },
                    { value: "8.5", label: "8.5" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Blood",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "trace", label: "Trace" },
                    { value: "+", label: "+" },
                    { value: "++", label: "++" },
                    { value: "+++", label: "+++" },
                  ],
                },
                {
                  type: "selector",
                  data: null,
                  multi: false,
                  label: "SG 1.000",
                  options: [
                    { value: "1.000", label: "1.000" },
                    { value: "1.005", label: "1.005" },
                    { value: "1.010", label: "1.010" },
                    { value: "1.015", label: "1.015" },
                    { value: "1.020", label: "1.020" },
                    { value: "1.025", label: "1.025" },
                    { value: "1.030", label: "1.030" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Ketones",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "5", label: "5" },
                    { value: "15", label: "15" },
                    { value: "40", label: "40" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Bilirubin trace",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "trace", label: "Trace" },
                    { value: "+", label: "+" },
                    { value: "++", label: "++" },
                    { value: "+++", label: "+++" },
                  ],
                },
                {
                  type: "selector",
                  data: { value: "negative", label: "Negative" },
                  multi: false,
                  label: "Glucose",
                  options: [
                    { value: "negative", label: "Negative" },
                    { value: "100", label: "100" },
                    { value: "250", label: "250" },
                    { value: "500", label: "500" },
                    { value: "1000", label: "1000" },
                    { value: ">2000", label: ">2000" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: ":" }],
              hiddenBlock: [
                {
                  type: "input",
                  placeholder: "",
                  data: "",
                },
              ],
            },
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "Pediatrician" },
                ],
              },
              {
                type: "text",
                data: "for further evaluation, or go to the nearest ED at any time for alteration of behavior or evidence of bleeding.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
