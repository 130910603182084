import React from "react";
import cn from "classnames";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";

export const Price = ({
  className,
  price,
  patientsAmount,
  requestType,
  totalPrice,
  giftCard,
}) => {
  return (
    <div className={cn("price", className)}>
      <div className="price__type price__row">
        <div>
          <Typography
            color={colors.navy}
            variant="title"
            className="price__title"
            text="Type:"
          />
          <Typography
            color={colors.grey}
            variant="title"
            className="price__request-name"
            text={requestType}
          />
        </div>
        <div className="price__price-type">
          {patientsAmount > 1 && (
            <Typography
              color={colors.navy}
              variant="title"
              className="price__amount"
              text={`${patientsAmount}x`}
            />
          )}
          <Typography
            color={colors.navy}
            variant="title"
            className="price__price-for-one"
            text={`$${price}`}
          />
        </div>
      </div>
      {giftCard?.price && (
        <div className="price__gift-card price__row">
          <div>
            <Typography
              color={colors.navy}
              variant="title"
              text="Gift card code : "
            />
            <Typography
              color={colors.grey}
              variant="title"
              text={giftCard.value}
            />
          </div>
          <Typography
            color={colors.navy}
            variant="title"
            text={`-$${Math.floor(giftCard.price)}`}
          />
        </div>
      )}
      <div className="price__total price__row">
        <Typography
          color={colors.navy}
          variant="title"
          className="price__title"
          text="Price:"
        />
        <Typography
          color={colors.navy}
          variant="h2"
          className="price__request-price"
          text={`$${totalPrice}`}
        />
      </div>
    </div>
  );
};
