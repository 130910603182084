import createReducer from "../createReducer.js";
import { SET_PATIENTS, SET_SYMPTOMS } from "./actionTypes";

const initialState = {
  symptoms: [],
  patients: null,
};

function setSymptoms(state, { payload }) {
  return { ...state, symptoms: payload };
}
const setPatients = (state, { payload }) => ({ ...state, patients: payload });

const createRequestReducer = createReducer(initialState, {
  [SET_SYMPTOMS]: setSymptoms,
  [SET_PATIENTS]: setPatients,
});

export default createRequestReducer;
