import { createSelector } from "reselect";

export const physicians = (store) => store.admin.tableData.results;
export const physiciansActive = (store) => store.admin.physicianActive;
export const shifts = (store) => store.admin.events;

export const selectorPhysiciansActive = createSelector(
  [physiciansActive],
  (items) =>
    items.map((i) => ({
      ...i,
      value: i.uuid,
      label: `${i.first_name} ${i.last_name}`,
    })),
);

export const selectorPhysicians = createSelector([physicians], (items) =>
  items
    .filter((i) => i)
    .map((i) => ({
      ...i,
      value: i.uuid,
      label: `${i.first_name} ${i.last_name}`,
    })),
);
