import React, { Component } from "react";
import cn from "classnames";

export const Expand = (props) => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3629 5.63711V10.1202H16.3569L16.364 3.636H9.87273L9.8798 5.63711H14.3629ZM5.63716 14.3628V9.87975H3.64312L3.63605 16.3639H10.1273L10.1202 14.3628H5.63716Z"
        fill="#5F6368"
      />
    </svg>
  </div>
);
