import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import cn from "classnames";

export const DoctorInfo = ({ isAppointment, data = {}, doctorLocation }) => {
  let fullName = "";
  fullName = `${data.first_name} ${data.last_name}, M.D.`;
  let location = data.region;
  let speciality = data.speciality;

  return (
    <div
      className={cn("doctor-info-event", {
        "doctor-info-event_extra": isAppointment,
      })}
    >
      <Typography
        capitalize
        variant="h4"
        text="Assigned to:"
        color={colors.steel}
      />
      <Typography capitalize variant="p" text={fullName} color={colors.black} />
      <div className="doctor-info-event_container-data">
        <div>
          <Typography
            capitalize
            variant="h4"
            text={speciality}
            color={colors.steel}
          />
          <Typography
            capitalize
            variant="h4"
            text={`, ${location || doctorLocation}`}
            color={colors.steel}
          />
        </div>
      </div>
    </div>
  );
};
