import React, { Component } from "react";
import cn from "classnames";

export const Plus = (props) => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9678 1.06811L8.97376 1.06811V8.97356L1.06831 8.97356V10.9676L8.97376 10.9676V18.8731L10.9678 18.8731L10.9678 10.9676H18.8733V8.97356H10.9678L10.9678 1.06811Z"
        fill="#1E427B"
      />
    </svg>
  </div>
);
