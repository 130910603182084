import React, { Component, useState } from "react";
import "./style.scss";
import { Textarea } from "../../../../Common/TextArea/Textarea";

export const TextArea = ({ DbData, data, updateState, error }) => {
  const [value, setValue] = useState(data.data);
  const blur = () => {
    updateState({
      ...data,
      data: value,
    });
  };

  const isError = error && !data.data && !data.hasOwnProperty("isRequired");
  return (
    <div className="textarea-emr-container">
      <Textarea
        onBlur={blur}
        placeholder={data.placeholder}
        value={value}
        maxLength={4000}
        error={isError}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};
