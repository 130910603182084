import React from "react";
import "./style.scss";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { formatViewDate } from "../../../utils/date";

export const AdminInfo = ({ data }) => {
  return (
    <>
      {data.admin_info && (
        <div className="admin-info">
          <Typography
            className="admin-info_title"
            text="Booked by:"
            variant="p"
          />
          <Typography
            className="admin-info_fullname"
            capitalize
            color={colors.black}
            text={`${data.admin_info?.first_name} ${data.admin_info?.last_name}`}
            variant="title"
          />
          <Typography color={colors.core70} text="Admin" variant="p" />
          <Typography
            className="admin-info_create-at"
            color={colors.core70}
            text={`Date of the request: ${formatViewDate(data.created_at)}`}
            variant="p"
          />
        </div>
      )}
    </>
  );
};
