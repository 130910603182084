import React from "react";
import Popup from "reactjs-popup";
import "./style.scss";
import { CloseExpand } from "../../Common/Icons";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { CustomTable } from "../../Table/Table";
import { Chart } from "../../Common/Chart/Chart";
import { RangeDatePicker } from "../../Common/RangeDatePicker/RangeDatePicker";
import {
  ProgressButton,
  TimeButton,
} from "../../DoctorFlow/Dashboard/components";
import { InfoWithMessage } from "../../Common/infoWithMessage/infoWithMessage";

export const ExpandPopup = ({
  configTableExpand,
  tableData,
  close,
  open,
  dataCharts,
  date,
  patientSatisfaction,
  responseTime,
}) => {
  const onClose = () => {
    close();
  };

  const infoPopupTitles = [
    "Notes Completion Statistics",
    "Patient's Satisfaction",
  ];
  const infoPopupCondition =
    configTableExpand &&
    infoPopupTitles.find((i) => i === configTableExpand.title);

  return (
    <Popup
      open={!!open}
      onClose={() => {}}
      // lockScroll={true}
      closeOnDocumentClick={false}
      className={cn("popup-component-common-expand")}
      overlayStyle={{
        zIndex: 1003,
      }}
      contentStyle={{
        padding: 30,
        width: "90vw",
        height: "90vh",
        borderRadius: 4,
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="header-expand">
        <div className="block-title">
          <Typography text={configTableExpand && configTableExpand.title} />
          {infoPopupCondition && (
            <InfoWithMessage
              label={configTableExpand && configTableExpand.label}
            />
          )}
        </div>
        <CloseExpand onClick={onClose} />
      </div>
      <div className="after-header">
        <div className="left-block">
          {open === "statisticks" && <Chart datasets={dataCharts} />}
          {open === "satisfaction" && (
            <>
              <ProgressButton
                label="You"
                value={patientSatisfaction.patient_satisfaction_by_doctor}
                delta={
                  patientSatisfaction.patient_satisfaction_by_doctor_difference
                }
              />
              <ProgressButton
                label="All UC (Palm Beach)"
                value={patientSatisfaction.patient_satisfaction_by_region}
                delta={
                  patientSatisfaction.patient_satisfaction_by_region_difference
                }
              />

              <ProgressButton
                label="All Uplin"
                value={patientSatisfaction.patient_satisfaction_by_uplin}
                delta={
                  patientSatisfaction.patient_satisfaction_by_uplin_difference
                }
              />
            </>
          )}
          {open === "time" && (
            <>
              <TimeButton
                className="time-expand_popup"
                last
                label="Order Response Time"
                labelnfo="How fast you accept the call"
                value={responseTime.order_response_time}
                delta={responseTime.order_response_time_difference}
              />
              <TimeButton
                className="time-expand_popup"
                last
                label="Request to Door Time"
                labelnfo="Time to get to Patient"
                value={responseTime.request_to_door_time}
                delta={responseTime.request_to_door_time_difference}
              />
              <TimeButton
                className="time-expand_popup"
                last
                label="Visit Duration"
                labelnfo="Time it takes you to complete the visit"
                value={responseTime.visit_duration}
                delta={responseTime.visit_duration_difference}
              />
            </>
          )}
        </div>
        <div className="right-block">
          <RangeDatePicker
            disabled
            dateTo={date.dateTo}
            dateFrom={date.dateFrom}
          />
        </div>
      </div>
      <div className="table-expand">
        {configTableExpand && (
          <CustomTable
            heightTable="100%"
            columnsHeader={configTableExpand.config}
            data={tableData}
            rowClassName="emr-page-container_table_row"
            className="emr-page-container_table"
          />
        )}
      </div>
    </Popup>
  );
};
