import React, { createRef } from "react";
import "./style.scss";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import cn from "classnames";
import InputMask from "react-input-mask";

const regexs = {
  text: /[^A-Za-z\s]/g,
  number: /[^0-9]/g,
  noSign: /[^A-Za-z0-9\s]/g,
  phone: /[^0-9\+]/g,
};

export const Input = ({
  disable,
  placeholder,
  error,
  value,
  onChange,
  className,
  type,
  field,
  mask,
  maskChar,
  formatChars,
  defaultValue,
  maxLength,
  customRegexs,
  ...props
}) => {
  const onChangeValue = (e) => {
    if (disable) return;
    if (type && !new RegExp(regexs[type]).test(e.target.value))
      onChange(field, e.target.value);

    if (customRegexs && new RegExp(customRegexs).test(e.target.value)) return;
    if (!type) onChange(field, e.target.value);
  };

  return (
    <div
      className={cn("custom-input-container", className, {
        "custom-input-container_error": error,
        "custom-input-container_disable": disable,
      })}
      tabIndex="-1"
    >
      <InputMask
        {...props}
        disabled={disable}
        className="custom-input-container_input"
        value={value}
        onChange={onChangeValue}
        mask={mask}
        maskChar={maskChar}
        formatChars={formatChars}
        maxLength={maxLength}
      />
      <span
        className={cn(
          "custom-input-container_placeholder",
          value && "custom-input-container_placeholder_isValue",
        )}
      >
        {placeholder}
      </span>
      {error && (
        <Typography
          variant="h5"
          text={error}
          color={colors.redError}
          className="custom-input-container_error"
        />
      )}
    </div>
  );
};
