import React from "react";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import "./style.scss";

export const TimeOfRequest = ({ applicationDate, spentTime, timeVisiting }) => {
  let capitalized = false;
  const checkTime = timeVisiting?.toLowerCase();
  if (
    checkTime &&
    !(checkTime.indexOf("am") >= 0 || checkTime.indexOf("pm") >= 0)
  ) {
    capitalized = true;
  }
  const time = timeVisiting
    ? timeVisiting
    : `${spentTime.timeOfStart} ${spentTime.periodOfStart} - ${spentTime.timeOfEnd} ${spentTime.periodOfEnd}`;
  return (
    <div className="request-time">
      <div className="request-time__date">
        <Typography
          capitalize
          variant="h4"
          text="Visit time: "
          color={colors.grey}
        />
        <Typography
          capitalize
          variant="h4"
          text={applicationDate}
          color={colors.black}
        />
      </div>
      <Typography
        className="request-time__period"
        variant="h4"
        text={time}
        color={colors.black}
        capitalize={capitalized}
      />
    </div>
  );
};
