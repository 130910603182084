import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import CreditCard from "../../../dist/images/CreditCard.svg";
import { Button } from "../../Common/Button/Button";
import { buttonsInnerText } from "../../../constants";
import cn from "classnames";
import { Trash } from "../../Common/Icons/trash";
import visa from "../../../dist/icons/visa.svg";
import mastercard from "../../../dist/icons/mastercard.svg";
import american from "../../../dist/icons/american.svg";
import indicator from "../../../dist/icons/indicatorCircle.svg";
import indicatorPassive from "../../../dist/icons/indicatorCirclePassive.svg";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { ButtonV2 } from "../../Common/ButtonV2";
import {
  changeDefaultCard,
  removeCard,
} from "../../../store/patientPaymentCard/actions";
import { useDispatch } from "react-redux";

export const Payment = ({
  controlAddNewCard,
  closeList,
  listCards,
  btnItems,
  onChangeDefaultCard,
}) => {
  const [defaultCard, setDefaultCard] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultCard(listCards.find((i) => i.is_default));
  }, [listCards.length]);

  const onChangeDefaultCardPressed = (paymentCard) => () => {
    if (onChangeDefaultCard) {
      onChangeDefaultCard({
        payment_profile_id: paymentCard.payment_profile_id,
      }).then(() => {
        setDefaultCard(paymentCard);
      });
      return;
    }
    dispatch(changeDefaultCard(paymentCard.payment_profile_id)).then(() => {
      setDefaultCard(paymentCard);
    });
  };
  const onRemovePaymentCardPressed = (id) => () => dispatch(removeCard(id));

  const addCreditCard = () => controlAddNewCard(true);
  return (
    <div className="personal-info-patient_payment">
      {listCards.length ? (
        listCards.map((i, index) => (
          <div className="personal-info-patient_payment_item" key={index}>
            <div>
              <img
                className="personal-info-patient_payment_item_radio"
                onClick={onChangeDefaultCardPressed(i)}
                src={
                  defaultCard?.payment_profile_id === i.payment_profile_id
                    ? indicator
                    : indicatorPassive
                }
                alt="payment-icon"
              />
              <img
                src={
                  i.card_type === "Visa"
                    ? visa
                    : i.card_type === "AmericanExpress"
                      ? american
                      : mastercard
                }
              />
              <div className="personal-info-patient_payment_item_info">
                <Typography
                  text={i.card_number.replace(/\X/g, "*")}
                  variant="p"
                  color={colors.black}
                />
                <Typography
                  text={i.expiry_date.substr(2).split("-").reverse().join("/")}
                  variant="p"
                  color={colors.grey}
                />
              </div>
            </div>
            {!!removeCard && (
              <Trash
                onClick={onRemovePaymentCardPressed(i.payment_profile_id)}
              />
            )}
          </div>
        ))
      ) : (
        <div className="personal-info-patient_payment_empty">
          <img src={CreditCard} />
          <Typography text="You Have Not Added Any" variant="h1_75" />
          <Typography text="Credit Cards Yet" variant="h1_75" />
        </div>
      )}
      <div
        className={cn("personal-info-patient_payment_footer", {
          "personal-info-patient_payment_footer_central": !listCards.length,
        })}
      >
        {closeList && (
          <Button
            text={buttonsInnerText.cancel}
            size="middle"
            variant="default"
            capitalize
            onClick={closeList}
          />
        )}
        <ButtonV2
          onClick={addCreditCard}
          variant={VARIATIONS_BTN.CONTAINED}
          color={COLORS_BTN.PRIMARY}
        >
          {buttonsInnerText.add_credit_card}
        </ButtonV2>
        {btnItems}
      </div>
    </div>
  );
};
