import React from "react";
import PropTypes from "prop-types";
import { Bar as BarChart, Chart as ChartJS } from "react-chartjs-2";
import { colors } from "../../../assets/colors/colors";

ChartJS.defaults.scale.grid.drawOnChartArea = false;
ChartJS.defaults.scale.grid.zeroLineColor = colors.navy;
ChartJS.defaults.scale.grid.color = colors.navy;

export const Chart = ({ datasets, admin }) => {
  return (
    <BarChart
      data={datasets}
      width={360}
      height={171}
      options={{
        datasets: {},
        maintainAspectRatio: false,
        elements: {
          bar: {
            borderRadius: 8,
          },
        },
        plugins: {
          legend: {
            maxWidth: 300,
            fullSize: true,
            display: true,
            position: "right",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              fontColor: admin ? "#000000" : "#717579",
              padding: 15,
              font: {
                size: admin ? 8 : 16,
              },
              usePointStyle: true,
            },
          },
        },
        scales: {
          y: {
            grid: {
              color: colors.navy,
              borderColor: colors.navy,
              tickColor: colors.navy,
            },
            beginAtZero: true,
            ticks: {
              color: [colors.navy],
              font: { size: 14 },
              min: 0,
              stepSize: 25,
            },
          },
          x: {
            grid: {
              color: colors.navy,
              borderColor: colors.navy,
            },
            ticks: {
              color: [colors.navy],
              font: { size: 14 },
              fontColor: colors.navy,
              fontSize: 14,
              min: 0,
            },
          },
        },
      }}
    />
  );
};

Chart.propTypes = {
  datasets: PropTypes.object,
};

Chart.defaultProps = {
  datasets: {
    labels: ["January"],
    datasets: [
      {
        label: "Completed on shift",
        data: [12],
        backgroundColor: "#219653",
        borderColor: "#219653",
        borderWidth: 0,
      },
      {
        label: "Completed < 24 hours",
        data: [7],
        backgroundColor: colors.navy,
        borderColor: colors.navy,
        borderWidth: 0,
      },
      {
        label: "Completed > 24 hours",
        data: [9],
        backgroundColor: "#F2BD42",
        borderColor: "#F2BD42",
        borderWidth: 0,
      },
      {
        label: "Not Completed",
        data: [8],
        backgroundColor: "#FF6666",
        borderColor: "#FF6666",
        borderWidth: 0,
      },
    ],
  },
};
