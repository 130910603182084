import React from "react";
import { Payment } from "../Payment";
import { AddCard } from "../AddCard";

export const ContainerPayment = ({
  isAddNewCard,
  controlAddNewCard,
  ...props
}) => {
  return (
    <>
      {!isAddNewCard && (
        <Payment {...props} controlAddNewCard={controlAddNewCard} />
      )}
      {isAddNewCard && (
        <AddCard {...props} controlAddNewCard={controlAddNewCard} />
      )}
    </>
  );
};
