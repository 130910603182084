import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  role: null,
};

const currentUserSlice = createSlice({
  name: "currentUserSlice",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload: { data, role } }) => {
      state.data = data;
      state.role = role;
    },
    updateCurrentUser: (state, { payload }) => {
      console.log("current state", state.data);
      console.log("payload", payload);
      state.data = { ...state.data, ...payload };
    },
    clearCurrentUserSlice: (state) => {
      state.data = {};
    },
  },
});

export const { setCurrentUser, updateCurrentUser, clearCurrentUserSlice } =
  currentUserSlice.actions;
export const currentUserReducer = currentUserSlice.reducer;
