import { MixpanelService } from "./Mixpanel";
import { GoogleAnalyticsService } from "./GoogleAnalytics";
import { FacebookPixelAnalyticsService } from "./FacebookPixel";

class AnalyticsService {
  constructor() {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    this.mixpanel = new MixpanelService();
    this.googleAnalytics = new GoogleAnalyticsService();
    this.pixelAnalytics = new FacebookPixelAnalyticsService();
  }

  track(event, payload) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    this.mixpanel.track(event, payload);
    this.pixelAnalytics.track(event, payload);
    this.googleAnalytics.track(event, payload);
  }

  register(profile) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    this.mixpanel.register(profile);
  }

  login(profile) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    this.mixpanel.login(profile);
  }

  identify(profile) {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    this.mixpanel.identify(profile);
  }
}

const Analytics = new AnalyticsService();

export default Analytics;
