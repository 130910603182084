import React from "react";
import { Chip as MaterialChip } from "@material-ui/core";
import "./style.scss";
import cn from "classnames";
import { TYPES_CHIPS } from "./constants";

export const Chip = ({ label, type, className, capitalize }) => {
  return (
    <MaterialChip
      label={label}
      className={cn("component-chip", className, {
        "component-chip_primary": TYPES_CHIPS.PRIMARY === type,
        "component-chip_pending": TYPES_CHIPS.PENDING === type,
        "component-chip_visit-completed": TYPES_CHIPS.VISIT_COMPLETED === type,
        "component-chip_secondary": TYPES_CHIPS.SECONDARY === type,
        "component-chip_default": TYPES_CHIPS.DEFAULT === type,
        "component-chip_now-later": TYPES_CHIPS.NOW_LATER_REQUEST === type,
        "component-chip_declined": TYPES_CHIPS.DECLINED === type,
        "component-chip_capitalize": capitalize,
      })}
    />
  );
};
