import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../styles/ProtectedApp.scss";
import { Route, Switch } from "react-router-dom";
import AdminPage from "./AdminPage";
import { routesCommon } from "../constants";
import DoctorFlow from "./DoctorFlow";
import { PatientFlow } from "./PatientFlow";
import { getServiceRequestTypes } from "../store/serviceRequestTypes/actions";
import { serviceRequestTypesSelector } from "../store/serviceRequestTypes/selectors";
import { getRelations } from "../store/relation/actions";
import { getTokenTwilio } from "../store/twilio/actions";

export const ProtectedApp = ({ path }) => {
  const dataNotification = useSelector(
    (store) => store.doctor.dataNotification,
  );
  const dispatch = useDispatch();
  const typesRequests = useSelector(serviceRequestTypesSelector);
  useEffect(() => {
    dispatch(getTokenTwilio());
    dispatch(getServiceRequestTypes(path.includes(routesCommon.admin)));
    dispatch(getRelations());
  }, []);

  if (!typesRequests.length) return <></>;

  return (
    <Switch>
      <div className="main-container-app">
        <Route
          path={routesCommon.admin}
          render={({ location }) => {
            return <AdminPage location={location} />;
          }}
        />
        <Route
          path={routesCommon.patient}
          render={({ location }) => {
            return (
              <PatientFlow
                location={location}
                dataNotification={dataNotification}
              />
            );
          }}
        />
        <Route
          path={routesCommon.doctor}
          render={({ location }) => {
            return (
              <DoctorFlow
                location={location}
                dataNotification={dataNotification}
              />
            );
          }}
        />
      </div>
    </Switch>
  );
};
