import React, { useEffect, useState } from "react";
import { LoginFrom } from "../LoginForm/LoginForm";
import history from "../../helpers/history";
import { routesCommon } from "../../constants";

export const LoginForms = ({ params }) => {
  const [loginForm, setLoginForm] = useState(false);
  const [loginFormAdmin, setLoginFormAdmin] = useState(false);
  const [loginFormPatient, setLoginFormPatient] = useState(false);

  useEffect(() => {
    if (params?.match?.params?.user === "admin") setLoginFormAdmin(true);
    if (params?.match?.params?.user === "doctor") setLoginForm(true);
    if (params?.match?.params?.user === "patient") setLoginFormPatient(true);
  }, []);

  const pushToAdmin = () => history.push(routesCommon.admin);
  const pushToDoctor = () => history.push(`${routesCommon.doctor}`);
  const pushToPatient = () => {
    if (params.location?.state?.type)
      history.push(
        `${routesCommon.create_request}/${params.location?.state?.type}`,
      );
    else history.push(`${routesCommon.patient}`);
  };

  const close = () => {
    window.location.href = routesCommon.landing;
  };

  return (
    <div className="login-forms-landing">
      <LoginFrom
        onClose={close}
        prefix="admin"
        open={loginFormAdmin}
        pushToPage={pushToAdmin}
      />
      <LoginFrom
        onClose={close}
        prefix="doctor"
        open={loginForm}
        pushToPage={pushToDoctor}
      />
      <LoginFrom
        onClose={close}
        prefix="patient"
        open={loginFormPatient}
        pushToPage={pushToPatient}
      />
    </div>
  );
};
