import React from "react";
import "./style.scss";
import { VeradigmButton } from "../../DoctorFlow/Templates/Components/VeradigmButton";

export const Veradigm = () => {
  return (
    <div className="veradigm-container-page">
      <VeradigmButton
        url="veradigm/admin-sso/?mode=TaskMode"
        title="Task mode"
      />
      <VeradigmButton
        url="veradigm/admin-sso/?mode=UtilityMode"
        title="Utility mode"
      />
    </div>
  );
};
