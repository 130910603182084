import React, { Component } from "react";
import cn from "classnames";
import { colors } from "../../../assets/colors/colors";

export const CloudDownload = (props) => (
  <div
    onClick={props.onClick}
    className={cn(props.className, "icon-component")}
    style={props.style}
  >
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM17 9L12 14L7 9H10V5H14V9H17Z"
        fill={props.color || colors.navy}
      />
    </svg>
  </div>
);
