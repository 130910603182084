export const SIDEBAR_TITLES = {
  DOCTOR: [
    {
      title: "New Patient Now",
    },
    {
      title: "What is your ETA?",
    },
    {
      title: "Updated Timeline",
    },
    {
      title: "Updated Timeline",
    },
    {
      title: "Updated Timeline",
    },
    {
      title: "Order Details",
    },
    {
      title: "Additional Services",
    },
    {
      title: "",
    },
    {
      title: "List Of Patients",
    },
    {
      title: "Patient Details",
    },
  ],
  ADMIN: [
    {
      title: "Order Details",
    },
    {
      title: "Shift Details",
    },
    {
      title: "",
    },
    {
      title: "List Of Patients",
    },
  ],
};

export const DOCTOR_STEPS = {
  CONFIRM: 0,
  NEXT: 1,
  MINS_LEFT: 2,
  HERE: 3,
  TRIGGER_SERVICES: 4,
  ORDER_DETAILS: 5,
  ADD_SERVICES: 6,
  MULTIPLE: 7,
  PATIENTS_LIST: 8,
  EXAMINATION: 9,
};

export const REQUEST_STATUS_INTO_PAGES = {
  PENDING: 0,
  ASSIGNED: 0,
  DECLINED: 5,
  ACTIVE: 0,
  DRAFT: 5,
  "IN PROGRESS": 4,
  "VISIT COMPLETED": 5,
  "REQUEST COMPLETED": 5,
};

export const ADMIN_STEPS = {
  ORDER_DETAILS: 0,
  SHIFT_PAGE: 1,
  MULTIPLE: 2,
  PATIENTS_LIST: 3,
};
