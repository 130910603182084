import React, { useEffect, useState } from "react";
import "./style.scss";
import { Typography } from "../../components/Common/Typography/Typography";
import { colors } from "../../assets/colors/colors";
import { InfoIcon, Marker } from "../../components/Common/Icons";
import cn from "classnames";
import GoogleMapReact from "google-map-react";
import { GoogleMarker } from "../../components/Common/GoogleMarker/GoogleMarker";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { styles } from "./styleMap";

export const Map = ({
  data,
  hideMap,
  isShowSuite,
  className,
  comment,
  isOutWorkingZone,
}) => {
  const [address, setAddress] = useState({ address: "", latLng: {} });

  useEffect(() => {
    if (data?.location?.address_line) {
      geocodeByAddress(data.location?.address_line)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => setAddress({ address: data, latLng: latLng }));
    }
  }, [data?.location?.address_line]);

  const defaultMapOptions = {
    fullscreenControl: false,
    styles: styles,
  };

  const zoom = 13;
  if (!data) return <></>;
  const building =
    data.location.apartment &&
    isShowSuite &&
    `Suite ${data.location.apartment}`;
  return (
    <div className={cn("container-map", className)}>
      <Typography text="Address" variant="p" color={colors.grey} />
      <div className="container-map_address-container">
        <Marker className="container-map_address-container_img" />
        <div className="container-map_address-container_text">
          <Typography text={data.location.address_line} color={colors.navy} />
          {building && (
            <Typography text={`, ${building}`} color={colors.navy} />
          )}
        </div>

        {isOutWorkingZone && (
          <div className="container-map_address-container_out-working-zone">
            <InfoIcon />
            Out Of Working Zone
          </div>
        )}
      </div>
      {address.latLng.lat && !hideMap && (
        <div
          className="container-map_map"
          style={{ height: "173px", width: "100%" }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDdm7tYTjT39nEbzBKakm7PZGy8xYGtN_s",
            }}
            center={address.latLng}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onCenterChanged={address.latLng}
            options={defaultMapOptions}
          >
            <GoogleMarker
              lat={address.latLng.lat}
              lng={address.latLng.lng}
              name="My Marker"
              color={colors.navy}
            />
          </GoogleMapReact>
        </div>
      )}
      {!!comment && (
        <div className="container-map__comment">
          <Typography
            color={colors.greyMiddle}
            variant="p"
            text="Additional Info"
            className="container-map__title"
          />
          <Typography
            color={colors.black}
            variant="p"
            text={comment}
            className="container-map__text"
          />
        </div>
      )}
    </div>
  );
};
