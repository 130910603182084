import React from "react";
import "./style.scss";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { HeaderPatientRequest } from "../../SideBarRequest/ComponentRequestPatient/HeaderPatientRequest";
import { RequestInfo } from "../../Common/RequestInfo/RequestInfo";
import { Chip } from "../../Common/Chip";
import { defineChipType } from "../../../helpers/helpers";
import { RequestedBy } from "../../Common/RequestedBy/RequestedBy";

export const CardDetails = ({ openChat, details, isGroup }) => {
  return (
    <div className="component-card-request-details">
      <div className="component-card-request-details_header">
        <Typography
          color={colors.greyMiddle}
          variant="p"
          text={`${isGroup ? "Group" : "Single"} request`}
        />
        <Chip
          capitalize
          type={defineChipType(details.status)}
          label={details.status}
        />
      </div>
      <HeaderPatientRequest
        openChat={openChat}
        className="component-card-request-details-details_doctor"
        data={details}
      />
      <RequestInfo hideType data={details} doctor />
      <div className="separator" />
      <RequestedBy data={details} />
      <div className="component-card-request-details_price-block">
        <div className="component-card-request-details_price-block_left" />
        <div className="component-card-request-details_price-block_right" />
      </div>
    </div>
  );
};
