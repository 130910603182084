import React from "react";

export const TablePatient = ({
  dataRequest,
  targetRequest,
  dateService,
  className,
}) => {
  const {
    location: { address_line, apartment },
  } = dataRequest;
  const { first_name, last_name, date_of_birth } = targetRequest;
  return (
    <div className={className}>
      <table style={{ width: "100%" }}>
        <tr>
          <td>Patient Name</td>
          <td>{`${first_name} ${last_name}`}</td>
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>{date_of_birth}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{`${address_line}${apartment ? `, Suite ${apartment}` : ""}`}</td>
        </tr>
        <tr>
          <td>Date of Service</td>
          <td>{dateService}</td>
        </tr>
      </table>
    </div>
  );
};
