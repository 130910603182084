import React from "react";
import {
  Radio,
  RadioGroup as MuRadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import "./style.scss";
import cn from "classnames";

export const RadioGroup = ({
  label,
  onChange,
  field,
  value,
  options,
  className,
  error,
  disabled,
  horizonal,
}) => {
  const handleChange = (e) => onChange(field, e.target.value);

  return (
    <FormControl
      error={error}
      className={cn("radio-block-component", className, {
        "radio-block-component_error": error,
      })}
    >
      <FormLabel>{label}</FormLabel>
      <MuRadioGroup row={!horizonal} value={value} onChange={handleChange}>
        {options.map((i) => (
          <FormControlLabel
            disabled={i.disabled || disabled}
            value={i.value}
            control={<Radio />}
            label={i.label}
          />
        ))}
      </MuRadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
