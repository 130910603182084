import React from "react";
import "./style.scss";
import { routesCommon } from "../../constants";

export const Page404 = () => {
  return (
    <div className="container-page-404">
      <span className="container-page-404_number">404</span>
      <span className="container-page-404_message">Page not found</span>
      <button
        onClick={() => {
          window.location.href = routesCommon.landing;
        }}
        className="no-default-style-button"
      >
        Go to main page
      </button>
    </div>
  );
};
