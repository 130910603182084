import React, { useEffect } from "react";
import cn from "classnames";
import AnimateHeight from "react-animate-height";
import { ListSymptoms } from "./ListSymptoms";
import { steps } from "../../../constants";
import { PatientCard } from "../../Common/PatientCard";
import { groupPatientsByRelation } from "../../../helpers/helpers";

export const PatientsList = (props) => {
  const {
    activePatient,
    simpleUpdateState,
    selectedPatients,
    activeStep,
    needRebuild,
    showGroup,
  } = props;

  useEffect(() => {
    if (activePatient === null && activeStep === steps.Symptoms) {
      simpleUpdateState("activePatient", selectedPatients[0].uuid);
    }
  }, []);

  const handleClick = (uuid) => {
    if (uuid === activePatient && needRebuild) {
      simpleUpdateState("activePatient", null);
    } else {
      simpleUpdateState("activePatient", uuid);
    }
  };

  return (
    <div className="patients-list__wrapper">
      <div className="patients-list">
        {groupPatientsByRelation(selectedPatients)?.map((data, ind) => {
          const label =
            data.label === "null"
              ? "You"
              : `${data.label[0].toUpperCase()}${data.label.slice(1)}`;
          return (
            <React.Fragment key={ind}>
              {showGroup && (
                <div className="list-patients__group-name">{label}</div>
              )}
              {data.items?.map((item) => {
                const ind = selectedPatients.findIndex(
                  (k) => k.uuid === item.uuid,
                );
                return (
                  <React.Fragment key={item.uuid}>
                    <PatientCard
                      classNames={cn("patients-list__item", {
                        "patients-list__item--active":
                          activePatient === item.uuid,
                      })}
                      user={item}
                      hideArrow={
                        !(activeStep === steps.Symptoms && needRebuild)
                      }
                      symptoms={selectedPatients[ind].selected}
                      onClick={
                        activeStep === steps.Symptoms
                          ? () => handleClick(item.uuid)
                          : () => {}
                      }
                      otherSymptoms={selectedPatients[ind].otherSymptoms}
                    />
                    {needRebuild && (
                      <AnimateHeight
                        className="patients-list__symptoms-list"
                        duration={300}
                        height={activePatient === item.uuid ? "auto" : 0}
                      >
                        <ListSymptoms {...props} />
                      </AnimateHeight>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
