import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Typography } from "../../Common/Typography/Typography";
import { Input } from "../../Common/Input/Input";
import {
  buttonsText,
  contactUsUrl,
  placeholdersPatients,
  providerPrivacyPolicyUrl,
  placeholdersUserInfo,
  sex,
  validationMessagesAge,
  termsAndConditionsUrl,
  uplinPrivacyNoticeUrl,
  steps,
} from "../../../constants";
import { Buttons } from "../Buttons";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { colors } from "../../../assets/colors/colors";
import {
  validation18Year,
  validation6Month,
  validationDOB,
  validationEmail,
  validationIsEmpty,
  validationPhone,
  validationFromBE,
  validationNameFields,
} from "../../../utils/validation";
import {
  converterDataField,
  convertPhoneValue,
  filterObj,
  lengthObj,
} from "../../../utils/common";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { Checkbox } from "../../Common/Checkbox/Checkbox";
import { Popup6Month } from "./Popup6Month";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { relationsSelector } from "../../../store/relation/selectors";
import { DropDown } from "../../Common/DropDown/DropDown";
import { getRelations } from "../../../store/relation/actions";
import { updateCurrentUser } from "../../../store/currentUser/actions";

export const UserInfo = forwardRef(
  (
    {
      initialState,
      user,
      createPatient,
      simpleUpdateState,
      isMultipleRequest,
      isUser,
      disabled,
      getUserData,
      setFieldsDisable,
      errorsFromResp,
      moveToTheStep,
    },
    ref,
  ) => {
    const [userInfo, setUserInfo] = useState({ ...initialState });
    const [errors, setErrors] = useState({ ...initialState });
    const [showPopup18, setShowPopup18] = useState(false);
    const [showPopup6, setShowPopup6] = useState(false);
    const dispatch = useDispatch();
    const relations = useSelector(relationsSelector);

    useEffect(() => {
      setErrors((prev) => ({
        ...prev,
        ...errorsFromResp,
      }));
    }, [errorsFromResp]);

    useEffect(() => {
      if (isUser) {
        Analytics.track(EVENTS.User_Info_Page_Open);
        setUserInfo((prev) => ({ ...prev, ...user }));
      } else {
        dispatch(getRelations());
        Analytics.track(
          EVENTS.Rapid_Covid_Test_Patient_Add_New_Patient_Page_Open,
        );
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getUserInfo: saveUserInfo,
      };
    });

    useEffect(() => {
      if (isMultipleRequest) {
        user && !!lengthObj(user) && setUserInfo({ ...user });
      }
    }, [user]);

    const updateState = (field, value) => {
      if (field === "phone" && getUserData && setFieldsDisable) {
        if (value !== userInfo.phone) {
          if (!validationPhone(value)) {
            const convertedPhone = convertPhoneValue(value);
            getUserData(convertedPhone);
          } else {
            setFieldsDisable(value);
            setUserInfo({ ...initialState, phone: value });
          }
        }
      }
      setUserInfo({ ...userInfo, [field]: value });
      setErrors({ ...errors, [field]: false });
    };

    useEffect(() => {
      if (isMultipleRequest) {
        if (createPatient) {
          simpleUpdateState("createPatientMode", true);
          return () => simpleUpdateState("createPatientMode", false);
        } else simpleUpdateState("hideSteps", true);
      }
    }, []);

    const checkDOB = () => {
      let errorDOB =
        initialState.relationship !== undefined
          ? validation6Month(userInfo.birthday)
          : validation18Year(userInfo.birthday);
      if (errorDOB === validationMessagesAge.birthday18Year)
        setShowPopup18(true);
      if (errorDOB === validationMessagesAge.birthday6Month)
        setShowPopup6(true);
      return errorDOB;
    };

    const validation = () => {
      let errors = validationIsEmpty(userInfo);
      errors.firstName =
        errors.firstName || validationNameFields(userInfo.firstName);
      errors.lastName =
        errors.lastName || validationNameFields(userInfo.lastName);
      errors.birthday = validationDOB(userInfo.birthday);
      errors.birthday = errors.birthday || checkDOB();
      errors.phone = errors.phone || validationPhone(userInfo.phone);
      errors.email = errors.email || validationEmail(userInfo.email);

      return filterObj(errors);
    };

    const goToPreviousScreen = () =>
      simpleUpdateState("openConfirmation", true);
    const saveUserInfo = () => {
      const user = {
        ...userInfo,
        firstName: userInfo.firstName?.trim(),
        lastName: userInfo.lastName?.trim(),
      };
      const errors = validation();
      delete errors.twilio_sid;
      if (!lengthObj(errors)) {
        if (isMultipleRequest) {
          return user;
        }
        const isPatient = user.agreement;
        if (isPatient) {
          user.relationship = user.relationship.value;
        }
        let data = converterDataField(user, false);
        if (isMultipleRequest) return data;
        if (isPatient) {
          createPatient({ ...data, status: "other" }).then((resp) => {
            if (resp.uuid) {
              simpleUpdateState("patient", { ...user, uuid: resp.uuid });
              moveToTheStep(steps.PickingPatients);
            } else setErrors(validationFromBE(resp));
          });
        } else {
          dispatch(updateCurrentUser(data)).then((resp) => {
            if (resp.uuid) {
              simpleUpdateState("user", { ...user });
              moveToTheStep(steps.PickingPatients);
            } else setErrors(validationFromBE(resp));
          });
        }
      } else {
        setErrors(errors);
      }
    };
    const closePopup6 = () => setShowPopup6(false);
    const closePopup18 = () => setShowPopup18(false);
    const contactWithUs = () => window.open(contactUsUrl);

    const isPatient = initialState.relationship !== undefined;

    const placeholders = createPatient
      ? placeholdersPatients
      : placeholdersUserInfo;

    return (
      <>
        <div className="patient-info-container">
          {isPatient && (
            <div className="patient-info-container_block-row half-width">
              <DropDown
                label={placeholders.relationship}
                value={userInfo.relationship}
                error={errors["relationship"]}
                field="relationship"
                onChange={updateState}
                handlerSelect={updateState}
                options={relations}
              />
            </div>
          )}
          {isMultipleRequest && (
            <div
              className={cn("patient-info-container_block-row half-width", {
                "half-width": isMultipleRequest,
              })}
            >
              <Input
                placeholder={placeholders.phone}
                maskChar=" "
                mask="(999) 999-9999"
                value={userInfo.phone}
                error={errors["phone"]}
                field="phone"
                onChange={updateState}
              />
            </div>
          )}
          <div className="patient-info-container_block-row">
            <Input
              placeholder={placeholders.firstName}
              value={userInfo.firstName}
              maxLength="200"
              error={errors["firstName"]}
              field="firstName"
              onChange={updateState}
              disable={disabled}
            />
            <Input
              placeholder={placeholders.lastName}
              value={userInfo.lastName}
              error={errors["lastName"]}
              field="lastName"
              maxLength="200"
              onChange={updateState}
              disable={disabled}
            />
          </div>
          <div
            className={cn("patient-info-container_block-row", {
              "half-width": !isMultipleRequest,
            })}
          >
            <Input
              placeholder={placeholders.birthday}
              value={userInfo.birthday}
              error={errors["birthday"]}
              field="birthday"
              mask="99/99/9999"
              maskChar={"_"}
              defaultValue={"MM/DD/YYYY"}
              formatChars={{
                9: "[0-9]",
              }}
              onChange={updateState}
              disable={disabled}
            />
            {isMultipleRequest && (
              <Input
                placeholder={placeholders.email}
                className=""
                value={userInfo.email}
                error={errors["email"]}
                field="email"
                onChange={updateState}
                disable={disabled}
              />
            )}
          </div>
          <div className="patient-info-container_block-row">
            <div className="patient-info-container_block-row_radio-buttons">
              <Typography
                text={placeholders.sex}
                variant="p"
                color={colors.darkMuted}
              />
              <div className="patient-info-container_block-row_radio-buttons_container">
                {sex.map((i, index) => (
                  <Radiobutton
                    key={index}
                    label={i}
                    error={errors["sex"]}
                    onClick={() => updateState("sex", i)}
                    className=""
                    checked={userInfo.sex === i}
                    disable={disabled}
                  />
                ))}
              </div>
            </div>
          </div>
          {!isMultipleRequest && (
            <div className="patient-info-container_block-row">
              <Input
                placeholder={placeholders.phone}
                maskChar=" "
                mask="(999) 999-9999"
                value={userInfo.phone}
                error={errors["phone"]}
                field="phone"
                onChange={updateState}
              />
              <Input
                placeholder={placeholders.email}
                className=""
                value={userInfo.email}
                error={errors["email"]}
                field="email"
                onChange={updateState}
                disable={disabled}
              />
            </div>
          )}
          {isPatient && (
            <Checkbox
              error={errors["agreement"]}
              checked={userInfo.agreement}
              label={
                <Typography
                  variant="p"
                  className="links-from-list-symptoms"
                  dangerouslySetInnerHTML={`You Verify that Patient Agrees to the Requested Visit and the <a href=${providerPrivacyPolicyUrl} target="_blank">Provider Privacy Notice</a>, <a href=${termsAndConditionsUrl} target="_blank">Terms of Use</a> and <a target="_blank" href=${uplinPrivacyNoticeUrl}>Uplin Privacy Policy</a>.`}
                />
              }
              field="agreement"
              onClick={updateState}
            />
          )}
          <PopupContainer
            open={showPopup18}
            close={closePopup18}
            extraTitle="to Request A Doctor’s Visit"
            title="Sorry, You Must be Over 18 Years of Age"
            textDefault=""
            actionPrimaryButton={contactWithUs}
            textPrimary={buttonsText.contact}
          >
            <Typography
              color={colors.grey}
              variant="p"
              className="patient-info-container_verification-age"
              text="Please ask your Parents or Legal Guardian to Make an Appointment for You."
            />
            <Typography
              color={colors.grey}
              variant="p"
              className="patient-info-container_verification-age"
              text="If You are an Emancipated Minor with Proof of the Same, please Contact Us below."
            />
          </PopupContainer>
          <Popup6Month
            open={showPopup6}
            close={closePopup6}
            contactWithUs={contactWithUs}
            emailUser={userInfo.email}
          />
        </div>
        {!isMultipleRequest && (
          <Buttons
            DefaultClick={goToPreviousScreen}
            PrimaryClick={saveUserInfo}
            DefaultDisabled={false}
            PrimaryDisabled={false}
          />
        )}
      </>
    );
  },
);
