import React from "react";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";
import { RoundProgressBar } from "../../../Common/RoundProgressBar/RoundProgressBar";
import { Delta } from "../../../Common/Delta/Delta";
import { InfoWithMessage } from "../../../Common/infoWithMessage/infoWithMessage";

export const ProgressButton = ({ value, label, delta }) => {
  const infoCondition = ["All Uplin"];
  const adminDashboardCondition = infoCondition.find((i) => i === label);

  return (
    value !== undefined && (
      <div className="dashboard-progress">
        <div className="dashboard-progress_container">
          <Typography text={label} variant="p" className="title" />
          {adminDashboardCondition && (
            <InfoWithMessage label="Average Patient satisfaction for the whole company" />
          )}
        </div>
        <RoundProgressBar value={value} />
        <Delta delta={delta} />
      </div>
    )
  );
};
