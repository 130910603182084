import React, { createContext, useContext, useMemo, useState } from "react";
import "./PatientsList.style.scss";
import { CustomTable } from "../../Table/Table";
import { colors } from "../../../assets/colors/colors";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "../../../store/requestPatients/actions";
import { PanelFilters } from "../../AdminEmr/PanelFilters/PanelFilters";
import { ButtonV2 } from "../../Common/ButtonV2";
import { Plus } from "../../Common/Icons";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { useDebounce, useMountedEffect } from "../../../utils/customHooks";
import {
  CreateRequestFooter,
  useCreateRequestContext,
} from "../../../containers/AdminFlow/CreateAppointment";
import {
  isPatientsLoadedSelector,
  patientsSelector,
} from "../../../store/requestPatients/selectors";

const defaultStylesRow = {
  marginLeft: 0,
  marginRight: 0,
};

const defaultStyles = {
  fontSize: 14,
  letterSpacing: "0.25px",
  textTransform: "uppercase",
  color: colors.tuna,
  fontWeight: 500,
  marginRight: 0,
  height: 50,
};

const columns = [
  {
    headerClassName: "table-header-cell",
    label: "selection",
    dataKey: "selected",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData, parent }) => {
      let selectedPatient = parent.props.extraRowData.selectedPatient;
      return (
        <Radiobutton
          onClick={() => parent.props.actions.onPatientSelected(rowData)}
          checked={rowData.uuid === selectedPatient?.uuid}
        />
      );
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "first name",
    dataKey: "firstName",
    headerStyle: { width: "18%", ...defaultStyles },
    style: { width: "18%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "last name",
    dataKey: "lastName",
    headerStyle: { width: "18%", ...defaultStyles },
    style: { width: "18%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "date of birth",
    dataKey: "birthday",
    headerStyle: { width: "18%", ...defaultStyles },
    style: { width: "18%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "phone number",
    dataKey: "phone",
    headerStyle: { width: "18%", ...defaultStyles },
    style: { width: "18%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "member",
    dataKey: "isMember",
    headerStyle: { width: "18%", ...defaultStyles },
    style: { width: "18%", ...defaultStylesRow },
    customCell: ({ rowData }) => {
      return <span>{rowData.isMember ? "Yes" : "No"}</span>;
    },
  },
];

export const PatientsStepContext = createContext(null);
export const usePatientsStepContext = () => useContext(PatientsStepContext);
export const usePatientsStepState = () => {
  const [patient, setPatient] = useState();
  const [search, setSearch] = useState("");

  return useMemo(
    () => ({
      patient: patient,
      setPatient: setPatient,
      search: search,
      setSearch: setSearch,
    }),
    [patient, search],
  );
};

export const PatientsList = () => {
  const { onNext, setStepperHistory, configuration, stepperHistory } =
    useCreateRequestContext();
  const { patient, setPatient, search, setSearch } = usePatientsStepContext();
  const debounceSearch = useDebounce(search, 1000);

  const patients = useSelector(patientsSelector);
  const isLoaded = useSelector(isPatientsLoadedSelector);

  const dispatch = useDispatch();

  useMountedEffect(
    () => {
      dispatch(getPatients({ filters: { offset: 0, search: debounceSearch } }));
    },
    [debounceSearch],
    !isLoaded,
  );

  const onCreatePatientPressed = () => {
    setPatient(null);
    setStepperHistory([...stepperHistory, configuration.create_patient]);
  };

  const onSearchValueUpdated = (field, value) => setSearch(value);

  const onLoadMore = () => {
    if (!patients?.next) {
      return;
    }
    dispatch(
      getPatients({
        filters: { offset: patients.results.length, search: debounceSearch },
        data: patients,
      }),
    );
  };

  const onNextPressed = () => {
    if (!!patient) {
      onNext();
    }
  };

  return (
    <>
      <div className="patients-list">
        <PanelFilters
          value={search}
          onChange={onSearchValueUpdated}
          customButtonAdd={
            <ButtonV2
              className="patients-list_filters_add-button"
              onClick={onCreatePatientPressed}
              uppercase
              variant={VARIATIONS_BTN.TEXT}
              color={COLORS_BTN.PRIMARY}
            >
              <Plus className="patients-list_add-button_icon" />
              Create a new patient
            </ButtonV2>
          }
        />
        <div className="patients-list_divider" />
        <CustomTable
          actions={{ onPatientSelected: setPatient }}
          loadTableData={onLoadMore}
          heightTable="calc(100vh - 424px)"
          columnsHeader={columns}
          extraRowData={{ selectedPatient: patient }}
          data={patients?.results ? patients?.results : []}
        />
      </div>
      <CreateRequestFooter
        onNext={onNextPressed}
        disabledNextButton={!patient}
      />
    </>
  );
};
