import React from "react";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import "./style.scss";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    borderRadius: 0,
    maxWidth: 300,
    background: "#000000",
    lineHeight: "16px",
    fontSize: 12,
    fontWeight: 500,
    padding: 8,
  },
  arrow: {
    color: "#000000",
  },
}));

export const Tooltip = ({
  children,
  className = "",
  title,
  placement = "top",
  arrow = true,
  hide,
}) => {
  const classes = useStyles();
  if (hide) return children;
  return (
    <MuiTooltip
      TransitionComponent={Fade}
      title={title}
      arrow={arrow}
      placement={placement}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      className={cn("tooltip-component", className)}
    >
      {children}
    </MuiTooltip>
  );
};
