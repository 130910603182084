import React, { useEffect, useState } from "react";
import { Typography } from "../../Common/Typography/Typography";
import { Checkbox } from "../../Common/Checkbox/Checkbox";
import { Button } from "../../Common/Button/Button";
import { buttonsInnerText } from "../../../constants";
import history from "../../../helpers/history";
import { routersPatient } from "../../../constants/Patient";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { ArrowLeft } from "../../Common/Icons";
import { useDispatch } from "react-redux";
import { approveDeclineServices } from "store/additionalFacilityServiceRequest/actions";

export const Services = ({
  services,
  selectedServices,
  setSelectedServices,
  baseUrl,
  id,
  setActivePatientServices,
}) => {
  const [confirmation, setConfirmation] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) history.push(baseUrl + routersPatient.info);
  }, []);
  const openPopupDecline = () => {
    setConfirmation(true);
  };
  const closePopupDecline = () => {
    setConfirmation(false);
  };

  const declineAllServices = () => {
    dispatch(
      approveDeclineServices(
        {
          assignments_ids: services
            .filter((i) => i.payment_status === "pending")
            .map((i) => i.uuid),
        },
        id,
      ),
    ).then(() => {});
    setSelectedServices([]);
    setActivePatientServices(null);
    history.push(baseUrl + routersPatient.info);
  };

  const updateSelectedServices = (item) => {
    if (selectedServices.find((i) => i.uuid === item.uuid))
      setSelectedServices(selectedServices.filter((i) => i.uuid !== item.uuid));
    else setSelectedServices([...selectedServices, item]);
  };
  const goToListCard = () => history.push(baseUrl + routersPatient.cards);
  const goBack = () => {
    setSelectedServices([]);
    setActivePatientServices(null);
    history.push(baseUrl + routersPatient.info);
  };
  const pendingServices = services
    ? services.filter((i) => i.payment_status === "pending")
    : [];

  return (
    <div className="details-request-services">
      <div className="details-request-services_header">
        {" "}
        <ArrowLeft
          className="details-request-services_header_arrow"
          onClick={goBack}
        />
        <Typography variant="h2" text="Additional Services Recommended" />
      </div>
      <div className="details-request-services_list">
        <Typography
          variant="label"
          text="Your Doctor recommends following services:"
        />
        <div className="details-request-services_list_options">
          {pendingServices.map((i, index) => (
            <div
              key={index}
              className="details-request-services_list_options_option"
              onClick={() => updateSelectedServices(i)}
            >
              <Typography
                variant="p"
                text={`${i.name} $${Math.floor(i.price)}`}
              />
              <Checkbox
                className="emr-page-container_checkbox_item"
                checked={selectedServices.find((item) => item.uuid === i.uuid)}
                onClick={() => updateSelectedServices(i)}
              />
            </div>
          ))}
        </div>
        <div className="details-request-services_list_buttons">
          <Button
            text={buttonsInnerText.decline_all}
            size="middle"
            variant="default"
            onClick={openPopupDecline}
          />
          <Button
            disabled={!selectedServices.length}
            text={buttonsInnerText.pay}
            size="middle"
            variant="primary"
            onClick={goToListCard}
          />
        </div>
      </div>

      <PopupContainer
        open={confirmation}
        className="confirmation-decline-all"
        close={closePopupDecline}
        capitalize
        title="Are You Sure You Want To Decline Medical"
        extraTitle="Services Recommended by Your Doctor?"
        textDefault={buttonsInnerText.cancel}
        textPrimary={buttonsInnerText.decline}
        actionDefaultButton={closePopupDecline}
        actionPrimaryButton={declineAllServices}
      >
        {!!pendingServices &&
          pendingServices.map((i, index) => (
            <div
              key={index}
              className="confirmation-decline-all-content_options-confirmation"
            >
              <div className="confirmation-decline-all-content_options-confirmation_point" />
              <Typography variant="p" text={`${i.name} $${i.price}`} />
            </div>
          ))}
      </PopupContainer>
    </div>
  );
};
