import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayChat: false,
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    setDisplayChat: (state, { payload }) => {
      state.displayChat = payload;
    },
  },
});

export const { setDisplayChat } = appSlice.actions;
export const appReducer = appSlice.reducer;
