import { fetchWrapper } from "../../helpers/helpers";
import { setServiceRequests } from "./slice";

const getServiceRequestFilters = async (body) =>
  await fetchWrapper({
    url: "electronic-medical-record/service-requests/filters/",
    body: { ...body, statuses: body.checkbox },
  }).then((resp) => {
    let filters = {};
    for (let key in resp) {
      filters[key] = resp[key].map((i) => ({
        value: i.uuid,
        label: i.name || "N/A",
      }));
    }
    return filters;
  });

const getServiceRequests =
  (filters, data = { results: [] }) =>
  (dispatch) =>
    fetchWrapper({
      url: "electronic-medical-record/service-requests/",
      body: filters,
    }).then((resp) => {
      if (resp.results)
        dispatch(
          setServiceRequests({
            ...resp,
            results: data.results.concat(resp.results),
          }),
        );
    });

export { getServiceRequestFilters, getServiceRequests };
