import React, { useEffect, useState } from "react";
import { Button } from "../../Common/Button/Button";
import { buttonsText, steps } from "../../../constants";
import { Buttons } from "../Buttons";
import { ItemPatient } from "./ItemPatient";
import { converterDataField } from "../../../utils/common";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";
import { CheckboxV2 } from "../../Common/CheckboxV2";
import { COLORS_CHECKBOX } from "../../Common/CheckboxV2/constants";
import { groupPatientsByRelation } from "../../../helpers/helpers";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getPatients } from "../../../store/createRequest/actions";

export const ListPatients = ({
  listPatients,
  createPatient,
  simpleUpdateState,
  user,
  actionNextStep,
  moveToTheStep,
  selectedPatients,
  activeStep,
  createPatientMode,
  symptoms,
  currentRequestData,
}) => {
  const [patients, setPatients] = useState(selectedPatients);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatients());
    Analytics.track(EVENTS.Comprehensive_Patient_Page_Open);
  }, []);

  const primaryDisabled = patients.length === 0;

  const actionCancel = () => simpleUpdateState("openConfirmation", true);

  const handleChoice = (value) => {
    if (!patients.find((i) => i.uuid === value.uuid)) {
      setPatients((prev) => [...prev, value]);
    } else {
      setPatients((prev) => prev.filter((item) => item.uuid !== value.uuid));
    }
  };

  const addPatient = () => {
    moveToTheStep(steps.AddNew);
    simpleUpdateState("selectedPatients", patients);
  };

  const goToNextStep = () => {
    const patientsWithSymptoms = patients.map((i) => ({
      ...i,
      selected: i.selected || (symptoms.length === 1 ? symptoms : []),
      otherSymptoms:
        typeof i.otherSymptoms === "string"
          ? i.otherSymptoms
          : currentRequestData?.comment
            ? currentRequestData?.comment
            : symptoms.length === 1
              ? symptoms[0].name
              : "",
    }));
    const sortedPatients = _.sortBy(patientsWithSymptoms, "status");
    if (createPatientMode) {
      const data = { ...converterDataField(user, false), status: "main" };
      createPatient(data).then((resp) => {
        if (resp.uuid) {
          simpleUpdateState("selectedPatients", [
            ...sortedPatients,
            { ...resp },
          ]);
          simpleUpdateState("patientsAmount", sortedPatients.length);
          actionNextStep();
        }
      });
    } else {
      simpleUpdateState("selectedPatients", sortedPatients);
      simpleUpdateState("patientsAmount", sortedPatients.length);
      actionNextStep();
    }
    simpleUpdateState("activePatient", sortedPatients[0].uuid);
  };

  const pickRelationGroup = (group) => {
    if (
      patients.filter((i) => i.client_patient_relationship === group).length ===
      listPatients?.filter((i) => i.client_patient_relationship === group)
        .length
    ) {
      setPatients((prev) =>
        prev.filter((i) => i.client_patient_relationship !== group),
      );
    } else {
      setPatients((prev) => [
        ...prev,
        ...listPatients.filter(
          (i) =>
            i.client_patient_relationship === group &&
            !prev.find((j) => j.uuid === i.uuid),
        ),
      ]);
    }
  };

  return (
    <div className="list-patients">
      <div className="list-patients_list">
        {groupPatientsByRelation(listPatients)?.map((data, ind) => {
          const label =
            data.label === "null"
              ? "You"
              : `${data.label[0].toUpperCase()}${data.label.slice(1)}`;
          const checkedGroup =
            patients.filter((i) => i.client_patient_relationship === data.label)
              .length ===
            listPatients?.filter(
              (i) => i.client_patient_relationship === data.label,
            ).length;
          return (
            <React.Fragment key={ind}>
              <div className="list-patients__group-name">
                {data.label !== "null" ? (
                  <CheckboxV2
                    label={label}
                    color={COLORS_CHECKBOX.PRIMARY}
                    checked={checkedGroup}
                    onChange={() => pickRelationGroup(data.label)}
                  />
                ) : (
                  <>{label}</>
                )}
              </div>
              {data.items?.map((item) => {
                const selected = !!patients?.find((k) => k.uuid === item.uuid);
                return (
                  <ItemPatient
                    key={item.uuid}
                    selected={selected}
                    data={item}
                    onClick={() => handleChoice(item)}
                    activeStep={activeStep}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      <div className="list-patients_buttons-actions">
        <Button
          text={buttonsText.newPatient}
          size="middle"
          className="list-patients_buttons-actions_add-patient"
          variant="default"
          onClick={addPatient}
        />
        <Buttons
          PrimaryDisabled={primaryDisabled}
          DefaultClick={actionCancel}
          PrimaryClick={goToNextStep}
        />
      </div>
    </div>
  );
};
