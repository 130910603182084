import React from "react";
import pureFolder from "../../../dist/icons/pureFolder.svg";
import EllipseGreen from "../../../dist/icons/EllipseGreen.svg";
import EllipseYellow from "../../../dist/icons/EllipseYellow.svg";
import EllipseRed from "../../../dist/icons/EllipseRed.svg";
import EllipseBlue from "../../../dist/icons/EllipseBlue.svg";

import "./style.scss";
import { DENIED, NOT_SUPPORTED } from "../../../constants";

const FolderInfo = ({
  name,
  isDeclined,
  reload,
  error,
  acceptedStatus,
  link,
}) => {
  const redConditions = isDeclined === NOT_SUPPORTED || error;

  return (
    <a download href={link} target="_blank" rel="noreferrer">
      <img src={pureFolder} alt="circle" />
      <div className="folder">
        {acceptedStatus && (
          <img src={EllipseGreen} alt="ellipse" className="folder-ellipse" />
        )}
        {isDeclined === DENIED && (
          <img src={EllipseYellow} alt="ellipse" className="folder-ellipse" />
        )}
        {redConditions && (
          <img src={EllipseRed} alt="ellipse" className="folder-ellipse" />
        )}
        {reload && (
          <img src={EllipseBlue} alt="ellipse" className="folder-ellipse" />
        )}

        <div className="folder-view">{name}</div>
      </div>
    </a>
  );
};

export default FolderInfo;
