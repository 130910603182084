import React, { useState } from "react";
import "./style.scss";
import data from "../../CreateReqest/StepMobileCode/phoneCodesFlags";
import { CustomSelect } from "../Select/Select";
import { components } from "react-select";
import InputMask from "react-input-mask";
import Flag from "react-world-flags";
import cn from "classnames";

const customComponent = (props, height) => {
  return (
    <>
      {" "}
      <Flag height={height || "12"} code={props.code} />{" "}
      {height ? "" : props.dial_code}{" "}
    </>
  );
};

export const InputFlag = ({ onChange, className }) => {
  const [value, setValue] = useState(data[0]);
  const [number, setNumber] = useState("");

  const handlerSelect = (value) => {
    setValue(value);
  };

  const handleSelectNumber = (e) => {
    const fullValue = value.dial_code + e.target.value;
    setNumber(e.target.value);
    onChange(fullValue);
  };

  return (
    <div className={cn("container-input-flag", className)}>
      <CustomSelect
        customComponent={customComponent}
        options={data}
        value={value}
        handlerSelect={handlerSelect}
        className="container-input-flag_select-flag"
        components={{
          SingleValue: (props) => (
            <components.SingleValue {...props}>
              <img
                src={require(`../../../dist/flags/${props.data.code}.svg`)}
                style={{ width: 31 }}
              />
            </components.SingleValue>
          ),

          Option: (props) => {
            return (
              <components.Option {...props}>
                <img
                  src={require(`../../../dist/flags/${props.data.code}.svg`)}
                  style={{ width: 40, marginRight: 16 }}
                />
                <span> {props.data.name} </span>
                <span className="select__option--text">{`(${props.data.dial_code})`}</span>
              </components.Option>
            );
          },
        }}
      />
      <div className="container-input-flag__container">
        {/*<span className="container-input-flag__code">{`${value.dial_code}`}</span>*/}
        <InputMask
          maskChar=""
          // mask={"(999) 999-99999999".slice(0, -value.dial_code.length)}
          mask={"(999) 999-9999"}
          className="container-input-flag_input"
          value={number}
          onChange={handleSelectNumber}
        />
      </div>
    </div>
  );
};
