import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { BTN_LABELS, PLACEHOLDERS, routesCommon } from "../../../constants";
import { showFiltersValue } from "../../../utils/common";
import history from "../../../helpers/history";

import {
  checkboxValueRequest,
  routersPatient,
} from "../../../constants/Patient";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { PanelFilters } from "../../AdminEmr/PanelFilters/PanelFilters";
import { ContainerEvents } from "./ContainerEvents";
import { DetailsRequest } from "./DetailsRequest";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarEvents } from "../../../store/calendarEvent/actions";
import {
  countRequestsSelector,
  calendarEventsSelector,
} from "../../../store/calendarEvent/selectors";
import { chunkRequests, sortGroupRequest } from "../../../utils/requests";
import { serviceRequestTypesSelector } from "store/serviceRequestTypes/selectors";
import { requestsTypesFieldsPatient } from "store/serviceRequestTypes/constants";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { CheckboxV2 } from "../../Common/CheckboxV2";
import { COLORS_CHECKBOX } from "../../Common/CheckboxV2/constants";
import { DropDown } from "../../Common/DropDown/DropDown";

const initialState = {
  checkbox: [],
};

export const PatientListRequests = (props) => {
  const [state, setState] = useState({
    choosedFilters: {
      checkbox: [],
    },
    applyFilters: {
      checkbox: [],
    },
  });
  const [selectedVisitType, setSelectedVisitType] = useState(null);

  const dispatch = useDispatch();

  const typesRequests = useSelector(serviceRequestTypesSelector);
  const calendarEvents = useSelector(calendarEventsSelector);
  const countRequestInLine = useSelector(countRequestsSelector);

  useEffect(() => {
    if (
      props.location.pathname ===
      `${routesCommon.patient}${routersPatient.schedule}`
    ) {
      dispatch(getCalendarEvents());
    }
  }, [props.location.pathname]);

  const deleteAppliedFilters = () => {
    setState({
      ...state,
      choosedFilters: {
        ...initialState,
      },
      applyFilters: {
        ...initialState,
      },
    });
  };
  const upDateCheckbox = (field) => {
    const stateNew = { ...state };
    if (state.choosedFilters.checkbox.find((i) => i.label === field)) {
      stateNew.choosedFilters = {
        ...state.choosedFilters,
        checkbox: state.choosedFilters.checkbox.filter(
          (i) => i.label !== field,
        ),
      };
    } else {
      stateNew.choosedFilters = {
        ...state.choosedFilters,
        checkbox: [
          ...state.choosedFilters.checkbox,
          checkboxValueRequest.find((i) => i.label === field),
        ],
      };
    }
    setState(stateNew);
  };
  const applyFilterAction = () => {
    setState({
      ...state,
      applyFilters: {
        ...state.choosedFilters,
      },
    });
  };

  useEffect(() => {
    const historyData = props.location.state;
    if (historyData) {
      if (historyData.service_request_uuid)
        getDetailsEvent({
          uuid: historyData.service_request_uuid,
        });
    }
  }, [JSON.stringify(props.location)]);

  const getDetailsEvent = (data) => {
    history.push(
      `${routesCommon.patient}${routersPatient.details}/${data.uuid}`,
    );
  };

  const filtersValue = showFiltersValue(state.applyFilters);
  const checkbox = state.choosedFilters.checkbox;

  const sortedCalendarEvents = useMemo(
    () => sortGroupRequest(calendarEvents),
    [calendarEvents?.timestamp],
  );

  const futureRequest = () => {
    let data = [];
    const lengthFilters = state.applyFilters.checkbox.length;
    if (
      (state.applyFilters.checkbox.find(
        (i) => i.value === checkboxValueRequest[0].value,
      ) ||
        !lengthFilters) &&
      sortedCalendarEvents.future.length
    ) {
      data.push({ title: checkboxValueRequest[0].label });
      data = [
        ...data,
        ...chunkRequests(sortedCalendarEvents.future, countRequestInLine),
      ];
    }
    return data;
  };
  const pastRequest = () => {
    let data = [];
    const lengthFilters = state.applyFilters.checkbox.length;
    if (
      (state.applyFilters.checkbox.find(
        (i) => i.value === checkboxValueRequest[1].value,
      ) ||
        !lengthFilters) &&
      sortedCalendarEvents.past.length
    ) {
      data.push({ title: checkboxValueRequest[1].label });
      data = [
        ...data,
        ...chunkRequests(sortedCalendarEvents.past, countRequestInLine),
      ];
    }
    return data;
  };
  const options = useMemo(() => {
    let data = [];
    if (!sortedCalendarEvents) return data;
    data = [...futureRequest(), ...pastRequest()];
    return data;
  }, [
    calendarEvents?.timestamp,
    state.applyFilters.checkbox.length,
    countRequestInLine,
  ]);

  const handleTypeRequest = (field, value) => {
    setSelectedVisitType(value);
  };

  const handleNavCreateRequest = () => {
    history.push(requestsTypesFieldsPatient[selectedVisitType.value].urlNav);
  };
  const typesOptions = useMemo(
    () =>
      typesRequests
        .filter((i) => i.is_visible)
        .map((i) => ({ value: i.name, label: i.viewLabel })),
    [!!typesRequests?.length],
  );
  return (
    <div className="patient-list-requests">
      <Switch>
        <Route
          path={`${routesCommon.patient}${routersPatient.schedule}`}
          render={() => (
            <>
              <div className="patient-list-requests_main-title">
                <Typography
                  color={colors.blackMuted}
                  variant="h1_5"
                  text="Would You Like The Doctor to Come See You Now?"
                />
              </div>
              <div className="patient-list-requests_button-request">
                <DropDown
                  value={selectedVisitType}
                  handlerSelect={handleTypeRequest}
                  label={PLACEHOLDERS.SELECT_REQUEST_TYPE}
                  options={typesOptions}
                />
                <ButtonV2
                  onClick={handleNavCreateRequest}
                  disabled={!selectedVisitType}
                  capitalize
                  variant={VARIATIONS_BTN.CONTAINED}
                  color={COLORS_BTN.PRIMARY}
                >
                  {BTN_LABELS.YES_MAKE_A_REQUEST}
                </ButtonV2>
              </div>
              <div className="patient-list-requests_header">
                <Typography
                  color={colors.blackMuted}
                  variant="h1_5"
                  text="List Of Visits"
                />
                <PanelFilters
                  noSearch
                  filtersValue={filtersValue}
                  applyFilters={state.applyFilters}
                  applyFilterAction={applyFilterAction}
                  deleteAppliedFilters={deleteAppliedFilters}
                >
                  <Typography
                    color={colors.blackMuted}
                    text="Sort by:"
                    variant="p"
                  />
                  <div className="patient-checkbox-value-request">
                    {checkboxValueRequest.map((item, index) => (
                      <>
                        <CheckboxV2
                          key={index}
                          color={COLORS_CHECKBOX.PRIMARY}
                          label={item.label}
                          onChange={upDateCheckbox}
                          field={item.label}
                          checked={
                            !!checkbox.find((i) => i.label === item.label)
                          }
                        />
                      </>
                    ))}
                  </div>
                </PanelFilters>
              </div>
              <div className="patient-list-requests_container-list">
                {!!options.length && (
                  <ContainerEvents
                    useInitialHandleResize
                    data={options}
                    openRequest={state.detailsRequest}
                    showDetails={getDetailsEvent}
                  />
                )}
              </div>
            </>
          )}
        />
        <Route
          path={[
            ...[
              "",
              routersPatient.info,
              routersPatient.cards,
              routersPatient.services,
            ].map(
              (i) => `${routesCommon.patient}${routersPatient.details}/:id${i}`,
            ),
          ]}
          render={({ location }) => {
            return (
              <DetailsRequest
                location={location}
                updateData={location.state?.service_request_uuid}
                cards={props.cards}
                user={props.user}
                id={location.pathname.split("/")[3]}
                details={props.data}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};
