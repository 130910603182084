import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CreateRequestFooter,
  useCreateRequestContext,
} from "../../../containers/AdminFlow/CreateAppointment";
import "./RequestDetailsStep.style.scss";
import moment from "moment";
import { SingleDatePicker } from "../../Common/SingleDatePicker/SingleDatePicker";
import { DropDown } from "../../Common/DropDown/DropDown";
import { useSelector } from "react-redux";
import { serviceRequestTypesSelector } from "../../../store/serviceRequestTypes/selectors";
import { Textarea } from "../../Common/TextArea/Textarea";
import { TimeSlot } from "../../Common/TimeSlot";
import {
  validateTimePeriod,
  validationRequiredField,
} from "../../../utils/validation";
import { filterObj, lengthObj } from "../../../utils/common";
import { InputAddress } from "../../Common/Input/InputAddress";
import { placeholdersAddress } from "../../../constants";
import { Input } from "../../Common/Input/Input";

export const RequestDetailsContext = createContext(null);
export const useRequestDetailsContext = () => useContext(RequestDetailsContext);
export const useRequestDetailsStepState = (patientsStepState) => {
  const [time, setTime] = useState({});
  const [day, setDay] = useState(null);
  const [type, setType] = useState(null);
  const [note, setNote] = useState("");
  const [address, setAddress] = useState(null);
  const [apartment, setApartment] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [isOutWorkingZone, setIsOutWorkingZone] = useState(false);

  useEffect(() => {
    setAddress(patientsStepState?.patient?.home_address);
    setZipcode(patientsStepState?.patient?.zipcode);
    setApartment(patientsStepState?.patient?.apartment);
  }, [patientsStepState?.patient]);

  const values = useMemo(
    () => ({
      time: time,
      day: day,
      type: type,
      note: note,
      setTime: setTime,
      setDay: setDay,
      setType: setType,
      setNote: setNote,
      address: address,
      setAddress: setAddress,
      zipcode: zipcode,
      setZipcode: setZipcode,
      isOutWorkingZone: isOutWorkingZone,
      setIsOutWorkingZone: setIsOutWorkingZone,
      apartment: apartment,
      setApartment: setApartment,
    }),
    [time, day, type, note, address, zipcode, isOutWorkingZone, apartment],
  );
  return values;
};
export const RequestDetailsStep = () => {
  const [errors, setErrors] = useState({});
  const { onNext } = useCreateRequestContext();
  const {
    time,
    day,
    type,
    note,
    setTime,
    setDay,
    setType,
    setNote,
    address,
    setAddress,
    zipcode,
    setZipcode,
    setIsOutWorkingZone,
    apartment,
    setApartment,
  } = useRequestDetailsContext();

  const typesRequests = useSelector(serviceRequestTypesSelector);

  const typesOptions = useMemo(
    () =>
      typesRequests
        .filter((i) => i.is_admin_visible)
        .map((i) => ({
          value: i.name,
          label: i.viewLabel,
        })),
    [!!typesRequests?.length],
  );

  const validateForm = () => {
    let errors = {};
    errors.day = validationRequiredField(day);
    errors.type = validationRequiredField(type);
    errors.time = validateTimePeriod(
      time?.startTime?.value,
      time?.endTime?.value,
    );
    errors.address = validationRequiredField(address);
    return filterObj(errors);
  };

  const onNextPressed = () => {
    const errors = validateForm();
    if (lengthObj(errors)) {
      setErrors(errors);
      return;
    }

    onNext();
  };

  const updateAddress = (_, address, zipcode, __, isAddressOutServedArea) => {
    setAddress(address);
    setZipcode(zipcode);
    setIsOutWorkingZone(isAddressOutServedArea);
  };

  return (
    <div className="date-time-step">
      <DropDown
        className="date-time-step_type"
        value={type}
        handlerSelect={(_, value) => {
          setErrors((prev) => ({ ...prev, type: null }));
          setType(value);
        }}
        label="Select Visit Type"
        options={typesOptions}
        error={errors["type"]}
      />
      <div className="date-time-step_date">
        <SingleDatePicker
          className=""
          label="Date"
          popperPlacement="top"
          selected={day}
          onChange={setDay}
          maxDate={moment().add(3, "months").toDate()}
          error={day ? null : errors["day"]}
        />
        <TimeSlot
          menuPlacement="bottom"
          updateState={(field, value) => {
            setErrors((prev) => ({ ...prev, time: null }));
            setTime((prev) => ({ ...prev, [field]: value }));
          }}
          timeStart={time?.startTime}
          timeFinish={time?.endTime}
          error={errors["time"]}
        />
      </div>
      <div className="date-time-step_address">
        <InputAddress
          checkZipcode
          checkOutOfServedZone
          initialCheckOutOfServedZone
          placeholder={placeholdersAddress.address}
          error={errors["address"]}
          value={address}
          onChange={updateAddress}
        />
        <Input
          disable
          type="number"
          placeholder={placeholdersAddress.zipcode}
          value={zipcode}
          field="zipcode"
        />
      </div>

      <div className="date-time-step_additional-info">
        <Input
          type="noSign"
          placeholder={placeholdersAddress.apartment}
          className=""
          value={apartment}
          field="apartment"
          maxLength="12"
          onChange={(_, value) => setApartment(value)}
        />
        <Input
          placeholder="Notes"
          className=""
          value={note}
          field="comment"
          maxLength="1000"
          onChange={(_, value) => setNote(value)}
        />
      </div>
      <CreateRequestFooter onNext={onNextPressed} />
    </div>
  );
};
