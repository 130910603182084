import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { RangeDatePicker } from "../../Common/RangeDatePicker/RangeDatePicker";
import moment from "moment";
import { DropDown } from "../../Common/DropDown/DropDown";
import { placeholders } from "../../../constants";
import { Button } from "../../Common/Button/Button";
import React, { useEffect, useState } from "react";
import {
  downloadFileReport,
  getDoctorsData,
} from "../../../store/admin/actions";
import fileDownload from "js-file-download";
import "./style.scss";
import { useDispatch } from "react-redux";
import { getLocations } from "../../../store/actions";

const initialState = {
  locations: [],
  doctors: [],
};

export const DailyReport = ({ locations }) => {
  const [dateTo, setDateTo] = useState(moment().toDate());
  const [dateFrom, setDateFrom] = useState(moment().add(-30, "day").toDate());
  const [doctors, setDoctors] = useState([]);
  const [choosedFilters, setChoosedFilters] = useState({ ...initialState });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocations());
    getDoctorsData().then((data) =>
      setDoctors(
        data.results.map((item) => ({
          value: item.uuid,
          label: `${item.first_name} ${item.last_name}`,
        })),
      ),
    );
  }, []);

  const onChangeDate = (field, value) => {
    if (field === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom(value);
    }
  };

  const selectFilters = (field, value) => {
    setChoosedFilters({
      ...choosedFilters,
      [field]: value,
    });
  };

  const download = () => {
    downloadFileReport({
      select_period_before: moment(dateTo).format("YYYY-MM-DD"),
      select_period_after: moment(dateFrom).format("YYYY-MM-DD"),
      regions: choosedFilters.locations.map((item) => item.value),
      doctors: choosedFilters.doctors.map((item) => item.value),
    }).then((resp) => {
      resp.size &&
        fileDownload(
          resp,
          `Daily_Visit_Report_${moment(dateFrom).format("YYYY-MM-DD")}_${moment(dateTo).format("YYYY-MM-DD")}.xls`,
        );
    });
  };

  return (
    <div className="daily-report">
      <div className="daily-report-filters">
        <div className="daily-report__datepicker">
          <Typography
            capitalize
            variant="p"
            color={colors.grey}
            text="select period"
          />
          <RangeDatePicker
            onChange={onChangeDate}
            dateTo={dateTo}
            dateFrom={dateFrom}
            maxDateTo={moment().toDate()}
            maxDateFrom={dateTo}
          />
        </div>
        <div className="daily-report__dropdowns">
          <DropDown
            multi
            value={choosedFilters.locations}
            field="locations"
            handlerSelect={selectFilters}
            label={placeholders.location}
            options={locations}
          />
          <DropDown
            multi
            value={choosedFilters.doctors}
            field="doctors"
            handlerSelect={selectFilters}
            label={placeholders.doctor}
            options={doctors}
          />
        </div>
      </div>
      <Button
        text="get report"
        size="middle"
        variant="primary"
        capitalize
        onClick={download}
      />
    </div>
  );
};
