import React, { useEffect, useState } from "react";
import "./style.scss";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import { patientProfileTabs, routersPatient } from "../../../constants/Patient";
import { Profile } from "./Profile";
import history from "../../../helpers/history";
import { routesCommon } from "../../../constants";
import { useDispatch } from "react-redux";
import { getCardsPatient } from "store/patientPaymentCard/actions";
import { ContainerPayment } from "./ContainerPayment";
import { getCurrentUser } from "../../../store/currentUser/actions";
import { USER_ROLES } from "../../../store/currentUser/constants";

export const PersonalInfoPatient = ({ patient, paymentCards }) => {
  const [activeTab, setActiveTab] = useState(patientProfileTabs[0]);
  const [isAddNewCard, setIsAddNewCard] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser(USER_ROLES.PATIENT));
    dispatch(getCardsPatient());
  }, []);

  const controlAddNewCard = (value) => setIsAddNewCard(value);
  const changePage = (value) => setActiveTab(value);

  const closeList = () =>
    history.push(`${routesCommon.patient}${routersPatient.schedule}`);

  return (
    <div className="personal-info-patient">
      <div className="personal-info-patient_controls">
        <ButtonGroup
          id="btn-group"
          page={activeTab}
          buttonsTab={patientProfileTabs}
          changePage={changePage}
        />
      </div>
      {patientProfileTabs[0] === activeTab && <Profile patient={patient} />}
      {patientProfileTabs[1] === activeTab && (
        <ContainerPayment
          closeList={closeList}
          listCards={paymentCards}
          controlAddNewCard={controlAddNewCard}
          isAddNewCard={isAddNewCard}
        />
      )}
    </div>
  );
};
