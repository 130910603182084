export const GroupBlockReviewOfSystem = ({ title = "", radio = "" }) => {
  return {
    type: "group-block",
    canDelete: true,
    items: [
      {
        type: "text",
        data: title,
        editable: true,
        source: null,
      },
      {
        type: "radio-single",
        data: [{ checked: false, data: radio }],
      },
      {
        type: "text",
        data: ". ",
        editable: true,
        source: null,
      },
    ],
  };
};
