import React, { useEffect, useState } from "react";
import { DoctorInfo } from "./DoctorInfo";
import { CalendarEventShift } from "../../AdminPage/Shifts/CalendarEventShift";
import { CalendarEventDoctor } from "../../DoctorFlow/Schedule/CalendarEvent";
import { backToShift } from "../../../constants/Admin";

export const MultipleVisitsList = ({
  data,
  viewDetailsAppointmentOrShift,
  detailsAppointment,
  isMultiple,
  multipleUuid,
  closeRequest,
  doctorLocation,
  appointment,
  selectedEvent,
  isDoctor,
  user,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const container = document.querySelector(".container-sidebar");
    if (container) {
      if (!isOpen && isMultiple) {
        container.style.overflowY = "hidden";
      } else {
        container.style.overflowY = "initial";
      }
    }
  }, [isOpen, isMultiple]);

  useEffect(() => {
    if (closeRequest || multipleUuid) {
      setIsOpen(false);
    }
  }, [multipleUuid, closeRequest]);

  if (!detailsAppointment) {
    return <></>;
  } else
    return (
      <div className="multiple-visits">
        <div className="multiple-visits__content">
          {detailsAppointment.doctor_data && (
            <DoctorInfo
              data={detailsAppointment.doctor_data}
              doctorLocation={doctorLocation}
              isAppointment
            />
          )}
          <div className="multiple-visits__list">
            {data.map((i, ind) => {
              const event = { multipleUuid };
              event.data = { ...i };
              return (
                <div className="multiple-visits__item" key={ind}>
                  {!isDoctor ? (
                    <CalendarEventShift
                      hideTooltip
                      selectedEvent={selectedEvent}
                      onClick={() => {
                        viewDetailsAppointmentOrShift(
                          i,
                          appointment ? "" : backToShift,
                        );
                        setIsOpen(true);
                      }}
                      appointment={true}
                      event={event}
                    />
                  ) : (
                    <CalendarEventDoctor
                      selectedEvent={selectedEvent}
                      action={() => {
                        viewDetailsAppointmentOrShift(i);
                        setIsOpen(true);
                      }}
                      appointment={appointment}
                      event={event}
                      user={user}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
};
