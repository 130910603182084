import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: null,
  files: null,
};

const requestDetailsSlice = createSlice({
  name: "requestDetailsSlice",
  initialState,
  reducers: {
    setRequestDetails: (state, { payload }) => {
      state.details = payload;
    },
    setFilesRequest: (state, { payload }) => {
      state.files = payload;
    },
    clearRequestDetails: (state) => {
      state.details = null;
      state.files = null;
    },
  },
});

export const { clearRequestDetails, setRequestDetails, setFilesRequest } =
  requestDetailsSlice.actions;
export const requestDetailsReducer = requestDetailsSlice.reducer;
