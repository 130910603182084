import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.data = payload;
    },
    readNotification: (state, { payload }) => {
      state.data = state.data.map((j) =>
        j.payload.service_request_uuid === payload
          ? { ...j, is_read: true }
          : j,
      );
    },
    readAllNotifications: (state) => {
      state.data = state.data.map((i) => ({ ...i, is_read: true }));
    },
    clearNotificationsSlice: (state) => {
      state.data = [];
    },
  },
});

export const {
  setNotifications,
  clearNotificationsSlice,
  readAllNotifications,
  readNotification,
} = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
