import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { colors } from "../../../../../assets/colors/colors";
import { Typography } from "../../../../Common/Typography/Typography";
import {
  CloudDownload,
  Delete,
  Download,
  Edit,
} from "../../../../Common/Icons";
import { PopupContainer } from "../../../../Common/Popup/PopupContainer";
import { PMHEdit } from "./PMHEdit";
import {
  checkFileFormat,
  lengthObj,
  setFormatBE,
} from "../../../../../utils/common";
import { ContainerWithActions } from "../../../../Common/ContainerWithActions/ContainerWithActions";
import { toast } from "react-toastify";
import { FileBlock } from "../../../../Common/FileBlock/FileBlock";
import { ButtonMenu } from "../../../../Common/ButtonMenu/ButtonMenu";
import _ from "lodash";
import { Medications } from "../Medications";
import { Textarea } from "../../../../Common/TextArea/Textarea";
import { getSingleFromGroupRequest } from "../../../../../store/request/utils";

export const PastMedicalHistory = ({
  dataRequest,
  pmhDetailInfo,
  getUpdatePMHinfo,
  attachmentsPMH,
  createUpdateAttachmentsPMH,
  deleteAttachmentsPMH,
  familySocialHistoryOptions,
  icdList,
  id,
  role,
  doctorData,
}) => {
  const [popupState, setPopupState] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [attachmentEdit, setAttachmentEdit] = useState(null);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const targetRequest = useMemo(() => {
    return getSingleFromGroupRequest({ request: dataRequest, uuid: id });
  }, [dataRequest?.timestamp]);

  useEffect(() => {
    if (popupState) {
      setData({
        ...pmhDetailInfo,
      });
    } else {
      setData({});
    }
  }, [popupState]);

  useEffect(() => {
    if (pmhDetailInfo && lengthObj(data)) {
      let obj = {};
      for (let key in pmhDetailInfo) {
        if (key === "medications" || key === "medicationAllergies")
          obj[key] = pmhDetailInfo[key];
      }
      setData({
        ...data,
        ...obj,
      });
    }
  }, [JSON.stringify(pmhDetailInfo)]);

  const updateState = (field, value, index = null) => {
    let valueData = value;
    if (index !== null) {
      valueData = data[field].map((el, i) =>
        i === index ? { value: el.value, label: value.label } : el,
      );
    }
    setData({ ...data, [field]: valueData });
  };

  const closePMH = () => {
    setData({
      ...pmhDetailInfo,
    });
    setErrors({});
    setPopupState(false);
  };

  const openPMH = () => {
    setPopupState(true);
  };

  const updatePMH = () => {
    const body = {
      comment: data.comment,
      appointedAllergies: data.appointedAllergies,
      appointedMedications: data.appointedMedications,
      familySocialHistory: data.familySocialHistory.map((i) => ({
        name: i.label,
      })),
      pastMedicalHistory: data.pastMedicalHistory.map((i) => ({
        name: i.label,
        code: i.value,
      })),
    };
    getUpdatePMHinfo(targetRequest.patient_uuid, setFormatBE(body), true).then(
      (resp) => {
        setPopupState(false);
      },
    );
  };

  const inputFile = useRef(null);

  const handlerInputFile = (event) => {
    const file = event?.target?.files[0];

    if (file) {
      let checking = checkFileFormat({ size: file.size, path: file.name });
      if (!checking) {
        toast("Is not valid format or size", { autoClose: 3000 });
        return;
      }
      const format = file.name.split(".").reverse()[0];
      let fileName = file.name.split(".")[0];
      setAttachmentEdit([
        {
          format: format,
          name: fileName,
          fileLocal: file,
          size: file.size,
        },
      ]);
    }
  };

  const updateName = (value) => {
    createUpdateAttachmentsPMH(
      { name: value },
      targetRequest.patient_uuid,
      attachmentEdit[0].uuid,
      false,
    ).then((resp) => {
      if (resp.uuid) {
        closeEditMode();
      }
    });
  };

  const sendFile = (name) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      let data = new FormData();
      data.append("file", attachmentEdit[0].fileLocal);
      data.append("name", name);
      createUpdateAttachmentsPMH(
        data,
        targetRequest.patient_uuid,
        null,
        true,
      ).then((resp) => {
        if (resp.uuid) {
          closeEditMode();
        }
      });
    };
    reader.readAsDataURL(attachmentEdit[0].fileLocal);
  };

  const saveAttachmentsEdit = (name) => {
    if (attachmentEdit[0].fileLocal) sendFile(name);
    else updateName(name);
  };

  const closeEditMode = () => {
    setAttachmentEdit(null);
    inputFile.current.value = null;
  };

  const deleteItem = ({ value }, field) => {
    setData({
      ...data,
      [field]: data[field].filter((el) => el.value !== value),
    });
  };

  const addDropdown = (field) =>
    setData({
      ...data,
      [field]: [...data[field], { value: +new Date(), label: "" }],
    });

  const deleteAttachment = (el) => {
    deleteAttachmentsPMH(targetRequest.patient_uuid, el.uuid).then((resp) => {
      if (resp) {
        let arr = selectedAttachments.filter((item) => item.uuid !== el.uuid);
        setSelectedAttachments(arr);
      }
    });
  };
  return (
    <>
      <div className="past-medical-history">
        <div className="past-medical-history_header">
          <Typography
            color={colors.black}
            background={colors.pattensBlue}
            variant="h2_5"
            text="Past Medical History"
          />
          {role === "doctor" && <Edit onClick={openPMH} />}
        </div>
        <ContainerWithLabel title="Past Medical History">
          {pmhDetailInfo?.pastMedicalHistory.map((i, index) => (
            <Typography
              className="pmh-item-info"
              key={index}
              color={colors.black}
              background={colors.primary20}
              paddingSide={15}
              variant="title"
              text={i.label}
            />
          ))}
        </ContainerWithLabel>
        <ContainerWithLabel title="Family / Social History">
          {pmhDetailInfo?.familySocialHistory.map((i, index) => (
            <Typography
              key={index}
              color={colors.black}
              background={colors.pattensBlue}
              paddingSide={15}
              variant="title"
              text={i.label}
            />
          ))}
        </ContainerWithLabel>

        <Medications options={pmhDetailInfo?.medications} />
        {pmhDetailInfo?.appointedMedications && (
          <Textarea
            disabled
            maxLength={2000}
            placeholder="Medications"
            value={pmhDetailInfo?.appointedMedications}
            onChange={() => {}}
          />
        )}
        <ContainerWithLabel title="Allergies">
          <Typography
            variant="title"
            text={pmhDetailInfo?.medicationAllergies
              .map((i) => i.label)
              .join(", ")}
            color={colors.black}
          />
        </ContainerWithLabel>
        {pmhDetailInfo?.appointedAllergies && (
          <Textarea
            disabled
            maxLength={2000}
            placeholder="Allergies"
            value={pmhDetailInfo?.appointedAllergies}
            onChange={() => {}}
          />
        )}
        {pmhDetailInfo?.comment && (
          <Textarea
            disabled
            maxLength={2000}
            placeholder="Additional Information"
            value={pmhDetailInfo?.comment}
            onChange={() => {}}
          />
        )}
        <ContainerWithActions
          title="Attachment"
          hideButtonMenu
          hideAddItem={attachmentEdit}
          addItem={() => inputFile.current.click()}
          className="container__attachment-pmh"
        >
          {(attachmentEdit || attachmentsPMH).map((el, index) => {
            const canManage =
              el.created_by?.uuid ===
              (doctorData?.user?.uuid || doctorData?.uuid);
            return (
              <div key={index} className="attachment-item">
                <FileBlock
                  edit={attachmentEdit}
                  cancel={closeEditMode}
                  save={saveAttachmentsEdit}
                  checked={selectedAttachments.find((y) => y.uuid === el.uuid)}
                  file={el}
                />
                {!attachmentEdit && (
                  <ButtonMenu className="follow-ups-item_menu">
                    {canManage && (
                      <>
                        <li
                          onClick={() => setAttachmentEdit([el])}
                          className="past-medical-history_li-edit"
                        >
                          <Edit />
                          <Typography variant="p" text="Edit" />
                        </li>
                        <li
                          onClick={() => deleteAttachment(el)}
                          className="past-medical-history_li-delete"
                        >
                          <Delete />
                          <Typography variant="p" text="Delete" />
                        </li>
                      </>
                    )}
                    <li className="past-medical-history_li-download">
                      <a download href={el.file_download_link}>
                        <CloudDownload color="#5F6368" />{" "}
                        <Typography variant="p" text="Download" />
                      </a>
                    </li>
                  </ButtonMenu>
                )}
              </div>
            );
          })}
          <input
            type="file"
            ref={inputFile}
            onChange={handlerInputFile}
            className="attachment-input-file"
          />
        </ContainerWithActions>
      </div>
      <PopupContainer
        open={popupState}
        uppercaseButton
        capitalize
        className="pmh-container-content"
        close={closePMH}
        title="Please, Fill Fields Below"
        disabledButtonPrimary={false}
        actionDefaultButton={closePMH}
        actionPrimaryButton={updatePMH}
      >
        <PMHEdit
          state={data}
          updateState={updateState}
          errors={errors}
          medicationsOptions={data.medications}
          medicationAllergiesOptions={data.medicationAllergies}
          pastMedicalHistoryOptions={icdList}
          familySocialHistoryOptions={familySocialHistoryOptions}
          deleteItem={deleteItem}
          addDropdown={addDropdown}
          dataRequest={dataRequest}
        />
      </PopupContainer>
    </>
  );
};

const ContainerWithLabel = (props) => (
  <div className="past-medical-history_history">
    <Typography variant="p" text={props.title} color={colors.greyMiddle} />
    <div
      className={`past-medical-history_history_${
        props.list ? "list" : "items"
      }`}
    >
      {props.children}
    </div>
  </div>
);
