import React from "react";
import cn from "classnames";
import { PatientsList } from "./StepSymptoms/PatientsList";
import { steps } from "../../constants";

export const LeftPanel = (props) => {
  return (
    <div
      className={cn("app-container-request_wrapper_left-panel", {
        "app-container-request_wrapper_left-panel_hide": props.hideSteps,
      })}
    >
      {props.selectedPatients &&
        props.activeStep === steps.Symptoms &&
        !props.needRebuild && <PatientsList {...props} />}
    </div>
  );
};
