import moment from "moment";
import { momentAbbreviation } from "../constants";

const getDateFromBEFormat = (date) => moment(date, "YYYY-MM-DD").toDate();

const formatAppointmentDate = (date) => moment(date).format("YYYY-MM-DD");
const formatViewDate = (date) => moment(date).format("MM/DD/YYYY");

const getStartOfDayWithPrevDay = (date) =>
  moment(date || {})
    .startOf("day")
    .subtract(1, "days")
    .format("YYYY-MM-DD") + "T00:00";

const getStartOfDayWithNextDay = (date) =>
  moment(date || {})
    .endOf("day")
    .add(1, "days")
    .format("YYYY-MM-DD") + "T00:00";

const getStartOfWeek = (date) =>
  moment(date || {})
    .startOf("week")
    .format("YYYY-MM-DD") + "T00:00";
const getEndOfWeek = (date) =>
  moment(date || {})
    .endOf("week")
    .format("YYYY-MM-DD") + "T23:59";

const getStartOfWeekWithPrevDay = (date) =>
  moment(getStartOfWeek(date) || {})
    .subtract(1, "days")
    .format("YYYY-MM-DD") + "T00:00";
const getEndOfWeekWithNextDay = (date) =>
  moment(getEndOfWeek(date) || {})
    .add(1, "days")
    .format("YYYY-MM-DD") + "T23:59";

const getDateFromISO = (date) =>
  new Date(
    +date.substr(0, 4),
    +date.substr(5, 2) - 1,
    +date.substr(8, 2),
    +date.substr(11, 2),
    +date.substr(14, 2),
    +date.substr(17, 2),
  );
const changeFormatForRequest = (date) => moment(date).format("YYYY-MM-DD");

const dateFormatCalendarRequests = (date) =>
  moment(date).format("YYYY-MM-DD") + "T00:00";
const changeDateWithFormat = (date, convertToFE) => {
  if (date)
    return moment(date, convertToFE ? "YYYY-MM-DD" : "MM/DD/YYYY").format(
      convertToFE ? "MM/DD/YYYY" : "YYYY-MM-DD",
    );
  else return null;
};

const addFromCurrentInISO = (initialTime, ms) =>
  moment().add(initialTime, ms).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

const converTimeToMilleseconds = (value) =>
  value[0] * 60 * 60 * 1000 + value[1] * 60 * 1000;

const getTimeInFormat12 = (time) => moment(time).format("h:mm a");
const getTimeInFormat24 = (time) => moment(time).format("HH:mm");

const timeDelta = (data, value) =>
  moment.duration(moment(data).diff(moment())).as(value);

const durationTimeLaterRequest = (start, end) => ({
  timeOfStart: moment(start).format("h:mm"),
  timeOfEnd: moment(end).format("h:mm"),
  periodOfStart: moment(start).format("A"),
  periodOfEnd: moment(end).format("A"),
  month: moment(start).format("MMM"),
  day: moment(start).format("D"),
});

const convertToISO = () => moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

const dateMoreThenNowPlus90Min = (date) =>
  moment(addFromCurrentInISO(90, momentAbbreviation.minutes)) < moment(date);

const dateLesThenNowPlus90Min = (date) =>
  moment(addFromCurrentInISO(90, momentAbbreviation.minutes)) > moment(date);

const covertTime = (initialTime) =>
  moment().startOf("day").minutes(initialTime, "m").format("HH:mm");

const getFullYearsUser = (date) => moment().diff(date, "years");

const isToday = (date) => moment().startOf("day").add(1, "day") > moment(date);

const setTimeZoneForDate = (date) => moment(moment.utc(date).format()).format();

const setTimezoneEvents = (data) =>
  data.map((i) => ({
    ...i,
    application_can_start_at: setTimeZoneForDate(i.application_can_start_at),
    application_time: setTimeZoneForDate(i.application_time),
  }));

const durationInSec = ({ start, end }) => {
  return +moment(end).diff(moment(start), "seconds");
};

const addMilliseconds = (time, ms) => {
  return moment(time).add(ms, "ms");
};

export {
  addMilliseconds,
  durationInSec,
  setTimezoneEvents,
  setTimeZoneForDate,
  isToday,
  getFullYearsUser,
  covertTime,
  dateLesThenNowPlus90Min,
  dateMoreThenNowPlus90Min,
  convertToISO,
  timeDelta,
  durationTimeLaterRequest,
  getTimeInFormat24,
  getTimeInFormat12,
  converTimeToMilleseconds,
  addFromCurrentInISO,
  dateFormatCalendarRequests,
  changeFormatForRequest,
  getDateFromISO,
  getEndOfWeekWithNextDay,
  getStartOfWeekWithPrevDay,
  getEndOfWeek,
  getStartOfWeek,
  getStartOfDayWithNextDay,
  getStartOfDayWithPrevDay,
  formatAppointmentDate,
  formatViewDate,
  getDateFromBEFormat,
  changeDateWithFormat,
};
