export const BUTTONSDOCTOR = ["General Info", "Employment Application"];
export const TYPOGRAPHYNAME = [
  "Please Provide Your Information",
  `CREDENTIALING.</br> Please Provide The</br> Following Information:`,
];

export const requestDetailsControls = ["PMH", "PCP / Specialists"];

export const errorDate = "Please specify the expiration date";

export const routersDoctor = {
  table: "/table",
  schedule: "/schedule",
  personal_info: "/personal-info",
  dashboard: "/dashboard",
  emr: "/emr",
  request: "/request",
  info_request: "/info-request",
  template: "/template",
};

export const filtersCalendar = ["My requests", "All Requests"];

export const notificationsText = {
  completedRegistration:
    "Please complete the registration process within 10 days",
  appointment:
    "Your Employment Application is Complete. You will be notified when the Administrator reviews your Application. Thank you!",
  updateFiles: "Please Update Files That Were Flagged",
  waitingReview:
    "New patient appointment NOW. Please accept the assignment Now.",
};

export const STATUSES_EVENTS = {
  accepted: "accepted",
  assigned: "assigned",
  rejected: "rejected",
};

export const emailFax = [
  { label: "Email to PCP", value: "email" },
  { label: "FAX to PCP", value: "fax" },
];

export const placeholdersPCPDoctor = {
  firstName: "First Name*",
  lastName: "Last Name*",
  providerType: "Type of Provider*",
  degree: "Degree",
  email: "Email",
  phoneNumber: "Work Phone",
  faxNumber: "Fax",
  address: "Office Address",
  suite: "Suite",
  zipCode: "Zip Code",
};

export const placeholdersPMH = {
  pmh: "Past Medical History",
  familySocial: "Family / Social History",
  medications: "Medications",
  allergies: "Allergies to medications",
};

export const modesTemplate = {
  render: "render",
  preview: "preview",
  check: "check",
};

export const sidebarButtonsText = [
  "Confirm",
  "Confirm",
  "5 Mins left",
  "I'm Here",
  "Visit Complete",
  "",
  "Add Service",
  "",
  "Visit Complete",
  "Submit Patient",
];
