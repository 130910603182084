import React, { useEffect, useState } from "react";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { buttonsInnerText, placeholders } from "../../../constants";
import { DropDown } from "../../Common/DropDown/DropDown";
import "../../../styles/Admin/Calendar/style.scss";
export const ReassingTo = (props) => {
  const [physician, setPhysician] = useState(null);
  useEffect(() => {
    if (!props.open) setPhysician(null);
  }, [props.open]);
  const handlePhysician = (field, value) => setPhysician(value);
  return (
    <PopupContainer
      close={props.onClose}
      open={props.open}
      title="Reassign To"
      textPrimary={buttonsInnerText.assign}
      actionDefaultButton={props.onClose}
      actionPrimaryButton={() => {
        props.assignPhysician(physician);
      }}
      disabledButtonPrimary={!physician}
      uppercaseButton
      className="reassing-physician-container"
    >
      <DropDown
        className="reassing-physician-container_physician"
        value={physician}
        handlerSelect={handlePhysician}
        label={placeholders.physician}
        options={props.physicians}
      />
    </PopupContainer>
  );
};
