import React from "react";
import cn from "classnames";
import { Typography } from "../../../Common/Typography/Typography";
import "./style.scss";

export const Card = ({ children, label, className, none }) => (
  <div className={cn("dashboard-card", { none }, className)}>
    {!!label && <Typography variant="h2" text={label} className="card-title" />}
    {children}
  </div>
);
