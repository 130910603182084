import React from "react";
import {
  CreateRequestFooter,
  useCreateRequestContext,
} from "../../../containers/AdminFlow/CreateAppointment";
import "./SummaryStep.style.scss";
import { Card } from "../../CreateReqest/StepInfoRewiew/Card";
import { dateToDisplay } from "../../../helpers/helpers";
import moment from "moment";
import { Map } from "../../../compoenentsAdditional/Map";
import { PatientCard } from "../../Common/PatientCard";
import { colors } from "../../../assets/colors/colors";
import { Typography } from "../../Common/Typography/Typography";
import { Price } from "../../CreateReqest/StepRequestDataInfo/Price";
import { usePatientsStepContext } from "./PatientList";
import { useDoctorsStepContext } from "./DoctorsList";
import { useRequestDetailsContext } from "./RequestDetailsStep";
import { usePaymentStepContext } from "./PaymentStep";
import { useSelector } from "react-redux";
import { createAdminRequest } from "../../../store/createRequest/actions";
import { v1 as uuidv1 } from "uuid";
import { convertPhoneValue } from "../../../utils/common";
import history from "../../../helpers/history";
import { routesCommon } from "../../../constants";
import { routersAdmin } from "../../../constants/Admin";
import { changeDateWithFormat } from "../../../utils/date";

export const SummaryStep = () => {
  const { onClose } = useCreateRequestContext();
  const { patient } = usePatientsStepContext();
  const { doctor } = useDoctorsStepContext();
  const {
    time,
    day,
    type,
    note,
    address,
    zipcode,
    isOutWorkingZone,
    apartment,
  } = useRequestDetailsContext();
  const { price, chargeNow, informed } = usePaymentStepContext();

  const admin = useSelector((store) => store?.admin?.admin);
  let timeVisiting = `${time?.startTime?.label} - ${time?.endTime?.label}`;

  const processRequestTime = () => {
    const start = time?.startTime?.value;
    const end = time?.endTime?.value;

    const startTime = moment(
      `${moment(day).format("YYYY-MM-DD")}-${start}-00`,
      "YYYY-MM-DD-HH:mm-ss",
    );
    let endTime = moment(
      `${moment(day).format("YYYY-MM-DD")}-${end}-00`,
      "YYYY-MM-DD-HH:mm-ss",
    );

    if (endTime.isBefore(startTime)) {
      endTime = endTime.add(1, "days");
    }
    return {
      application_can_start_at: moment(startTime.valueOf()).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      ),
      application_time: moment(endTime.valueOf()).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      ),
    };
  };

  const onCompleteRequestPressed = async () => {
    try {
      const resp = await createAdminRequest({
        idempotency_key: uuidv1(),
        single_service_requests: [
          {
            service_type: type.value.replace(/-/g, "_"),
            symptoms: [],
            patient: {
              uuid: patient?.uuid,
              first_name: patient.firstName,
              last_name: patient.lastName,
              phone_number: convertPhoneValue(patient?.phone),
              email: patient.email,
              date_of_birth: changeDateWithFormat(patient?.birthday, false),
              sex: patient?.sex,
              is_member: !!patient?.isMember,
              home_address: {
                apartment: patient?.apartment || undefined,
                zip_code: patient?.zipcode,
                address_line:
                  patient?.home_address?.address_line ||
                  patient?.home_address?.address,
                address:
                  patient?.home_address?.street ||
                  patient?.home_address?.address,
                city: patient?.home_address?.city,
                state: patient?.home_address?.state,
              },
            },
            comment: "",
          },
        ],
        location: {
          apartment: apartment || undefined,
          address: address.street,
          zip_code: zipcode,
          address_line: address.address_line || address.address,
          city: address.city,
          state: address.state,
          comment: note,
        },
        ...processRequestTime(),
        service_type: type.value.replace(/-/g, "_"),
        urgency_type: "later",
        price: price,
        is_charge_now: chargeNow,
        notifier: informed?.value || null,
        doctor: doctor?.uuid,
      });
      if (resp?.uuid) {
        history.push({
          pathname: `${routesCommon.admin}${routersAdmin.calendar}`,
          state: { update: true },
        });
        onClose();
      }
    } catch (e) {}
  };

  return (
    <div className="summary-step">
      <div className="summary-step_doctor">
        <Typography
          color={colors.grey}
          text="Assigned to:"
          variant="p"
          className="summary-step_doctor_title"
        />
        <Typography
          capitalize
          color={colors.black}
          text={`${doctor.firstName} ${doctor.lastName}, M.D.`}
          variant="title"
        />
        <Typography color={colors.grey} text={doctor.location} variant="p" />
      </div>
      <Card
        requester={admin}
        applicationDate={dateToDisplay(day)}
        timeVisiting={timeVisiting}
      />
      <div className="summary-step_patient">
        <Typography text="Patient" variant="p" color={colors.grey} />
        <PatientCard
          classNames="summary-step_patient_card"
          user={patient}
          hideArrow={true}
          symptoms={[]}
          note={note}
        />
      </div>
      <Map
        data={{
          location: {
            address_line: address?.address_line || address?.address,
          },
        }}
        isOutWorkingZone={isOutWorkingZone}
      />
      <Price
        price={price}
        totalPrice={price}
        patientsAmount={1}
        requestType={type?.label}
        className="container-info-rewiew_container_price"
      />
      {chargeNow && (
        <div className="summary-step_charge-type">
          <Typography text="Charge now" variant="p" color={colors.grey} />
        </div>
      )}
      <CreateRequestFooter onNext={onCompleteRequestPressed} />
    </div>
  );
};
