import React from "react";
import "./style.scss";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";

const options = [
  "Heart attacks",
  "Strokes",
  "Conditions requiring surgery",
  "Severe pains",
  "Vaginal bleeding",
  "Fractures",
];

export const SymptomsNotTreat = () => {
  return (
    <div className="container-symptoms-not-treat">
      <ol>
        {options.map((i, index) => (
          <li key={index}>
            <Typography variant="p" text={i} />
          </li>
        ))}
      </ol>
      <div className="container-symptoms-not-treat_note">
        <Typography
          variant="p"
          text="Of course, in case of a Emergency,"
          color={colors.darkMuted}
        />
        <Typography variant="p" text=" call 9-1-1" color={colors.orangeMuted} />
      </div>
    </div>
  );
};
