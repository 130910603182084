import React, { useEffect, useState } from "react";
import cn from "classnames";
import { sidebarButtonsText } from "../../constants/Doctors";
import { ArrowLeft, Cross } from "../Common/Icons";
import { TitleSideBar } from "../SideBarRequest/ComponentsRequest/TitleSideBar";
import { showChats } from "../../utils/common";
import { BtnChat } from "../Chats/btn";
import { dateForMultiple } from "../../helpers/helpers";
import { Typography } from "../Common/Typography/Typography";
import { colors } from "../../assets/colors/colors";
import { DOCTOR_STEPS, SIDEBAR_TITLES, ADMIN_STEPS } from "./constants";
import { ButtonV2 } from "../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../Common/ButtonV2/constants";
import { Create } from "@material-ui/icons";
import { BTN_LABELS, DECLINED, visitNoteStatuses } from "../../constants";
import { USER_ROLES } from "store/currentUser/constants";

const showShadowHeader = "rgb(0 0 0 / 8%) 0px 2px 4px";
const showShadowFooter = "rgb(0 0 0 / 8%) 0px -2px 4px";
const removeShadow = "none";

export const SidebarWrapper = ({
  activePage,
  onClose,
  handlerBack,
  children,
  data,
  actionPrimary,
  open,
  hidePrimary,
  simpleUpdate,
  isInMultiple,
  isMultiple,
  multipleRequestList,
  disabledPrimary,
  userRole,
  hideSecondary,
  actionSecondary,
  actionAdditional,
  hideAdditional,
  increaseHeader,
  isFromEmr,
}) => {
  const content = document.querySelector(
    ".container-sidebar--open .container-sidebar__content",
  );
  const header = document.querySelector(
    ".container-sidebar--open .container-sidebar__header",
  );
  const footer = document.querySelector(
    ".container-sidebar--open .container-sidebar__footer",
  );
  const applicationPeriod = dateForMultiple(
    data?.application_can_start_at,
    data?.application_time,
  );
  const [hideFooter, setHideFooter] = useState(false);
  const patientsAmount = data?.patients_number;

  useEffect(() => {
    if (content) {
      setHideFooter(hidePrimary && hideSecondary && hideAdditional);
      if (open) {
        content.addEventListener("scroll", handleScroll);
      } else {
        content.removeEventListener("scroll", handleScroll);
      }
    }
  }, [open, content]);

  useEffect(() => {
    if (content) {
      if (isMultiple && !isInMultiple) {
        header.style.height = "179px";
        content.style.height = `calc(100vh - 179px)`;
        setHideFooter(isMultiple && !isInMultiple);
      } else {
        const hideFooterButtons =
          hidePrimary && hideSecondary && hideAdditional;
        setHideFooter(hideFooterButtons);
        header.style.height = "122px";
        if (hideFooterButtons) {
          content.style.height = `calc(100vh - 122px)`;
        } else {
          content.style.height = `calc(100vh - 218px)`;
        }
      }
      if (increaseHeader) {
        header.style.height = "162px";
        content.style.height = `calc(100vh - 258px)`;
      }
      const container = document.querySelector(".container-sidebar");
      if (container && !isMultiple) {
        container.style.overflowY = "initial";
      }
    }
  }, [
    isMultiple,
    isInMultiple,
    hidePrimary,
    hideSecondary,
    increaseHeader,
    content,
  ]);

  const handleScroll = (e) => {
    if (e.target.scrollTop >= 20) {
      header.style.boxShadow = showShadowHeader;
    } else {
      header.style.boxShadow = removeShadow;
    }
    if (footer) {
      if (
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop >=
        20
      ) {
        footer.style.boxShadow = showShadowFooter;
      } else {
        footer.style.boxShadow = removeShadow;
      }
    }
  };

  const handlerClose = () => {
    if (isInMultiple) {
      simpleUpdate("isMultiple", false);
      simpleUpdate("isInMultiple", false);
    }
    onClose();
  };

  const canCancelActivate =
    visitNoteStatuses[data?.status?.toLowerCase()]?.canCancelActivate &&
    activePage !== ADMIN_STEPS.SHIFT_PAGE;
  return (
    <>
      <div className="container-sidebar__header">
        <div
          className={cn("container-sidebar__controls")}
          style={{ justifyContent: handlerBack ? "space-between" : "flex-end" }}
        >
          {handlerBack && (
            <ArrowLeft
              className={"container-sidebar__arrow"}
              onClick={handlerBack}
            />
          )}
          <Cross
            className={"container-sidebar__cross"}
            onClick={handlerClose}
          />
        </div>
        {(!isMultiple || (isMultiple && isInMultiple)) && (
          <TitleSideBar
            text={`${SIDEBAR_TITLES[userRole][activePage]?.title}
              ${userRole === USER_ROLES.DOCTOR && activePage === DOCTOR_STEPS.PATIENTS_LIST ? ` (${patientsAmount})` : ""}
              ${userRole === USER_ROLES.ADMIN && activePage === ADMIN_STEPS.PATIENTS_LIST ? ` (${patientsAmount})` : ""}`}
            data={data}
            activePage={activePage}
            isAdmin={userRole === USER_ROLES.ADMIN}
          />
        )}
        {isMultiple && !isInMultiple && (
          <>
            <TitleSideBar text={applicationPeriod} />
            <Typography
              capitalize
              variant="title"
              color={colors.greyShuttle}
              text={`${multipleRequestList.length} requests`}
            />
          </>
        )}
      </div>
      <div className="container-sidebar__content">{children}</div>
      {!hideFooter && !isFromEmr && (
        <div className="container-sidebar__footer">
          {!hideAdditional && (
            <ButtonV2
              color={COLORS_BTN.SECONDARY}
              variant={VARIATIONS_BTN.CONTAINED}
              startIcon={<Create />}
              onClick={actionAdditional}
              className="container-sidebar__button--additional"
            />
          )}
          <div className="container-sidebar__main-buttons">
            {!hideSecondary && (
              <>
                {userRole === USER_ROLES.DOCTOR ? (
                  <BtnChat
                    request={data}
                    chatIsAvailable={showChats(data)}
                    onClick={actionSecondary}
                  />
                ) : (
                  canCancelActivate && (
                    <ButtonV2
                      color={COLORS_BTN.SECONDARY}
                      variant={VARIATIONS_BTN.CONTAINED}
                      onClick={actionSecondary}
                      className="container-sidebar__button--secondary"
                    >
                      {data?.status?.toLowerCase() === DECLINED
                        ? BTN_LABELS.REACTIVATE
                        : BTN_LABELS.CANCEL_REQUEST}
                    </ButtonV2>
                  )
                )}
              </>
            )}
            {!hidePrimary && (
              <ButtonV2
                disabled={disabledPrimary}
                className={cn("side-bar__btn-item")}
                onClick={actionPrimary}
                color={COLORS_BTN.PRIMARY}
                variant={VARIATIONS_BTN.CONTAINED}
              >
                {userRole === USER_ROLES.DOCTOR
                  ? sidebarButtonsText[activePage]
                  : "Reassign"}
              </ButtonV2>
            )}
          </div>
        </div>
      )}
    </>
  );
};
