import * as components from "./components";

import { GroupBlockReviewOfSystem } from "./components/GroupBlockReviewOfSystems";

export const template = {
  name: "Foot injury",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Foot injury",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who reports injuring",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "right" },
              { checked: false, data: "left" },
              { checked: false, data: "both" },
            ],
          },
          {
            type: "text",
            data: "foot when",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: ". Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "other injury" },
              { checked: false, data: "numbness" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "REVIEW OF SYSTEMS:",
        items: [
          GroupBlockReviewOfSystem({ title: "General:", radio: "fever" }),
          GroupBlockReviewOfSystem({ title: "HENT:", radio: "congestion" }),
          GroupBlockReviewOfSystem({ title: "Respiratory:", radio: "cough" }),
          GroupBlockReviewOfSystem({
            title: "Cardiovascular:",
            radio: "chest pain",
          }),
          GroupBlockReviewOfSystem({ title: "GI:", radio: "abdominal pain" }),
          GroupBlockReviewOfSystem({
            title: "GU:",
            radio: "urinary complaints",
          }),
          GroupBlockReviewOfSystem({
            title: "Musculoskeletal:",
            radio: "other aches or pains",
          }),
          GroupBlockReviewOfSystem({
            title: "Endocrine:",
            radio: "generalized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Neurological:",
            radio: "localized weakness",
          }),
          GroupBlockReviewOfSystem({
            title: "Psychiatric:",
            radio: "emotional stress",
          }),
        ],
      },
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert, cooperative, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis", "rash"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["scleral icterus"], true),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neck:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "stiffness",
                "lymphadenopathy",
                "thyromegaly",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "LOWER EXTREMITY:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mild swelling and tenderness with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "mild" },
                ],
              },
              {
                type: "text",
                data: "ecchymosis of",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "location",
                data: "",
              },
              {
                type: "text",
                data: ";",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["crepitus", "deformity"]),
              {
                type: "text",
                data: "Tendon function intact.",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "distal neurovascular deficit",
              ]),
              {
                type: "text",
                data: "Remainder of foot, digits and ankle:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["injury"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TestPerformed({
        items: [
          {
            type: "text",
            data: "X-ray:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "fracture" },
              { checked: false, data: "dislocation" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      }),
      components.TreatmentPerformed({
        items: [
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: true, data: "", isHide: true }],
              hiddenBlock: [
                {
                  type: "text",
                  data: "Toradol 60mg IM",
                  editable: true,
                  source: null,
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: true, data: "", isHide: true }],
              hiddenBlock: [
                {
                  type: "text",
                  data: "Patient treated with ace",
                  editable: true,
                  source: null,
                },
                {
                  type: "radio-block",
                  data: [
                    { checked: false, data: "bandage" },
                    { checked: false, data: "walking boot" },
                  ],
                },
                {
                  type: "text",
                  data: ". A pair of crutches provided and patient was instructed in crutch walking.",
                  editable: true,
                  source: null,
                },
              ],
            },
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "Podiatrist" },
                  { checked: false, data: "Orthopedic" },
                ],
              },
              {
                type: "text",
                data: "surgeon.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
