import React from "react";
import { Typography } from "../../../Common/Typography/Typography";
import { colors } from "../../../../assets/colors/colors";
import { buttonsInnerText, colorStatus } from "../../../../constants";
import { ButtonV2 } from "../../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../../Common/ButtonV2/constants";

export const ServicesGroups = ({ patientServices, payAdditionalServices }) => {
  let waitingServices = [];
  let servicesWithAnswer = [];
  let total = 0;
  let subTotal = 0;
  if (!!patientServices) {
    patientServices.forEach((i) => {
      if (i.payment_status === "paid") {
        total += Number(i.price);
        servicesWithAnswer.push(i);
      } else if (i.payment_status === "declined") {
        servicesWithAnswer.push(i);
      } else {
        subTotal += Number(i.price);
        waitingServices.push(i);
      }
    });
  }

  const Service = ({ i }) => (
    <div className="component-card-request-details_services_item">
      <div className="name">{i.name}</div>
      <div className="container-info">
        <Typography
          color={colors.navy}
          variant="p"
          text={
            i.payment_status === "declined" ? "" : `$${Math.floor(i.price)}`
          }
          className="container-info_cost"
        />
        <Typography
          color={colorStatus[i.payment_status].toLowerCase()}
          variant="p"
          capitalize
          text={i.payment_status}
          className="container-info_status"
        />
      </div>
    </div>
  );

  return !!patientServices ? (
    <div className="component-card-request-details_services">
      {servicesWithAnswer.map((i, index) => (
        <Service key={index} i={i} />
      ))}
      {!!servicesWithAnswer.length && (
        <div className="component-card-request-details_services_price-services">
          <Typography variant="title" text="Total:" />
          <Typography color={colors.navy} variant="title" text={"$" + total} />
        </div>
      )}
      {!!servicesWithAnswer.length && !!waitingServices.length && (
        <div className="component-card-request-details_services_separator" />
      )}
      {waitingServices.map((i, index) => (
        <Service key={index} i={i} />
      ))}
      {!!waitingServices.length && (
        <>
          <div className="component-card-request-details_services_price-services">
            <Typography variant="title" text="Sub-total:" />
            <Typography
              color={colors.navy}
              variant="title"
              text={"$" + subTotal}
            />
          </div>
          {payAdditionalServices && (
            <div className="component-card-request-details_services_button">
              <ButtonV2
                onClick={payAdditionalServices}
                variant={VARIATIONS_BTN.CONTAINED}
                color={COLORS_BTN.PRIMARY}
              >
                {buttonsInnerText.recommended_services}
              </ButtonV2>
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};
