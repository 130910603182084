import React from "react";
import { Route } from "react-router-dom";
import { routesCommon } from "../../constants";
import { routersDoctor } from "../../constants/Doctors";
import TablePageEMR from "../AdminEmr/TablePageEMR";
import { EMRDetailsRequest } from "../../containers/DoctorFlow/EMRDetailsRequest";
import { routersAdmin } from "../../constants/Admin";

export const ContainerEmr = (props) => {
  let baseUrl = routesCommon.doctor;
  let routeTable = `${baseUrl}${routersDoctor.emr}`;
  let routeEmr = `${baseUrl}${routersDoctor.request}`;
  if (props.role === "admin") {
    baseUrl = routesCommon.admin;
    routeTable = `${baseUrl}${routersAdmin.emr}`;
    routeEmr = `${baseUrl}${routersAdmin.request}`;
  }
  return (
    <>
      <Route
        path={routeTable}
        render={() => <TablePageEMR urlNavigationToEMR={routeEmr} {...props} />}
      />
      <Route
        path={[routeEmr]}
        render={({ location }) => {
          return (
            <EMRDetailsRequest
              {...props}
              basePart={routeEmr}
              baseUrl={baseUrl}
              id={location.pathname.split("/").reverse()[0]}
            />
          );
        }}
      />
    </>
  );
};
