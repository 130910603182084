import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import cn from "classnames";
import { AdditionalServices } from "../../../compoenentsAdditional/AdditionalServices";

export const ViewServices = ({ className, selectedServices, isAdmin }) => {
  return (
    <div className={cn("view-services", className)}>
      {isAdmin && (
        <Typography text="Additional Services Applied:" color="grey" />
      )}
      <AdditionalServices noLabel services={selectedServices} />
    </div>
  );
};
