import React from "react";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { backToShift } from "../../../constants/Admin";
import { BTN_LABELS, colorRequestStatus } from "../../../constants";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";

export const ListAppointmetns = ({ data, viewDetailsAppointmentOrShift }) => {
  return (
    <div className="list-appointments">
      {data.map((i, index) => (
        <div className="list-appointments_item" key={index}>
          <Typography
            variant="title"
            text={i.patient_name}
            color={colors.black}
          />
          <div className="list-appointments_item_request">
            <div>
              <Typography
                variant="p"
                text="Request status: "
                color={colors.greyMiddle}
              />
              <Typography
                variant="p"
                text={i.service_request_status.toLowerCase()}
                color={
                  colorRequestStatus[i.service_request_status.toLowerCase()]
                }
              />
            </div>
            <ButtonV2
              uppercase
              variant={VARIATIONS_BTN.TEXT}
              color={COLORS_BTN.PRIMARY}
              onClick={() => {
                viewDetailsAppointmentOrShift(i, backToShift);
              }}
            >
              {BTN_LABELS.VIEW_MORE}
            </ButtonV2>
          </div>
        </div>
      ))}
    </div>
  );
};
