import React from "react";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { durationTimeLaterRequest } from "../../../utils/date";
import { colorRequestStatus } from "../../../constants";
import { dateToDisplay } from "../../../helpers/helpers";
import { TimeOfRequest } from "../TimeOfRequest/TimeOfRequest";
import { TYPES_CHIPS } from "../Chip/constants";
import { Chip } from "../Chip";
import "./style.scss";
import { Error } from "@material-ui/icons";
import { Tooltip } from "../Tooltip";
import { useSelector } from "react-redux";
import { serviceRequestTypesSelector } from "../../../store/serviceRequestTypes/selectors";

export const RequestInfo = ({ data, doctor, status, hideType }) => {
  const requestTypes = useSelector(serviceRequestTypesSelector);
  let requestStatus = data.status;
  let EMRstatus = "";
  let spentTime =
    data &&
    durationTimeLaterRequest(
      data.application_can_start_at,
      data.application_time,
    );
  let typeLabel =
    data?.service_type?.viewLabel ||
    requestTypes.find(
      (type) => type.name.replace(/-/g, "_") === data.service_type?.name,
    )?.viewLabel;
  const date = dateToDisplay(data.application_can_start_at);
  return (
    <div className="request-info-event">
      <Chip
        type={TYPES_CHIPS.NOW_LATER_REQUEST}
        label={`${data.urgency_type && data.urgency_type[0].toUpperCase() + data.urgency_type.slice(1)} request`}
      />
      {!hideType && (
        <div className="request-info-event__type">
          <Typography capitalize color="grey" text="Type: " />
          <Typography capitalize color="black" text={typeLabel} />
        </div>
      )}
      {spentTime && (
        <TimeOfRequest spentTime={spentTime} applicationDate={date} />
      )}
      {!doctor && (
        <>
          <div>
            <Typography
              capitalize
              variant="h4"
              text="Request status: "
              color={colors.grey}
            />
            <Typography
              capitalize
              variant="h4"
              text={requestStatus}
              color={colorRequestStatus[requestStatus]}
            />
          </div>
          <div className="request-info-event_status">
            <Typography text="Note status: " color="grey" />
            <Typography text={status?.statusView} color={status?.color} />
            {status?.reason && (
              <Tooltip placement="top-end" title={status.reason}>
                <Error />
              </Tooltip>
            )}
          </div>
        </>
      )}
      {EMRstatus && (
        <div>
          <Typography
            capitalize
            variant="h4"
            text="EMR status: "
            color={colors.grey}
          />
          <Typography
            capitalize
            variant="h4"
            text={EMRstatus}
            color={colors.navy}
          />
        </div>
      )}
    </div>
  );
};
