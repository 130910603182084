import React from "react";
import "./style.scss";
import { RoundProgressBar } from "components/Common/RoundProgressBar/RoundProgressBar";
import { Typography } from "components/Common/Typography/Typography";
import { colors } from "assets/colors/colors";
import cn from "classnames";

export const ProgressStepper = ({
  className,
  activeStep,
  stepCount,
  title,
  subtitle,
}) => {
  return (
    <div className={cn("progress-stepper", className)}>
      <RoundProgressBar
        value={activeStep}
        maxValue={stepCount}
        duration={0.2}
        toFixedValue={0}
      />
      <div className="progress-stepper_text-data">
        <Typography
          capitalize
          color={colors.blackMuted}
          text={title}
          variant="h3"
        />
        {subtitle && (
          <Typography
            capitalize
            color={colors.grey}
            text={subtitle}
            variant="title"
          />
        )}
      </div>
    </div>
  );
};
