import * as components from "./components";

export const template = {
  name: "Abscess (I and D)",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Abscess (I and D)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who complains of a",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "mild" },
              { checked: false, data: "moderate" },
              { checked: false, data: "severe" },
            ],
          },
          {
            type: "text",
            data: "painful mass of gradually increasing size of the",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "Location (arm / leg)",
            data: "",
          },
          {
            type: "text",
            data: "Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: true, data: "foreign body" },
              { checked: false, data: "fever" },
              { checked: false, data: "chills" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      {
        type: "label",
        data: "TETANUS STATUS:",
        items: [
          {
            type: "radio-single",
            data: [{ checked: false, data: "up to date" }],
          },
        ],
      },
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "text",
                data: "Tender, fluctuant, erythematous mass of the",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "location",
                data: "",
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "scalp swelling" }],
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes([
            {
              type: "text",
              data: ",",
              editable: true,
              source: null,
            },
            {
              type: "radio-single",
              data: [{ checked: false, data: "scleral icterus" }],
            },
          ]),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck([
            {
              type: "text",
              data: ",",
              editable: true,
              source: null,
            },
            {
              type: "radio-single",
              data: [{ checked: false, data: "thyromegaly" }],
            },
          ]),
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "guarding" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rebound" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "palpable masses" }],
              },
              {
                type: "text",
                data: ", ",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "CVA tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Extremities([
            {
              type: "text",
              data: ".",
              editable: true,
              source: null,
            },
          ]),
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      {
        type: "label",
        data: "PROCEDURE PERFORMED:",
        items: [
          {
            type: "checkbox",
            data: [{ checked: false, data: "Consent was obtained" }],
          },
          {
            type: "text",
            data: ". With appropriate anesthesia, the abscess was incised and drained of purulent material under aseptic conditions. Sterile dressing applied.",
            editable: true,
            source: null,
          },
        ],
      },
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged by",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "patient" },
                  { checked: false, data: "caretaker" },
                  { checked: false, data: "me" },
                ],
              },
              {
                type: "text",
                data: "with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "Dermotologyst" },
                ],
              },
              {
                type: "text",
                data: "in",
                editable: true,
                source: null,
              },
              {
                type: "input-static-text",
                label: "",
                data: [{ data: "", mask: "999", min: 0, max: 365 }],
                deviders: [],
              },
              {
                type: "text",
                data: "days for further evaluation.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
