import React, { useMemo, useState } from "react";
import "./style.scss";
import { Typography } from "../../../Common/Typography/Typography";
import { Delete, Edit, Plus } from "../../../Common/Icons";
import { colors } from "../../../../assets/colors/colors";
import { PopupContainer } from "../../../Common/Popup/PopupContainer";
import { buttonsInnerText } from "../../../../constants";
import { AddPCPForm } from "./AddPCPForm";
import {
  convertPhoneValue,
  filterObj,
  lengthObj,
  setFormatBE,
} from "../../../../utils/common";
import { emailFax, placeholdersPCPDoctor } from "../../../../constants/Doctors";
import { ButtonMenu } from "../../../Common/ButtonMenu/ButtonMenu";
import {
  validationNameFields,
  validationEmail,
  validationIsEmpty,
  validationLength,
  validationPhone,
  validationZipCodeRegex,
} from "../../../../utils/validation";
import { getSingleFromGroupRequest } from "../../../../store/request/utils";

const initialState = {
  firstName: "",
  lastName: "",
  providerType: null,
  // degree: null,
  email: "",
  phoneNumber: "",
  fax: "",
  zipCode: "",
  address: "",
  suite: "",
  contactType: emailFax[0],
};

export const ContainerPCPSpec = (props) => {
  const [popup, setPopup] = useState(false);
  const [data, updateData] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const openAddPCP = () => setPopup(true);
  const closeAddPCP = () => {
    setPopup(false);
    updateData({ ...initialState });
    setErrors({});
  };

  const editPCPDoctor = (i) => {
    updateData({
      ...i,
      phoneNumber: i.phoneNumber && convertPhoneValue(i.phoneNumber, true),
      // degree: i.degree ? { value: i.degree, label: i.degree } : null,
      contactType: emailFax.find((item) => item.value === i.contactType),
      providerType: i.providerType
        ? { value: i.providerType, label: i.providerType }
        : null,
      address: { address: i.address, latLng: null },
      fax: i.faxNumber && convertPhoneValue(i.faxNumber, true),
    });
    openAddPCP();
  };

  const updateState = (field, value) => {
    const errorInitial = { ...errors };

    if (field === "contactType") {
      delete errorInitial["fax"];
      delete errorInitial["email"];
    } else {
      delete errorInitial[field];
    }

    let newState = { ...data, [field]: value };
    setErrors({ ...errorInitial });
    updateData(newState);
  };

  const targetRequest = useMemo(() => {
    return getSingleFromGroupRequest({
      request: props.dataRequest,
      uuid: props.id,
    });
  }, [props.dataRequest?.timestamp]);

  const deleteDoctor = () => {
    props.deletePCPDoctor(data.uuid, targetRequest.patient_uuid);
    closeConfirmation();
  };
  const closeConfirmation = () => {
    setConfirmation(false);
    updateData({ ...data, uuid: null });
  };
  const openConfirmation = (uuid) => {
    setConfirmation(true);
    updateData({ ...data, uuid: uuid });
  };

  const validation = () => {
    const checkFieldObj = {
      firstName: data.firstName,
      lastName: data.lastName,
      providerType: data.providerType,
      [data.contactType.value]: data[data.contactType.value],
    };

    let error = validationIsEmpty(checkFieldObj);
    error.providerType =
      error.providerType ||
      (data.providerType &&
        validationLength(
          data.providerType.value,
          placeholdersPCPDoctor.providerType,
          2,
        ));
    // error.degree =
    //   data.degree &&
    //   validationLength(data.degree.value, placeholdersPCPDoctor.degree, 2);
    error.firstName = error.firstName || validationNameFields(data.firstName);
    error.lastName = error.lastName || validationNameFields(data.lastName);
    error.zipCode = data.zipCode ? validationZipCodeRegex(data.zipCode) : "";
    error.phoneNumber = data.phoneNumber
      ? validationPhone(data.phoneNumber)
      : error.phoneNumber;
    error.fax = data.fax
      ? validationPhone(
          data.fax,
          `Incorrect ${placeholdersPCPDoctor.faxNumber} Number`,
        )
      : error.fax;
    error.email = data.email ? validationEmail(data.email) : error.email;

    return filterObj(error);
  };

  const requestPCPDoctor = () => {
    const errorValue = validation();

    setErrors({ ...errorValue });

    if (!lengthObj(errorValue)) {
      const { fax, ...dataValues } = data;
      props
        .createUpdatePCPDoctor(
          setFormatBE({
            ...dataValues,
            firstName: dataValues.firstName?.trim(),
            lastName: dataValues.lastName?.trim(),
            phoneNumber: dataValues.phoneNumber
              ? convertPhoneValue(dataValues.phoneNumber, false)
              : "",
            // degree: data.degree?.value,
            patient: targetRequest.patient_uuid,
            address: data?.address?.address || null,
            contactType: data.contactType.value,
            providerType: data.providerType.value,
            faxNumber: fax ? convertPhoneValue(fax, false) : "",
          }),
          targetRequest.patient_uuid,
          data.uuid,
        )
        .then((resp) => resp.uuid && closeAddPCP());
    }
  };

  return (
    <>
      <div className="container-pcp-spec">
        <div className="container-pcp-spec_header">
          <Typography variant="h2_5" text="PCP" />
          <button className="no-default-style-button" onClick={openAddPCP}>
            <Plus />
            <Typography variant="p" text="Add PCP" color={colors.navy} />
          </button>
        </div>
        <div className="container-pcp-spec_list">
          {props.pcpDoctors.map((i, index) => {
            let address = i.address || "";
            if (i.suite) {
              address = address
                ? `${i.address} </br> Suite ${i.suite}`
                : `Suite ${i.suite}`;
            }
            return (
              <div className="container-pcp-spec_list_item" key={index}>
                <ItemPCPCard
                  label={`${i.providerType}:`}
                  value={`${i.firstName} ${i.lastName}`}
                />
                <ItemPCPCard
                  color={colors.navy}
                  label="PCP Address:"
                  dangerouslySetInnerHTML={address}
                />
                <ItemPCPCard
                  label={`${placeholdersPCPDoctor.phoneNumber}:`}
                  value={
                    i.phoneNumber && convertPhoneValue(i.phoneNumber, true)
                  }
                />
                <ItemPCPCard
                  label={`${placeholdersPCPDoctor.faxNumber}:`}
                  value={i.faxNumber && convertPhoneValue(i.faxNumber, true)}
                />
                <ItemPCPCard
                  color={colors.navy}
                  label={`${placeholdersPCPDoctor.email}:`}
                  value={i.email}
                />
                <ButtonMenu>
                  <li onClick={() => editPCPDoctor(i)}>
                    <Edit /> <Typography variant="p" text="Edit" />
                  </li>
                  <li
                    onClick={() => {
                      openConfirmation(i.uuid);
                    }}
                  >
                    <Delete /> <Typography variant="p" text="Delete" />
                  </li>
                </ButtonMenu>
              </div>
            );
          })}
        </div>
      </div>
      <PopupContainer
        open={popup}
        uppercaseButton
        className="pcp-container-content"
        close={closeAddPCP}
        title="Primary Care Provider"
        disabledButtonPrimary={false}
        actionDefaultButton={closeAddPCP}
        actionPrimaryButton={requestPCPDoctor}
      >
        <AddPCPForm
          state={data}
          errors={errors}
          // degree={props.degree}
          updateState={updateState}
          typesDoctors={props.typesDoctors}
        />
      </PopupContainer>
      <PopupContainer
        close={closeConfirmation}
        open={confirmation}
        textPrimary={buttonsInnerText.confirm}
        actionDefaultButton={closeConfirmation}
        actionPrimaryButton={deleteDoctor}
        uppercaseButton
        className="confirmation-delete-doctor"
        title="Are you sure you want to delete doctor?"
      />
    </>
  );
};

const ItemPCPCard = ({
  label,
  value,
  renderValue,
  color,
  dangerouslySetInnerHTML,
}) => {
  if (!value && !dangerouslySetInnerHTML) return "";
  return (
    <div className="container-pcp-spec_list_item_block">
      <Typography color={colors.greyMiddle} variant="p" text={label} />
      {renderValue ? (
        renderValue(value)
      ) : (
        <Typography
          color={color || colors.black}
          variant="title"
          text={value}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      )}
    </div>
  );
};
