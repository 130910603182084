import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Input } from "../../Common/Input/Input";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import React, { useEffect, useState } from "react";
import { buttonsText, regexsWithMessage } from "../../../constants";
import { fetchWrapper } from "../../../helpers/helpers";

export const PopupZipcode = ({ open, close, emailUser, zip }) => {
  const [email, setEmail] = useState(emailUser);

  useEffect(() => setEmail(emailUser), [emailUser]);

  const updateState = (field, value) => setEmail(value);
  const notifyAction = () => {
    fetchWrapper({
      url: "patients/zip-code-not-found-email/",
      method: "POST",
      body: { email: email, zip: zip },
    }).then(() => {
      setEmail(emailUser);
      close();
    });
  };

  const disabledButtonPrimary = !regexsWithMessage.email.regex.test(email);

  return (
    <PopupContainer
      open={open}
      close={close}
      title="Sorry, The Doctors Do Not Service"
      extraTitle="This Zip Code Quite Yet"
      hideCancel
      className="zip-code-popup"
      actionPrimaryButton={notifyAction}
      textPrimary={buttonsText.notify}
      disabledButtonPrimary={disabledButtonPrimary}
    >
      <div className="patient-info-container_verification-6-month">
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-6-month_text"
          text="Currently, the Doctors cover most of Palm Beach County, FL."
        />
        <Typography
          color={colors.grey}
          variant="p"
          className="patient-info-container_verification-6-month_text"
          text="If you would like for us to send an update when the Doctors do cover your Zip Code, please send us your email address below."
        />
        <Typography
          color={colors.grey}
          variant="p"
          className=""
          text="Thank you!"
        />
        <Input
          placeholder="Email"
          value={email}
          className="patient-info-container_verification-6-month_email"
          field="email"
          onChange={updateState}
        />
      </div>
    </PopupContainer>
  );
};
