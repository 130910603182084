import React, { useState } from "react";
import { requestDetailsControls } from "../../../../constants/Doctors";
import ButtonGroup from "../../../Common/ButtonGroup/ButtonGroup";
import { ContainerPMH } from "../ContainerPMH";
import { ContainerPCPSpec } from "../ContainerPCPSpec";
import "./style.scss";

export const PatientData = (props) => {
  const [activeControls, setActiveControls] = useState(
    requestDetailsControls[0],
  );
  return (
    <div className="patient-data-container">
      <div className="patient-data-container_controls">
        <ButtonGroup
          page={activeControls}
          buttonsTab={requestDetailsControls}
          changePage={setActiveControls}
        />
      </div>
      <div className="patient-data-container_data-patient-request">
        {activeControls === requestDetailsControls[0] && (
          <ContainerPMH {...props} />
        )}
        {activeControls === requestDetailsControls[1] && (
          <ContainerPCPSpec {...props} />
        )}
      </div>
    </div>
  );
};
