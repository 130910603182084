import { combineReducers } from "redux";
import request from "./createRequest/reducer.js";
import { serviceRequestsReducer as serviceRequests } from "./serviceRequests/slice";
import admin from "./admin/reducer";
import doctor from "./doctor/reducer";
import { requestDetailsReducer } from "./request/slice";
import { calendarEventReducer as calendarEvents } from "./calendarEvent/slice";
import { shiftsReducer as shifts } from "./shifts/slice";
import { doctorsReducer as doctors } from "./doctors/slice";
import { createRequestAdminDoctorsReducer as createRequestAdminDoctors } from "./createRequestAdminDoctors/slice";
import { appReducer as app } from "./app/slice";
import { requestPatientsReducer as requestPatients } from "./requestPatients/slice";
import { patientPaymentCardReducer as patientPaymentCard } from "./patientPaymentCard/slice";
import { serviceRequestTypesReducer as serviceRequestTypes } from "./serviceRequestTypes/slice";
import { relationReducer as relation } from "./relation/slice";
import { currentUserReducer as currentUser } from "./currentUser/slice";
import { notificationReducer as notification } from "./notification/slice";
import { messageReducer as message } from "./message/slice";
import { twilioReducer as twilio } from "./twilio/slice";

const rootReducer = combineReducers({
  request,
  admin,
  doctor,
  requestDetailsReducer,
  requestPatients,
  calendarEvents,
  shifts,
  doctors,
  app,
  createRequestAdminDoctors,
  patientPaymentCard,
  serviceRequests,
  serviceRequestTypes,
  relation,
  currentUser,
  notification,
  message,
  twilio,
});

export default rootReducer;
