import "firebase/analytics";
import ReactPixel from "react-facebook-pixel";

const trackingId = process.env.REACT_APP_FACEBOOK_PIXEL_ANALYTICS;

export class FacebookPixelAnalyticsService {
  constructor() {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(trackingId, advancedMatching, options);
    ReactPixel.pageView();
  }

  track(event, payload) {
    ReactPixel.track(event, payload);
  }
}
