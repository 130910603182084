import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "../../Common/Typography/Typography";
import { Button } from "../../Common/Button/Button";
import ButtonGroup from "../../Common/ButtonGroup/ButtonGroup";
import { ADMIN } from "../../../constants";
import { Archive } from "../Archive";

import EmploymentApplication from "../../DoctorFlow/EmploymentApplication/EmploymentApplication";
import PersonalInfoForm from "../../DoctorFlow/PersonalInfoForm";
import {
  getLocations,
  getSpeciality,
  getArchivedDocuments,
  unArchiveDocument,
} from "../../../store/actions";

import "./style.scss";
import {
  BUTTONSADDNEWDOCTOR,
  BUTTONSEDITDOCTOR,
  routersAdmin,
} from "../../../constants/Admin";
import { lengthObj } from "../../../utils/common";
import { clearDoctorData } from "../../../store/admin/actions";
import history from "../../../helpers/history";
import { routesCommon } from "../../../constants";
import { saveSignature } from "../../../store/doctor/actions";

export const AddEditDoctor = ({
  getDataDoctor,
  id,
  doctorData,
  signature,
  uploadApplications,
  updateUploadedFiles,
  updatePersonalInfo,
  updateDoctor,
}) => {
  const [state, setState] = useState({
    statePage: BUTTONSEDITDOCTOR[0],
    data: {},
  });
  const dispatch = useDispatch();

  const locations = useSelector(
    (store) => store.doctor.addEditDoctor.locations,
  );
  const speciality = useSelector(
    (store) => store.doctor.addEditDoctor.speciality,
  );
  const archivedFiles = useSelector((store) => store.admin.archivedFiles);

  const methods = useRef({
    funcSave: () => {},
    func: () => {},
    funcCancel: () => {},
  });

  useEffect(() => {
    if (getDataDoctor) {
      getDataDoctor(id, ADMIN);
      dispatch(saveSignature(null, id));
    }
    dispatch(getSpeciality());
    dispatch(getLocations());

    return () => dispatch(clearDoctorData());
  }, []);

  const changePage = (title) => {
    if (title !== state.statePage) {
      let switchTab = true;
      if (title === BUTTONSEDITDOCTOR[1] || title === BUTTONSEDITDOCTOR[2])
        switchTab = methods.current.funcSave(true);
      if (switchTab)
        setState((prev) => ({
          ...prev,
          statePage: title,
        }));
    }
  };

  const unArchiveFiles = (uuidFile, bool) =>
    dispatch(unArchiveDocument(id, uuidFile, { force: bool }));

  const saveElements = () => methods.current.func();
  const actionCancel = () => methods.current.funcCancel();
  const setPersonalInfo = (data) =>
    setState((prev) => ({ ...prev, data: data }));
  const updateStatusPersonalInfo = (status) =>
    setState((prev) => ({ data: { ...prev.data.data, status: status } }));

  const buttonsTab = [
    ...(!!lengthObj(doctorData) ? BUTTONSEDITDOCTOR : BUTTONSADDNEWDOCTOR),
  ];
  const essentialDoctorData = lengthObj(state.data) ? state.data : doctorData;

  return (
    <>
      <div className="container-add-new-doctor">
        <ButtonGroup
          page={state.statePage}
          buttonsTab={buttonsTab}
          changePage={changePage}
          className="container-add-new-doctor__btn"
        />
        <Typography
          capitalize
          text={`${id ? "Edit" : "Add A New"} Doctor`}
          variant="h3"
          className="doctor-page-personal-info_title"
        />

        {state.statePage === BUTTONSEDITDOCTOR[0] && (
          <PersonalInfoForm
            setPersonalInfo={setPersonalInfo}
            updatePersonalInfo={updatePersonalInfo}
            flag={ADMIN}
            id={id}
            updateDoctor={updateDoctor}
            doctorData={essentialDoctorData}
            locations={locations}
            speciality={speciality}
            saveElements={(func, funcCancel) => {
              methods.current.func = func;
              methods.current.funcSave = func;
              methods.current.funcCancel = funcCancel;
            }}
          />
        )}

        {state.statePage === BUTTONSEDITDOCTOR[1] && (
          <EmploymentApplication
            listOfDocumentsFor={essentialDoctorData.employeeStatus}
            flag={ADMIN}
            saveSignature={dispatch(saveSignature)}
            signature={signature}
            updateStatusPersonalInfo={updateStatusPersonalInfo}
            saveDataDoctor={methods.current.funcSave}
            doctorData={doctorData}
            uploadApplications={uploadApplications}
            updateUploadedFiles={updateUploadedFiles}
            saveElements={(func, funcCancel) => {
              methods.current.func = func;
              methods.current.funcCancel = funcCancel;
            }}
          />
        )}

        {state.statePage === BUTTONSEDITDOCTOR[2] && (
          <Archive
            doctorType={essentialDoctorData.employeeStatus}
            archived={archivedFiles}
            unArchiveFiles={unArchiveFiles}
            getDataDoctor={getDataDoctor}
            getArchivedDocuments={(id) => {
              methods.current.func = methods.current.funcSave;
              methods.current.funcCancel = () => {
                history.push(`${routesCommon.admin}${routersAdmin.table}`);
              };
              dispatch(getArchivedDocuments(id));
            }}
            id={id}
          />
        )}

        <div className="doctor-page-personal-info_title-btn">
          <Button
            text="Cancel"
            size="middle"
            variant="default"
            onClick={actionCancel}
          />
          <Button
            text="Save"
            size="middle"
            variant="primary"
            onClick={saveElements}
          />
        </div>
      </div>
    </>
  );
};
