import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";
import "./style.scss";
import CreatableSelect from "react-select/creatable";
import { Checkbox } from "../Checkbox/Checkbox";
import { Input } from "../Input/Input";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { useDebounce } from "../../../utils/customHooks";
import { fetchWrapper } from "../../../helpers/helpers";

export const DropDown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(null);

  const debounceSearchValue = useDebounce(searchValue, 1000);

  useEffect(() => {
    debounceSearchValue && props.urlLoadingOptions && getOptions();
  }, [debounceSearchValue]);

  const getOptions = () => {
    fetchWrapper({
      url: props.urlLoadingOptions,
      body: {
        search: debounceSearchValue,
      },
    }).then((resp) => {
      setLoadingOptions(resp.map(props.proccedOptions).filter((i) => i));
    });
  };

  const allProps = {
    value: props.value,
    className: classNames(
      "drop-down-container",
      props.className,
      props.classNameOpenTop,
      { "drop-down-container_isOpen": isOpen },
    ),
    onMenuOpen: () => {
      setIsOpen(true);
      if (props.urlLoadingOptions && !isOpen) getOptions();
    },
    onInputChange: (valueInput) => {
      setSearchValue(valueInput);
    },
    onMenuClose: () => {
      if (props.action && selectedValue) {
        props.action(props.field);
        setSelectedValue(null);
      }
      setSearchValue("");
      setLoadingOptions(null);
      setIsOpen(false);
    },
    classNamePrefix: "drop-down",
    onChange: (value, action) => {
      if (!!props.multi && !value) {
        value = [];
      }
      let newValue = value;
      if (props.creatable) {
        if (Array.isArray(value)) {
          newValue = value.map((i) => {
            return i.__isNew__ ? { value: i.value, label: i.value } : i;
          });
        } else
          newValue = value.__isNew__
            ? { value: value.value, label: value.value }
            : value;
      }
      props.handlerSelect(props.field, newValue);
      setSelectedValue(action.action);
    },
    // menuIsOpen: true,
    options: loadingOptions || props.options || [],
    noOptionsMessage: props.noOptionsMessage,
    isDisabled: props.disabled,
    placeholder: props.placeholder || "",
    isMulti: !!props.multi,
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.multi,
    isOptionDisabled: props.isOptionDisabled,
    menuPlacement: props.menuPlacement || "bottom",
    components: {
      DropdownIndicator: ({ isFocused }) => {
        return <div className="indicator" />;
      },
      IndicatorSeparator: () => null,
      ClearIndicator: () => null,
      Option: (props) => {
        // if (props.isMulti) {
        //   return (
        //     <components.Option {...props}>
        //       <Checkbox label={props.data.label} checked={props.isSelected} />
        //     </components.Option>
        //   );
        // } else {
        //   return <components.Option {...props} />;
        // }
        return <components.Option {...props} />;
      },
      ...props.components,
    },
  };

  if (props.urlLoadingOptions)
    allProps.filterOptions = (options, filter, current_values) => options;

  const isValue = Array.isArray(props.value)
    ? props.value.length
    : !!props.value;
  return (
    <div
      className={classNames("drop-down-wrapper", props.className, {
        "drop-down-wrapper_error": props.error,
        "drop-down-wrapper_disabled": props.disabled,
      })}
    >
      {props.label && (
        <span
          className={classNames("drop-down-wrapper_label", {
            "drop-down-wrapper_label_isValue": isValue,
            "drop-down-wrapper_label_isOpen": isOpen,
            "drop-down-wrapper_label_error": props.error,
          })}
        >
          {props.label}
        </span>
      )}
      {props.creatable ? (
        <CreatableSelect {...allProps} />
      ) : (
        <Select {...allProps} />
      )}
      {props.error && (
        <Typography
          variant="h5"
          text={props.error}
          color={colors.redError}
          className="drop-down-wrapper_error-text"
        />
      )}
    </div>
  );
};
