import React from "react";
import cn from "classnames";
import FolderInfo from "../../Common/FolderInfo";
import { Typography } from "../../Common/Typography/Typography";
import { ACCEPTED } from "../../../constants";
import { EventsDocument } from "../../Common/EventsDocument/EventsDocument";
import { PERCENT_OF_LOADING } from "../../../constants";
import { TryReload } from "../../Common/Icons/TryReload";
import { getFileName } from "../../../utils/common";

const DocumentInfo = ({
  valueLoading,
  file,
  onDelete,
  flag,
  updateDataFile,
  commentD,
  isDeclined,
  reload,
  reloadFile,
  error,
}) => {
  const name = getFileName(file.name);
  const nameFile = getFileName(file.name, 1);
  const opacityFolder = valueLoading !== PERCENT_OF_LOADING.state100;
  const acceptedStatus = file.statusFile === ACCEPTED;

  return (
    <div className="document">
      <div className="document-common">
        <div
          className={cn("document_info", {
            "opacity-complete": opacityFolder,
          })}
        >
          <FolderInfo
            link={file.file}
            name={nameFile}
            isDeclined={isDeclined}
            reload={reload}
            error={error}
            acceptedStatus={acceptedStatus}
          />
          <div className="document_info-optional">
            <Typography
              variant="span"
              text={name}
              className="document_info-name"
            />
          </div>
        </div>

        <EventsDocument
          file={file}
          updateDataFile={updateDataFile}
          valueLoading={valueLoading}
          onDelete={onDelete}
          flag={flag}
        />
      </div>
      <div className="document-optional">
        <Typography text={isDeclined} className="decline-status" variant="p" />
        {reload && (
          <div className="decline-reload">
            <Typography
              text={reload}
              className="decline-reload__info"
              variant="p"
            />
            <div className="decline-reload__container">
              <TryReload className="decline-reload__btn" onClick={reloadFile} />
              <Typography
                text={"TRY AGAIN"}
                className="decline-reload"
                variant="p"
              />
            </div>
          </div>
        )}
        <Typography
          text={commentD}
          className={cn({ "document-optional_comment": !!commentD })}
          variant="p"
        />
      </div>
    </div>
  );
};

export default DocumentInfo;
