import React from "react";
import cn from "classnames";
import "./style.scss";
import { colors } from "../../../assets/colors/colors";

const style = {
  Incomplete: {
    color: colors.peach,
    background: colors.apricot,
  },
  Complete: {
    color: colors.elfName,
    background: colors.offGreen,
  },
  Declined: {
    color: colors.orangeMuted,
    background: colors.lightRed,
  },
  Unassigned: {
    color: colors.orangeMuted,
    background: colors.lightRed,
  },
};

export const RequestStatus = ({ className, status }) => {
  return (
    <span className={cn("request-status", className)} style={style[status]}>
      {status}
    </span>
  );
};
