import { createSlice } from "@reduxjs/toolkit";
import { MODES_ADDITIONAL_LOADING } from "./constants";
import moment from "moment";
import _ from "lodash";

const initialState = {
  data: null,
  countRequestInLine: 3,
};

const calendarEventSlice = createSlice({
  name: "calendarEvent",
  initialState,
  reducers: {
    setCalendarEventsData: (state, { payload }) => {
      state.data = payload;
    },
    addMoreCalendarEvents: (state, { payload: { mode, data } }) => {
      if (mode === MODES_ADDITIONAL_LOADING.TOP)
        state.data = {
          timestamp: data.timestamp,
          data: _.uniqBy(data.data.concat(state.data?.data || []), "uuid"),
        };
      else
        state.data = {
          timestamp: data.timestamp,
          data: _.uniqBy(state.data.data.concat(data.data), "uuid"),
        };
    },
    updateStatusMessageRequest: (state, { payload, ...g }, t) => {
      const func = (i) =>
        i.uuid === payload.uuid
          ? {
              ...i,
              service_request_chat: {
                ...i.service_request_chat,
                has_unread_messages: payload.mess,
              },
            }
          : i;
      const funcAll = (i) => ({
        ...i,
        service_request_chat: {
          ...i.service_request_chat,
          has_unread_messages: false,
        },
      });
      let data = state.data?.data;
      if (payload.allRead) data = data?.map(funcAll);
      else data = data?.map(func);
      state.data = { timestamp: moment().format(), data: data };
    },
    updateRequestsStatuses: (state, { payload }) => {
      const data = state.data?.data.map((i) =>
        i.uuid === payload.uuid
          ? { ...i, service_request_status: payload.status, status: "accepted" }
          : i,
      );
      state.data = { timestamp: moment().format(), data: data };
    },
    clearCalendarEventSlice: (state) => {
      state.data = null;
    },
    updateCountRequestInList: (state, { payload }) => {
      state.countRequestInLine = payload;
    },
  },
});

export const {
  setCalendarEventsData,
  addMoreCalendarEvents,
  clearCalendarEventSlice,
  updateStatusMessageRequest,
  updateRequestsStatuses,
  updateCountRequestInList,
} = calendarEventSlice.actions;
export const calendarEventReducer = calendarEventSlice.reducer;
