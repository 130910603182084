import React, { useMemo } from "react";
import { DropDown } from "../DropDown/DropDown";
import { PLACEHOLDERS } from "../../../constants";
import "./style.scss";
import { timeOptions } from "./constants";
import { Typography } from "../Typography/Typography";
import { colors } from "assets/colors/colors";

export const TimeSlot = ({
  updateState,
  error,
  timeStart,
  timeFinish,
  menuPlacement,
}) => {
  const options = useMemo(() => timeOptions(), []);

  return (
    <div className="time-slot__container">
      <div className="dropdowns__container">
        <DropDown
          value={timeStart}
          field="startTime"
          label={PLACEHOLDERS.timeStart}
          options={options}
          handlerSelect={updateState}
          error={!!error}
          menuPlacement={menuPlacement}
        />
        <DropDown
          value={timeFinish}
          field="endTime"
          label={PLACEHOLDERS.timeFinish}
          options={options}
          handlerSelect={updateState}
          error={!!error}
          menuPlacement={menuPlacement}
        />
      </div>
      <div className="time-slot__error-container">
        <Typography variant="h5" text={error} color={colors.redError} />
      </div>
    </div>
  );
};
