import React from "react";
import { Typography } from "../Common/Typography/Typography";
import { ArrowLeft, Cross } from "../Common/Icons";
import { steps } from "../../constants";
import cn from "classnames";
import { colors } from "../../assets/colors/colors";
import { RoundProgressBar } from "../Common/RoundProgressBar/RoundProgressBar";

const stepsTitles = [
  {
    title: "Who needs the visit?",
    subtitle: "Please select patients from below and/or add new patients",
  },
  {
    title: "What are the symptoms?",
  },
  {
    title: "Please Tell Us The Address Where You Would Like Medical Care",
  },
  {
    title:
      "Would You Like a Doctor to Come NOW (in About 60 Minutes) or LATER (You Choose the Date and Time)?",
  },
  {
    title: "Review your information before requesting",
  },
  {
    title: "",
  },
  {
    title: "Please Complete Your Profile",
    subtitle: "To Make A Request Please Provide Your Information",
  },
  {
    title: "Please tell us about the patient",
  },
];

export const HeaderPanel = ({
  simpleUpdateState,
  activeStep,
  moveToTheStep,
  actionPrevStep,
  createPatientMode,
  mobileVerification,
  hideSteps,
  needRebuild,
}) => {
  const pushToPage = () => simpleUpdateState("openConfirmation", true);

  const handlePrevStep = () => {
    if (activeStep > 6 || activeStep === steps.Address) {
      moveToTheStep(steps.PickingPatients);
    } else {
      actionPrevStep();
    }
  };
  const hideArrow = !!(
    activeStep === steps.FillInfo || activeStep === steps.PickingPatients
  );
  const label =
    createPatientMode && activeStep !== steps.FillInfo
      ? "Add New Patient"
      : mobileVerification
        ? mobileVerification
        : "Requesting The Doctor";
  return (
    <>
      {needRebuild && (
        <div className="app-container-request_header-panel__navigation">
          <div className="app-container-request_header-panel_icon-back">
            {!hideArrow && <ArrowLeft onClick={handlePrevStep} />}
          </div>
          <div className="app-container-request_header-panel_icon-close">
            <Cross onClick={pushToPage} />
          </div>
        </div>
      )}
      <div
        className={cn("app-container-request_header-panel", {
          "app-container-request_header-panel_hide-steps": hideSteps,
        })}
      >
        {!needRebuild && (
          <div className="app-container-request_header-panel_icon-back">
            {!hideArrow && <ArrowLeft onClick={handlePrevStep} />}
          </div>
        )}
        <div className="app-container-request_header-panel_text">
          <Typography
            variant="h1"
            text={label}
            className="app-container-request_header-panel_title"
          />
        </div>
        {!needRebuild && (
          <div className="app-container-request_header-panel_icon-close">
            <Cross onClick={pushToPage} />
          </div>
        )}
      </div>
      <div className="app-container-request_header-panel__title">
        <div className="app-container-request_header-panel__title__space" />
        <div className="app-container-request_header-panel__title__status">
          {activeStep < 6 && (
            <div className="app-container-request_header-panel__title__status__indicator">
              <RoundProgressBar
                value={activeStep + 1}
                maxValue={5}
                duration={0.2}
                toFixedValue={0}
                className="app-container-request_header-panel__title__status__indicator--progress"
              />
            </div>
          )}
          <div className="app-container-request_header-panel__title__status__text">
            <Typography
              capitalize
              color={colors.blackMuted}
              text={stepsTitles[activeStep].title}
              variant="h3"
              className="app-container-request_header-panel__title__status__text--title"
            />
            {stepsTitles[activeStep].subtitle && (
              <Typography
                capitalize
                color={colors.grey}
                text={stepsTitles[activeStep].subtitle}
                variant="h3"
                className="app-container-request_header-panel__title__status__text--subtitle"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
