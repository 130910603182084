import React, { useEffect, useMemo, useState } from "react";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { DropDown } from "../../Common/DropDown/DropDown";
import { BTN_LABELS, PLACEHOLDERS, placeholders } from "../../../constants";
import { SingleDatePicker } from "../../Common/SingleDatePicker/SingleDatePicker";
import { timeOptionsShift } from "../../../constants/Admin";
import { Checkbox } from "../../Common/Checkbox/Checkbox";
import { changeFormatForRequest } from "../../../utils/date";
import moment from "moment";
import { validationDropdown } from "../../../utils/validation";
import { filterObj, lengthObj } from "../../../utils/common";
import { CheckboxV2 } from "../../Common/CheckboxV2";
import { Input } from "../../Common/Input/Input";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_CHECKBOX } from "../../Common/CheckboxV2/constants";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import cn from "classnames";
import { BirdSelect } from "../../Common/Icons";
import { RadioGroup } from "../../Common/RadioGroup";

const initialState = {
  location: [],
  specialty: [],
  doctor: "",
  dateFrom: moment().toDate(),
  dateTo: "",
  timeFrom: null,
  timeTo: null,
  endOnDate: "",
};

const optionsRadios = [
  { value: "shift", label: "This Shift" },
  { value: "shifts", label: "This And Following Shifts" },
];

const repetitionDayWeekOptions = ["Day", "Week"];

let repeatOnOptions = [
  { label: "S", value: 6, name: "Sun", isSelect: false },
  { label: "M", value: 0, name: "Mon", isSelect: false },
  { label: "T", value: 1, name: "Tue", isSelect: false },
  { label: "W", value: 2, name: "Wed", isSelect: false },
  { label: "T", value: 3, name: "Thu", isSelect: false },
  { label: "F", value: 4, name: "Fri", isSelect: false },
  { label: "S", value: 5, name: "Sat", isSelect: false },
];

export const AddShifts = (props) => {
  const setCurrentDayWeek = (date) => {
    return JSON.parse(
      JSON.stringify(
        repeatOnOptions.map((i) =>
          i.name === moment(date).format("ddd")
            ? {
                ...i,
                isSelect: true,
              }
            : i,
        ),
      ),
    );
  };

  const [state, setState] = useState({ ...initialState });
  const [backUp, setBackUp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isRecurrence, setIsRecurrence] = useState(false);
  const [countRepeat, setCountRepeat] = useState("1");
  const [repetitionDays, setRepetitionDays] = useState(setCurrentDayWeek());
  const [changeForAllShifts, setChangesForAllShifts] = useState(
    optionsRadios[0].value,
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [repetitionDayWeek, setRepetitionDayWeek] = useState(
    repetitionDayWeekOptions[0],
  );
  const [isChanges, setIChanges] = useState(false);

  const isEdit = props.editDetailsShift;

  const validation = () => {
    errors.specialty =
      errors.specialty || validationDropdown(state.specialty.length);
    errors.timeFrom = errors.timeFrom || validationDropdown(state.timeFrom);
    errors.location =
      errors.location || validationDropdown(state.location.length);
    errors.dateFrom = errors.dateFrom || validationDropdown(state.dateFrom);
    errors.doctor = errors.doctor || validationDropdown(state.doctor);
    errors.dateTo = errors.dateTo || validationDropdown(state.dateTo);
    errors.timeTo = errors.timeTo || validationDropdown(state.timeTo);
    if (isRecurrence) errors.endOnDate = validationDropdown(state.endOnDate);
    return filterObj(errors);
  };

  const [confirmationCreate, setConfirmationCreate] = useState(false);

  useEffect(() => {
    if (props.editDetailsShift) {
      const data = props.editDetailsShift;
      const doctorLocation = Array.isArray(data.doctor.region)
        ? data.doctor.region
        : [props.locations.find((i) => i.label === data.doctor.region)];
      const doctorSpecialty = Array.isArray(data.doctor.speciality)
        ? data.doctor.speciality
        : [props.speciality.find((i) => i.label === data.doctor.speciality)];
      const newState = {
        doctor: data.doctor,
        dateTo: data.dateTo,
        timeTo: data.timeTo,
        dateFrom: data.dateFrom,
        timeFrom: data.timeFrom,
        location: doctorLocation,
        specialty: doctorSpecialty,
      };
      if (data.recurrence_state) {
        setIsRecurrence(true);
        setCountRepeat(
          data.recurrence_state.weeks || data.recurrence_state.days,
        );
        if (data.recurrence_state.weeks) {
          setRepetitionDayWeek(repetitionDayWeekOptions[1]);
          setRepetitionDays(
            repeatOnOptions.map((i) =>
              data.recurrence_state.weekdays.includes(i.value)
                ? {
                    ...i,
                    isSelect: true,
                  }
                : i,
            ),
          );
        } else {
          setRepetitionDayWeek(repetitionDayWeekOptions[0]);
        }
        newState.endOnDate = moment(
          data.recurrence_state.recurrence_end_date,
        ).toDate();
      }
      setState({ ...newState });
      const isTomorrow =
        moment(moment().format("MM/DD/YYYY")).diff(
          moment(data.dateFrom, "MM/DD/YYYY"),
          "days",
        ) < 0;
      const isInPast =
        moment(moment().format("MM/DD/YYYY")).diff(
          moment(data.dateTo, "MM/DD/YYYY"),
          "days",
        ) > 0;
      const isYesterday =
        moment(moment().format("MM/DD/YYYY")).diff(
          moment(data.dateFrom, "MM/DD/YYYY"),
          "days",
        ) > 0;
      if (isTomorrow) {
        setDisabled(false);
        setState((prev) => ({
          ...prev,
          minDate: moment().toDate(),
        }));
      } else if (isInPast) {
        setState((prev) => ({
          ...prev,
          minDate: moment(data.dateFrom).toDate(),
        }));
        setDisabled(true);
      } else if (!isInPast) {
        setDisabled(false);
      }
      if (isYesterday) {
        setIsStarted(true);
      } else {
        setIsStarted(false);
      }
      setBackUp(data.is_backup === "backed_up");
    }
  }, [props.editDetailsShift?.uuid]);

  useEffect(() => {
    if (!!state.location.length && !!state.specialty.length)
      props.getTableData({
        validation_statuses: "Active",
        regions: state.location.map((i) => i.value),
        specialty: state.specialty.map((i) => i.value),
      });
  }, [state.location, state.specialty]);

  const updateState = (field, value) => {
    const newState = { ...state, [field]: value };
    if (field === "dateFrom") {
      setRepetitionDays(setCurrentDayWeek(value));
      newState.dateTo = "";
    }
    const errorsState = { ...errors };
    setState(newState);
    setErrors({ ...errorsState, [field]: false });
  };

  const changeRecurrence = (field, value) => {
    if (!value) {
      setCountRepeat("1");
      setRepetitionDays(setCurrentDayWeek(state.dateFrom));
    }
    if (isEdit) setIChanges(true);
    setIsRecurrence(value);
  };

  const onSave = () => {
    if (deleteConfirmation) {
      props
        .deleteShift(
          props.editDetailsShift?.uuid,
          changeForAllShifts === optionsRadios[0].value,
        )
        .then(() => {
          closeConfirmationCreate();
          closeShiftPopup();
        });
      return;
    }
    if (!state.doctor) closeConfirmationCreate();
    const errors = validation();
    if (lengthObj(errors)) {
      closeConfirmationCreate();
      setErrors(errors);
    } else {
      closeConfirmationCreate();
      const data = {
        start_date: changeFormatForRequest(state.dateFrom),
        end_date: changeFormatForRequest(state.dateTo),
        start_time: state.timeFrom.value,
        end_time: state.timeTo.value,
        doctor: state.doctor.value,
        region: state.location.value,
        is_recurred: false,
        is_backup: backUp ? "backed_up" : "not_backed_up",
        is_single_shift_update: optionsRadios[0].value === changeForAllShifts,
      };
      if (
        (isRecurrence && !isEdit) ||
        (isEdit &&
          isRecurrence &&
          changeForAllShifts === optionsRadios[1].value)
      ) {
        data.is_recurred = true;
        if (repetitionDayWeek === repetitionDayWeekOptions[0]) {
          data.days = countRepeat;
        } else {
          data.weekdays = repetitionDays
            .filter((i) => i.isSelect)
            .map((i) => i.value);
          data.weeks = countRepeat;
        }
        data.recurrence_end_date = changeFormatForRequest(state.endOnDate);
      }

      props.createShifts(data, props.editDetailsShift?.uuid).then((resp) => {
        if (resp.uuid) {
          props.getFiltersShifts();
          clearState();
          props.onClose();
          props.updateShifts();
        }
      });
    }
  };

  const handleBackUp = (field, value) => setBackUp(value);

  const changeActiveDay = (item) => {
    if (isEdit) setIChanges(true);
    if (item.name === moment(state.dateFrom).format("ddd")) return;
    setRepetitionDays(
      repetitionDays.map((i) =>
        i.value === item.value ? { ...i, isSelect: !i.isSelect } : i,
      ),
    );
  };
  const clearState = () => {
    closeConfirmationCreate();
    setState({ ...initialState });
    setErrors({});
    setDisabled(false);
    setIsStarted(false);
    setIsRecurrence(false);
    setCountRepeat("1");
    setRepetitionDays(setCurrentDayWeek(state.dateFrom));
    setChangesForAllShifts(optionsRadios[0].value);
    setRepetitionDayWeek(repetitionDayWeekOptions[0]);
    setIChanges(false);
  };

  const disableDoctor = !state.location.length || !state.specialty.length;
  const title = `${isEdit ? "Edit" : "Add"} Shift`;
  let confirmationTitle = isEdit
    ? `${deleteConfirmation ? "Delete" : "Edit"} Recurring Shift`
    : "Save Changes?";
  const txtEditConfirmation =
    isEdit &&
    !isRecurrence &&
    !deleteConfirmation &&
    "All following shifts from this recurrent schedule will be deleted";

  if (isEdit && !props?.editDetailsShift?.recurrence_state) {
    confirmationTitle = deleteConfirmation
      ? "The shift information will be lost"
      : "Edit Changes?";
  }
  const textPrimaryBtn = isEdit
    ? deleteConfirmation
      ? BTN_LABELS.DELETE_SHIFT
      : BTN_LABELS.CONFIRM
    : BTN_LABELS.SAVE;
  const closeConfirmationCreate = () => {
    setConfirmationCreate(false);
    setDeleteConfirmation(false);
  };
  const openConfirmationCreate = () => {
    if (isChanges) setChangesForAllShifts(optionsRadios[1].value);
    setConfirmationCreate(true);
  };
  const openConfirmationDelete = () => {
    setChangesForAllShifts(optionsRadios[0].value);
    setDeleteConfirmation(true);
  };

  const closeShiftPopup = () => {
    props.onClose();
    clearState();
  };

  const changeCountRepeat = (field, value) => {
    setCountRepeat(value);
    if (isEdit) setIChanges(true);
  };

  const changeRadios = (field, value) => {
    setChangesForAllShifts(value);
  };

  const changeRepetitionDayWeek = (value) => {
    setRepetitionDays(setCurrentDayWeek(state.dateFrom));
    setRepetitionDayWeek(value);
    if (isEdit) setIChanges(true);
  };

  const footer = (
    <div className="add-shift-popup_confirmation-delete-footer">
      {isEdit && (
        <ButtonV2
          color={COLORS_BTN.DEFAULT}
          capitalize
          className="delete"
          onClick={openConfirmationDelete}
          variant={VARIATIONS_BTN.CONTAINED}
        >
          {BTN_LABELS.DELETE_SHIFT}
        </ButtonV2>
      )}
      <ButtonV2
        capitalize
        onClick={closeShiftPopup}
        color={COLORS_BTN.DEFAULT}
        variant={VARIATIONS_BTN.CONTAINED}
      >
        {BTN_LABELS.CANCEL}
      </ButtonV2>{" "}
      <ButtonV2
        capitalize
        onClick={openConfirmationCreate}
        color={COLORS_BTN.PRIMARY}
        variant={VARIATIONS_BTN.CONTAINED}
      >
        {BTN_LABELS.SAVE}
      </ButtonV2>
    </div>
  );

  const finalRadiosOptions = useMemo(() => {
    if (isChanges && !deleteConfirmation)
      return optionsRadios.map((i) =>
        i.value === "shift" ? { ...i, disabled: true } : i,
      );
    return optionsRadios;
  }, [isChanges, deleteConfirmation]);
  return (
    <>
      <PopupContainer
        close={closeShiftPopup}
        open={props.open}
        title={title}
        uppercaseButton
        footer={footer}
        className="add-shift-popup"
        actionDefaultButton={closeShiftPopup}
        actionPrimaryButton={openConfirmationCreate}
      >
        <div className="add-shift-popup_wrapper-time add-shift-popup_wrapper-time_top">
          <SingleDatePicker
            label="Date From"
            className="add-shift-popup_wrapper-time_date"
            popperPlacement="top"
            selected={state.dateFrom}
            minDate={state.minDate}
            onChange={(e) => updateState("dateFrom", e)}
            disabled={isStarted || disabled}
            error={errors["dateFrom"]}
          />
          <DropDown
            className="add-shift-popup_wrapper-time_hour"
            value={state.timeFrom}
            label="Time"
            options={timeOptionsShift}
            handlerSelect={updateState}
            onChange={updateState}
            disabled={disabled}
            field="timeFrom"
            error={errors["timeFrom"]}
          />
        </div>
        <div className="add-shift-popup_wrapper-time">
          <SingleDatePicker
            label="Date To"
            className="add-shift-popup_wrapper-time_date"
            popperPlacement="top"
            minDate={state.dateFrom}
            selected={state.dateTo}
            onChange={(e) => updateState("dateTo", e)}
            disabled={disabled}
            error={errors["dateTo"]}
          />
          <DropDown
            className="add-shift-popup_wrapper-time_hour"
            value={state.timeTo}
            label="Time"
            options={timeOptionsShift}
            handlerSelect={updateState}
            onChange={updateState}
            disabled={disabled || !state.dateTo}
            field="timeTo"
            error={errors["timeTo"]}
          />
        </div>
        <div className="add-shift-popup_recurrence">
          <CheckboxV2
            color={COLORS_CHECKBOX.PRIMARY}
            label="Recurrence"
            checked={isRecurrence}
            onChange={changeRecurrence}
          />
          {txtEditConfirmation && props.editDetailsShift?.recurrence_state && (
            <span className="add-shift-popup_recurrence_txt-confirm">
              {txtEditConfirmation}
            </span>
          )}
          {isRecurrence && (
            <>
              <div className="add-shift-popup_recurrence_first-block">
                <Input
                  onBlur={() => {
                    if (!countRepeat) setCountRepeat("1");
                  }}
                  type="number"
                  value={countRepeat}
                  placeholder={PLACEHOLDERS.REPEAT_EVERY}
                  onChange={changeCountRepeat}
                />
                {repetitionDayWeekOptions.map((i) => {
                  const isSelected = repetitionDayWeek === i;
                  return (
                    <ButtonV2
                      color={
                        isSelected ? COLORS_BTN.PRIMARY : COLORS_BTN.DEFAULT
                      }
                      variant={VARIATIONS_BTN.CONTAINED}
                      onClick={() => changeRepetitionDayWeek(i)}
                    >
                      {i} {isSelected && <BirdSelect />}
                    </ButtonV2>
                  );
                })}
              </div>
              <div className="add-shift-popup_recurrence_second-block">
                {repetitionDayWeek === repetitionDayWeekOptions[1] && (
                  <>
                    <div className="add-shift-popup_recurrence_second-block_label">
                      {PLACEHOLDERS.REPEAT_ON}
                    </div>
                    <div className="add-shift-popup_recurrence_second-block_days">
                      {repetitionDays.map((i) => (
                        <div
                          className={cn(
                            "add-shift-popup_recurrence_second-block_days_item",
                            {
                              "add-shift-popup_recurrence_second-block_days_item_active":
                                i.isSelect,
                            },
                          )}
                          onClick={() => changeActiveDay(i)}
                        >
                          {i.label}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <SingleDatePicker
                  className="add-shift-popup_recurrence_second-block_end-on"
                  label={PLACEHOLDERS.END_ON}
                  popperPlacement="top"
                  minDate={state.dateFrom}
                  error={errors?.endOnDate}
                  maxDate={moment(state.dateFrom).add(6, "month").toDate()}
                  selected={state.endOnDate}
                  onChange={(e) => {
                    if (isEdit) setIChanges(true);
                    updateState("endOnDate", e);
                  }}
                  disabled={disabled}
                />
              </div>
            </>
          )}
        </div>
        <DropDown
          multi
          value={state.location}
          handlerSelect={updateState}
          label={placeholders.location}
          options={props.locations}
          error={errors["location"]}
          field="location"
        />
        <DropDown
          multi
          value={state.specialty}
          handlerSelect={updateState}
          label={placeholders.speciality}
          options={props.speciality}
          error={errors["specialty"]}
          field="specialty"
        />
        <DropDown
          disabled={disableDoctor}
          noOptionsMessage={() => "No suggested doctors"}
          value={state.doctor}
          handlerSelect={updateState}
          label={placeholders.physician}
          options={props.physicians}
          error={errors["doctor"]}
          field="doctor"
        />
        <Checkbox
          className="add-shift-popup_wrapper-time_back-up"
          checked={backUp}
          onClick={handleBackUp}
          label="Back Up"
        />
      </PopupContainer>
      <PopupContainer
        close={closeConfirmationCreate}
        open={confirmationCreate || deleteConfirmation}
        actionDefaultButton={closeConfirmationCreate}
        actionPrimaryButton={onSave}
        textPrimary={textPrimaryBtn}
        capitalize
        title={confirmationTitle}
        className="admin-shifts-page_confirmation-create"
      >
        {isEdit &&
          props?.editDetailsShift?.recurrence_state &&
          !txtEditConfirmation && (
            <RadioGroup
              horizonal
              options={finalRadiosOptions}
              value={changeForAllShifts}
              onChange={changeRadios}
            />
          )}
      </PopupContainer>
    </>
  );
};
