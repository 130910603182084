import { fetchV2 } from "../../helpers/helpers";
import moment from "moment";
import { durationInSec } from "../../utils/date";

const getAvailableTimeSlots =
  (date, patientsNumber, address, zip_code) => (dispatch) => {
    const body = {};
    if (date) body.date = date;
    if (zip_code) body.zip_code = zip_code;
    if (patientsNumber) body.patients_number = patientsNumber;
    if (address) body.address_line = address;
    return dispatch(
      fetchV2({
        url: `shifts/day-slots/`,
        body,
      }),
    ).then((resp) => {
      const timeSlots = resp.data?.map((item) => {
        const startTime = moment
          .utc(item.start.substring(0, 19))
          .format("hh:mm a");
        const endTime = moment.utc(item.end.substring(0, 19)).format("hh:mm a");
        const duration = durationInSec({ start: item.start, end: item.end });
        return {
          duration: duration,
          value: moment.utc(item.start.substring(0, 19)).format("HH:mm"),
          label: `${startTime} - ${endTime}`,
          isFree: item.is_free,
        };
      });
      return {
        ...resp,
        data: timeSlots,
      };
    });
  };

export { getAvailableTimeSlots };
