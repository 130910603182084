import React, { useState, useEffect, useMemo, useRef } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import {
  getStartOfWeekWithPrevDay,
  getEndOfWeekWithNextDay,
  getStartOfDayWithPrevDay,
  getStartOfDayWithNextDay,
} from "../../../utils/date";
import {
  ACTIVE,
  configNotification,
  DOCTOR,
  INVITE,
  PENDING,
  ROUTERS_DOCTORS,
  routesCommon,
  statesFiles,
  SUSPENDED,
} from "../../../constants";
import history from "../../../helpers/history";
import { Notification } from "../../Notifications/Notification";
import completedRegistration from "../../../dist/images/notifications/completedRegistration.svg";
import {
  filtersCalendar,
  notificationsText,
  routersDoctor,
} from "../../../constants/Doctors";
import appointment from "../../../dist/images/notifications/appointment.svg";
import updateFiles from "../../../dist/images/notifications/updateFiles.svg";
import ScheduleEvents from "../../ScheduleEvents";
import { CalendarEventDoctor } from "./CalendarEvent";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { getMultipleRequestList } from "../../../store/admin/actions";
import { DoctorSidebar } from "../DocotrSidebar/DoctorSidebar";
import { useDispatch, useSelector } from "react-redux";
import { calendarEventsSelector } from "store/calendarEvent/selectors";
import { requestDetailsSelector } from "store/request/selectors";
import { getCalendarEvents } from "store/calendarEvent/actions";
import { prepareCalendarEvents } from "store/calendarEvent/utils";
import { getDataRequest } from "../../../store/request/actions";
import { clearRequestDetails } from "../../../store/request/slice";
import { displayChatSelector } from "../../../store/app/selectors";
import { setDisplayChat } from "../../../store/app/slice";
import { Views } from "react-big-calendar";

const Schedule = (props) => {
  const [state, setState] = useState({
    showMyRequests: true,
    sideBarOpen: false,
    isMultiple: false,
    multipleRequestList: [],
    isInMultiple: false,
    status: "",
    location: {},
    selectedEvent: {},
    isAdditional: false,
    additionalFromUuid: "",
    triggerUpdateRequests: true,
  });

  const prevProps = useRef();

  const dispatch = useDispatch();
  const isChatOpen = useSelector(displayChatSelector);
  const calendarEvents = useSelector(calendarEventsSelector);
  const detailsRequest = useSelector(requestDetailsSelector);

  const mappedEvents = useMemo(() => {
    if (!!calendarEvents) {
      return prepareCalendarEvents(calendarEvents.data);
    } else {
      return [];
    }
  }, [calendarEvents?.timestamp]);

  useEffect(() => {
    return () => dispatch(clearRequestDetails());
  }, []);

  useEffect(() => {
    showNotifications(props.doctor);
  }, []);

  const openSideBar = () => simpleUpdate("sideBarOpen", true);
  const closeSideBar = () => {
    setState((prev) => ({
      ...prev,
      sideBarOpen: false,
      selectedEvent: {},
    }));
  };

  const getEvents = (after, before, data, modeLoading, filters = {}) => {
    const details = data || props;
    const isGetEvent =
      details.doctor?.status?.toLowerCase() === ACTIVE ||
      details.doctor?.status?.toLowerCase() === SUSPENDED;
    return (
      isGetEvent &&
      dispatch(
        getCalendarEvents({
          event_date_after: after,
          event_date_before: before,
          modeLoading,
          ...filters,
          doctors: state.showMyRequests ? details.doctor.uuid : "",
        }),
      )
    );
  };

  const getDetailsEvent = (data) => {
    dispatch(getDataRequest({ uuid: data.uuid })).then(() => {
      if (!data.noSetDisplayChat) dispatch(setDisplayChat(false));
      openSideBar();
      if (data.is_grouped) {
        if (state?.multipleUuid !== data.uuid) {
          getMultipleRequestList(data.uuid).then((data) => {
            setState((prev) => ({
              ...prev,
              isMultiple: true,
              multipleRequestList: data,
              isInMultiple: false,
            }));
          });
        }
      } else {
        const isInMultiple = !!state.multipleRequestList.find(
          (i) => i.uuid === data.uuid,
        );
        if (!isInMultiple) {
          simpleUpdate("isMultiple", false);
        }
        simpleUpdate("isInMultiple", isInMultiple);
        simpleUpdate("selectedEvent", data);
        if (props.location?.state?.isAdditional) {
          simpleUpdate("isAdditional", true);
          simpleUpdate(
            "additionalFromUuid",
            props.location?.state?.service_request_uuid,
          );
        }
      }
    });
  };

  const simpleUpdate = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const requestEventsWeek = (date, timeMode, modeLoading, filters) => {
    if (timeMode === Views.DAY)
      return getEvents(
        getStartOfDayWithPrevDay(date),
        getStartOfDayWithNextDay(date),
      );
    else if (timeMode === Views.WEEK)
      return getEvents(
        getStartOfWeekWithPrevDay(date),
        getEndOfWeekWithNextDay(date),
      );
    else if (timeMode === Views.LIST)
      return getEvents(date.start, date.end, props, modeLoading, filters);
  };
  useEffect(() => {
    prevProps.current = props;
  });

  useEffect(() => {
    const container = document.querySelector(".container-sidebar");
    if (props.location?.state) {
      if (props.location.state.service_request_uuid) {
        updateStatusEvent();
        getDetailsEvent({
          uuid: props.location.state.service_request_uuid,
          noSetDisplayChat: true,
        });
      }
      history.replace(`${routesCommon.doctor}${routersDoctor.schedule}`);
    }
    if (container && !state.isMultiple) {
      container.style.overflowY = "initial";
    }
  }, [props?.location?.state]);

  const showNotifications = (doctor) => {
    const isPending = doctor.status === PENDING;
    const isInvite = doctor.status === INVITE;
    if (isPending || isInvite) {
      props.saveSignature(null, doctor.uuid, false).then((resp) => {
        const isDecline = !!doctor.uploadApplications.find(
          (i) => i.statusFile === statesFiles.declined,
        );
        const allFile = doctor.uploadApplications.length >= 9 && !!resp;
        const goToDetails = () =>
          history.push(
            `${routesCommon.doctor}${ROUTERS_DOCTORS.personal_info}`,
          );
        if (!allFile) {
          toast(
            <Notification
              img={completedRegistration}
              text={notificationsText.completedRegistration}
              buttonText="Complete now"
              actionButton={goToDetails}
            />,
            configNotification,
          );
        }
        if (allFile && !isDecline) {
          toast(
            <Notification
              img={appointment}
              text={notificationsText.appointment}
              buttonText="got it"
              actionButton={() => {}}
            />,
            configNotification,
          );
        }
        if (allFile && isDecline) {
          toast(
            <Notification
              img={updateFiles}
              text={notificationsText.updateFiles}
              buttonText="view details"
              actionButton={goToDetails}
            />,
            configNotification,
          );
        }
      });
    }
  };

  const switchEvents = () => {
    simpleUpdate("showMyRequests", !state.showMyRequests);
  };
  const updateStatusEvent = () => {
    simpleUpdate("triggerUpdateRequests", !state.triggerUpdateRequests);
  };

  return (
    <div className="doctor-page-container">
      <ScheduleEvents
        {...state}
        isDoctor
        activeRequest={detailsRequest}
        requests={calendarEvents}
        onClickListRequest={getDetailsEvent}
        requestEventsWeek={requestEventsWeek}
        className="doctor-page-schedule"
        simpleUpdate={simpleUpdate}
        CustomEvent={(data) => (
          <CalendarEventDoctor
            action={getDetailsEvent}
            user={props.doctor}
            selectedEvent={state.selectedEvent}
            {...data}
          />
        )}
        events={mappedEvents}
        user={DOCTOR}
      >
        <div className="doctor-page-schedule_radiobuttons">
          {filtersCalendar.map((i, index) => (
            <Radiobutton
              label={i}
              key={index}
              onClick={switchEvents}
              checked={
                index === 0 ? state.showMyRequests : !state.showMyRequests
              }
            />
          ))}
        </div>
      </ScheduleEvents>
      <DoctorSidebar
        isChatOpen={isChatOpen}
        userData={props.doctor}
        open={state.sideBarOpen}
        data={detailsRequest}
        doctorCurrent={props.doctor}
        onClose={closeSideBar}
        setStatusAndArrive={props.setStatusAndArrive}
        updateStatusEvent={updateStatusEvent}
        multipleRequestList={state.multipleRequestList}
        viewDetailsAppointmentOrShift={getDetailsEvent}
        isMultiple={state.isMultiple}
        isInMultiple={state.isInMultiple}
        simpleUpdate={simpleUpdate}
        isAdditional={state.isAdditional}
        additionalFromUuid={state.additionalFromUuid}
      />
    </div>
  );
};

export default Schedule;
