import React, { useEffect, useState } from "react";
import { Card, ProgressButton, TimeButton } from "./components";
import "./style.scss";
import { Typography } from "../../Common/Typography/Typography";
import { Chart } from "../../Common/Chart/Chart";
import moment from "moment";
import { formatAppointmentDate } from "../../../utils/date";
import { RangeDatePicker } from "../../Common/RangeDatePicker/RangeDatePicker";
import { Expand } from "../../Common/Icons";
import { ExpandPopup } from "../../Dashboard/Popup/PopupContainer";
import { InfoWithMessage } from "../../Common/infoWithMessage/infoWithMessage";
import { colors } from "../../../assets/colors/colors";

const defaultStyles = {
  fontSize: 14,
  letterSpacing: "0.25px",
  textTransform: "capitalize",
  color: colors.tuna,
  fontWeight: 500,
  marginRight: 0,
  height: 29,
};

const columnsForChart = [
  {
    headerClassName: "table-header-cell",
    label: "doctor",
    dataKey: "doctor_name",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "specialty",
    dataKey: "specialty",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "location",
    dataKey: "region",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "total visits",
    dataKey: "visits",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "New Patients",
    dataKey: "new_patients",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Established Patients",
    dataKey: "established_patients",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Order Response Time",
    dataKey: "order_response_time",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Request To Door Time",
    dataKey: "road_time",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Visit Duration",
    dataKey: "visit_time",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "notes completed on shift",
    dataKey: "completed_note_on_shift",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "notes completed < 24 hours",
    dataKey: "completed_note_in_24_hours",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "notes completed > 24 hours",
    dataKey: "completed_note_more_than_24_hours",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "notes not completed",
    dataKey: "not_completed_note",
    headerStyle: { width: "7.7%", ...defaultStyles },
    style: { width: "7.7%" },
  },
];

const columnsForTime = [
  {
    headerClassName: "table-header-cell",
    label: "doctor",
    dataKey: "doctor_name",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "specialty",
    dataKey: "specialty",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "location",
    dataKey: "region",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "you",
    dataKey: "score",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "New Patients",
    dataKey: "new_patients",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Established Patients",
    dataKey: "established_patients",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Order Response Time",
    dataKey: "order_response_time",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Request To Door Time",
    dataKey: "road_time",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Visit Duration",
    dataKey: "visit_time",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "all UC (Palm Beach)",
    dataKey: "patient_satisfaction_by_region",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "all uplin",
    dataKey: "patient_satisfaction_by_uplin",
    headerStyle: { width: "9.1%", ...defaultStyles },
    style: { width: "9.1%" },
  },
];

const columnsForSatisfaction = [
  {
    headerClassName: "table-header-cell",
    label: "doctor",
    dataKey: "doctor_name",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "specialty",
    dataKey: "specialty",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "location",
    dataKey: "region",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "New Patients",
    dataKey: "new_patients",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "Established Patients",
    dataKey: "established_patients",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "order response time",
    dataKey: "order_response_time",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "request to door time",
    dataKey: "road_time",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
  {
    headerClassName: "table-header-cell",
    label: "visit duration",
    dataKey: "visit_time",
    headerStyle: { width: "12.5%", ...defaultStyles },
    style: { width: "12.5%" },
  },
];
const modesPopup = {
  statisticks: {
    title: "Notes Completion Statistics",
    config: columnsForChart,
    label: "How timely is your notes completion",
  },
  satisfaction: {
    title: "Patient's Satisfaction",
    config: columnsForTime,
    label: "Based on Patient's scores",
  },
  time: {
    title: "Time Speed",
    config: columnsForSatisfaction,
  },
};

export const Dashboard = (props) => {
  const [date, setDate] = useState({
    dateFrom: moment().startOf("month").toDate(),
    dateTo: moment().toDate(),
  });
  const [mode, setModePopup] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getData = (dates) => {
    return props.getDashboardDataDoctor({
      date_from: formatAppointmentDate(dates.dateFrom),
      date_to: formatAppointmentDate(dates.dateTo),
    });
  };

  useEffect(() => {
    getData(date).then((resp) => {
      setTableData(resp);
    });
  }, []);

  const onChangeDate = (field, value) => {
    const valueDate = { ...date, [field]: value };
    setDate(valueDate);
    getData(valueDate).then((resp) => {
      setTableData(resp);
    });
  };

  const dataCharts = props.dashboardData.notesChar;
  const patientSatisfaction = props.dashboardData.patientSatisfaction;
  const you = patientSatisfaction.patient_satisfaction_by_doctor;
  const byRegion = patientSatisfaction.patient_satisfaction_by_region;
  const byUplin = patientSatisfaction.patient_satisfaction_by_uplin;

  const responseTime = props.dashboardData.responseTime;

  console.log("mode", mode);
  return (
    <div className="doctor-page-container">
      <div className="doctor-page-dashboard">
        <div className="doctor-page-dashboard_filters">
          <RangeDatePicker
            maxDateTo={moment().toDate()}
            maxDateFrom={moment().toDate()}
            onChange={onChangeDate}
            dateTo={date.dateTo}
            dateFrom={date.dateFrom}
          />
        </div>
        <div className="doctor-page-dashboard_container">
          <div className="right-card">
            <Card className="doctor-page-dashboard_container_chart">
              <div className="chart-wrapper">
                <div className="left">
                  <div className="notes-statistics__container">
                    <Typography
                      variant="h2"
                      text="Notes Completion Statistics"
                    />

                    <InfoWithMessage label="Physician Chart Completion Efficiency" />
                  </div>
                  <div className="chart-container">
                    <Chart datasets={dataCharts} />
                  </div>
                </div>
                <div className="right">
                  <Expand
                    className="doctor-page-dashboard_expand right-expand"
                    onClick={() => {
                      setModePopup("statisticks");
                    }}
                  />
                  <div className="dashboard-right__container">
                    <Typography
                      variant="h2"
                      text="Total Visits"
                      className="card-title"
                    />
                    <InfoWithMessage label="Your Total Number of Visits" />
                  </div>

                  <div className="total-text">
                    <Typography
                      variant="h0"
                      text={props.dashboardData.visits.total_visits}
                    />
                  </div>
                </div>
              </div>
            </Card>

            <Card
              label="Patient's Satisfaction"
              className="doctor-page-dashboard_container_card"
            >
              <Expand
                className="doctor-page-dashboard_expand"
                onClick={() => {
                  setModePopup("satisfaction");
                }}
              />
              <div className="stat-wrapper">
                {you && (
                  <ProgressButton
                    label="You"
                    value={you}
                    delta={
                      patientSatisfaction.patient_satisfaction_by_doctor_difference
                    }
                  />
                )}
                {byRegion && (
                  <ProgressButton
                    label="All UC (Palm Beach)"
                    value={byRegion}
                    delta={
                      patientSatisfaction.patient_satisfaction_by_region_difference
                    }
                  />
                )}
                {patientSatisfaction.patient_satisfaction_by_uplin && (
                  <ProgressButton
                    label="All Uplin"
                    value={byUplin}
                    delta={
                      patientSatisfaction.patient_satisfaction_by_uplin_difference
                    }
                  />
                )}
              </div>
            </Card>
          </div>
          <Card
            className="left-card doctor-page-dashboard_container_card"
            label=""
          >
            <Expand
              className="doctor-page-dashboard_expand"
              onClick={() => {
                setModePopup("time");
              }}
            />
            <TimeButton
              label="Order Response Time"
              labelnfo="How fast you accept the call"
              value={responseTime.order_response_time}
              delta={responseTime.order_response_time_difference}
            />
            <TimeButton
              label="Request to Door Time"
              labelnfo="Time to get to Patient"
              value={responseTime.request_to_door_time}
              delta={responseTime.request_to_door_time_difference}
            />
            <TimeButton
              last
              label="Visit Duration"
              labelnfo="Time it takes you to complete the visit"
              value={responseTime.visit_duration}
              delta={responseTime.visit_duration_difference}
            />
          </Card>
        </div>
      </div>
      <ExpandPopup
        textPrimary="Got it"
        open={mode}
        date={date}
        patientSatisfaction={patientSatisfaction}
        responseTime={responseTime}
        dataCharts={dataCharts}
        configTableExpand={modesPopup[mode]}
        tableData={tableData}
        close={() => setModePopup(false)}
        title="Please Note That We Do NOT Treat The"
        extraTitle="Following Conditions:"
      />
    </div>
  );
};
