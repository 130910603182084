import { fetchV2 } from "../../helpers/helpers";
import { setCreateRequestAdminDoctorsData } from "./slice";
import { converterDataField } from "../../utils/common";

export const getCreateRequestAdminDoctors =
  (filters = {}, data = { results: [] }) =>
  (dispatch) =>
    dispatch(
      fetchV2({
        url: "admin/doctors/",
        body: { ...filters, limit: 30 },
      }),
    ).then((resp) => {
      let convertedResult =
        resp.data?.results?.map((item) => converterDataField(item, true)) || [];
      const processData = {
        timestamp: resp.timestamp,
        ...resp.data,
        results: data.results.concat(convertedResult),
      };
      delete processData.data;
      dispatch(setCreateRequestAdminDoctorsData(processData));
    });
