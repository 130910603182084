import React, { Component } from "react";
import "./style.scss";
import { Input } from "../../Common/Input/Input";
import { DropDown } from "../../Common/DropDown/DropDown";
import { RadioGroup } from "../../Common/RadioGroup";
import { setDoctorCreate } from "../../../store/doctor/actions";
import history from "../../../helpers/history";
import { ADMIN, PLACEHOLDERS, ACCOUNTSTATUS } from "../../../constants";
import { InputAddress } from "../../Common/Input/InputAddress";
import {
  validation18Year,
  validationDOB,
  validationNameFields,
  validationEmail,
  validationIsEmpty,
  validationNPI,
  validationPhone,
} from "../../../utils/validation";
import {
  converterDataField,
  filterObj,
  lengthObj,
} from "../../../utils/common";
import { BUTTONSEDITDOCTOR, routersAdmin } from "../../../constants/Admin";
import { BUTTONSDOCTOR, routersDoctor } from "../../../constants/Doctors";
import { placeholdersUserInfo, routesCommon, sex } from "../../../constants";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { Radiobutton } from "../../Common/RadioButton/Radiobutton";
import { emloyeeOptions } from "../../../constants";
class PersonalInfoForm extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      lastName: "",
      firstName: "",
      birthday: "",
      phone: "",
      email: "",
      address: "",
      suite: "",
      sex: "",
      npi: "",
      employeeStatus: "employee",
      title: "",
    };

    this.adminState = {
      location: "",
      status: ACCOUNTSTATUS[0],
      speciality: null,
    };

    this.state = {
      ...this.initialState,
      ...(this.props.flag === ADMIN ? this.adminState : {}),
      errors: {},
      statePage:
        this.props.flag === ADMIN ? BUTTONSEDITDOCTOR[0] : BUTTONSDOCTOR[0],
    };

    this.props.saveElements(this.saveElements, this.actionCancel);
  }

  componentDidMount() {
    if (this.props.doctorData && this.props.doctorData.uuid) {
      this.setLocations(this.props.locations, this.props.doctorData.location);
      this.setSpeciality(
        this.props.speciality,
        this.props.doctorData.speciality,
      );

      this.setStatus(this.props.doctorData.status);
      this.setData(this.props.doctorData);
    }
  }

  actionCancel = () => {
    if (this.props.flag === ADMIN)
      history.push(`${routesCommon.admin}${routersAdmin.table}`);
    else history.push(`${routesCommon.doctor}${routersDoctor.schedule}`);
  };

  setStateToStore = (noSetStore) => {
    let data = {};
    data.user = {
      uuid: this.props.doctorData.user
        ? this.props.doctorData.user.uuid
        : "create",
      phone: this.state.phone,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      birthday: this.state.birthday,
      sex: this.state.sex,
    };
    data.employeeStatus = this.state.employeeStatus;
    data.npi = this.state.npi;
    data.uuid = this.props.doctorData.uuid || "create";
    data.suite = this.state.suite;
    data.address = this.state.address || null;
    data.uploadApplications = this.props.doctorData.uploadApplications;
    data.title = this.state.title;
    if (this.props.flag === ADMIN) {
      data.status = this.state.status.label;
      data.location = this.state.location ? this.state.location.value : null;
      data.speciality = this.state.speciality
        ? this.state.speciality.value
        : null;
    } else {
      data.status = this.props.doctorData.status;
      data.location = this.props.doctorData.location;
    }

    if (noSetStore) return data;
    else this.props.updatePersonalInfo(data);
  };

  setLocations = (dataLocations, value) => {
    if (dataLocations && dataLocations.length) {
      this.setState({
        location: dataLocations.find((i) => i.value === value),
      });
    }
  };

  setSpeciality = (dataSpeciality, value) => {
    if (dataSpeciality && dataSpeciality.length) {
      this.setState({
        speciality: dataSpeciality.find((i) => i.value === value),
      });
    }
  };
  setStatus = (value) => {
    if (!!value) {
      this.setState({
        status: ACCOUNTSTATUS.find(
          (i) => i.value === value.replace(/\s/g, "_").toLowerCase(),
        ),
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.doctorData.uuid) {
      this.setData(nextProps.doctorData);
      if (this.props.flag === ADMIN && this.props.id) {
        this.setStatus(nextProps.doctorData.status);
        if (nextProps.locations.length || this.props.locations.length) {
          const dataLocations = this.props.locations.length
            ? this.props.locations
            : nextProps.locations;
          this.setLocations(
            dataLocations,
            this.props.doctorData.location || nextProps.doctorData.location,
          );
        }
        if (nextProps.speciality.length || this.props.speciality.length) {
          const dataSpeciality = this.props.speciality.length
            ? this.props.speciality
            : nextProps.speciality;
          this.setSpeciality(
            dataSpeciality,
            nextProps.doctorData.speciality || this.props.doctorData.speciality,
          );
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setPersonalInfo(this.setStateToStore(true));
  }

  makeRequest = () => {
    const {
      phone,
      speciality,
      location,
      status,
      suite,
      birthday,
      firstName,
      lastName,
      email,
      address,
      employeeStatus,
      uuid,
      sex,
      npi,
      title,
    } = this.state;

    const funcCatch = (e) => {
      if (e?.user?.phone_number) {
        this.setState({
          errors: { phone: "Phone number must be unique" },
        });
      }
    };
    const adminOptionalDate = {
      speciality: speciality ? speciality.value : null,
      location: location ? location.value : null,
      status: status ? status.label : null,
    };

    let data = {
      user: {
        phone,
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        birthday,
        email,
        sex,
      },
      ...(this.props.flag === ADMIN ? adminOptionalDate : {}),
      employeeStatus: employeeStatus,
      address: address,
      npi: npi,
      suite: suite,
      title: title,
    };

    data = {
      ...converterDataField(data, false),
      user: converterDataField(data.user, false),
    };
    if (uuid && uuid !== "create") {
      this.props
        .updateDoctor(
          this.props.doctorData.uuid,
          data,
          this.props.flag === ADMIN,
        )
        .then(() => {
          this.setStateToStore();
          if (this.props.flag === ADMIN)
            history.push(`${routesCommon.admin}${routersAdmin.table}`);
          else {
            // history.goBack();
            history.push(`${routesCommon.doctor}${routersDoctor.schedule}`);
          }
        })
        .catch(funcCatch);
    } else
      setDoctorCreate(data)
        .then(() => {
          history.push(`${routesCommon.admin}${routersAdmin.table}`);
        })
        .catch(funcCatch);
  };

  validation = () => {
    const { address, ...objElements } = this.state;
    const errors = validationIsEmpty({
      ...objElements,
      address: address?.address,
    });

    delete errors.suite;

    errors.firstName = errors.firstName
      ? errors.firstName
      : validationNameFields(this.state.firstName);
    errors.lastName = errors.lastName
      ? errors.lastName
      : validationNameFields(this.state.lastName);
    errors.birthday = errors.birthday
      ? errors.birthday
      : validationDOB(this.state.birthday);
    errors.birthday = errors.birthday || validation18Year(this.state.birthday);
    errors.phone = errors.phone || validationPhone(this.state.phone);
    errors.email = errors.email || validationEmail(this.state.email);
    errors.npi = errors.npi || validationNPI(this.state.npi);
    return filterObj(errors);
  };

  validationAdmin = () => {
    const objElements = {
      phone: this.state.phone,
      speciality: this.state.speciality,
      email: this.state.email,
      location: this.state.location,
      sex: this.state.sex,
      title: this.state.title,
      birthday: this.state.birthday,
    };

    const errors = validationIsEmpty(objElements);
    delete errors.suite;
    errors.firstName =
      this.state.firstName && validationNameFields(this.state.firstName);
    errors.lastName =
      this.state.lastName && validationNameFields(this.state.lastName);
    errors.birthday = errors.birthday
      ? errors.birthday
      : validationDOB(this.state.birthday);
    errors.birthday = errors.birthday || validation18Year(this.state.birthday);
    errors.phone = errors.phone || validationPhone(this.state.phone);
    errors.email = errors.email || validationEmail(this.state.email);
    errors.npi = errors.npi || validationNPI(this.state.npi);

    return filterObj(errors);
  };

  saveElements = (noRequest) => {
    let errorsValues =
      this.props.flag === ADMIN ? this.validationAdmin() : this.validation();
    this.setState({
      errors: {
        ...errorsValues,
      },
    });

    if (!lengthObj(errorsValues) && !noRequest) {
      this.makeRequest();
    }
    return !lengthObj(errorsValues);
  };

  updateState = (field, value) => {
    let errors = { ...this.state.errors };
    delete errors[field];

    this.setState({
      [field]: value,
      errors: errors,
    });
  };

  setData = (data) => {
    this.setState({
      ...data.user,
      npi: data.npi,
      address: data.address,
      suite: data.suite,
      employeeStatus: data.employeeStatus,
      title: data.title,
    });
  };

  render() {
    return (
      <div>
        {this.props.flag === ADMIN && (
          <div className="doctor-page-personal-info_radio-group">
            <RadioGroup
              field="employeeStatus"
              options={emloyeeOptions}
              value={this.state.employeeStatus}
              onChange={this.updateState}
            />
          </div>
        )}
        <div className="doctor-page-personal-info_title-block">
          <Input
            placeholder={PLACEHOLDERS.firstName}
            value={this.state.firstName}
            error={this.state.errors["firstName"]}
            field="firstName"
            maxLength="200"
            onChange={this.updateState}
          />

          <Input
            placeholder="Last Name"
            value={this.state.lastName}
            error={this.state.errors["lastName"]}
            field="lastName"
            maxLength="200"
            onChange={this.updateState}
          />
        </div>

        <div className="doctor-page-personal-info_title-block">
          <Input
            placeholder={PLACEHOLDERS.title}
            value={this.state.title}
            error={this.state.errors["title"]}
            field="title"
            maxLength="5"
            onChange={this.updateState}
          />
          <Input
            placeholder={PLACEHOLDERS.birthday}
            value={this.state.birthday}
            mask="99/99/9999"
            maskChar={"_"}
            defaultValue={"MM/DD/YYYY"}
            formatChars={{
              9: "[0-9]",
            }}
            error={this.state.errors["birthday"]}
            field="birthday"
            onChange={this.updateState}
          />
        </div>
        <div className="custom-input-container half-width">
          <Input
            placeholder={PLACEHOLDERS.phone}
            value={this.state.phone}
            mask="(999) 999-9999"
            maskChar={""}
            error={this.state.errors["phone"]}
            field="phone"
            onChange={this.updateState}
          />
        </div>
        <div className="doctor-page-personal-info_title-block doctor-page-personal-info_title-block_container-radio">
          <Typography
            text={placeholdersUserInfo.sex}
            variant="p"
            className="doctor-page-personal-info_title-block_container-radio_title"
            color={colors.darkMuted}
          />
          <div className="doctor-page-personal-info_title-block_container-radio_radio-buttons">
            {sex.map((i, index) => (
              <Radiobutton
                key={index}
                label={i}
                error={this.state.errors["sex"]}
                onClick={() => this.updateState("sex", i)}
                className=""
                checked={this.state.sex === i}
              />
            ))}
          </div>
        </div>

        <div className="doctor-page-personal-info_title-block">
          <Input
            className={this.props.flag === ADMIN ? "" : "half-width"}
            placeholder={PLACEHOLDERS.email}
            value={this.state.email}
            error={this.state.errors["email"]}
            field="email"
            maxLength="200"
            onChange={this.updateState}
          />
          {this.props.flag === ADMIN && (
            <DropDown
              label={PLACEHOLDERS.speciality}
              value={this.state.speciality}
              field="speciality"
              handlerSelect={this.updateState}
              error={this.state.errors["speciality"]}
              onChange={this.updateState}
              options={
                this.props.flag === ADMIN
                  ? this.props.speciality
                  : ACCOUNTSTATUS
              }
            />
          )}
        </div>

        <div className="doctor-page-personal-info_title-block">
          <InputAddress
            placeholder={PLACEHOLDERS.address}
            value={this.state.address}
            error={this.state.errors["address"]}
            field="address"
            maxLength="200"
            onChange={this.updateState}
          />

          {this.props.flag === ADMIN ? (
            <DropDown
              label={PLACEHOLDERS.location}
              className="custom-input-container"
              value={this.state.location}
              error={this.state.errors["location"]}
              field="location"
              onChange={this.updateState}
              handlerSelect={this.updateState}
              options={this.props.locations}
            />
          ) : (
            <Input
              placeholder={PLACEHOLDERS.suite}
              value={this.state.suite}
              error={this.state.errors["suite"]}
              field="suite"
              maxLength="12"
              onChange={this.updateState}
            />
          )}
        </div>

        {this.props.flag === ADMIN && (
          <div className="doctor-page-personal-info_title-block">
            <Input
              placeholder={PLACEHOLDERS.suite}
              value={this.state.suite}
              error={this.state.errors["suite"]}
              field="suite"
              maxLength="12"
              onChange={this.updateState}
            />

            <DropDown
              menuPlacement="top"
              disabled={!this.props.id}
              label={PLACEHOLDERS.account_status}
              value={this.state.status}
              field="status"
              handlerSelect={this.updateState}
              error={this.state.errors["status"]}
              onChange={this.updateState}
              options={ACCOUNTSTATUS}
            />
          </div>
        )}
        <div className="doctor-page-personal-info_title-block">
          <Input
            className={"half-width"}
            placeholder={PLACEHOLDERS.npi}
            value={this.state.npi}
            error={this.state.errors["npi"]}
            field="npi"
            mask={"999-999-9999"}
            onChange={this.updateState}
          />
        </div>
      </div>
    );
  }
}

export default PersonalInfoForm;
