import React from "react";
import cn from "classnames";
import { InfoIcon } from "../Icons";
import "./style.scss";
import { Tooltip } from "../Tooltip";

export const InfoWithMessage = ({ label }) => {
  return (
    <Tooltip className="card-container" title={label}>
      <div>
        <InfoIcon />
      </div>
    </Tooltip>
  );
};
