import { fetchWrapper } from "../../helpers/helpers";
import { setServiceRequestTypes } from "./slice";
import otherSymptoms from "dist/img/otherSymptoms.png";

const getServiceRequestTypes = (isAdmin) => (dispatch) =>
  fetchWrapper({
    url: "service-requests/types/",
  })
    .then((resp) => {
      const types = resp.map((i) =>
        i.name === "common" || i.name === "covid"
          ? {
              ...i,
              symptoms: [
                ...i.symptoms,
                {
                  name: "Other (You’ll Need to Specify)",
                  uuid: "",
                  image: otherSymptoms,
                },
              ],
            }
          : i,
      );
      dispatch(setServiceRequestTypes({ data: types, isAdmin: isAdmin }));
    })
    .catch((resp) => resp);

export { getServiceRequestTypes };
