export const VitalSigns = () => {
  return {
    type: "label",
    data: "Vital Signs:",
    required: true,
    canDelete: true,
    items: [
      {
        type: "blockCheckboxCondition",
        extraType: "vitalSigns",
        data: {
          notUseDisable: true,
          checkbox: [{ checked: false, data: "Refused by Patient" }],
          hiddenBlock: [
            {
              type: "input-static-text",
              label: "BP:*",
              checkSecondSmallerFirst: true,
              data: [
                { data: "", mask: "999", min: 50, max: 250 },
                { data: "", mask: "999", min: 20, max: 150 },
              ],
              deviders: ["/", "mm Hg"],
              nameBE: "blood_pressure",
            },
            {
              type: "input-static-text",
              label: "P:*",
              data: [{ data: "", mask: "999", min: 20, max: 300 }],
              deviders: ["/ min"],
              nameBE: "pulse",
            },
            {
              type: "input-static-text",
              label: "RR:*",
              data: [{ data: "", mask: "999", min: 0, max: 100 }],
              deviders: ["/ min"],
              nameBE: "respiration_rate",
            },
            {
              type: "input-static-text",
              label: "T:*",
              data: [{ data: "", mask: "88888", min: 90, max: 108 }],
              deviders: ["F"],
              nameBE: "temperature",
            },
            {
              type: "input-static-text",
              label: "P.Ox.:*",
              data: [{ data: "", mask: "999", min: 40, max: 100 }],
              deviders: ["%"],
              nameBE: "pulse_oximetry",
            },
            {
              type: "input-static-text",
              label: "WT:*",
              noMandatory: true,
              data: [{ data: "", mask: "88888", min: 1, max: 999.9 }],
              deviders: ["Ibs"],
              nameBE: "weight",
            },
            {
              type: "input-static-text",
              label: "HT:*",
              noMandatory: true,
              data: [
                { data: "", mask: "9", min: 1, max: 8 },
                { data: "", mask: "99", min: 0, max: 11 },
              ],
              deviders: ["ft", "in"],
              nameBE: "height",
            },
          ],
        },
      },
    ],
  };
};
