import React, { Component, useMemo, useRef } from "react";
import "./style.scss";
import { DisabledOverlay } from "../DisabledOverlay";
import { Button } from "../../../../Common/Button/Button";
import { fetchWrapper } from "../../../../../helpers/helpers";
import { routesCommon } from "../../../../../constants";
import { routersLanding } from "../../../../../constants/Landing";
import fetch from "isomorphic-fetch";
import { Plus } from "../../../../Common/Icons";
import { Typography } from "../../../../Common/Typography/Typography";
import { colors } from "../../../../../assets/colors/colors";
import { getSingleFromGroupRequest } from "../../../../../store/request/utils";
import { ButtonV2 } from "../../../../Common/ButtonV2";
import {
  COLORS_BTN,
  VARIATIONS_BTN,
} from "../../../../Common/ButtonV2/constants";

export const VeradigmButton = ({
  dataRequest = {},
  title,
  viewButtonAsText,
  url,
}) => {
  const inputValue = useRef(null);
  const inputSubmit = useRef(null);
  const formRef = useRef(null);
  const uuidRequest = window.location.pathname.split("/").reverse()[0];
  const uuidPatient = useMemo(() => {
    if (dataRequest)
      return getSingleFromGroupRequest({
        request: dataRequest,
        uuid: uuidRequest,
      })?.patient_uuid;
  }, [dataRequest.timestamp]);
  const click = () => {
    fetchWrapper({ url: url || `veradigm/sso/${uuidPatient}/` }).then(
      (resp) => {
        if (resp?.body?.SAMLResponse) {
          inputValue.current.value = resp.body.SAMLResponse;
          formRef.current.action = resp.url;
          inputSubmit.current.click();
        }
      },
    );
  };
  return (
    <div className="veradigm-button-container">
      <ButtonV2
        uppercase
        onClick={click}
        variant={
          viewButtonAsText ? VARIATIONS_BTN.TEXT : VARIATIONS_BTN.CONTAINED
        }
        color={COLORS_BTN.PRIMARY}
      >
        {title}
      </ButtonV2>
      <form ref={formRef} method="post" id="samlpost" target="_blank">
        <input type="hidden" name="SAMLResponse" ref={inputValue} value="" />
        <input type="submit" ref={inputSubmit} />
      </form>
    </div>
  );
};
