import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const messageSlice = createSlice({
  name: "messageSlice",
  initialState,
  reducers: {
    setMessages: (state, { payload }) => {
      state.data = [...state.data, ...payload];
    },
    clearMessageSlice: (state) => {
      state.data = [];
    },
  },
});

export const { setMessages, clearMessageSlice } = messageSlice.actions;
export const messageReducer = messageSlice.reducer;
