import moment from "moment";

export const timeOptions = () => {
  const options = [];

  let time = moment.utc().startOf("day");
  do {
    const value = time.format("HH:mm");
    const label = time.format("hh:mm a");

    options.push({ value: value, label: label });

    time.add(15, "minutes");
  } while (options.length !== 96);

  return options;
};
