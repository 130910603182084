import React, { useEffect, useState } from "react";
import { PopupContainer } from "../../../../../Common/Popup/PopupContainer";
import "./style.scss";
import { Typography } from "../../../../../Common/Typography/Typography";
import { excuse } from "../../../../../../constants";
import { colors } from "../../../../../../assets/colors/colors";
import { SingleDatePicker } from "../../../../../Common/SingleDatePicker/SingleDatePicker";
import moment from "moment";
import {
  changeFormatForRequest,
  formatViewDate,
  getDateFromBEFormat,
  getTimeInFormat12,
} from "../../../../../../utils/date";
import { Input } from "../../../../../Common/Input/Input";
import { lengthObj } from "../../../../../../utils/common";

const variant = "p";
const color = colors.blackMuted;

export const EditorExcuse = ({
  open,
  close,
  updateExcuse,
  patient,
  doctorArrived,
  defaultValues = {},
}) => {
  const [date, setDate] = useState(moment().toDate());
  const [days, setDays] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (defaultValues && lengthObj(defaultValues) && open) {
      setDays(defaultValues.days_number);
      setDate(getDateFromBEFormat(defaultValues.desired_date));
    } else {
      setDate(moment().toDate());
      setDays("");
      setErrors({});
    }
  }, [open]);

  const handleDays = (field, value) => {
    setErrors({});
    setDays(value);
  };
  const closeExcuse = () => {
    close();
  };

  return (
    <PopupContainer
      className="excuse-container-editor"
      textDefault="CANCEL"
      textPrimary="SAVE"
      open={open}
      close={closeExcuse}
      actionDefaultButton={closeExcuse}
      title="Excuse for Work/School"
      actionPrimaryButton={() => {
        if (!+days) {
          setErrors({ days: "Put more than 1 days" });
        } else {
          close();
          updateExcuse({
            desired_date: changeFormatForRequest(date),
            days_number: days,
          });
        }
      }}
    >
      <div className="excuse-container-editor-content_container">
        <Typography text={excuse.textExcuse1} variant={variant} />
      </div>
      <div className="excuse-container-editor-content_container">
        <Typography text={patient?.first_name} variant={variant} />
        <Typography text={patient?.last_name} variant={variant} />
        <Typography text={excuse.textExcuse2} variant={variant} />
        <Typography text={formatViewDate(doctorArrived)} variant={variant} />
        <Typography text={getTimeInFormat12(doctorArrived)} variant={variant} />
        <Typography text={excuse.textExcuse3} variant={variant} />
        <SingleDatePicker
          classNameContainer="excuse-container-editor-content_container_date-picker"
          label="Desired Date"
          className=""
          popperPlacement="bottom"
          selected={date}
          minDate={moment().toDate()}
          onChange={setDate}
        />
      </div>
      <div className="excuse-container-editor-content_container">
        <Typography text={excuse.textExcuse4} variant={variant} />
        <Typography text={patient?.first_name} variant={variant} />
        <Typography text={excuse.textExcuse5} variant={variant} />
        <Input
          type="number"
          placeholder="Number Days"
          value={days}
          error={errors["days"]}
          field="days"
          maxLength="2"
          onChange={handleDays}
        />
        <Typography text={excuse.textExcuse6} variant={variant} />
      </div>
      <div className="excuse-container-editor-content_container">
        <Typography text={excuse.textExcuse7} variant={variant} />
      </div>
    </PopupContainer>
  );
};
