import * as components from "./components";

export const template = {
  name: "Bee Sting",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Insect sting",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "whose reports insect sting time prior to arrival. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "dyspnea" },
              { checked: false, data: "swelling of throat" },
              { checked: false, data: "generalized rash" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.TetanusStatus(),
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert, cooperative, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "urticaria" }],
              },
              {
                type: "text",
                data: ". Mild swelling and erythema of location, with no stinger present,",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "lymphangitis" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "scalp swelling" }],
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Eyes:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "periorbital swelling" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "swelling of lips" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "swelling of throat" }],
              },
              {
                type: "text",
                data: ". Airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "hoarseness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck([
            {
              type: "text",
              data: ",",
              editable: true,
              source: null,
            },
            {
              type: "radio-single",
              data: [{ checked: false, data: "thyromegaly" }],
            },
          ]),
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            required: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rales" }],
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rhonchi" }],
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "wheezes" }],
              },
              {
                type: "text",
                data: "; breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "tenderness" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "guarding" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rebound" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "palpable masses" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "CVA tenderness" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "deformity" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "edema" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),

      {
        type: "label",
        data: "TREATMENT",
        required: true,
        items: [
          {
            type: "blockCheckboxCondition",
            extraType: "treatment",
            data: {
              checkbox: [{ checked: true, data: "", isHide: true }],
              hiddenBlock: [
                {
                  type: "text",
                  data: "Tetanus prophylaxis",
                  editable: true,
                  source: null,
                },
                {
                  type: "radio-block",
                  data: [
                    { checked: false, data: "was" },
                    { checked: false, data: "was not" },
                  ],
                },
                {
                  type: "text",
                  data: "administered.",
                  editable: true,
                  source: null,
                },
                {
                  type: "input",
                  placeholder: "",
                  data: "",
                },
              ],
            },
          },
        ],
      },
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Go to the nearest ED in case of for any difficulty breathing, wheezing or sensation of swelling of throat. Follow up with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "Allergist" },
                ],
              },
              {
                type: "text",
                data: "for any evidence of infection.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
