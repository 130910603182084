import { fetchWrapper } from "../../helpers/helpers";
import { getDataRequest } from "../request/actions";

const approveDeclineServices = (body, id) => (dispatch) =>
  fetchWrapper({
    url: `service-requests/additional-services/${body.payment_profile_id || body.card_info ? "approve/" : "decline/"}`,
    method: "POST",
    body,
  }).then((resp) => {
    if (resp.assignments_ids)
      dispatch(getDataRequest({ uuid: id, noClearStore: true }));
    else throw "error";
  });

export { approveDeclineServices };
