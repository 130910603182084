import React from "react";
import "./style.scss";
import { DetailsOfRequest } from "./DetailsOfRequest";
import { ListRequests } from "./ListRequests";

export const DataRequests = ({
  dataRequest,
  listLastRequests,
  changeShowingRequest,
  additionalInfo,
  updateAdditionalInfo,
  deleteAdditionalInfo,
  doctorData,
  id,
  role,
}) => {
  return (
    <div className="data-request-block">
      <DetailsOfRequest
        id={id}
        role={role}
        sendInfo={updateAdditionalInfo}
        info={additionalInfo}
        deleteAdditionalInfo={deleteAdditionalInfo}
        doctorData={doctorData}
        dataRequest={dataRequest}
      />
      <ListRequests
        id={id}
        current={dataRequest}
        data={listLastRequests}
        changeShowingRequest={changeShowingRequest}
      />
    </div>
  );
};
