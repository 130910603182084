import { fetchV2 } from "../../helpers/helpers";
import { setCalendarEventsData, addMoreCalendarEvents } from "./slice";

const getCalendarEvents =
  (
    body = {
      event_date_after: "1971-02-24T00:00",
      event_date_before: "2200-02-24T00:00",
      grouping: true,
    },
  ) =>
  (dispatch) => {
    return dispatch(
      fetchV2({
        url: "service-requests/calendar/events/",
        body: body,
      }),
    ).then((resp) => {
      if (body.modeLoading)
        dispatch(addMoreCalendarEvents({ data: resp, mode: body.modeLoading }));
      else dispatch(setCalendarEventsData(resp));
    });
  };

export { getCalendarEvents };
