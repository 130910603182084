import React from "react";
import classNames from "classnames";
import "./buttonMenu.scss";
import { Points } from "../Icons";

export const ButtonMenu = ({ className, children, horizontal }) => {
  return (
    <div
      className={classNames("button-menu", className, {
        "button-menu_horizontal": horizontal,
      })}
      tabIndex="0"
    >
      <button>
        <Points className="button-menu_icon" />
        <ul className="menu-list">{children}</ul>
      </button>
    </div>
  );
};
