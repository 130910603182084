import React, { Component } from "react";
import { PanelFilters } from "../PanelFilters/PanelFilters";
import { DropDown } from "../../Common/DropDown/DropDown";
import { CustomTable } from "../../Table/Table";
import { colors } from "../../../assets/colors/colors";
import { Edit } from "../../Common/Icons";
import { getFilters, sendResendToEmail } from "../../../store/admin/actions";
import { Typography } from "../../Common/Typography/Typography";
import { ROUTERS_ADMIN, STATUSES_DOCTORS_ROW } from "../../../constants/Admin";
import history from "../../../helpers/history";
import { routesCommon } from "../../../constants";
import { convertPhoneValue } from "../../../utils/common";
import { PENDING } from "../../../constants";

const defaultStyles = {
  fontSize: 14,
  letterSpacing: "0.25px",
  textTransform: "capitalize",
  color: colors.tuna,
  fontWeight: 500,
  marginRight: 0,
  height: 50,
};

const defaultStylesRow = {
  marginLeft: 0,
  marginRight: 0,
};

const colorStatus = {
  active: colors.elfName,
  pending: colors.core40,
  disabled: colors.redError,
  suspended: colors.peach,
  invite_sent: colors.core70,
};

const columnsAdmin = [
  {
    headerClassName: "table-header-cell",
    label: "account status",
    dataKey: "account_status",
    headerStyle: { width: "10%", ...defaultStyles },
    style: {
      width: "10%",
      display: "flex",
      justifyContent: "center",
      ...defaultStylesRow,
    },
    customCell: ({ rowData }) => {
      const color =
        colorStatus[
          rowData.validation_status.toLowerCase().replace(/\s/g, "_")
        ];
      return (
        <div
          className="custom-indicator-account-status"
          style={{ backgroundColor: color }}
        />
      );
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "first name",
    dataKey: "first_name",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "last name",
    dataKey: "last_name",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "phone number",
    dataKey: "phone_number",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData }) => {
      const phone = convertPhoneValue(rowData.phone_number, true);
      return <span>{phone}</span>;
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "speciality",
    dataKey: "speciality",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "email",
    dataKey: "email",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "location",
    dataKey: "region",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
  },
  {
    headerClassName: "table-header-cell",
    label: "uploaded files",
    dataKey: "uploaded_files_number:",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData }) => {
      return (
        <span className="uploaded-files-number-cell">
          {rowData.uploaded_files_number}/9
        </span>
      );
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "invite status",
    dataKey: "invite_status",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData }) => {
      const color =
        colorStatus[
          rowData.validation_status.toLowerCase().replace(/\s/g, "_")
        ];
      return (
        <span className="invite_status_cell" style={{ color: color }}>
          {rowData.validation_status.replace(/_/g, " ")}
        </span>
      );
    },
  },
  {
    headerClassName: "table-header-cell",
    label: "",
    dataKey: "actions",
    headerStyle: { width: "10%", ...defaultStyles },
    style: { width: "10%", ...defaultStylesRow },
    customCell: ({ rowData, parent }) => {
      const click = () =>
        history.push(
          `${routesCommon.admin}${ROUTERS_ADMIN.edit}/${rowData.uuid}`,
        );

      const sendResend = () => {
        sendResendToEmail(rowData.uuid).then((resp) => {
          resp.uuid &&
            rowData.validation_status.toLowerCase() === PENDING &&
            parent.props.actions.setInviteSentStatus(rowData.uuid);
        });
      };
      return (
        <div className="custom-table-cell-actions">
          <button onClick={sendResend} className="no-default-style-button">
            <Typography
              text={
                STATUSES_DOCTORS_ROW.find(
                  (i) => i.status === rowData.validation_status,
                ).text
              }
              color={colors.navy}
              uppercase
            />
          </button>
          <Edit onClick={click} />
        </div>
      );
    },
  },
];

class TablePageAdmin extends Component {
  initialState = {
    regions: [],
    // invite_status: [],
    account_status: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",

      choosedFilters: {
        ...this.initialState,
      },
      applyFilters: {
        ...this.initialState,
      },
      valueFilters: {
        ...this.initialState,
      },
    };
  }

  updateSearchValue = (field, value) => {
    this.props.getTableData({
      limit: 100,
      offset: 0,
      search: value,
      ...this.getUuidFilters(this.state.applyFilters),
    });
    this.setState({
      [field]: value,
    });
  };

  selectFilters = (field, value) => {
    this.setState({
      choosedFilters: {
        ...this.state.choosedFilters,
        [field]: value,
      },
    });
  };

  applyFilterAction = () => {
    this.props.getTableData({
      limit: 100,
      offset: 0,
      ...this.getUuidFilters(this.state.choosedFilters),
      search: this.state.searchValue,
    });
    this.setState({
      applyFilters: {
        ...this.state.choosedFilters,
      },
    });
  };

  deleteAppliedFilters = () => {
    this.props.getTableData({
      limit: 100,
      offset: 0,
      search: this.state.searchValue,
    });
    this.setState({
      choosedFilters: { ...this.initialState },
      applyFilters: { ...this.initialState },
    });
  };

  componentWillUnmount() {
    this.props.clearTableData();
  }

  async componentDidMount() {
    this.props.getTableData({ limit: 100, offset: 0 });
    const filtersValue = await getFilters();
    this.setState({
      valueFilters: {
        regions: filtersValue.regions.map((i) => ({
          label: i.name,
          value: i.uuid,
        })),
        // invite_status: filtersValue.invite_status.map(i => ({
        //     value: i.validation_status,
        //     label: i.validation_status
        // })),
        account_status: filtersValue.validation_statuses.map((i) => ({
          value: i.validation_status,
          label: i.validation_status,
        })),
      },
    });
  }

  getUuidFilters = (filtersObject) => {
    let filters = {};
    for (let key in filtersObject) {
      filters[key] = filtersObject[key].map((i) => i.value);
    }

    filters.validation_statuses = filters.account_status;
    delete filters.account_status;
    return filters;
  };

  loadTableData = () => {
    !!this.props.tableData.next &&
      this.props.getTableData(
        {
          limit: 100,
          offset: this.props.tableData.results.length,
          ...this.getUuidFilters(this.state.choosedFilters),
          search: this.state.searchValue,
        },
        { results: this.props.tableData.results },
      );
  };

  showFiltersValue = () => {
    const obj = this.state.applyFilters;
    let filtersValue = "";
    let countFilters = -1;
    for (let key in obj) {
      if (obj[key].length) {
        if (countFilters === -1)
          filtersValue = obj[key].map((i) => i.label).join(", ");
        countFilters = countFilters + 1;
      }
    }
    if (filtersValue !== "" && !!countFilters)
      filtersValue = `${filtersValue}, +${countFilters}`;
    return filtersValue;
  };

  render() {
    const { regions, account_status } = this.state.choosedFilters;
    const { regions: regionsV, account_status: accountStatusV } =
      this.state.valueFilters;

    let filtersValue = this.showFiltersValue();

    return (
      <>
        <PanelFilters
          showButtonAdd
          clearDoctorData={this.props.clearDoctorData}
          className="admin-page-container_panel-filters"
          filtersValue={filtersValue}
          value={this.state.searchValue}
          applyFilters={this.state.applyFilters}
          applyFilterAction={this.applyFilterAction}
          deleteAppliedFilters={this.deleteAppliedFilters}
          onChange={this.updateSearchValue}
        >
          <DropDown
            multi
            value={regions}
            field="regions"
            handlerSelect={this.selectFilters}
            label="Regions"
            options={regionsV}
          />
          <DropDown
            multi
            value={account_status}
            field="account_status"
            handlerSelect={this.selectFilters}
            label="Account status"
            options={accountStatusV}
          />
        </PanelFilters>
        <CustomTable
          actions={{ setInviteSentStatus: this.props.setInviteSentStatus }}
          loadTableData={this.loadTableData}
          heightTable="calc(100vh - 190px)"
          columnsHeader={columnsAdmin}
          data={this.props.tableData.results}
          rowClassName="admin-page-container_table_row"
          className="admin-page-container_table"
        />
      </>
    );
  }
}

export default TablePageAdmin;
