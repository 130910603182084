import moment from "moment";
import _ from "lodash";

export const sortGroupRequest = (data) => {
  if (!data?.data) return { future: [], past: [] };
  let past = [];
  let future = [];
  [...data.data]
    .sort((a, b) => moment(b.application_time) - moment(a.application_time))
    .forEach((i) => {
      if (moment(i.application_time) > moment()) future.push(i);
      else past.push(i);
    });
  return { future: future, past: past };
};

export const chunkRequests = (data, countInline) => _.chunk(data, countInline);
