import React from "react";

import "./style.scss";

export const FooterNotes = ({ dataRequest, signature }) => {
  const doctorInitial =
    dataRequest?.doctor_data?.first_name +
    " " +
    dataRequest?.doctor_data?.last_name;
  const degree = dataRequest?.doctor_data?.title;
  return (
    <div className="templates-footer">
      <p>Sincerely,</p>
      <p className="templates-footer_signature">
        {signature && (
          <img src={signature?.download || signature} alt="download" />
        )}
      </p>
      <span>{`${doctorInitial}${degree ? `, ${degree}` : ""}`}</span>
    </div>
  );
};
