import { colors } from "../assets/colors/colors";

export const statesButtonsTime = ["now", "later"];

export const steps = {
  PickingPatients: 0,
  Symptoms: 1,
  Address: 2,
  Time: 3,
  Review: 4,
  Cards: 5,
  FillInfo: 6,
  AddNew: 7,
};

export const UNITS = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//doctor flow
export const DECLINED = "declined";
export const ACCEPTED = "accepted";
export const DENIED = "Denied. Please upload other version";
export const NOT_SUPPORTED = "Uploaded file format or size is not supported";
export const PENDING = "pending";
export const ASSIGNED = "assigned";
export const INVITE = "invite sent";
export const ACTIVE = "active";
export const SUSPENDED = "suspended";
export const VISIT_COMPLETED = "visit completed";
export const REQUEST_COMPLETED = "request completed";
export const DOCTOR = "doctor";
export const ADMIN = "admin";
export const RELOAD_FILE = "Something went wrong. Please reload file";
export const ARCHIVED = "archived";
export const PAID = "paid";
export const IN_PROGRESS = "in progress";

export const PLACEHOLDERS = {
  email: "Email Address",
  lastName: "Last Name",
  firstName: "First Name",
  birthday: " Date of Birth",
  phone: "Cell Phone #",
  speciality: "Speciality",
  zipcode: "Zip Code",
  address: "Address",
  apartment: "Apartment",
  suite: "Suite",
  floor: "Floor",
  account_status: "Account Status",
  location: "Location",
  npi: "NPI",
  title: "Title",
  timeStart: "Time Start",
  timeFinish: "Time Finish",

  END_ON: "End On",
  EMAIL: "Email Address",
  LAST_NAME: "Last Name",
  FIRST_NAME: "First Name",
  BIRTHDAY: " Date of Birth",
  PHONE: "Cell Phone #",
  CLIENT_PATIENT_RELATIONSHIP: "Requester Relationship to the Patient?",
  SPECIALTY: "Speciality",
  ZIPCODE: "Zip Code",
  ADDRESS: "Address",
  APARTMENT: "Apartment",
  SUITE: "Suite",
  FLOOR: "Floor",
  DATE: "Date",
  TIME_SLOT: "Time slot",
  ASSIGN_TO: "Assign to",
  REQUEST_TYPE: "Request type",
  GENDER: "Gender",
  REASON: "Reason",
  STATUS: "Status",
  REPEAT_EVERY: "Repeat Every",
  REPEAT_ON: "Repeat On",
  SELECT_REQUEST_TYPE: "Select Request Type",
  MEMBERSHIP_STATUS: "Membership status",
};

export const ACCOUNTSTATUS = [
  { value: "pending", label: "Pending" },
  { value: "invite_sent", label: "Invite Sent" },
  { value: "suspended", label: "Suspended" },
  { value: "disabled", label: "Disabled" },
  { value: "active", label: "Active" },
];

export const colorRequestStatus = {
  pending: "#C5BA1B",
  assigned: "#29A35D",
  "in progress": "#1771B7",
  "visit completed": "#E4AB3A",
  "request completed": "#1C7D45",
  declined: "#B81414",
  draft: "#5F6368",
};

export const colorStatus = {
  pending: colors.grey,
  paid: colors.elfName,
  declined: colors.orangeMuted,
  active: colors.navy,
};

// Employment Applications

export const LABELS_DOCTORS = {
  app: "Employment Application",
  cv: "CV",
  verificationForm: "Form I-9 Employment Eligibility Verification",
  w4Form: "W4 Form",
  photo: "Copy of Government-Issued Photo ID",
  card: "Copy of Social Security Card",
  voidedCheck: "Voided Check (For Payments)",
  confidentialityForm: "Confidentiality Form",
  license: "Copy of State Medical License",
  dea: "Copy of DEA",
  form1099: "1099 Form",
};

export const FILE_FORMATS = [
  "png",
  "jpeg",
  "jpg",
  "gif",
  "ppt",
  "pptx",
  "key",
  "pps",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "pdf",
  "txt",
];

//SideBar Request

export const requestStatuses = {
  pending: "pending",
  assigned: "assigned",
  inProgress: "in progress",
  visitCompete: "visit completed",
  requestCompleted: "request completed",
  declined: "declined",
  draft: "draft",
};

export const momentAbbreviation = {
  minutes: "minutes",
  milliseconds: "milliseconds",
};

export const pageConditions = {
  TriggerOptionalServices: "TriggerOptionalServices",
  AddNewServices: "AddNewServices",
};

export const infoCloudSearch =
  "You can search (by numbers) by columns: Patient’s name, Patient’s Phone number, Specialty, Location, Doctor’s name.";

export const statusesTemplate = {
  final: "final",
  draft: "draft",
};
