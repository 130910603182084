import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Bell } from "../Common/Icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { secondsToDhms } from "../../utils/common";
import cn from "classnames";
import history from "../../helpers/history";
import { BTN_LABELS, routesCommon } from "../../constants";
import { routersDoctor } from "../../constants/Doctors";
import { routersPatient } from "../../constants/Patient";
import { fetchWrapper } from "../../helpers/helpers";
import {
  readNotification,
  readAllNotifications,
} from "../../store/notification/slice";
import { updateStatusMessageRequest } from "../../store/calendarEvent/slice";
import { setDisplayChat } from "../../store/app/slice";
import { ButtonV2 } from "../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../Common/ButtonV2/constants";
import { notificationsSelector } from "store/notification/selectors";

export const BlockNotifications = (props) => {
  const [showNotes, setShowNotes] = useState(false);
  const icon = useRef(null);
  const notifications = useSelector(notificationsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    const clickOnIcon = icon.current && icon.current.contains(e.target);
    if (!clickOnIcon) {
      setShowNotes(false);
    }
  };

  const handlerClick = (i) => {
    dispatch(setDisplayChat(true));
    dispatch(readNotification(i.payload.service_request_uuid));

    dispatch(
      updateStatusMessageRequest({
        mess: false,
        uuid: i.payload.service_request_uuid,
      }),
    );
    history.push({
      pathname:
        props.user === "doctor"
          ? `${routesCommon.doctor}${routersDoctor.schedule}`
          : `${routesCommon.patient}${routersPatient.details}/${i.payload.service_request_uuid}`,
      state: { service_request_uuid: i.payload.service_request_uuid },
    });
    setShowNotes(false);
  };

  const markAllAsReaded = () => {
    fetchWrapper({
      method: "PATCH",
      url: "notifications/mark-all-as-read/",
    }).then(() => {
      dispatch(updateStatusMessageRequest({ allRead: true }));
      dispatch(readAllNotifications());
    });
  };

  const activeBell = notifications?.find((i) => !i?.is_read);

  return (
    <div
      onClick={props.onClick}
      className="container-notifications"
      style={props.style}
      ref={icon}
    >
      <Bell
        active={activeBell}
        className={cn({ "container-notifications_active-bell": activeBell })}
        onClick={() => setShowNotes(!showNotes)}
      />
      {showNotes && (
        <div className="container-notifications_container">
          <div className="container-notifications_list">
            {notifications.map((i, index) => {
              let time = "Just now";
              const diff = moment().diff(moment(i.send_time), "seconds");
              if (diff > 60) time = secondsToDhms(diff);
              return (
                <div
                  key={index}
                  onClick={() => handlerClick(i)}
                  className={cn("container-notifications_list_item", {
                    "container-notifications_list_item_read": i.is_read,
                  })}
                >
                  <div className="container-notifications_list_item_header">
                    <div className="container-notifications_list_item_header_time">
                      {time}
                    </div>
                    <div className="container-notifications_list_item_header_status">
                      {i.is_read ? "read" : "unread"}
                    </div>
                  </div>
                  <div className="container-notifications_list_item_body">
                    You have a message from {i.payload.sender_full_name}.
                  </div>
                  <div className="container-notifications_list_item_footer">
                    <ButtonV2
                      uppercase
                      variant={VARIATIONS_BTN.TEXT}
                      color={COLORS_BTN.PRIMARY}
                    >
                      {" "}
                      {BTN_LABELS.VIEW_MESSAGE}
                    </ButtonV2>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="container-notifications_container_action">
            <ButtonV2
              onClick={markAllAsReaded}
              capitalize
              variant={VARIATIONS_BTN.CONTAINED}
              color={COLORS_BTN.DEFAULT}
            >
              {BTN_LABELS.MARK_ALL_AS_READ}
            </ButtonV2>
          </div>
        </div>
      )}
    </div>
  );
};
