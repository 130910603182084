import React from "react";
import "./style.scss";
import { IconButton } from "../../../../Common/IconButton";
import { SIZES_ICON_BUTTON } from "../../../../Common/IconButton/constants";

export const DeleteBox = ({ data, children, updateState }) => {
  if (!data.canDelete) {
    return children;
  }

  const onDeletePressed = () => {
    updateState(null);
  };
  return (
    <div className="emr-delete-box">
      {children}
      <IconButton
        onClick={onDeletePressed}
        className="emr-delete-box_delete"
        size={SIZES_ICON_BUTTON.SMALL}
      />
    </div>
  );
};
