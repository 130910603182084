import { useEffect, useRef, useState } from "react";
import { addFromCurrentInISO } from "./date";
import { updateRequestData } from "../store/request/actions";
import { useDispatch } from "react-redux";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value]);

  return debouncedValue;
}

const useCustomState = (initial = {}) => {
  const [state, setState] = useState(initial);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.patientForExamination) {
      changeState({ checkboxServices: [] });
    }
  }, [state.patientForExamination]);

  const changeState = (newState) => {
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const clearTimeInput = () => {
    setState((prev) => ({
      ...prev,
      inputMin: "",
      inputHour: "",
    }));
  };

  const onChangeTime = (newTime, data) => {
    return dispatch(
      updateRequestData(data.uuid, {
        doctor_should_arrive_at: addFromCurrentInISO(
          newTime >= 0 ? newTime : state.initialTime,
          "ms",
        ),
      }),
    ).then((resp) => {
      if (resp.uuid) {
        setState((prev) => ({
          ...prev,
          initialTime: newTime,
          isTimerEditorOpen: false,
          inputHour: "",
          inputMin: "",
          timeChanged: true,
        }));
      }
      return resp;
    });
  };

  const moveToPage = (activePage) => {
    setState((prev) => ({
      ...prev,
      activePage,
    }));
  };

  return {
    state,
    onChangeTime,
    clearTimeInput,
    moveToPage,
    changeState,
  };
};

const useMountedEffect = (callback, deps, didMountInitial = false) => {
  const didMountRef = useRef(didMountInitial);

  useEffect(() => {
    console.log("doctor_issue: doctors request useMountedEffect - useEffect");
    if (didMountRef.current) {
      console.log("doctor_issue: doctors request useMountedEffect - condition");
      return callback();
    }
    didMountRef.current = true;
  }, deps);
};

export { useDebounce, useCustomState, useMountedEffect };
