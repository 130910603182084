import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  timestamp: null,
};

const patientPaymentCardSlice = createSlice({
  name: "patientPaymentCardSlice",
  initialState,
  reducers: {
    setPatientPaymentCards: (state, { payload }) => {
      state.data = payload.data;
      state.timestamp = payload.timestamp;
    },
    clearPatientPaymentCardSlice: (state) => {
      state.data = [];
      state.timestamp = null;
    },
  },
});

export const { setPatientPaymentCards, clearPatientPaymentCardSlice } =
  patientPaymentCardSlice.actions;
export const patientPaymentCardReducer = patientPaymentCardSlice.reducer;
