import React from "react";
import { Cross } from "../Common/Icons";
import { BTN_LABELS } from "../../constants";
import "./style.scss";
import cn from "classnames";
import { COLORS_BTN } from "../Common/ButtonV2/constants";
import { ButtonV2 } from "../Common/ButtonV2";

export const PusherNotification = ({
  body,
  title,
  actionButton,
  classNameTitle,
}) => (
  <div className="custom-pusher-toaster">
    <div className="custom-pusher-toaster_container-info">
      <div className="custom-pusher-toaster_container-info_block-text">
        <div
          className={cn(
            "custom-pusher-toaster_container-info_block-text_title",
            classNameTitle,
          )}
        >
          <span>{title}</span>
          <Cross />
        </div>
        <div className="custom-pusher-toaster_container-info_block-text_text">
          {title ? body : `Welcome. ${body}`}
        </div>
      </div>
    </div>
    {actionButton && (
      <div className="custom-pusher-toaster_button-navigate">
        <ButtonV2 onClick={actionButton} color={COLORS_BTN.PRIMARY}>
          {BTN_LABELS.CLICK_HERE_FOR_UPDATE}
        </ButtonV2>
      </div>
    )}
  </div>
);
