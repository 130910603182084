import "firebase/analytics";
import history from "../../helpers/history";

export class GoogleAnalyticsService {
  constructor() {
    history.listen(() => {
      if (window.gtag) {
        window.gtag("event", "page_view", window.location.pathname);
      }
    });
  }

  track(event, payload) {
    if (window.gtag) {
      window.gtag("event", event, payload);
    }
  }
}
