import * as components from "./components";

export const template = {
  name: "Physical, General",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "comprehensive PE",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "quality and severity",
            data: "",
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: "who requests PE.",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Sleep:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Diet:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Exercise:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Work:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Stress level:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Spare time:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Health problems:",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "",
            data: "",
          },
        ],
      },
      {
        type: "label",
        data: "",
        items: [
          {
            type: "text",
            data: "Wears glasses and visits optometrist every year.",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            required: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis", "rash"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["scleral icterus"], true),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(
            components.RenderRadioSingleBLocks(["thyromegaly"], true),
          ),
          components.ChestRespiratory(),
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Extremities(),
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "DIAGNOSTICS ORDERED AND INTERPRETED BY ME:",
            canDelete: true,
            items: [
              {
                type: "text",
                data: "EKG",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      {
        type: "label",
        data: "COURSE/TREATMENT:",
        items: [
          {
            type: "text",
            data: "Patient's condition stable during evaluation.",
            editable: true,
            source: null,
          },
        ],
      },
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up consultation to be arranged by",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "patient" },
                  { checked: false, data: "caretaker" },
                  { checked: false, data: "me" },
                ],
              },
              {
                type: "text",
                data: "with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "specialist" },
                ],
              },
              {
                type: "text",
                data: "in",
                editable: true,
                source: null,
              },
              {
                type: "input-static-text",
                label: "",
                data: [{ data: "", mask: "999", min: 0, max: 365 }],
                deviders: [],
              },
              {
                type: "text",
                data: "days for further evaluation.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
