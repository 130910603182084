import React from "react";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { Typography } from "../../Common/Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import { buttonsInnerText } from "../../../constants";

export const PopupAddressFields = ({ isOpen, handleClose }) => {
  return (
    <PopupContainer
      open={isOpen}
      close={handleClose}
      textPrimary={buttonsInnerText.confirm}
      actionPrimaryButton={handleClose}
      uppercaseButton
      hideCancel
    >
      <div className="address-fields-verification">
        <Typography
          color={colors.grey}
          variant="p"
          text="Address is not correct. Please check and update."
        />
      </div>
    </PopupContainer>
  );
};
