import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../styles/App.scss";
import { Route, Switch } from "react-router-dom";
import { CreateRequest } from "./CreateRequest";
import history from "../helpers/history";
import { ToastContainer } from "react-toastify";
import { ProtectedApp } from "./ProtectedApp";
import { routesCommon } from "../constants";
import { LoaderPage } from "../components/LoaderPage";
import { Page404 } from "../components/404";
import { LoginForms } from "../components/LoginForms";

export class App extends Component {
  constructor(props) {
    super(props);
    window.localStorage.setItem("chats", "{}");
    window.localStorage.removeItem("requests");
    window.localStorage.removeItem("refresh_extra_url");
    window.localStorage.removeItem("listPatients");
    window.localStorage.removeItem("loadingTemp");
    if (props.location.pathname === "/") {
      window.open(routesCommon.landing);
    } else {
      history.push(this.props.location.pathname);
    }
  }

  render() {
    return (
      <Route path="/">
        <Switch>
          <Route
            path={`${routesCommon.login_form}/:user`}
            render={(params) => {
              return <LoginForms params={params} />;
            }}
          />
          <Route
            path={`${routesCommon.create_request}/:type`}
            render={() => <CreateRequest />}
          />
          <Route
            path={[
              routesCommon.admin,
              routesCommon.doctor,
              routesCommon.patient,
            ]}
            render={({ match: { url } }) => {
              return <ProtectedApp path={`${url}`} />;
            }}
          />
          <Route component={Page404} />
          <ToastContainer
            position="top-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            containerId="test"
            rtl={false}
            pauseOnVisibilityChange
            draggable
          />
        </Switch>
        <LoaderPage />
      </Route>
    );
  }
}
