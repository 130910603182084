import {
  FILE_FORMATS,
  REGEXP,
  TEN_MB_CONVER_TO_BYTES,
  visitNoteStatuses,
} from "../constants";
import {
  changeDateWithFormat,
  changeFormatForRequest,
  covertTime,
} from "./date";
import moment from "moment";
import { isLoading } from "../helpers/helpers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import fileDownload from "js-file-download";
import {
  configPMH,
  configPMHExtra,
} from "../components/DoctorFlow/Templates/constants";
import fetch from "isomorphic-fetch";

const lengthObj = (obj) => (obj ? Object.keys(obj).length : 0);

const filterObj = (obj) => {
  let newObj = {};
  for (let key in obj) {
    if (!!obj[key]) newObj[key] = obj[key];
  }
  return newObj;
};

const showFiltersValue = (obj) => {
  let filtersValue = "";
  let countFilters = -1;
  for (let key in obj) {
    if (obj[key].length) {
      if (countFilters === -1)
        filtersValue = obj[key].map((i) => i.label).join(", ");
      countFilters = countFilters + 1;
    }
  }
  if (filtersValue !== "" && !!countFilters)
    filtersValue = `${filtersValue}, +${countFilters}`;
  return filtersValue;
};

const checkFileFormat = (file) => {
  let format = file.path.split(".").reverse()[0].toLowerCase();
  return (
    FILE_FORMATS.find((el) => el === format) &&
    file.size < TEN_MB_CONVER_TO_BYTES
  );
};

const convertPhoneValue = (phone, convertToFE) => {
  let array = [];
  if (convertToFE) {
    array = phone.split("");
    array.splice(2, 0, " (");
    array.splice(6, 0, ") ");
    array.splice(10, 0, "-");

    array.splice(0, 3, "("); // when we will use phone other country it should be deleted
    return array.join("");
  } else return "+1" + phone.replace(/\s|_|-|\(|\)/g, "");
};

const convertExpiryDate = (value, convertToFE) => {
  if (!!value) {
    if (convertToFE) {
      const date = value.split("-");
      return new Date(+date[0], +date[1] - 1, +date[2]);
    } else return changeFormatForRequest(value);
  }
  return value;
};

const getFileName = (name, i = null) => {
  const nameArr = name.split("?")[0].split(".").reverse();
  return i ? nameArr[0] : nameArr[nameArr.length - 1];
};
const getArchivedName = (name) => {
  const arrName = name.split("?")[0].split(".").reverse();
  return `${arrName[1].split("/").reverse()[0]}.${arrName[0]}`;
};

const convertDate = (file) => ({
  ...file,
  file_type: file.file_type ? file.file_type : "N/A",
  file: getArchivedName(file.file),
  folder: true,
});

const convertArchiveFiles = (resp) => {
  let fill = {};

  resp.forEach((item) => {
    if (!fill[item.file_type]) {
      fill[item.file_type] = [convertDate(item)];
    } else {
      fill[item.file_type].push(convertDate(item));
    }
  });

  const defaultArr = [];

  for (let key in fill) {
    const labelobj = {
      file_type: key,
      expiry_date: "",
      uploaded_at: "",
      archive_date: "",
    };
    defaultArr.push(labelobj, ...fill[key]);
  }
  return defaultArr;
};

const convertingCommonField = (parameter, convertToFE, data) => ({
  field: convertToFE ? parameter[0] : parameter[1],
  value: data,
});

const converterDataField = (data, convertToFE) => {
  let convertingData = {};

  for (let key in data) {
    const converting = convertingField(key, convertToFE, data[key]);
    convertingData[converting.field] = converting.value;
  }
  return convertingData;
};

const convertingField = (field, convertToFE, data) => {
  if (fields.FN.find((i) => i === field))
    return convertingCommonField(fields.FN, convertToFE, data);
  else if (fields.LN.find((i) => i === field))
    return convertingCommonField(fields.LN, convertToFE, data);
  else if (fields.ES.find((i) => i === field))
    return convertingCommonField(fields.ES, convertToFE, data);
  else if (fields.DOB.find((i) => i === field))
    return {
      field: convertToFE ? fields.DOB[0] : fields.DOB[1],
      value: changeDateWithFormat(data, convertToFE),
    };
  else if (fields.PN.find((i) => i === field))
    return {
      field: convertToFE ? fields.PN[0] : fields.PN[1],
      value:
        typeof data === "string" ? convertPhoneValue(data, convertToFE) : data,
    };
  else if (fields.AD.find((i) => i === field)) {
    if (convertToFE)
      return { field: fields.AD[0], value: { address: data, latLng: null } };
    else return { field: fields.AD[1], value: data && data.address };
  } else if (fields.ST.find((i) => i === field)) {
    return convertToFE
      ? { field: fields.ST[0], value: data.toLowerCase() }
      : {
          field: fields.ST[1],
          value: `${data[0].toUpperCase()}${data.substr(1)}`,
        };
  } else if (fields.UP.find((i) => i === field)) {
    return convertingCommonField(fields.UP, convertToFE, data);
  } else if (fields.LC.find((i) => i === field)) {
    return convertingCommonField(fields.LC, convertToFE, data);
  } else if (fields.FT.find((i) => i === field)) {
    return convertingCommonField(fields.FT, convertToFE, data);
  } else if (fields.DC.find((i) => i === field)) {
    return convertingCommonField(fields.DC, convertToFE, data);
  } else if (fields.UT.find((i) => i === field)) {
    return convertingCommonField(fields.UT, convertToFE, data);
  } else if (fields.AS.find((i) => i === field)) {
    return convertingCommonField(fields.AS, convertToFE, data);
  } else if (fields.ED.find((i) => i === field)) {
    return {
      field: convertToFE ? fields.ED[0] : fields.ED[1],
      value: convertExpiryDate(data, convertToFE),
    };
  } else if (fields.RL.find((i) => i === field)) {
    return convertingCommonField(fields.RL, convertToFE, data);
  } else if (fields.AR.find((i) => i === field)) {
    return convertingCommonField(fields.AR, convertToFE, data);
  } else if (fields.MEMBER.find((i) => i === field)) {
    return convertingCommonField(fields.MEMBER, convertToFE, data);
  } else return { field: field, value: data };
};

const fields = {
  FN: ["firstName", "first_name"],
  LN: ["lastName", "last_name"],
  DOB: ["birthday", "date_of_birth"],
  PN: ["phone", "phone_number"],
  ES: ["employeeStatus", "employee_type"],
  LC: ["location", "region"],
  AD: ["address", "info_address_line"],
  ST: ["status", "validation_status"],
  UP: ["uploadApplications", "upload_applications"],

  FT: ["fileType", "file_type"],
  DC: ["doctorUuid", "doctor"],
  UT: ["uploadedAt", "uploaded_at"],
  AS: ["statusFile", "accepted_status"],
  ED: ["expiryDate", "expiry_date"],
  RL: ["relationship", "client_patient_relationship"],

  AR: ["archivedAt", "archived_at"],
  MEMBER: ["isMember", "is_member"],
};

const setFormatBE = (data) => {
  let obj = {};
  for (let key in data) {
    obj[key.replace(/([A-Z]+)/g, "_$1").toLowerCase()] = data[key];
  }
  return obj;
};

const setFormatFE = (data) => {
  let obj = {};
  for (let key in data) {
    obj[key.replace(/(_+[a-z])/g, (i) => i[1].toUpperCase())] = data[key];
  }
  return obj;
};

const convertResponseTime = (respObj) => {
  const timeArr = [
    "order_response_time",
    "request_to_door_time",
    "visit_duration",
  ];

  if (respObj.order_response_time !== undefined) {
    let responseTimeFE = { ...respObj };

    for (let key in respObj) {
      if (timeArr.find((i) => i === key)) {
        responseTimeFE[key] = respObj[key] ? covertTime(respObj[key]) : 0;
      }
    }
    return responseTimeFE;
  }
  return {};
};

const convertResponseTimeTable = (respArrTime) => {
  const timeArr = [
    "order_response_time",
    "patient_satisfaction_by_region",
    "patient_satisfaction_by_uplin",
    "visit_time",
    "road_time",
  ];

  return respArrTime.map((item) => {
    let responseTimeFE = { ...item };
    for (let key in item) {
      if (timeArr.find((i) => i === key) && item[key]) {
        responseTimeFE[key] = covertTime(item[key]);
      }
    }
    return responseTimeFE;
  });
};

const processBodyTemplate = (
  template,
  pmhDetailInfo,
  status,
  veradigmData,
  updatePrescriber,
  updateMed,
) => {
  const getTextData = (item) => {
    if (item.textData.length === pmhDetailInfo[item.type].length)
      return item.textData;
    return pmhDetailInfo[item.type].map((i) => ({ placeholder: "", data: "" }));
  };

  return template.map((i) => ({
    ...i,
    items: i.items.map((y) => {
      const config = configPMH[y.type];
      if (config) {
        const isMed = y.type === "medications";
        let extraParams = {};
        if (config?.hasOwnProperty("textAreaData")) {
          extraParams = {
            textAreaData: pmhDetailInfo[config.fieldDataTextArea],
          };
        }

        if (isMed && updateMed) {
          return {
            ...y,
            ...config,
            ...extraParams,
            textData: pmhDetailInfo[y.type].map((i) => ({
              placeholder: "",
              data: "",
            })),
            data: pmhDetailInfo[y.type],
          };
        }
        if (status) {
          let extraOptions = pmhDetailInfo[y.type].filter(
            (i) => !y.data.find((j) => j.value === i.value),
          );
          if (extraOptions.length)
            return {
              ...y,
              ...extraParams,
              textData: [
                ...y.textData,
                ...extraOptions.map((j) => ({ placeholder: "", data: "" })),
              ],
              data: [...y.data, ...extraOptions],
            };
          else return { ...y, ...config, ...extraParams };
        }
        return {
          ...y,
          ...config,
          textData: getTextData(y),
          data: pmhDetailInfo[y.type],
          ...extraParams,
        };
      } else if (configPMHExtra[y.type]) {
        if (veradigmData && y.type === "medicationsPrescribed") {
          if (veradigmData.length === 0 && y.data.filter((m) => m).length === 0)
            return { ...y, ...configPMHExtra[y.type] };
          let newData = {
            ...y,
            textData: veradigmData.map((j) => ({ placeholder: "", data: "" })),
            data: veradigmData.map((k) => ({
              ...k,
              value: k.name,
              label: k.name,
            })),
          };
          return newData;
        }
        return { ...y, ...configPMHExtra[y.type] };
      } else return y;
    }),
  }));
};

const isntNullish = (val) => !(val === undefined || val === null);
const removeLoadingPdf = () => isLoading(false, "note-loading");
const showPreview = (
  templateRef,
  noteView,
  modePreview = false,
  download = false,
  notHideLoading = false,
) => {
  isLoading(true, "note-loading");
  const elem = templateRef?.current ? templateRef.current : templateRef;
  var HTML_Width = elem.offsetWidth;
  var HTML_Height = elem.offsetHeight;
  var PDF_Width = 595;
  var PDF_Height = 842;
  var top_left_margin = 0;
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;

  var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  window.scrollTo(0, 0);
  return html2canvas(elem, {
    allowTaint: true,
    scrollX: 0,
    scrollY: 0,
    scale: 3,
  })
    .then((canvas) => {
      canvas.getContext("2d");

      var imgData = canvas.toDataURL("image/png", 1);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height,
        "",
        "FAST",
      );

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "PNG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height,
          "",
          "FAST",
        );
      }
      let blobPDF = new Blob([pdf.output("blob")], {
        type: "application/pdf",
      });

      let blobUrl = URL.createObjectURL(blobPDF);

      if (download) {
        pdf.save(`${noteView}.pdf`);
        return;
      }
      if (modePreview) window.open(blobUrl, "_blank");
      else return pdf.output("blob");
    })
    .finally(() => {
      !notHideLoading && isLoading(false, "note-loading");
    });
};

const toCapitalizeChart1 = (str) => {
  let strArr = str.split("");
  strArr[0] = strArr[0].toUpperCase();
  return strArr.join("");
};

const formatValueTime = (value) => {
  let viewValue = value;
  if (value === 0) viewValue = "00";
  else if (value > 0 && value < 10) viewValue = "0" + value;
  return viewValue;
};

const showChats = (request) => {
  return (
    visitNoteStatuses[request?.status?.toLowerCase()]?.availableChat &&
    moment().isBetween(
      moment(request?.service_request_chat?.start_at),
      moment(request?.service_request_chat?.expire_at),
    )
  );
};

const hideButton = (request) => {
  return (
    visitNoteStatuses[request?.status?.toLowerCase()]?.availableChat &&
    !!request?.service_request_chat
  );
};

const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day ago" : " days ago ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? "h ago" : " h ago") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min ago" : " min ago") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec ago" : " sec ago") : "";

  if (dDisplay) return dDisplay;
  if (hDisplay) return hDisplay;
  if (mDisplay) return mDisplay;
  if (sDisplay) return sDisplay;

  return dDisplay + hDisplay + mDisplay + sDisplay;
};

const arrayFromNumber = (number) => Array.from(Array(number).keys()) || [];

export {
  arrayFromNumber,
  lengthObj,
  filterObj,
  showFiltersValue,
  checkFileFormat,
  convertPhoneValue,
  convertExpiryDate,
  getFileName,
  getArchivedName,
  convertDate,
  convertArchiveFiles,
  convertingCommonField,
  converterDataField,
  convertingField,
  setFormatBE,
  setFormatFE,
  convertResponseTime,
  convertResponseTimeTable,
  processBodyTemplate,
  isntNullish,
  removeLoadingPdf,
  showPreview,
  toCapitalizeChart1,
  formatValueTime,
  showChats,
  hideButton,
  secondsToDhms,
};
