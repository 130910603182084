import React, { useEffect, useState } from "react";
import "./style.scss";
import chatImg from "../../dist/icons/chats.svg";
import chatImgPasive from "../../dist/icons/chatPassive.svg";
import { useSelector } from "react-redux";
import { ButtonV2 } from "../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../Common/ButtonV2/constants";
import { notificationsSelector } from "store/notification/selectors";

export const BtnChat = ({ onClick, chatIsAvailable, request }) => {
  const [hasUnread, setHasUnread] = useState(false);
  let notifications = useSelector(notificationsSelector);
  useEffect(() => {
    if (notifications?.length) {
      setHasUnread(
        !!notifications.find(
          (i) =>
            !i.is_read && i.payload?.service_request_uuid === request?.uuid,
        ),
      );
    }
  }, [JSON.stringify(notifications)]);
  if (!request.service_request_chat) return <></>;
  return (
    <>
      <ButtonV2
        onClick={onClick}
        className="button-chat"
        variant={VARIATIONS_BTN.CONTAINED}
        color={COLORS_BTN.DEFAULT}
      >
        <img src={hasUnread ? chatImg : chatImgPasive} alt="chat" />
        <span>{chatIsAvailable ? "Open chat" : "Review Chat History"}</span>
      </ButtonV2>
    </>
  );
};
