import React, { useEffect, useState } from "react";
import "./style.scss";
import { Textarea } from "../../Common/TextArea/Textarea";
import cn from "classnames";
import { DataItem } from "./DataItem";
import { PopupContainer } from "../../Common/Popup/PopupContainer";
import { SymptomsNotTreat } from "./SymptomsNotTreat";
import {
  extraSymptomsText,
  otherSymptomsText,
  steps,
} from "../../../constants";
import Analytics from "../../../utils/analytics/AnalyticsService";
import { EVENTS } from "../../../utils/analytics/Events";

export const ListSymptoms = ({
  selectSymptom,
  simpleUpdateState,
  selectOtherSymptoms,
  activePatient,
  selectedPatients,
  symptoms,
}) => {
  const selected =
    selectedPatients.find((i) => i.uuid === activePatient)?.selected || [];
  const otherSymptoms =
    selectedPatients.find((i) => i.uuid === activePatient)?.otherSymptoms || "";
  const [notificationSymptoms, setNotificationSymptoms] = useState(false);
  useEffect(() => {
    Analytics.track(EVENTS.Comprehensive_Symptoms_Page_Open);
    simpleUpdateState("activeStep", steps.Symptoms);
    if (activePatient === null) {
      simpleUpdateState("activePatient", selectedPatients[0].uuid);
    }
  }, []);

  const closeNotificationSymptoms = () => {
    setNotificationSymptoms(false);
  };

  const isFindOther = () => selected.find((i) => !i.uuid);

  const placeholderTextarea = isFindOther()
    ? otherSymptomsText
    : extraSymptomsText;

  const selectItem = (item) => {
    if (!item.uuid && !isFindOther()) setNotificationSymptoms(true);
    selectSymptom(item);
  };

  const setValueTextarea = (e) => {
    selectOtherSymptoms(e.target.value);
  };

  const disableChoose = symptoms?.length === 1;
  return (
    <>
      <div className="step-symptoms-list">
        <div
          className={cn("step-symptoms-list_wrapper", {
            "step-symptoms-list_wrapper_isTextarea": !!selected.length,
          })}
        >
          {symptoms.map((item, index) => {
            const onClick = () => {
              selectItem(item);
            };
            const select = !!selected.find((i) => item.uuid === i.uuid);
            return (
              <DataItem
                disableChoose={disableChoose}
                onClick={onClick}
                select={select}
                key={index}
                image={item.image}
                text={item.name}
              />
            );
          })}
        </div>
        <div className="step-symptoms-list_wrapper-other-symptoms">
          {!!selected.length && (
            <Textarea
              onBlur={() =>
                setValueTextarea({ target: { value: otherSymptoms?.trim() } })
              }
              placeholder={placeholderTextarea}
              value={otherSymptoms}
              onChange={setValueTextarea}
              maxLength={500}
              className="step-symptoms-list_wrapper-other-symptoms_textarea"
            />
          )}
        </div>
      </div>

      <PopupContainer
        textDefault=""
        textPrimary="Got it"
        open={notificationSymptoms}
        close={closeNotificationSymptoms}
        title="Please Note That The Doctors Do NOT Treat The Following Conditions:"
        actionPrimaryButton={closeNotificationSymptoms}
      >
        <SymptomsNotTreat />
      </PopupContainer>
    </>
  );
};
