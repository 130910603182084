import React, { useEffect } from "react";
import { Column, Table, AutoSizer } from "react-virtualized";
import "./style.scss";
import "react-virtualized/styles.css";
import cn from "classnames";
import { Typography } from "../Common/Typography/Typography";
import { colors } from "../../assets/colors/colors";

let isLoading = false;
export const CustomTable = ({
  loadTableData,
  columnsHeader,
  className,
  data,
  rowClassName,
  heightTable,
  rowHeight,
  actions,
  onRowClick,
  extraRowData,
}) => {
  const addData = () => {
    loadTableData && loadTableData();
  };
  useEffect(() => {
    isLoading = false;
  }, [data?.length]);

  return (
    <div style={{ height: heightTable }} className={cn(className)}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            onRowClick={onRowClick}
            actions={actions}
            extraRowData={extraRowData}
            rowClassName={rowClassName}
            width={width}
            height={height}
            headerHeight={80}
            noRowsRenderer={() => (
              <div className="placeholder-table-empty">
                <Typography
                  variant="h2"
                  color={colors.greyMiddle}
                  text="No result matches your criteria"
                />
              </div>
            )}
            rowHeight={rowHeight ? rowHeight : 44}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
              if (scrollHeight < scrollTop + clientHeight + 40 && !isLoading) {
                isLoading = true;
                addData();
              }
            }}
          >
            {columnsHeader.map((item, index) => {
              let cellOptions = {};
              if (item.customCell) {
                cellOptions.cellRenderer = item.customCell;
              }

              return (
                <Column
                  {...item}
                  key={index}
                  style={item.style}
                  {...cellOptions}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};
