import { fetchV2 } from "../../helpers/helpers";
import {
  setRequestDetails,
  clearRequestDetails,
  setFilesRequest,
} from "./slice";
import conversionBytes from "../../utils/conversionBytes";
import { mapPatients } from "./utils";
import { setLastRequestsOfPatient } from "../doctor/actions";
import { REQUEST_STATUS } from "./constants";

export const getDataRequest =
  ({ uuid, noClearStore }) =>
  (dispatch, getStore) => {
    if (!noClearStore) dispatch(clearRequestDetails());
    return dispatch(
      fetchV2({
        url: `service-requests/${uuid}/`,
      }),
    ).then((resp) => {
      const store = getStore();
      dispatch(
        setRequestDetails({
          timestamp: resp.timestamp,
          data: mapPatients({
            request: resp.data,
            requestsTypes: store.serviceRequestTypes.data,
          }),
        }),
      );
      return resp;
    });
  };

export const completeRequest =
  (files, targetRequest, dataRequest, listRequests) => (dispatch, getStore) =>
    dispatch(
      fetchV2(
        {
          url: `doctors/service-requests/${targetRequest.uuid}/request-complete/`,
          method: "PUT",
          body: files,
        },
        true,
      ),
    ).then((resp) => {
      if (resp.timestamp) {
        const store = getStore();
        const newData = mapPatients({
          request: resp.data,
          requestsTypes: store.serviceRequestTypes.data,
        });
        dispatch(
          setRequestDetails({
            timestamp: resp.timestamp,
            data: newData,
          }),
        );
        dispatch(
          setLastRequestsOfPatient(
            listRequests.map((i) =>
              i.service_request_uuid === targetRequest?.uuid
                ? {
                    ...i,
                    status: REQUEST_STATUS.COMPLETED,
                  }
                : i,
            ),
          ),
        );
      } else throw "request has not completed";
    });

export const updateRequestData = (uuid, body) => (dispatch, getStore) =>
  dispatch(
    fetchV2({
      url: `doctors/service-requests/${uuid}/update/`,
      method: "PATCH",
      body,
    }),
  ).then((resp) => {
    if (resp.data?.uuid) {
      const store = getStore();
      dispatch(
        setRequestDetails({
          timestamp: resp.timestamp,
          data: mapPatients({
            request: resp.data,
            requestsTypes: store.serviceRequestTypes.data,
          }),
        }),
      );
    }
    return resp;
  });

export const changeRequestDetails =
  (field, uuid, value) => (dispatch, getStore) => {
    const store = getStore();
    const details = store.requestDetailsReducer.details;
    const idx = details.data.patients.findIndex((i) => i.uuid === uuid);
    dispatch(
      setRequestDetails({
        ...details,
        data: {
          ...details.data,
          patients: [
            ...details.data.patients.slice(0, idx),
            { ...details.data.patients[idx], [field]: value },
            ...details.data.patients.slice(idx + 1),
          ],
        },
      }),
    );
  };

export const updateRequestDetailsPatients =
  ({ uuid, body }) =>
  (dispatch) => {
    return dispatch(
      fetchV2({
        method: "PATCH",
        url: `service-requests/update-request-patients/${uuid}/`,
        body: body,
      }),
    );
  };

export const updateRequestDetailsCommonInfo =
  ({ uuid, body }) =>
  (dispatch) => {
    return dispatch(
      fetchV2({
        method: "PATCH",
        url: `service-requests/update-request-info/${uuid}/`,
        body: body,
      }),
    );
  };

export const getFilesRequest =
  ({ id }) =>
  (dispatch) =>
    dispatch(
      fetchV2({
        url: `patients/emr-documents/${id}/`,
        method: "GET",
      }),
    ).then((resp) => {
      if (resp?.data?.length) {
        const arr = resp.data.map((i) => {
          let data = [];
          const docs = i.documents;
          if (docs?.discharge_file)
            data.push({
              ...docs.discharge_file,
              name: "Discharge Summary.pdf",
            });
          if (docs?.receipt_file) data.push(docs.receipt_file);
          if (docs?.excuse_file) data.push(docs.excuse_file);
          if (docs?.covid_test_result_file)
            data.push({
              ...docs.covid_test_result_file,
              name: "Covid Test Result.pdf",
            });
          if (docs?.follow_up_files && Array.isArray(docs?.follow_up_files))
            data = [
              ...data,
              ...docs.follow_up_files?.map((i) => ({
                ...i,
                name: i.name?.replace(/_/g, " "),
              })),
            ];
          return {
            ...i,
            documents: data.map((i) => ({
              ...i,
              size: conversionBytes(i.size),
            })),
          };
        });
        dispatch(setFilesRequest({ timestamp: resp.timestamp, data: arr }));
      }
    });
