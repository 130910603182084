import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const relationSlice = createSlice({
  name: "relationSlice",
  initialState,
  reducers: {
    setRelations: (state, { payload }) => {
      state.data = payload;
    },
    clearRelationSlice: (state) => {
      state.data = [];
    },
  },
});

export const { setRelations, clearRelationSlice } = relationSlice.actions;
export const relationReducer = relationSlice.reducer;
