import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/App.scss";
import "../styles/CresteReuest.scss";
import { LeftPanel, RightPanel } from "../components/CreateReqest";
import { HeaderPanel } from "../components/CreateReqest/HeaderPanel";
import cn from "classnames";
import { buttonsInnerText, routesCommon, steps } from "../constants";
import { getCurrentUser } from "../store/currentUser/actions";
import { getServiceRequestTypes } from "../store/serviceRequestTypes/actions";
import history from "../helpers/history";
import { PopupContainer } from "../components/Common/Popup/PopupContainer";
import moment from "moment";
import { filterObj, lengthObj } from "../utils/common";
import { validationIsEmpty } from "../utils/validation";
import { getRelations } from "../store/relation/actions";
import { useRouter } from "../utils/useRouter";
import { serviceRequestTypesSelector } from "../store/serviceRequestTypes/selectors";
import { createPatient, createRequest } from "../store/createRequest/actions";
import { currentUserSelector } from "../store/currentUser/selectors";
import { patientPaymentCardsSelector } from "../store/patientPaymentCard/selectors";
import { USER_ROLES } from "../store/currentUser/constants";

export const CreateRequest = (props) => {
  const { query } = useRouter();
  const [state, setState] = useState({
    activeStep: 0,
    activeButtonTime: "",
    dateVisiting: moment().toDate(),
    timeVisiting: null,
    selectedPatients: [],
    address: {},
    createPatientMode: false,
    openConfirmation: false,
    user_mobile: "",
    user_uuid: "",
    patientsAmount: 0,
    activePatient: null,
    needRebuild: false,
    hideSteps: false,
    hideStepsHeader: false,
    user: {},
    mobileVerification: false,
    requestType: query.type,
  });

  const types = useSelector(serviceRequestTypesSelector);
  const currentUser = useSelector(currentUserSelector);
  const listPatients = useSelector((store) => store.request.patients);
  const paymentCards = useSelector(patientPaymentCardsSelector);
  const currentRequestData = types.find((i) => i.name === state.requestType);
  const symptoms = currentRequestData?.symptoms || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceRequestTypes());
    handleResize(window);
    if (!!window.localStorage.user_uuid) {
      dispatch(getCurrentUser(USER_ROLES.PATIENT)).then((resp) => {
        const essentialData = {
          firstName: resp.firstName,
          lastName: resp.lastName,
          birthday: resp.birthday,
          phone: resp.phone,
          sex: resp.sex,
          email: resp.email,
        };
        if (!!lengthObj(filterObj(validationIsEmpty(essentialData)))) {
          moveToTheStep(steps.FillInfo);
        }
        simpleUpdateState("user", resp);
        dispatch(getRelations());
      });
    } else {
      history.push(`${routesCommon.login_form}/patient`, {
        type: query.type,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = (e) => {
    let width;
    if (e.currentTarget) {
      width = e.currentTarget.innerWidth;
    } else {
      width = e.innerWidth;
    }
    if (width > 1200) {
      setState((prev) => ({ ...prev, needRebuild: false }));
    } else if (width <= 1200) {
      setState((prev) => ({ ...prev, needRebuild: true }));
    }
  };

  const clickSymptomsDefault = () => {
    setState((prev) => ({
      ...prev,
      openConfirmation: true,
    }));
  };

  const selectSymptom = (item) => {
    if (state.activePatient) {
      const patientInd = state.selectedPatients.findIndex(
        (i) => i.uuid === state.activePatient,
      );
      const patientWithSymptoms = !!state.selectedPatients[
        patientInd
      ].selected.find((i) => i.uuid === item.uuid)
        ? {
            ...state.selectedPatients[patientInd],
            selected: state.selectedPatients[patientInd].selected.filter(
              (i) => i.uuid !== item.uuid,
            ),
          }
        : {
            ...state.selectedPatients[patientInd],
            selected: [...state.selectedPatients[patientInd].selected, item],
          };
      if (!patientWithSymptoms.selected.length) {
        patientWithSymptoms.otherSymptoms = "";
      }
      setState((prev) => ({
        ...prev,
        selectedPatients: [
          ...prev.selectedPatients.slice(0, patientInd),
          patientWithSymptoms,
          ...prev.selectedPatients.slice(patientInd + 1),
        ],
      }));
    }
  };

  const selectOtherSymptoms = (text) => {
    const patientInd = state.selectedPatients.findIndex(
      (i) => i.uuid === state.activePatient,
    );
    const patientWithOtherSymptoms = {
      ...state.selectedPatients[patientInd],
      otherSymptoms: text,
    };
    setState((prev) => ({
      ...prev,
      selectedPatients: [
        ...prev.selectedPatients.slice(0, patientInd),
        patientWithOtherSymptoms,
        ...prev.selectedPatients.slice(patientInd + 1),
      ],
    }));
  };

  const simpleUpdateState = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const actionNextStep = () => {
    setState((prev) => ({
      ...prev,
      activeStep: prev.activeStep === 7 ? prev.activeStep : prev.activeStep + 1,
    }));
  };

  const moveToTheStep = (step) => {
    setState((prev) => ({
      ...prev,
      activeStep: step,
    }));
  };

  const actionPrevStep = () => {
    setState((prev) => ({
      ...prev,
      activeStep: prev.activeStep - 1,
    }));
  };

  const closeConfirmation = () =>
    setState((prev) => ({
      ...prev,
      openConfirmation: false,
    }));

  const closeRequest = () => {
    setState((prev) => ({
      ...prev,
      openConfirmation: false,
    }));
    if (window.localStorage.user_uuid) {
      history.push(routesCommon.patient);
    } else {
      window.location.href = routesCommon.landing;
    }
  };

  if (!lengthObj(state.user)) {
    return <></>;
  } else {
    let additionalProps = {
      userData: currentUser,
      listPatients: listPatients,
      cards: paymentCards,
      getCurrentUser: (...args) => dispatch(getCurrentUser(...args)),
      createPatient: (...args) => dispatch(createPatient(...args)),
      createRequest: (...args) => dispatch(createRequest(...args)),
    };

    return (
      <div className="app-container-request">
        {!state.hideStepsHeader && (
          <HeaderPanel
            mobileVerification={state.mobileVerification}
            simpleUpdateState={simpleUpdateState}
            createPatientMode={state.createPatientMode}
            activeStep={state.activeStep}
            location={props.location}
            hideSteps={state.hideSteps || state.hideStepsHeader}
            actionPrevStep={actionPrevStep}
            moveToTheStep={moveToTheStep}
            needRebuild={state.needRebuild}
          />
        )}
        <div className={cn("app-container-request_wrapper")}>
          <LeftPanel
            {...state}
            {...props}
            {...additionalProps}
            simpleUpdateState={simpleUpdateState}
            selectOtherSymptoms={selectOtherSymptoms}
            clickSymptomsDefault={clickSymptomsDefault}
          />
          <RightPanel
            {...state}
            {...props}
            {...additionalProps}
            currentRequestData={currentRequestData}
            requestTypes={types}
            symptoms={symptoms}
            hideSteps={state.hideSteps || state.hideStepsHeader}
            path={routesCommon.create_request}
            simpleUpdateState={simpleUpdateState}
            selectSymptom={selectSymptom}
            actionPrevStep={actionPrevStep}
            actionNextStep={actionNextStep}
            moveToTheStep={moveToTheStep}
            selectOtherSymptoms={selectOtherSymptoms}
            clickSymptomsDefault={clickSymptomsDefault}
          />
        </div>
        <PopupContainer
          close={closeConfirmation}
          open={state.openConfirmation}
          textPrimary={buttonsInnerText.confirm}
          actionDefaultButton={closeConfirmation}
          actionPrimaryButton={closeRequest}
          uppercaseButton
          title="Are you sure you want to close the visit?"
          className="app-container-request_confirm-message"
        />
      </div>
    );
  }
};
