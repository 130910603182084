import React, { useMemo, useState } from "react";
import { Typography } from "../../Common/Typography/Typography";
// import Timer from "react-compound-timer";
import cn from "classnames";
import { ASSIGNED } from "../../../constants";
import moment from "moment";
import { durationTimeLaterRequest } from "../../../utils/date";
import { formatValueTime } from "../../../utils/common";
import { Edit } from "components/Common/Icons";
import { createTimeModel, useTimeModel } from "react-compound-timer";

export const ApproximateEta = ({
  initialTime,
  data,
  editable,
  openTimerEditor,
  showWhenEdit,
  isTimerEditorOpen,
  isFromEmr,
  className,
}) => {
  const [openTimer, setOpenTimer] = useState(true);
  const [timeZero, setTimeZero] = useState(false);

  const timeLateCondition =
    !openTimer &&
    (data.status === ASSIGNED || data.status === "pending") &&
    moment(data.application_can_start_at) > moment() &&
    !timeZero;

  return (
    <div className="approximate-eta-container">
      {(!isTimerEditorOpen || showWhenEdit) && !!initialTime && (
        <Timer
          className={className}
          editable={editable}
          isFromEmr={isFromEmr}
          initialTime={initialTime}
          setTimeZero={setTimeZero}
          setOpenTimer={setOpenTimer}
          openTimerEditor={openTimerEditor}
        />
      )}
      {timeLateCondition && data && (
        <TimeVisit data={data} className={className} />
      )}
    </div>
  );
};

const Timer = ({
  editable,
  isFromEmr,
  initialTime,
  setOpenTimer,
  setTimeZero,
  openTimerEditor,
  className,
}) => {
  let timer = useMemo(
    () =>
      createTimeModel({
        initialTime: initialTime,
        direction: "backward",
        checkpoints: [
          {
            time: 0,
            callback: () => {
              setTimeZero(true);
              setOpenTimer(false);
            },
          },
        ],
      }),
    [initialTime],
  );

  const { value } = useTimeModel(timer);
  return (
    <div className={cn("approximate-eta", className)}>
      <Typography text="Approximate ETA" variant="span" />
      <div className="approximate-eta_container">
        <span className="approximate-eta_time">
          <span>{formatValueTime(value.h)}</span>
          <Typography
            text="H"
            variant="p"
            className="approximate-eta_time__unit"
          >
            H
          </Typography>
          <span>{formatValueTime(value.m)}</span>
          <Typography
            text="min"
            variant="p"
            className="approximate-eta_time__unit"
          >
            min
          </Typography>
          <span>{formatValueTime(value.s)}</span>
          <Typography
            text="sec"
            variant="p"
            className="approximate-eta_time__unit"
          >
            sec
          </Typography>
        </span>
      </div>
      {editable && !isFromEmr && (
        <div className="approximate-eta__edit-button">
          <Edit onClick={openTimerEditor} />
        </div>
      )}
    </div>
  );
};

const TimeVisit = ({ data, className }) => {
  const timeLaterRequest =
    data &&
    durationTimeLaterRequest(
      data.application_can_start_at,
      data.application_time,
    );
  return (
    <div className={cn("approximate-eta", className)}>
      <Typography
        text={moment(data.application_can_start_at).format("ll").slice(0, 6)}
        variant="span"
      />
      <div className="approximate-eta_container-extra">
        <span className="approximate-eta_time">
          {`${timeLaterRequest.timeOfStart} - ${timeLaterRequest.timeOfEnd}`}
        </span>
        <Typography
          text={timeLaterRequest.periodOfEnd.toLowerCase()}
          variant="span"
        />
      </div>
    </div>
  );
};
