import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const doctorsSlice = createSlice({
  name: "doctorsSlice",
  initialState,
  reducers: {
    setDoctorsData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { setDoctorsData } = doctorsSlice.actions;
export const doctorsReducer = doctorsSlice.reducer;
