import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector } from "react-redux";
import { InputAddress } from "../../Common/Input/InputAddress";
import { DropDown } from "../../Common/DropDown/DropDown";
import {
  placeholdersMultipleVisit,
  placeholdersAddress,
  PLACEHOLDERS,
} from "../../../constants";
import { Input } from "../../Common/Input/Input";
import { InfoCloud } from "../../Common/InfoCloud/InfoCloud";
import { PopupZipcode } from "../../CreateReqest/StepRequestDataInfo/PopupZipcode";
import { SingleDatePicker } from "../../Common/SingleDatePicker/SingleDatePicker";
import { getDoctorsForMultiple } from "../../../store/admin/actions";
import {
  validationDropdown,
  validationIsEmpty,
  validationZipCodeRegex,
  validateTimePeriod,
} from "../../../utils/validation";
import { validationZipCode } from "../../../store/createRequest/actions";
import { filterObj, lengthObj } from "../../../utils/common";
import "./stlye.scss";
import moment from "moment";
import { serviceRequestTypesSelector } from "../../../store/serviceRequestTypes/selectors";
import { TimeSlot } from "../../Common/TimeSlot";

const initialState = {
  zipcode: "",
  address: "",
  apartment: "",
  startTime: "",
  endTime: "",
  date: "",
  comment: "",
};

export const VisitDetails = forwardRef((props, ref) => {
  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const types = useSelector(serviceRequestTypesSelector);

  useEffect(() => {
    getDoctorsForMultiple().then((data) =>
      setDoctors(
        data.results.map((item) => ({
          value: item.uuid,
          label: `${item.first_name} ${item.last_name}`,
        })),
      ),
    );
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getVisitDetails: saveDetails,
    };
  });

  const formatted = types
    ?.filter((type) => type.is_admin_visible)
    .map((item) => ({
      value: item.name,
      label: item.viewLabel,
    }));

  const validation = async () => {
    const errors = validationIsEmpty(state);
    errors.zipcode = validationZipCodeRegex(state.zipcode);
    delete errors.apartment;
    delete errors.comment;
    if (!errors.zipcode) {
      const isValid = await validationZipCode(state.zipcode);
      if (!isValid) {
        setShowPopup(true);
        errors.zipcode = " ";
      }
    }
    errors.assigned = errors.assigned || validationDropdown(state.assigned);
    errors.type = errors.type || validationDropdown(state.type);
    errors.startTime =
      errors.startTime ||
      validateTimePeriod(state.startTime?.value, state.endTime?.value);
    return filterObj(errors);
  };

  const saveDetails = async () => {
    const errors = await validation();
    if (!lengthObj(errors)) {
      return state;
    } else {
      setErrors(errors);
    }
  };

  const closePopup = () => setShowPopup(false);

  const updateState = (field, value, zipCode) => {
    const newState = { ...state, [field]: value };
    const errorsState = { ...errors };
    if (zipCode !== undefined) {
      newState.zipcode = zipCode;
    }
    if (field === "address") {
      errorsState.zipcode = false;
    }
    setState(newState);
    setErrors({ ...errorsState, [field]: false });
  };

  return (
    <div className="visit-details">
      <div className="visit-details__row">
        <DropDown
          value={state.assigned}
          field="assigned"
          handlerSelect={updateState}
          label={placeholdersMultipleVisit.assignTo}
          options={doctors}
          error={errors["assigned"]}
        />
        <DropDown
          value={state.type}
          field="type"
          handlerSelect={updateState}
          label={placeholdersMultipleVisit.type}
          options={formatted}
          error={errors["type"]}
        />
      </div>
      <div className="visit-details__row">
        <SingleDatePicker
          classNameContainer="visit-details__row_date-picker"
          label={PLACEHOLDERS.DATE}
          popperPlacement="top"
          selected={state.date}
          minDate={moment().add(-3, "months").toDate()}
          onChange={(value) => updateState("date", value)}
          maxDate={moment().add(3, "months").toDate()}
          error={errors["date"]}
        />
        <TimeSlot
          updateState={updateState}
          error={errors.startTime || errors.endTime}
          timeStart={state?.startTime}
          timeFinish={state?.endTime}
        />
      </div>
      <div className="visit-details__row">
        <InputAddress
          checkZipcode
          placeholder={placeholdersAddress.address}
          error={errors["address"]}
          field="address"
          value={state.address}
          onChange={updateState}
        />
        <InfoCloud
          top
          dangerouslySetInnerHTML={`Zip Code is added based on address`}
        >
          <Input
            disable
            type="number"
            placeholder={placeholdersAddress.zipcode}
            value={state.zipcode}
            error={errors["zipcode"]}
            field="zipcode"
            maxLength="5"
            onChange={updateState}
          />
        </InfoCloud>
        <PopupZipcode
          open={showPopup}
          close={closePopup}
          emailUser={""}
          zip={state.zipcode}
        />
      </div>
      <div className="visit-details__row half-width">
        <Input
          type="noSign"
          placeholder={placeholdersAddress.apartment}
          className=""
          value={state.apartment}
          field="apartment"
          maxLength="12"
          onChange={updateState}
        />
      </div>
    </div>
  );
});
