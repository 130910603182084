import React from "react";
import "./style.scss";
import { PickerDate } from "../DateTimePicker/DatePicker";
import cn from "classnames";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";
import moment from "moment";
export const SingleDatePicker = ({
  className,
  selected,
  minDate = moment().toDate(),
  maxDate,
  onChange,
  label,
  error,
  disabled,
  classNameContainer,
  ...other
}) => {
  return (
    <div className={classNameContainer}>
      <div
        className={cn(
          "single-date-picker",
          className,
          {
            "single-date-picker_error-container": error,
          },
          { "single-date-picker_disabled": disabled },
        )}
      >
        {label && <div className="single-date-picker_label">{label}</div>}
        <PickerDate
          disabled={disabled}
          className={className}
          popperPlacement="top"
          selected={selected}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          {...other}
        />
      </div>
      {error && (
        <Typography
          variant="h5"
          text={error}
          color={colors.redError}
          className="single-date-picker_error"
        />
      )}
    </div>
  );
};
