import * as components from "./components";

export const template = {
  name: "Pharyngitis (child)",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Sore throat",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "whose caretaker reports the child has",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: "history of sore throat",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: true, data: "fever" },
              { checked: false, data: "abdominal" },
            ],
          },
          {
            type: "text",
            data: "pain. Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "cough" },
              { checked: false, data: "vomiting" },
              { checked: false, data: "alteration of activity" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          {
            type: "label",
            data: "Generalized Appearance:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Alert,",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "smilling" },
                  { checked: false, data: "crying" },
                  { checked: false, data: "playful" },
                ],
              },
              {
                type: "text",
                data: "child, in",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                  { checked: false, data: "severe" },
                ],
              },
              {
                type: "text",
                data: "distress.",
                editable: true,
                source: null,
              },
            ],
          },
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            required: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis", "rash"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["scleral icterus"], true),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "TMs:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["erythema"]),
              {
                type: "text",
                data: ". Pharynx:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tonsillar erythema",
                "tonsillar exudate",
                "deviation of uvula",
              ]),
              {
                type: "text",
                data: ". Able to swallow saliva. Airway patent:",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "air hunger",
                "stridor",
                "hoarseness",
              ]),
              {
                type: "text",
                data: ". Mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neck:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "stiffness",
                "meningismus",
              ]),
              {
                type: "text",
                data: ", with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "no" },
                  { checked: false, data: "anterior" },
                  { checked: false, data: "posterior" },
                ],
              },
              {
                type: "text",
                data: "lymphadenopathy.",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "retractions" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rales" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "rhonchi" }],
              },
              {
                type: "text",
                data: ",",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "wheezes" }],
              },
              {
                type: "text",
                data: ";",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          components.HeartCardiovascular(),
          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "splenomegaly",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "deformity" }],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above; interacts appropriately for age. Strength and tone good.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.TestPerformed({
        items: [
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "None" }],
              hiddenBlock: [],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Covid 19 test -" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Negative" },
                    { checked: false, data: "Positive" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Strep test:" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Negative" },
                    { checked: false, data: "Positive" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: "Influenza A and B test:" }],
              hiddenBlock: [
                {
                  type: "radio-block",
                  data: [
                    { checked: true, data: "Negative" },
                    { checked: false, data: "Positive for ___" },
                  ],
                },
              ],
            },
          },
          {
            type: "blockCheckboxCondition",
            data: {
              checkbox: [{ checked: false, data: ":" }],
              hiddenBlock: [
                {
                  type: "input",
                  placeholder: "",
                  data: "",
                },
              ],
            },
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Follow up with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: true, data: "PCP" },
                  { checked: false, data: "ENT" },
                ],
              },
              {
                type: "text",
                data: "specialist as directed. Please go to nearest ED in case you develop dyspnea.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
