import React, { Component } from "react";
import { configPMH, updateArrJson } from "./constants";
import "./style.scss";
import _ from "lodash";
import { Eye } from "../../Common/Icons";
import { BTN_LABELS } from "../../../constants";
import cn from "classnames";
import {
  getMedicationFromVeradigm,
  getTemplateCommon,
} from "../../../store/doctor/actions";
import {
  lengthObj,
  processBodyTemplate,
  setFormatBE,
  showPreview,
} from "../../../utils/common";
import { Element } from "./chooserElements";
import { modesTemplate } from "../../../constants/Doctors";
import { GenerationPDFnotes } from "../GenerationPDFnotes/GenerationPDFnotes";
import { toast } from "react-toastify";
import { getSingleFromGroupRequest } from "../../../store/request/utils";
import history from "../../../helpers/history";
import { ButtonV2 } from "../../Common/ButtonV2";
import { COLORS_BTN, VARIATIONS_BTN } from "../../Common/ButtonV2/constants";
import { DeleteBox } from "./Components/DeleteBox";

export default class Templates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jsonTree: null,
      modePreview: false,
      errors: {},
      isLoading: false,
    };

    if (props.role === "admin") history.back();
  }

  componentDidMount() {
    this.setJsonTree(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setJsonTree(nextProps);
  }

  setJsonTree = (props) => {
    const patientUuid = getSingleFromGroupRequest({
      request: this.props.dataRequest,
      uuid: this.props.idRequest,
    })?.patient_uuid;
    if (
      !!props.pmhDetailInfo?.updatedAt &&
      this.props.pmhDetailInfo?.updatedAt !== props.pmhDetailInfo?.updatedAt &&
      this.state.jsonTree
    ) {
      getMedicationFromVeradigm(this.props.idRequest).then((data) => {
        if (Array.isArray(data)) {
          this.props
            .getUpdatePMHinfo(
              patientUuid,
              null,
              false,
              this.props.templateData?.uuid,
            )
            .then((resp) => {
              this.setState({
                jsonTree: processBodyTemplate(
                  this.state.jsonTree,
                  resp,
                  true,
                  data,
                  true,
                  true,
                ),
              });
            });
        }
      });
    }
    if (
      !this.state.jsonTree &&
      props.pmhDetailInfo &&
      !this.state.isLoading &&
      !window.localStorage.loadingTemp
    ) {
      window.localStorage.setItem("loadingTemp", true);
      this.setState({ isLoading: true }, () => {
        const action = this.props.templateData
          ? () => Promise.resolve(this.props.templateData.body)
          : () => getTemplateCommon(this.props.templateId);

        Promise.all([
          this.props.getDbDataTemplate(this.props.idRequest),
          action().then((template) => {
            return this.props
              .getUpdatePMHinfo(
                patientUuid,
                null,
                false,
                this.props.templateData?.uuid,
              )
              .then((resp) => {
                window.localStorage.removeItem("loadingTemp");
                this.setState(
                  {
                    jsonTree: this.props.templateData?.body
                      ? this.props.templateData?.body.map((i) => ({
                          ...i,
                          items: i.items.map((y) => {
                            const config = configPMH[y.type];
                            if (config?.hasOwnProperty("textAreaData")) {
                              return {
                                ...y,
                                textAreaData: resp[config.fieldDataTextArea],
                              };
                            }
                            return y;
                          }),
                        }))
                      : processBodyTemplate(template, resp),
                  },
                  () => {
                    if (!this.props.templateData) {
                      this.saveTemplate("draft", true);
                    }
                  },
                );
              });
          }),
        ]);
      });
    }
  };

  showPreviewPdf = () => {
    let elem = document.getElementById(`pdf-prewie-note`);
    showPreview(elem, "note", true).then(() => {
      this.setState({
        modePreview: false,
      });
    });
  };

  updateState = (newData, idxChild, idxParent) => {
    this.setState({
      jsonTree: updateArrJson({
        newData,
        idxChild,
        idxParent,
        jsonTree: this.state.jsonTree,
      }),
    });
  };

  deleteBlock = ({ data, idxItem }) => {
    const newErrors = { ...this.state.errors };
    const targetBlock = this.state.jsonTree.find((i, idx) => idx === idxItem);
    if (!!targetBlock) {
      targetBlock.items.forEach((i) => delete newErrors[i.data]);
      delete newErrors[targetBlock.data];
    }
    this.setState({
      jsonTree: this.state.jsonTree.map((i, idx) =>
        idx === idxItem ? data : i,
      ),
      errors: newErrors,
    });
  };

  saveTemplate = (status, noNavigate) => {
    const patientUuid = getSingleFromGroupRequest({
      request: this.props.dataRequest,
      uuid: this.props.idRequest,
    })?.patient_uuid;

    this.props
      .getUpdatePMHinfo(patientUuid, null, false, this.props.templateData?.uuid)
      .then((resp) => {
        let hasUpdated = false;
        if (this.props.pmhDetailInfo?.updatedAt !== resp?.updatedAt)
          hasUpdated = true;

        const funcCheckError = ({ parent, child }) => {
          let processedData = Element({
            data: child,
            mode: modesTemplate.check,
            DbData: this.props.dbDataTemplate,
            type: child.type,
          });
          if (child.hasOwnProperty("isRequired") && !child.isRequired) {
            processedData.error = false;
          }
          if (child.assessmentPlanKey) {
            processedData.data = {
              assessmentsPlanExtra: {
                ...(dataRequiredFields.assessmentsPlanExtra || {}),
                [child.assessmentPlanKey]: Element({
                  data: { ...child, isJson: true },
                  mode: modesTemplate.preview,
                  DbData: this.props.dbDataTemplate,
                  type: child.type,
                }),
              },
              ...(processedData.data || {}),
            };
          }
          if (processedData.error)
            errorValidation[parent.data] = processedData.error;
          if (processedData.data) {
            dataRequiredFields = {
              ...dataRequiredFields,
              ...processedData.data,
            };
          }
          return processedData;
        };
        let body;
        let dataPMH = {};
        let errorValidation = {};
        let dataRequiredFields = {};
        let dataRequiredFieldsBody = {};
        const isFinal = status === "final";
        const processJson = (errorCheck) =>
          JSON.parse(JSON.stringify(this.state.jsonTree)).map((i) => {
            const isParent = i.parent;
            let isError = false;
            let items = i.items.map((y) => {
              let item = y;
              if (isParent && i.isDelete) return item;
              if (configPMH[y.type]) {
                y.data = y.data.filter((i) => i);
              }

              let processedData = {};
              const isNeedCheck = i.required && errorCheck;
              if (isNeedCheck && !isParent) {
                processedData = funcCheckError({ child: y, parent: i });
              } else if (y.required && errorCheck && isParent) {
                processedData = y.items.map((k) =>
                  funcCheckError({ child: k, parent: y }),
                );
                if (!isError) {
                  isError = !!processedData.find((n) => n.error);
                  if (isError) errorValidation[i.data] = true;
                }
              }

              if (y?.hasOwnProperty("textAreaData")) {
                dataPMH = { ...dataPMH, [y.fieldDataTextArea]: y.textAreaData };
              }
              if (configPMH[y.type] && !processedData.error && hasUpdated) {
                let extraOptions = resp[y.type].filter(
                  (i) => !y.data.find((j) => j.value === i.value),
                );
                item = {
                  ...y,
                  textData: [
                    ...y.textData,
                    ...extraOptions.map(() => ({ placeholder: "", data: "" })),
                  ],
                  data: [...y.data, ...extraOptions],
                };
              }
              return item;
            });
            if (!isError && i.extraType === "tests_performed" && !i.isDelete) {
              dataRequiredFields.tests_performed = i.items
                .map((j) => {
                  const el = Element({
                    data: j,
                    mode: modesTemplate.preview,
                    type: j.type,
                    DbData: this.props.dbDataTemplate,
                  });
                  if (!el) return "";
                  return `<span>${el}</span>`;
                })
                .join("");
            }
            let branch = { ...i, items: items };
            if (errorValidation[i.data] || isError) branch.error = true;
            else delete branch.error;
            isError = false;
            return branch;
          });
        body = {
          body: processJson(isFinal),
          status: status,
        };
        if (!this.props.templateData)
          body = {
            ...body,
            common_emr_note: this.props.templateId,
            service_request: this.props.idRequest,
          };

        if (lengthObj(errorValidation)) {
          toast.error("Please fill in all required fields with the valid data");
          this.setState({ errors: errorValidation });
          return;
        }
        if (isFinal) {
          dataRequiredFieldsBody = {
            vital_signs: dataRequiredFields.vital_signs,
            assessments: {
              diagnoses: dataRequiredFields.diagnoses,
              medications: dataRequiredFields.medications,
              assessments_plan: JSON.stringify({
                ...(dataRequiredFields.assessmentsPlanExtra || {}),
                assessments_plan: dataRequiredFields.assessments_plan,
              }),
            },
          };
          if (dataRequiredFields.treatment)
            dataRequiredFieldsBody.treatment = {
              content: dataRequiredFields.treatment,
            };
          if (dataRequiredFields.tests_performed)
            dataRequiredFieldsBody.tests_performed =
              dataRequiredFields.tests_performed;
        }
        this.props
          .createUpdateTemplate(
            body,
            this.props.idRequest,
            !!this.props.templateData,
            dataRequiredFieldsBody,
            this.state.jsonTree,
            this.props.pathRedirect,
            noNavigate,
          )
          .then(() => {
            // this.props.redirectToInfoRequest()
            this.props.getUpdatePMHinfo(
              patientUuid,
              setFormatBE(dataPMH),
              true,
            );
          })
          .then(() => {});
      });
  };

  handleClickPreview = () => {
    this.setState({
      modePreview: true,
    });
  };

  render() {
    return (
      <div
        className="templates-container"
        suppressContentEditableWarning={true}
      >
        {this.state.modePreview && (
          <GenerationPDFnotes
            notesView={["note"]}
            {...this.props}
            dataRequest={this.props.dataRequest?.data}
            callBackAfterRender={this.showPreviewPdf}
            templateData={{ body: this.state.jsonTree }}
          />
        )}
        <div
          className={cn("templates-container_doc", {
            "templates-container_doc_mode-preview": this.state.modePreview,
          })}
        >
          {!!this.props.dbDataTemplate &&
            this.state.jsonTree?.map((i, idx) => (
              <TemplateBLock
                key={idx}
                data={i}
                deleteBlock={(props) =>
                  this.deleteBlock({ ...props, idxItem: idx })
                }
                dataRequest={this.props.dataRequest}
                DbData={this.props.dbDataTemplate}
                error={this.state.errors[i.data]}
                allErrors={this.state.errors}
                updateState={(...args) => this.updateState(...args, idx)}
              />
            ))}
        </div>
        <div className="templates-container_buttons">
          <ButtonV2
            uppercase
            className="templates-container_buttons_preview"
            onClick={this.handleClickPreview}
            variant={VARIATIONS_BTN.TEXT}
            color={COLORS_BTN.PRIMARY}
          >
            <Eye />
            {BTN_LABELS.PREVIEW_DOCUMENT}
          </ButtonV2>
          <div className="container-buttons">
            <ButtonV2
              onClick={() => this.props.redirectToInfoRequest()}
              uppercase
              variant={VARIATIONS_BTN.CONTAINED}
              color={COLORS_BTN.DEFAULT}
            >
              {BTN_LABELS.CANCEL}
            </ButtonV2>
            <div className="container-buttons_save-button">
              <ButtonV2
                className="container-buttons_save-button_button"
                tabIndex="-1"
                uppercase
                variant={VARIATIONS_BTN.CONTAINED}
                color={COLORS_BTN.PRIMARY}
              >
                {BTN_LABELS.SAVE}
              </ButtonV2>
              <div className="container-buttons_save-button_options">
                <ButtonV2
                  uppercase
                  color={COLORS_BTN.PRIMARY}
                  variant={VARIATIONS_BTN.TEXT}
                  onClick={() => this.saveTemplate("draft")}
                >
                  {BTN_LABELS.SAVE_AS_DRAFT}
                </ButtonV2>
                <ButtonV2
                  uppercase
                  color={COLORS_BTN.PRIMARY}
                  variant={VARIATIONS_BTN.TEXT}
                  onClick={() => this.saveTemplate("final")}
                >
                  {BTN_LABELS.SAVE_AS_FINAL}
                </ButtonV2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TemplateBLock = React.memo(
  ({
    data,
    updateState,
    error,
    DbData,
    dataRequest,
    deleteBlock,
    allErrors,
  }) => {
    const items = data?.isDelete ? [] : data.items;
    return (
      <div className="template-block-container">
        <DeleteBox updateState={updateState} data={data}>
          <Element
            mode={modesTemplate.render}
            updateState={updateState}
            data={data.data}
            type={data.type}
            DbData={DbData}
            parentData={data}
            actions={{ deleteBlock: deleteBlock }}
          />
          <div>
            {items.map((i, idx) => {
              if (data.parent) {
                return (
                  <TemplateBLock
                    key={idx}
                    data={i}
                    dataRequest={dataRequest}
                    DbData={DbData}
                    error={allErrors && allErrors[i.data]}
                    updateState={(...args) => {
                      let newData = args[0];
                      if (newData == null) {
                        return updateState(newData, idx);
                      }
                      return updateState(
                        updateArrJson({
                          newData: args[0],
                          idxChild: args[1],
                          idxParent: idx,
                          jsonTree: data.items,
                        })[idx],
                        idx,
                      );
                    }}
                  />
                );
              }
              return (
                <Element
                  key={idx}
                  parentData={data}
                  dataRequest={dataRequest}
                  mode={modesTemplate.render}
                  error={error}
                  type={i.type}
                  updateState={(newData) => {
                    return updateState(newData, idx);
                  }}
                  data={i}
                  DbData={DbData}
                />
              );
            })}
          </div>
        </DeleteBox>
      </div>
    );
  },
  (prev, next) => {
    return (
      _.isEqual(prev.data, next.data) &&
      prev.error === next.error &&
      prev.error === next.error &&
      prev.isDelete === next.isDelete
    );
  },
);
