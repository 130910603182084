import React from "react";
import "./style.scss";
import { Input } from "../../../../Common/Input/Input";
import { DropDown } from "../../../../Common/DropDown/DropDown";
import { InputAddress } from "../../../../Common/Input/InputAddress";
import {
  emailFax,
  placeholdersPCPDoctor,
} from "../../../../../constants/Doctors";
import { Radiobutton } from "../../../../Common/RadioButton/Radiobutton";
import { Typography } from "../../../../Common/Typography/Typography";
import { colors } from "../../../../../assets/colors/colors";

export const AddPCPForm = ({ state, updateState, typesDoctors, errors }) => {
  return (
    <div className="pcp-container-form">
      <div className="pcp-container-form_row">
        <Input
          placeholder={placeholdersPCPDoctor.firstName}
          value={state.firstName}
          field="firstName"
          error={errors["firstName"]}
          maxLength="200"
          onChange={updateState}
        />
        <Input
          placeholder="Last Name, Title"
          value={state.lastName}
          maxLength="200"
          field="lastName"
          error={errors["lastName"]}
          onChange={updateState}
        />
      </div>
      <div className="pcp-container-form_row">
        <DropDown
          creatable
          value={state.providerType}
          field="providerType"
          handlerSelect={(field, value) => {
            updateState(field, {
              value: value.value.substring(0, 200),
              label: value.label.substring(0, 200),
            });
          }}
          label={placeholdersPCPDoctor.providerType}
          options={typesDoctors}
          error={errors["providerType"]}
        />
        <Input
          placeholder={placeholdersPCPDoctor.email}
          value={state.email}
          field="email"
          error={errors["email"]}
          onChange={updateState}
        />
      </div>
      <div className="pcp-container-form_row">
        <Input
          placeholder={placeholdersPCPDoctor.phoneNumber}
          value={state.phoneNumber}
          field="phoneNumber"
          error={errors["phoneNumber"]}
          maskChar="_"
          mask="(999) 999-9999"
          onChange={updateState}
        />
        <Input
          placeholder={placeholdersPCPDoctor.faxNumber}
          value={state.fax}
          field="fax"
          maskChar="_"
          mask="(999) 999-9999"
          error={errors["fax"]}
          onChange={updateState}
        />
      </div>
      <div className="pcp-container-form_row">
        <InputAddress
          placeholder={placeholdersPCPDoctor.address}
          field="address"
          maxLength="200"
          value={state.address}
          onChange={updateState}
        />
        <Input
          placeholder={placeholdersPCPDoctor.suite}
          value={state.suite}
          maxLength="12"
          field="suite"
          error={errors["suite"]}
          onChange={updateState}
        />
      </div>
      <div className="pcp-container-form_row half">
        <Input
          placeholder={placeholdersPCPDoctor.zipCode}
          value={state.zipCode}
          field="zipCode"
          error={errors["zipCode"]}
          mask="99999"
          maxLength="6"
          onChange={updateState}
        />
      </div>
      <div className="pcp-container-form_row-radio">
        <Typography
          variant="title"
          text="Send by default:"
          color={colors.grey}
          className="pcp-container-form_row-radio_title"
        />
        {emailFax.map((i, index) => (
          <Radiobutton
            label={i.label}
            key={index}
            onClick={() => updateState("contactType", i)}
            checked={i.value === state.contactType.value}
          />
        ))}
      </div>
    </div>
  );
};
