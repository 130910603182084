import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const twilioSlice = createSlice({
  name: "twilioSlice",
  initialState,
  reducers: {
    setTwilioClient: (state, { payload }) => {
      state.client = payload;
    },
    clearTwilioSlice: (state) => {
      state.client = [];
    },
  },
});

export const { setTwilioClient, clearTwilioSlice } = twilioSlice.actions;
export const twilioReducer = twilioSlice.reducer;
