import { fetchWrapper } from "helpers/helpers";
import { converterDataField } from "../../utils/common";
import { setCurrentUser } from "./slice";
import { USER_ROLES } from "./constants";

const getCurrentUser = (role) => (dispatch) =>
  fetchWrapper({
    url:
      role === USER_ROLES.DOCTOR
        ? "doctors/current/"
        : `users/${window.localStorage.getItem("user_uuid")}/`,
  })
    .then((resp) => processResponse(resp, role, dispatch))
    .catch((resp) => resp);

const updateCurrentUser = (role, body) => (dispatch) =>
  fetchWrapper({
    url:
      role === USER_ROLES.DOCTOR
        ? "doctors/current/"
        : `users/${window.localStorage.getItem("user_uuid")}/`,
    body: body,
    method: "PUT",
  })
    .then((resp) => processResponse(resp, role, dispatch))
    .catch((resp) => resp);

const processResponse = (resp, role, dispatch) => {
  if (!resp.uuid) {
    return;
  }
  let data;
  if (role === USER_ROLES.PATIENT) {
    data = converterDataField(
      {
        ...resp,
        suite: resp.home_address?.apartment,
        home_address: resp.home_address && {
          address: resp.home_address.address_line,
          latLng: null,
        },
      },
      true,
    );
  }
  if (role === USER_ROLES.DOCTOR) {
    data = {
      ...converterDataField(resp, true),
      user: converterDataField(resp.user, true),
    };
    data.uploadApplications = data.uploadApplications.map((i) =>
      converterDataField(i, true),
    );
  }
  if (role === USER_ROLES.ADMIN) {
    data = converterDataField(resp, true);
  }

  dispatch(setCurrentUser({ data: data, role: role }));
  return data;
};

export { getCurrentUser, updateCurrentUser };
