import * as components from "./components";

export const template = {
  name: "DVT",
  body: {
    body: [
      {
        type: "label",
        data: "PRESENTING PROBLEM:",
        items: [
          {
            type: "text",
            data: "Leg pain (DVT)",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "HISTORY OF THE PRESENT ILLNESS:",
        required: true,
        items: [
          ...components.PatientData(),
          {
            type: "text",
            data: "who reports",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "mild" },
              { checked: false, data: "mod" },
              { checked: false, data: "severe" },
            ],
          },
          {
            type: "text",
            data: "pain and swelling of",
            editable: true,
            source: null,
          },
          {
            type: "radio-block",
            data: [
              { checked: false, data: "left" },
              { checked: false, data: "right" },
              { checked: false, data: "both" },
            ],
          },
          {
            type: "text",
            data: "leg",
            editable: true,
            source: null,
          },
          {
            type: "input",
            placeholder: "duration",
            data: "",
          },
          {
            type: "text",
            data: ". Otherwise:",
            editable: true,
            source: null,
          },
          {
            type: "checkbox",
            data: [
              { checked: false, data: "trauma" },
              { checked: false, data: "chest pain" },
              { checked: false, data: "dyspnea" },
              { checked: false, data: "hemoptysis" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: true,
            source: null,
          },
        ],
      },
      {
        type: "label",
        data: "RISK FACTORS FOR DVT:",
        items: [
          {
            type: "checkbox",
            data: [
              { checked: false, data: "prior thromboembolic disease" },
              { checked: false, data: "prolonged immobility or travel" },
              { checked: false, data: "CHF" },
              { checked: false, data: "known malignancy" },
            ],
          },
          {
            type: "text",
            data: ".",
            editable: false,
            source: null,
          },
        ],
      },
      components.ReviewOfSystem(),
      components.PMH(),
      components.GYM(),
      components.FamSocHistory(),
      components.Medications(),
      components.Allergies(),
      components.PhysicianExamination({
        items: [
          components.GeneralizedAppearance(),
          components.VitalSigns(),
          {
            type: "label",
            data: "Skin:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Warm, dry;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks(["cyanosis", "rash"]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Head:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "scalp swelling",
                "tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          components.Eyes(
            components.RenderRadioSingleBLocks(["scleral icterus"], true),
          ),
          {
            type: "label",
            data: "ENMT:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Pharynx:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "erythema" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "airway patent:",
                editable: true,
                source: null,
              },
              {
                type: "radio-single",
                data: [{ checked: false, data: "stridor" }],
              },
              {
                type: "text",
                data: "; ",
                editable: true,
                source: null,
              },
              {
                type: "text",
                data: "mucous membranes moist.",
                editable: true,
                source: null,
              },
            ],
          },
          components.Neck(
            components.RenderRadioSingleBLocks(["thyromegaly"], true),
          ),
          {
            type: "label",
            data: "Chest/Respiratory:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              ...components.RenderRadioSingleBLocks([
                "rales",
                "rhonchi",
                "wheezes",
                "pleural friction rub",
              ]),
              {
                type: "text",
                data: "; breath sounds equal bilaterally.",
                editable: true,
                source: null,
              },
            ],
          },
          components.HeartCardiovascular(
            components.RenderRadioSingleBLocks(["pericardial rub"], true),
          ),

          {
            type: "label",
            data: "Abdomen/GI:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Soft;",
                editable: true,
                source: null,
              },
              ...components.RenderRadioSingleBLocks([
                "tenderness",
                "guarding",
                "rebound",
                "palpable masses",
                "CVA tenderness",
              ]),
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Extremities:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "minimal" },
                  { checked: false, data: "mild" },
                  { checked: false, data: "moderate" },
                ],
              },
              {
                type: "text",
                data: "swelling and tenderness of",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "leg" },
                  { checked: false, data: "calf" },
                  { checked: false, data: "thigh" },
                ],
              },
              {
                type: "text",
                data: "with",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "no" },
                  { checked: false, data: "minimal" },
                  { checked: false, data: "moderate" },
                ],
              },
              {
                type: "text",
                data: "distal edema, and no palpable cord. Opposite leg: Normal. Distal pulses:",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "norm" },
                  { checked: false, data: "absent" },
                ],
              },
              {
                type: "text",
                data: ".",
                editable: true,
                source: null,
              },
            ],
          },
          {
            type: "label",
            data: "Neuro/Psych:",
            canDelete: true,
            items: [
              {
                type: "input",
                placeholder: "",
                data: "",
              },
              {
                type: "text",
                data: "Mental status as above. Cranial nerves grossly intact; strength symmetric.",
                editable: true,
                source: null,
              },
            ],
          },
        ],
      }),
      components.AssessmentPlan([
        {
          type: "blockCheckboxCondition",
          extraType: "assessmentContent",
          data: {
            checkbox: [{ checked: true, data: "", isHide: true }],
            hiddenBlock: [
              {
                type: "text",
                data: "Patient's condition stable during evaluation. ED was contacted. Patient was sent to the nearest ED,",
                editable: true,
                source: null,
              },
              {
                type: "input",
                placeholder: "name of ED",
                data: "",
              },
              {
                type: "text",
                data: "via",
                editable: true,
                source: null,
              },
              {
                type: "radio-block",
                data: [
                  { checked: false, data: "ambulance" },
                  { checked: false, data: "private" },
                ],
              },
              {
                type: "text",
                data: "car.",
                editable: true,
                source: null,
              },
            ],
          },
        },
      ]),
    ],
  },
};
