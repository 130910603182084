import React, { useState } from "react";
import "./style.scss";
import { DropDown } from "../DropDown/DropDown";
import { Trash } from "../../../../Common/Icons/trash";
import { Input } from "../Input";
import { Plus } from "../../../../Common/Icons";
import { VeradigmButton } from "../VeradigmButton";
import { Textarea } from "../../../../Common/TextArea/Textarea";
import { ButtonV2 } from "../../../../Common/ButtonV2";
import {
  COLORS_BTN,
  VARIATIONS_BTN,
} from "../../../../Common/ButtonV2/constants";
export const PMHBlock = ({ updateState, label, data, error, dataRequest }) => {
  const [valueTextArea, setValueTextArea] = useState(data.textAreaData || "");
  const handlerAdd = () => {
    updateState({
      ...data,
      data: [...data.data, null],
      textData: [...data.textData, { placeholder: "", data: "" }],
    });
  };
  const handlerDelete = (index) => {
    const newData = {
      ...data,
      data: data.data.filter((i, idx) => idx !== index),
      textData: data.textData.filter((i, idx) => idx !== index),
    };
    if (newData.data.length === 0) {
      newData.data = [null];
      newData.textData = [{ placeholder: "", data: "" }];
    }
    updateState(newData);
  };

  const updateText = (value, index) =>
    updateState({
      ...data,
      textData: data.textData.map((i, idx) => (idx === index ? value : i)),
    });

  const updateSelector = (value, index) => {
    updateState({
      ...data,
      data: data.data.map((i, idx) => (idx === index ? value.data : i)),
    });
  };

  const updateDataBlock = () => {
    updateState({ ...data, textAreaData: valueTextArea });
  };

  let optionsView = data.data;

  const useTextArea = data.hasOwnProperty("textAreaData");
  const isMed = data.type === "medications";
  if (isMed) optionsView = optionsView.filter((i) => i.status === "active");
  return (
    <div className="pmh-block">
      {useTextArea && (
        <Textarea
          maxLength={2000}
          placeholder={data.placeholderTextArea}
          value={valueTextArea}
          onBlur={updateDataBlock}
          onChange={(e) => {
            setValueTextArea(e.target.value);
          }}
        />
      )}
      {optionsView.map((i, index, arr) => (
        <div
          className="pmh-block_item"
          style={{ marginBottom: arr.length - 1 === index ? 10 : 0 }}
        >
          {!data.noTextField && (
            <Input
              styles={{ maxWidth: "70%" }}
              updateState={(value) => {
                updateText(value, index);
              }}
              data={data.textData[index]}
            />
          )}
          <DropDown
            data={{
              data: i,
              options: null,
              label: label,
            }}
            disabled={data.disabled}
            creatable={data.creatable}
            urlOptions={data.urlOptions}
            proceedOptions={data.proceedOptions}
            error={error && !i && data.type !== "medicationsPrescribed"}
            updateState={(value) => updateSelector(value, index)}
            // error={error && !i}
          />
          {!data.noAddOptions && (
            <Trash
              onClick={() => {
                handlerDelete(index);
              }}
            />
          )}
        </div>
      ))}

      {data.veradigm ? (
        <VeradigmButton dataRequest={dataRequest} title="Go To Veradigm" />
      ) : data.noAddOptions ? (
        <></>
      ) : (
        <ButtonV2
          onClick={handlerAdd}
          variant={VARIATIONS_BTN.TEXT}
          color={COLORS_BTN.PRIMARY}
          className="pmh-block_add-button"
        >
          <Plus className="" />
          {data.textAddButton}
        </ButtonV2>
      )}
    </div>
  );
};
